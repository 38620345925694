import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import { CardDetail } from "../../../../component/atoms/CardDetail"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../component/atoms/Tabel"
import { clearDataFO, getListByMitra } from "../../../../redux/actions/FieldOfficerAction"
import { DetailSvgIcon } from "../../../../component/atoms/Icons/SVG"
import { TableSkeleton } from "../../../../component/index.jsx"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { FIELD_OFFICER_ROLE_ENUM } from "../../../../constants/StringConstant.js"
import { toCapitalize } from "../../../helpers/TextHelper.js"

export function CardDetailMitra({
  titleCardDetail,
  mitraType,
  titleImage,
  descriptionLeft,
  flatIconAndText2Rows,
  data,
  descriptionRight,
  history,
  status
}) {
  const dispatch = useDispatch()
  const { General, FieldOfficer } = useSelector((state) => state)

  const changePage = useCallback(
    (param) => {
      dispatch(getListByMitra({ id: data.id, pageReq: param }))
    },
    [dispatch, data]
  )

  useEffect(() => {
    if (data?.id) {
      dispatch(getListByMitra({ id: data.id, pageReq: initialFilterTable }))

      return () => {
        dispatch(clearDataFO())
      }
    }
  }, [data])

  useEffect(() => {
    if (data?.id) {
      dispatch(getListByMitra({ id: data.id, pageReq: initialFilterTable }))
    }
  }, [dispatch])

  const uiCode = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder  mb-1 font-size-lg">{e.code ?? "-"}</p>
      </>
    )
  }
  const uiName = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.name ?? "-"}</p>
        <span className="text-muted d-block">{e.phone ?? "-"}</span>
      </>
    )
  }

  const uiAddress = (e) => {
    return (
      <>
        <p className="text-dark mb-1">{e.full_address ?? "-"}</p>
      </>
    )
  }
  const uiAction = (e) => {
    return (
      <>
        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Lihat Detail</Tooltip>}>
          <div
            className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
            onClick={() =>
              history.push(
                RoutesConstants.FIELD_OFFICER.DETAIL_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER, e.id)
              )
            }>
            <DetailSvgIcon />
          </div>
        </OverlayTrigger>
      </>
    )
  }
  const columns = [
    {
      dataField: "code",
      text: "ID FO",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "name",
      text: "NAMA",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      }
    },
    {
      dataField: "full_address",
      text: "ALAMAT",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAddress
      }
    },

    {
      dataField: "action",
      text: "Actions",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  const rejectedCard = () => {
    if (status == "rejected") {
      return (
        <Card className="mb-4 reject-list-card">
          <Card.Header>
            <h4>Alasan Penolakan</h4>
          </Card.Header>
          <Card.Body>
            <div>{toCapitalize((data?.rejected_reason ?? "").toLowerCase())}</div>
          </Card.Body>
        </Card>
      )
    }
  }

  const penanggungJawab = () => {
    if (status == "active") {
      return (
        <RowModule>
          <Card className={"card-custom"}>
            <CardHeader title={"Penanggung Jawab"} />
            <CardBody>
              <div className={"row"}>
                <div className={"col-md-3"}>
                  <div className={"color-gray space-1"}>Nama</div>
                  <div className={"text-dark font-weight-bolder mt-1 font-size-lg"}>
                    {data?.name}
                  </div>
                </div>
                <div className={"col-md-3"}>
                  <div className={"color-gray space-1"}>No. KTP</div>
                  <div className={"text-dark font-weight-bolder mt-1 font-size-lg"}>
                    {data?.ktp_no}
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </RowModule>
      )
    }
  }

  return (
    <>
      {data?.rejected_reason && status === "rejected" && rejectedCard()}
      {data?.mitra_activation?.toLowerCase() === "inactive" && status === "inactive" && (
        <RowModule>
          <Card style={{ background: "#F8F8F8" }}>
            <CardHeader title={"Status Change Reason"} className="mb-0 pb-2 bg-gray-card" />
            <CardBody>{data?.rejected_reason}</CardBody>
          </Card>
        </RowModule>
      )}
      <RowModule>
        <CardDetail
          kurBNI={data?.has_kur}
          kurBNIImageurl={data?.bank_image}
          joinDate={data?.join_date}
          titleImage={titleImage}
          titleCardDetail={titleCardDetail}
          descriptionLeft={descriptionLeft}
          descriptionRight={descriptionRight}
          flatIconAndText2Rows={flatIconAndText2Rows}
        />
      </RowModule>
      {mitraType === "EDUCATIONAL_INSTITUTIONS"
        ? penanggungJawab()
        : mitraType === "GOVERNMENT_AGENCIES"
        ? penanggungJawab()
        : mitraType === "PRIVATE_COMPANIES"
        ? penanggungJawab()
        : ""}
      {General.loading ? (
        <TableSkeleton column={5} row={5} />
      ) : FieldOfficer.pageData ? (
        status === "active" &&
        FieldOfficer.pageData.length > 0 && (
          <RowModule withoutSeparator={true}>
            <Card className={"card-custom"}>
              <CardHeader title={"Field Officer"}>
                {/* <p>{dummyData.length} orang</p> */}
              </CardHeader>
              <CardBody>
                <TableView
                  columnProperties={columns}
                  dataTable={FieldOfficer.pageData}
                  currentPage={FieldOfficer.page.currentPage}
                  currentElement={FieldOfficer.page.currentElement}
                  totalCount={FieldOfficer.page.totalCount}
                  loadingGetData={General.loading}
                  callbackAfterPageChange={changePage}
                />
              </CardBody>
            </Card>
          </RowModule>
        )
      ) : null}
    </>
  )
}
