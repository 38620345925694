import React, { useCallback, useEffect, useRef, useState } from "react"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../component/atoms/Tabel"
import { useSubheader } from "../../../_metronic/layout"
import { EmptyCard, Search, TableSkeleton } from "../../../component"
import { Button, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Card, CardBody } from "../../../_metronic/_partials/controls"
import { PRIVILEGE_ENUM } from "../../../config/privilegeCollection"
import { DeleteSvgIcon, DetailSvgIcon } from "../../../component/atoms/Icons/SVG"
import { loadUserLogin } from "../../service/userManagementAction"
import { useDispatch, useSelector } from "react-redux"
import { getListBroadcast, removeBroadcast } from "../../../redux/actions/BroadcastAction"
import { timeStampToDate } from "../../helpers/DateHelper"
import { PopupQuestion } from "../../../component/atoms/Popup"
import swal from "sweetalert"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { ILUSTRATION_CONSTANS } from "../../../constants/ImageConstants"
import { linkWithCtrl } from "../../helpers/Utils"
import { useTranslation } from "react-i18next"

const BroadcastList = ({ history }) => {
  const { t } = useTranslation()
  const privileges = loadUserLogin().privilege
  const dispatch = useDispatch()
  const subHeader = useSubheader()
  const { General, Broadcast } = useSelector((state) => state)
  const formRef = useRef()
  const [showPopup, setShowPopup] = useState(false)
  const [idDelete, setIdDelete] = useState("")
  const [isDelete, setIsDelete] = useState(false)
  const [search, setSearch] = useState({
    string_filter: ""
  })

  useEffect(() => {
    dispatch(getListBroadcast({ pageReq: initialFilterTable }))
  }, [dispatch])

  useEffect(() => {
    subHeader.setButton(false)
    subHeader.setTitle("Broadcast Message")
  }, [])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (isDelete) {
        swal({
          text: t("messages.response.broadcast_success_delete"),
          icon: toAbsoluteUrl(ILUSTRATION_CONSTANS.BROADCAST_SUCCESS_DELETE)
        })
        dispatch(getListBroadcast({ pageReq: initialFilterTable }))
      }
    }
  }, [General.isFinishUpdateOrDelete])

  const changePage = useCallback(
    (param) => {
      let newParam = {
        ...search,
        ...param
      }
      dispatch(getListBroadcast({ pageReq: newParam }))
    },
    [dispatch, search]
  )

  const handlerRemove = async () => {
    dispatch(removeBroadcast(idDelete))
    setShowPopup(false)
  }

  const searchFunc = (value) => {
    let param = {
      ...initialFilterTable,
      string_filter: value.string_filter
    }
    setSearch({
      string_filter: value.string_filter
    })

    dispatch(getListBroadcast({ pageReq: param }))
  }

  const handleResetSearch = () => {
    setSearch({
      string_filter: ""
    })
    let param = {
      ...initialFilterTable,
      string_filter: ""
    }
    dispatch(getListBroadcast({ pageReq: param }))
  }

  const uiTitle = (e) => {
    return (
      <>
        <span className="text-dark font-weight-bolder mb-1 font-size-lg">{e.title}</span>
      </>
    )
  }

  const uiDate = (e) => {
    return (
      <>
        <div className="mb-1">{timeStampToDate(e?.submitted_time, "dd-MM-yyyy HH:mm:ss")}</div>
      </>
    )
  }

  const uiAction = (e) => {
    return (
      <>
        {privileges.indexOf(PRIVILEGE_ENUM.BROADCAST_MESSAGE_DETAIL) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-detail-tooltip">Detail</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-1"
              onClick={() => history.push("/broadcast/detail/" + e.id)}>
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.BROADCAST_MESSAGE_REMOVE) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Hapus</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-1"
              onClick={() => {
                setShowPopup(true)
                setIdDelete(e.id)
                setIsDelete(true)
              }}>
              <DeleteSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </>
    )
  }

  const columns = [
    {
      dataField: "title",
      text: "HEADLINE",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiTitle
      },
      headerStyle: () => {
        return { width: "50%" }
      }
    },
    {
      dataField: "receiver",
      text: "RECEIVER",
      formatter: formatterRowComponent
    },
    {
      dataField: "submitted_time",
      text: "SUBMITTED TIME",
      formatExtraData: {
        externalStyle: uiDate
      },
      formatter: formatterRowComponent
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  return (
    <div>
      <PopupQuestion
        onCancel={() => {
          setShowPopup(false)
          setIsDelete(false)
        }}
        title={"HAPUS BROADCAST MESSAGE"}
        onOk={handlerRemove}
        variantOkButton={"danger"}
        show={showPopup}
        bodyCustom={
          <Col>
            <p className={"text-center"}>
              Apakah anda yakin menghapus Broadcast Message? Broadcast Message yang sudah terhapus
              <strong> akan hilang.</strong>
            </p>
          </Col>
        }
      />
      <div className={"filter-container"}>
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            placeholder={"Masukkan headline"}
            onSubmitForm={searchFunc}
            initialValues={search}
            name={"string_filter"}
            innerRef={formRef}
            trigerReset={handleResetSearch}
          />
          <Button
            variant="primary"
            onClick={() => {
              history.push("/broadcast/new")
            }}>
            Tambah
          </Button>
        </div>
      </div>

      <>
        <Card>
          <CardBody>
            {General.loading ? (
              <TableSkeleton column={4} row={10} />
            ) : Broadcast?.pageData?.length === 0 ? (
              <EmptyCard body={t("messages.response.empty_state")} />
            ) : (
              ""
            )}
            <TableView
              callbackAfterPageChange={changePage}
              columnProperties={columns}
              dataTable={Broadcast?.pageData}
              currentPage={Broadcast?.page?.currentPage}
              currentElement={Broadcast?.page?.currentElement}
              totalCount={Broadcast?.page?.totalCount}
              loadingGetData={General.loading}
              rowClasses={() => {
                return "cursor-pointer"
              }}
              rowEvents={{
                onClick: (e, row) => {
                  if (
                    privileges.indexOf(PRIVILEGE_ENUM.BROADCAST_MESSAGE_DETAIL) > -1 &&
                    e.target.cellIndex < 3
                  ) {
                    const url = "/broadcast/detail/" + row.id
                    if (e.ctrlKey) {
                      linkWithCtrl(url)
                    } else {
                      history.push(url)
                    }
                  }
                }
              }}
            />
          </CardBody>
        </Card>
      </>
    </div>
  )
}

export default BroadcastList
