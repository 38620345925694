import React, { useEffect, useState } from "react"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { parse } from "node-html-parser"
import {
  FORM_NEED,
  getMaximumErrorString,
  getStringErrorMassage
} from "../../../util/constStringCollection"
import { VARIABLE_CONSTANT } from "../../../constants/VariableConstant"

export const CkEditorCustom = ({ tool, label, data, onChange, counter }) => {
  const [counterValue, setCounterValue] = useState(0)
  const [invalid, setInvalid] = useState(false)
  const [validationStringBlur, setValidationStringBlur] = useState(false)
  const validationString = getMaximumErrorString(VARIABLE_CONSTANT.MAX_CARACTER, "Hello")
  const validationNeed = getStringErrorMassage("Body", FORM_NEED.harusDiIsi)
  const editorConfiguration = {
    toolbar: {
      items: tool,
      shouldNotGroupWhenFull: true
    }
  }

  useEffect(() => {
    const dataParse = parse(data).text
    setCounterValue(dataParse.length)
    if (counterValue >= counter) {
      setInvalid(true)
    } else {
      setInvalid(false)
    }
    if (data.length < 1) {
      setValidationStringBlur(true)
    } else {
      setValidationStringBlur(false)
    }
  }, [data, counterValue])
  return (
    <div className="CkEditor">
      <p>{label}</p>
      <div className={`border ${invalid || validationStringBlur ? "border-danger" : ""}`}>
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={data}
          onChange={onChange}
          onReady={() => setValidationStringBlur(false)}
        />
      </div>
      <div className={"d-flex justify-content-between w-100"}>
        {validationStringBlur ? (
          <div className={"text-danger w-100"}>{validationNeed}</div>
        ) : invalid ? (
          <div className={"text-danger w-100"}>{validationString}</div>
        ) : (
          ""
        )}
        <div className={"font-10 text-right w-100"}>
          {counterValue}/{counter}
        </div>
      </div>
    </div>
  )
}
