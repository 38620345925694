import React, { Fragment, useEffect, useState } from "react"
import { timeStampToDate } from "../../../helpers/DateHelper"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { Popup, PopupImage } from "../../../../component/atoms/Popup"
import { DetailOrderRowText } from "../DetailsOrder"
import { TableView } from "../../../../component"
import { formatterRowComponent } from "../../../../component/atoms/Tabel"
import { ORDER_STATUS_ENUM } from "../../../../constants/StringConstant"
import { Skeleton } from "@material-ui/lab"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { checkCommaInDataPic } from "../../../helpers/TextHelper.js"

export const SectionDetailOrderActivity = ({ data }) => {
  const [previewImage, setPreviewImage] = useState(undefined)
  const [openPopupTableList, setOpenPopupTableList] = useState(false)
  const [dataListMember, setDataListMember] = useState([])
  const status = data?.status_enum
  const { General } = useSelector((state) => state)
  const { t } = useTranslation()

  useEffect(() => {
    if (data.pic) {
      setDataListMember(data?.pic)
    }
  }, [data])

  const uiAnggota = (e) => {
    return (
      <Fragment className={"font-weight-bolder"}>
        {e?.member.map((item, i) => {
          if (i === e?.member.length - 1) return item
          else return item + ", "
        })}
      </Fragment>
    )
  }

  const columns = [
    {
      dataField: "name",
      text: "NAMA TIM",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      headerStyle: () => {
        return { width: "25%" }
      }
    },
    {
      dataField: "member",
      text: "ANGGOTA",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAnggota
      }
    }
  ]

  function uiName(e) {
    return <div className={"font-weight-bolder"}>{e?.name ?? "-"}</div>
  }

  function dataPic() {
    return (
      <>
        {
          <div>
            {dataListMember.map((item, i) => (
              <>
                {i < 2 && (
                  <Fragment>{`${item?.name} (${item?.member.map((mem, i) => {
                    if (i === item?.member?.length - 1) {
                      if (item?.member?.length === 1) return mem
                      else return " " + mem
                    } else return mem
                  })})${checkCommaInDataPic(i, dataListMember.length) ? "," : ""} `}</Fragment>
                )}
              </>
            ))}
            {dataListMember.length > 2 && (
              <span
                onClick={() => setOpenPopupTableList(true)}
                className={"text-success font-weight-bolder  underline-hover cursor-pointer"}
                style={{ fontSize: 12, textDecoration: "underline" }}>
                +{dataListMember.length - 2} Lainnya
              </span>
            )}
          </div>
        }
      </>
    )
  }

  function bodyPopupTable() {
    return (
      <div className={"table_list_popup_schedule table_hidden_bottom"}>
        <TableView dataTable={dataListMember} loadingGetData={false} columnProperties={columns} />
      </div>
    )
  }

  function checkKondisiLahan() {
    if (data?.is_additional) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <Popup
        contentClassName={"modal-main modal_detail_team_customs"}
        show={openPopupTableList}
        titleCustom={<h1>Anggota Tim</h1>}
        closeButton={true}
        showButton={false}
        onClick={() => setOpenPopupTableList(false)}
        body={bodyPopupTable()}
        size={"lg"}
        onCancel={() => {
          setOpenPopupTableList(false)
        }}
      />

      <PopupImage
        show={!!previewImage}
        images={previewImage}
        type={"image-preview"}
        onCancel={() => setPreviewImage(null)}
      />
      <div className={"col-xl-10"}>
        <h4>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={150} />
          ) : data?.is_before_planting ? (
            "2. DATA AKTIVITAS"
          ) : (
            "3. DATA AKTIVITAS"
          )}
        </h4>
        <RowModule customColumnCss={" mb-2 mt-4 col-6"}>
          <div className={"p-0"}>
            {(status === ORDER_STATUS_ENUM.PENDING || status === ORDER_STATUS_ENUM.REJECTED) && (
              <DetailOrderRowText
                label={"Pengajuan Tanggal Aktivitas"}
                value={
                  data?.submission_date
                    ? timeStampToDate(data?.submission_date, "dd LLLL yyyy") ?? "-"
                    : "-"
                }
              />
            )}

            {(status === ORDER_STATUS_ENUM.APPROVED || status === ORDER_STATUS_ENUM.CANCELED) && (
              <DetailOrderRowText
                label={
                  data?.reschedule_detail?.reschedule_date
                    ? t("label.first_scheduled_date")
                    : t("label.scheduled_date")
                }
                value={
                  data?.activity_date ? timeStampToDate(data?.activity_date, "dd MMMM yyyy") : "-"
                }
              />
            )}

            {(status === ORDER_STATUS_ENUM.APPROVED || status === ORDER_STATUS_ENUM.CANCELED) && (
              <DetailOrderRowText
                label={
                  data?.reschedule_detail?.reschedule_date ? t("label.first_pic") : t("label.pic")
                }
                value={data?.pic ? dataPic() : "-"}
              />
            )}
            {(status === ORDER_STATUS_ENUM.APPROVED || status === ORDER_STATUS_ENUM.CANCELED) && (
              <>
                <DetailOrderRowText
                  showTooltip={true}
                  tooltipMessage={"Daerah Regional keuangan untuk pelaporan kegiatan"}
                  label={"Regional"}
                  value={data?.regional ?? "-"}
                />
              </>
            )}
            {checkKondisiLahan() && (
              <DetailOrderRowText
                label={
                  data?.is_before_planting
                    ? t("label.scheduled_reason")
                    : t("label.land_conditions")
                }
                value={data?.note ?? "-"}
              />
            )}
          </div>
        </RowModule>
        {data.image && (
          <>
            <div>{t("label.supporting_photo")}</div>
            <div
              className={"d-flex  align-items-center mt-4"}
              style={{ gap: 14, width: "fit-content" }}>
              {data.image.map((item, i) => (
                <>
                  <div
                    style={{
                      width: 128,
                      height: 128
                    }}>
                    <img
                      onClick={() => setPreviewImage(item)}
                      alt={item}
                      src={item}
                      key={i}
                      className={"h-100 w-100 rounded"}
                    />
                  </div>
                </>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}
