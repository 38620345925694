import React, { useEffect, useState } from "react"
import { useSubheader } from "../../../../_metronic/layout"
import { useDispatch, useSelector } from "react-redux"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { DetailText, RowText } from "../../../../component"
import { getDetailSeedling, updateAreaActivityImage } from "../../../../redux/actions/AreaAction"
import { timeStampToDate } from "../../../helpers/DateHelper"
import { toMeter } from "../../../helpers/TextHelper"
import { AREA } from "../../../../constants/InitTypeConstants"
import { RoutesConstants } from "../../../../constants/RoutesConstants"
import { ActivityDetailHeader } from "../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader"
import { ACTIVITY_ICONS } from "../../../../constants/ImageConstants"

export const DetailActivitySemai = ({
  match: {
    params: { status, id, activity_id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { MasterData, General, Area } = useSelector(state => state)
  const [detail, setDetail] = useState({})
  const [idDocument, setIdDocument] = useState(null)

  useEffect(() => {
    dispatch(getDetailSeedling(activity_id))
  }, [])

  useEffect(() => {
    if (Area.data) {
      setDetail(Area.data)
    }
  }, [Area])

  useEffect(() => {
    if (detail) {
      let newStatus
      if (status === "active") {
        newStatus = "teregistrasi"
      }
      subHeader.setButton(null)
      subHeader.setBreadcrumbs([
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: "Lahan"
        },
        {
          pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
          title: newStatus
        },
        {
          pathname: "/lahan/list/" + status + "/" + id,
          title: detail?.activity_detail_response?.area_name
        },
        {
          title: "Semai"
        }
      ])
    }
  }, [detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === "UPLOAD") {
        const data = {
          id: detail?.activity_detail_response?.image_and_id_activity[idDocument]?.id,
          url: MasterData.urlResponseUpload
        }

        dispatch(updateAreaActivityImage(data))
      } else if (General.actionInitType === AREA.UPDATE_ACTIVITY_IMAGE) {
        dispatch(getDetailSeedling(activity_id))
      }
    }
  }, [General])

  const titleActivity = (
    <ActivityDetailHeader
      icon={ACTIVITY_ICONS.IC_SEMAI}
      title={` AKTIVITAS Semai KE-${detail?.activity_detail_response?.seq}`}
    />
  )
  const detailActivitas = (
    <>
      <Card>
        <CardBody>
          <h4 className={"mb-6 text-uppercase"}>INFORMASI LAHAN</h4>
          <RowModule customColumnCss={"col-md-6 mb-2"}>
            <RowModule>
              <RowText label={"Lahan"} value={detail?.area_information?.area_name ?? "-"} />
            </RowModule>
            <RowModule>
              <RowText
                label={"Luas Lahan (Manual)"}
                value={
                  detail?.area_information?.area_land_manual
                    ? toMeter(detail?.area_information?.area_land_manual)
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                hasIcon={detail?.area_information?.has_kur}
                icon={detail?.area_information?.bank_image}
                label={"Petani"}
                value={
                  detail?.area_information?.farmer_name
                    ? `${detail?.area_information?.farmer_name} (${detail?.area_information?.farmer_code})`
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Luas Lahan (Polygon)"}
                value={
                  detail?.area_information?.area_land_polygon
                    ? toMeter(detail?.area_information?.area_land_polygon)
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Mitra"}
                value={
                  detail?.area_information?.mitra_name
                    ? `${detail?.area_information?.mitra_name} (${detail?.area_information?.mitra_code})`
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Jenis Tanaman"}
                value={detail?.area_information?.plant_type_name ?? "-"}
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Lokasi Lahan"}
                value={detail?.area_information?.full_address ?? "-"}
              />
            </RowModule>
          </RowModule>
        </CardBody>
      </Card>

      <Card>
        {" "}
        <CardBody>
          <h4 className={"mb-6 mt-6 text-uppercase"}>Detail Aktivitas</h4>
          <RowModule customColumnCss={"col-md-6 mb-2"}>
            <RowModule>
              <RowText
                label={"Submitted by"}
                value={detail?.activity_detail_response?.submitted_by ?? "-"}
              />
            </RowModule>
            <RowModule>
              <RowText label={"Asal Benih"} value={detail?.seed_source_name ?? "-"} />
            </RowModule>
            <RowModule>
              <RowText
                label={"Submitted time"}
                value={
                  detail?.activity_detail_response?.submitted_date
                    ? timeStampToDate(
                        detail?.activity_detail_response?.submitted_date,
                        "dd-MM-yyyy HH:mm:ss"
                      )
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Jenis Varietas"}
                value={detail?.area_information?.variety_name ?? "-"}
              />
            </RowModule>

            <RowModule>
              <RowText label={"Semai ke"} value={detail?.activity_detail_response?.seq ?? "-"} />
            </RowModule>
            <RowModule>
              <RowText
                label={"Jumlah Benih"}
                value={detail?.seed_qty ? `${detail?.seed_qty} Kg` : "-"}
              />
            </RowModule>
            <RowModule>
              <RowText
                label={"Requested by"}
                value={detail?.activity_detail_response?.request_by_string ?? "-"}
              />
            </RowModule>
            <RowModule>
              <RowText label={"Jenis Persemaian"} value={detail?.seed_method_string ?? "-"} />
            </RowModule>
            <RowModule>
              <RowText
                label={"Tanggal Aktivitas"}
                value={
                  detail?.activity_detail_response?.activity_date
                    ? timeStampToDate(detail?.activity_detail_response?.activity_date, "dd-MM-yyyy")
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText label={"Kepemilikan Alsintan"} value={detail?.owner_tool_string ?? "-"} />
            </RowModule>
            <RowModule>
              <RowText
                label={"HST"}
                value={`${detail?.activity_detail_response?.hst} HST` ?? "-"}
              />
            </RowModule>
          </RowModule>
          <div className={"my-10"}>
            <h4 className={"mb-6"}>CATATAN</h4>
            <div className={"row mb-4"}>
              {detail?.activity_detail_response?.image_and_id_activity &&
                detail?.activity_detail_response?.image_and_id_activity.map((item, index) => {
                  return (
                    <div className={"col-md-3"} key={index}>
                      <DetailText
                        type="image"
                        value={item.url}
                        indexKey={index}
                        menu={AREA.ACTIVITY_SEEDLING}
                        setIdDocument={setIdDocument}
                      />
                    </div>
                  )
                })}
            </div>
            <span className="font-weight-bold text-dark-50 font-size-sm">
              {detail?.activity_detail_response?.note ?? "-"}
            </span>
          </div>
        </CardBody>
      </Card>
    </>
  )

  return (
    <>
      <div className={"d-flex flex-column"} style={{ gap: 12 }}>
        {titleActivity}
        {detailActivitas}
      </div>
    </>
  )
}
