import { loadUserLogin } from "../../../../service/userManagementAction"
import { useSubheader } from "../../../../../_metronic/layout"
import React, { useEffect, useState } from "react"
import { PRIVILEGE_ENUM } from "../../../../../config/privilegeCollection"
import { Badge, Button, Card } from "react-bootstrap"
import { RoutesConstants } from "../../../../../constants/RoutesConstants"
import { formatterRowComponent } from "../../../../../component/atoms/Tabel"
import { sortCaret } from "../../../../../_metronic/_helpers"
import { CardBody } from "../../../../../_metronic/_partials/controls"
import { EmptyCard, TableSkeleton, TableView } from "../../../../../component"
import { linkWithCtrl } from "../../../../helpers/Utils"
import { toMeter } from "../../../../helpers/TextHelper"
import { activityBulkList } from "../../../../../redux/actions/AreaAction"
import { timeStampToDate } from "../../../../helpers/DateHelper"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

export const ListAreaActivityTabsWaiting = ({ data, changePage, history, general, isFilter }) => {
  const privileges = loadUserLogin().privilege
  const subheader = useSubheader()
  const { t } = useTranslation()
  const { General } = useSelector((state) => state)
  const dispatch = useDispatch()

  const [selectedRows, setSelectedRows] = useState([])
  const [count, setCount] = useState(0)

  useEffect(() => {
    subheader.setButton(undefined)
  }, [])

  useEffect(() => {
    setCount(selectedRows.length)
  }, [selectedRows])

  const uiCode = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.area_name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">
          {e?.area_size ? toMeter(e?.area_size) : "-"}
        </span>
      </>
    )
  }
  const uiFarmer = (e) => {
    //using data dummy before endpoint ready
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">
          {e?.farmer_name ? e?.farmer_name : "-"}
        </p>
        <span className="text-muted font-weight-bold d-block">
          {e?.farmer_code ? e?.farmer_code : "-"}
        </span>
      </>
    )
  }
  const uiMitra = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.mitra_name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">{e?.mitra_code ?? "-"}</span>
      </>
    )
  }
  const uiPlant = (e) => {
    //using data dummy before endpoint ready
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">
          {e?.plant_type_name ? e?.plant_type_name : "-"}
        </p>
        <span className="text-muted font-weight-bold d-block">
          {e?.plant_type_varietas ? e?.plant_type_varietas : "-"}
        </span>
      </>
    )
  }
  const uiType = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.activity_name ?? "-"}</p>
      </>
    )
  }
  const uiCreatedDate = (e) => {
    const dateConvert = e?.submitted_date ? timeStampToDate(e?.submitted_date, "dd-MM-yyyy") : "-"
    const TimeConvert = e?.submitted_date ? timeStampToDate(e?.submitted_date, "HH:mm:ss") : "-"

    return (
      <>
        <p className="text-dark mb-1 font-size-lg">
          {dateConvert} <br /> {TimeConvert}
        </p>
      </>
    )
  }

  const linkDetail = (area_id, type, id, e) => {
    const url = "/lahan/active/" + area_id + "/" + type + "/" + id

    if (e?.ctrlKey) {
      linkWithCtrl(url)
    } else {
      history.push(url)
    }
  }

  const uiStatus = (e) => {
    switch (e?.status_order_enum) {
      case "CONFIRMED_FO":
        return <Badge variant="warning">{e.status_order_string}</Badge>
      case "CONFIRMED_ERP":
        return <Badge variant="approved">{e.status_order_string}</Badge>
      case "NEED_BA":
        return <Badge variant="rescheduled">{e.status_order_string}</Badge>
      case "WAITING":
        return <Badge variant="warning">{e.status_order_string}</Badge>
      case "APPROVED":
        return <Badge variant="approved">{e.status_order_string}</Badge>
      default:
        return e?.status_order_string
    }
  }

  const columns = [
    {
      dataField: "plantingSeason.area.name",
      text: t("table_header.area_name"),
      sort: false,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "plantingSeason.area.farmer.name",
      text: t("table_header.farmer"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiFarmer
      }
    },
    {
      dataField: "plantingSeason.area.farmer.account.mitra.name",
      text: t("table_header.mitra"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitra
      }
    },
    {
      dataField: "plant_name",
      text: t("table_header.planting_type"),
      sort: false,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiPlant
      }
    },
    {
      dataField: "activity_name",
      text: t("table_header.activity_type"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiType
      },
      headerStyle: () => {
        return { width: "20%" }
      }
    },
    {
      dataField: "status_enum",
      text: t("table_header.status"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiStatus
      }
    },
    {
      dataField: "createdDate",
      text: t("table_header.created_date"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCreatedDate
      }
    }
  ]

  const handleBulkErp = () => {
    if (selectedRows.length) {
      dispatch(activityBulkList(selectedRows))
      history.push(RoutesConstants.AREA.PREVIEW_BULK_ERP())
    }
  }

  return (
    <>
      <Card className="card-shadowless">
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : data?.pageDataActivity?.length == 0 ? (
            <EmptyCard
              body={
                isFilter
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state")
              }
            />
          ) : (
            ""
          )}
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={data.pageDataActivity}
            currentPage={data.pageActivity.currentPage}
            currentElement={data.pageActivity.currentElement}
            totalCount={data.pageActivity.totalCount}
            loadingGetData={general.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            keyField={"id"}
            selectable={true}
            onSelect={(row, isSelected) => {
              let rows = JSON.parse(JSON.stringify(selectedRows))
              if (isSelected) {
                rows.push(row)
              } else {
                rows = rows.filter((e) => e?.id !== row?.id)
              }
              setSelectedRows(rows)
            }}
            onSelectAll={(isSelect, rows) => {
              let rowses = JSON.parse(JSON.stringify(selectedRows))
              if (isSelect) {
                if (rows?.length > 0) {
                  rows?.map((e) => {
                    rowses.push(e)
                  })
                }
              } else {
                if (rows?.length > 0) {
                  let rowsesNew = rowses
                  rows?.map((row) => {
                    const removeIndex = rowses.findIndex((e) => e?.mitra_id === row?.mitra_id)
                    rowsesNew.splice(removeIndex, 1)
                  })
                  rowses = rowsesNew
                }
              }
              setSelectedRows(rowses)
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (
                  privileges.indexOf(
                    PRIVILEGE_ENUM["DETAIL_ACTIVITY_" + row?.activity_type_enum?.toUpperCase()]
                  ) > -1 &&
                  e.target.cellIndex < 6
                ) {
                  linkDetail(row?.area_id, row?.activity_type_enum?.toLowerCase(), row.id, e)
                }
              }
            }}
          />
        </CardBody>
      </Card>
      {selectedRows.length > 1 ? (
        <div className={"mt-10 mb-6 px-10 w-100 d-flex justify-content-end"}>
          <Button onClick={handleBulkErp}>{t("button.bulk_erp", { count })}</Button>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
