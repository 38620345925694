import { loadUserLogin } from "../../../../service/userManagementAction"
import { useLocation } from "react-router"
import { useSubheader } from "../../../../../_metronic/layout"
import React, { useEffect } from "react"
import { PRIVILEGE_ENUM } from "../../../../../config/privilegeCollection"
import { Badge, Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { RoutesConstants } from "../../../../../constants/RoutesConstants"
import { DetailSvgIcon } from "../../../../../component/atoms/Icons/SVG"
import { formatterRowComponent } from "../../../../../component/atoms/Tabel"
import { sortCaret } from "../../../../../_metronic/_helpers"
import { getUrlParsingPageFilter } from "../../../../../config/endpointCollection"
import { CardBody } from "../../../../../_metronic/_partials/controls"
import { EmptyCard, TableSkeleton, TableView } from "../../../../../component"
import { linkWithCtrl } from "../../../../helpers/Utils"
import { toThousandConvert } from "../../../../helpers/TextHelper"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

export const ListAll = ({
  data,
  changePage,
  history,
  general,
  initialFilterTable,
  isActiveSearch,
  filterOn
}) => {
  const privileges = loadUserLogin().privilege
  const location = useLocation()
  const subheader = useSubheader()
  const { t } = useTranslation()
  const { General } = useSelector((state) => state)

  useEffect(() => {
    subheader.setButton(undefined)
  }, [])

  const uiCode = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.area_name ?? "-"}</p>
      </>
    )
  }
  const uiMitra = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.mitra_name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">{e.mitra_phone ?? "-"}</span>
      </>
    )
  }
  const uiName = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.farmer_name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">{e.farmer_phone ?? "-"}</span>
      </>
    )
  }

  const uiStatus = (e) => {
    return (
      <Badge
        variant={
          e?.planting_status_enum === "REGISTERED"
            ? "registered"
            : e?.planting_status_enum === "ACTIVE"
            ? "primary"
            : "secondary"
        }>
        {e?.planting_status_string ?? "-"}
      </Badge>
    )
  }
  const uiAreaStatus = (e) => {
    return <div>{e?.area_status_string ?? "-"}</div>
  }
  const uiAction = (e) => {
    return (
      <>
        {privileges.indexOf(PRIVILEGE_ENUM.AREA_DETAIL_REGISTERED) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <div
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={() => {
                history.push(RoutesConstants.AREA.DETAIL_AREA("active", e.area_id))
              }}>
              <DetailSvgIcon></DetailSvgIcon>
            </div>
          </OverlayTrigger>
        )}
      </>
    )
  }

  const uiLuas = (e) => {
    return (
      <>
        <span>{toThousandConvert(e.land_area) ?? "-"}</span>
      </>
    )
  }

  const headerLuas = (
    <>
      Luas (<span className={"text-lowercase"}>m</span>
      <sup>2</sup>)
    </>
  )

  const uiKur = (e) => {
    return (
      <div className={"kur-bni list"}>
        {e?.has_kur && e?.bank_image ? <img alt={"bni"} src={e?.bank_image} /> : <span>-</span>}
      </div>
    )
  }

  const columns = [
    {
      dataField: "name",
      text: "Nama Lahan",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "farmer.account.name,",
      text: "Mitra",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitra
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "farmer.name,",
      text: "Petani",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "landArea",
      text: headerLuas,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiLuas
      }
    },
    {
      dataField: "area_status_enum",
      text: "Status Lahan",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAreaStatus
      }
    },
    {
      dataField: "planting_status_enum",
      text: "Status Tanam",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiStatus
      }
    },
    {
      dataField: "farmer.hasKur",
      text: "KUR",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiKur
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  return (
    <>
      <Card className="card-shadowless">
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={7} row={10} />
          ) : data?.pageData.length == 0 ? (
            <EmptyCard
              body={
                filterOn || isActiveSearch
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state")
              }
            />
          ) : (
            ""
          )}
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={data?.pageData ?? []}
            currentPage={data?.page?.currentPage ?? 1}
            currentElement={data?.page?.currentElement ?? 10}
            totalCount={data?.page?.totalCount ?? 0}
            loadingGetData={general.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.AREA_DETAIL_REGISTERED) > 1) {
                  if (e.target.cellIndex < 6) {
                    const url = RoutesConstants.AREA.DETAIL_AREA("active", row.area_id)
                    if (e.ctrlKey) {
                      linkWithCtrl(url)
                    } else {
                      history.push(url)
                    }
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}
