import { GeneralSlice } from "../reducers/General"
import { MitraListSlice, MitraSlice } from "../reducers/Mitra"
import { GENERAL, MITRA } from "../../constants/InitTypeConstants"
import { requestDelete, requestGet, requestPost, requestPut } from "../../config"
import { MASTER_URL, MITRA_URL } from "../../constants/UrlConstants"
import {
  getUrlParsingPage,
  getUrlParsingPageFilter,
  getUrlParsingStatus,
  getUrlParsingSync
} from "../../config/endpointCollection"
import swal from "sweetalert"
import { toAbsoluteUrl } from "../../_metronic/_helpers/index.js"
import { ILLUSTRATION_ASSETS_V2 } from "../../constants/ImageConstants.js"

const { actions: general } = GeneralSlice
const { actions: mitra } = MitraSlice
const { actions: mitralist } = MitraListSlice

export const getDetail = (param) => {
  return async (dispatch) => {
    if (param) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: MITRA.GET_DETAIL
        })
      )
      try {
        let res = await requestGet(MITRA_URL.GET_DETAIL_V2 + param)
        dispatch(mitra.getPage(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MITRA.GET_DETAIL
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const clearDataDetail = () => {
  return async (dispatch) => {
    dispatch(mitra.getPage(false))
  }
}

export const getPageData = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPage(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MITRA.GET_LIST_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(MITRA_URL.GET_LIST_PAGE + urlData)
      dispatch(
        mitra.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MITRA.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

function setEmptyPageDataBeforeFetching(dispatch) {
  dispatch(
    mitra.setPageData({
      pageData: [],
      page: {
        totalCount: 0,
        currentElement: 0,
        currentPage: 0
      }
    })
  )
}

export function getListMitraV3(status, param) {
  return async (dispatch) => {
    setEmptyPageDataBeforeFetching(dispatch)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MITRA.GET_LIST_MITRA
      })
    )
    try {
      let res = await requestGet(
        MITRA_URL.GET_LIST_MITRA(status) + param ?? ""
        // MITRA_URL.GET_LIST_VERIFIED_PAGE + param ?? ""
      )
      dispatch(
        mitra.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MITRA.GET_LIST_MITRA
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPageDataVerified = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MITRA.GET_LIST_VERIFIED_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(MITRA_URL.GET_LIST_VERIFIED_PAGE + param ?? "")
      dispatch(
        mitra.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MITRA.GET_LIST_VERIFIED_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getMitraListNonKur = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MITRA.GET_LIST_MITRA_NON_KUR
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    await requestGet(MITRA_URL.GET_LIST_MITRA_NON_KUR + param ?? "")
      .then((res) => {
        dispatch(
          mitra.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MITRA.GET_LIST_MITRA_NON_KUR
          })
        )
      })
      .catch((e) => {
        dispatch(
          mitra.setPageData({
            pageData: [],
            page: {
              totalCount: 0,
              currentElement: 0,
              currentPage: 0
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MITRA.GET_LIST_MITRA_NON_KUR
          })
        )
        dispatch(general.setError(e))
      })
  }
}

export const getMitraListKur = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MITRA.GET_LIST_MITRA_KUR
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    await requestGet(MITRA_URL.GET_LIST_MITRA_KUR + param ?? "")
      .then((res) => {
        dispatch(
          mitra.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MITRA.GET_LIST_MITRA_KUR
          })
        )
      })
      .catch((e) => {
        dispatch(
          mitra.setPageData({
            pageData: [],
            page: {
              totalCount: 0,
              currentElement: 0,
              currentPage: 0
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: MITRA.GET_LIST_MITRA_KUR
          })
        )
        dispatch(general.setError(e))
      })
  }
}

export const getPageDataByFo = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MITRA.GET_LIST_BY_FO
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(MITRA_URL.GET_LIST_BY_FO + param.pageReq.id + urlData)
      dispatch(
        mitra.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MITRA.GET_LIST_BY_FO
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPageDataPending = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MITRA.GET_LIST_PENDING_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(MITRA_URL.GET_LIST_PENDING_PAGE + urlData)
      dispatch(
        mitra.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MITRA.GET_LIST_PENDING_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getPageDataRejected = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MITRA.GET_LIST_REJECTED_PAGE
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(MITRA_URL.GET_LIST_REJECTED_PAGE + urlData)
      dispatch(
        mitra.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MITRA.GET_LIST_REJECTED_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const actionsMitra = (params, action = "create") => {
  return async (dispatch) => {
    dispatch(general.setLoading({ loading: true, actionInitType: MITRA.CREATE }))
    try {
      if (action == "create") {
        await requestPost(MITRA_URL.ACTION, params)
      } else {
        await requestPut(MITRA_URL.ACTION, null, params)
      }

      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: MITRA.CREATE
        })
      )
      dispatch(mitra.setActions(true))
    } catch (e) {
      dispatch(general.setError(e))
    }
    dispatch(
      general.setLoading({
        loading: false,
        actionInitType: MITRA.CREATE
      })
    )
  }
}

export const createMitra = (params, action) => {
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: MITRA.CREATE }))
    try {
      let res
      if (action === "create") {
        res = await requestPost(MITRA_URL.CREATE_MITRA, params)
      } else {
        res = await requestPut(MITRA_URL.CREATE_MITRA, null, params)
      }
      dispatch(mitra.setMessage(res.data))
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: MITRA.CREATE
        })
      )
      dispatch(general.setLoadingGlobal({ loading: false, actionInitType: MITRA.CREATE }))
      dispatch(mitra.setActions(true))
    } catch (e) {
      dispatch(general.setError(e))
    }
    dispatch(
      general.setLoading({
        loading: false,
        actionInitType: MITRA.CREATE
      })
    )
  }
}

export const updateStatus = (id, status, reasons) => {
  const payload = {
    status: status,
    rejected_reason: reasons
  }
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: MITRA.UPDATE }))
    try {
      let res = await requestPut(MITRA_URL.ACTION_APPROVAL + id, null, payload)
      dispatch(mitra.setMessage(res.data))
      dispatch(general.setLoadingGlobal({ loading: false, actionInitType: MITRA.UPDATE }))
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: MITRA.UPDATE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const clearActionsMitra = () => {
  return async (dispatch) => {
    dispatch(mitra.setActions(false))
  }
}

export const getAllMitra = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MITRA.GET_ALL_FOR_COMBOBOX
      })
    )
    try {
      let res = await requestGet(MITRA_URL.GET_LIST_ALL_COMBOBOX)
      dispatch(mitralist.getPage(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: MITRA.GET_ALL_FOR_COMBOBOX
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getCheckCanDeactivate = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MITRA.CHECK_DEACTIVATE
      })
    )
    try {
      let res = await requestGet(MITRA_URL.GET_CHECK_CAN_DEACTIVATE + id)
      dispatch(mitra.getCanDeactivate(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MITRA.CHECK_DEACTIVATE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const resetCheckCanDeactivate = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MITRA.CHECK_DEACTIVATE
      })
    )
    try {
      dispatch(mitra.getCanDeactivate(null))
      dispatch(mitra.getCheckHasFarmer(false))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MITRA.CHECK_DEACTIVATE
        })
      )
    } catch (e) {
      dispatch(mitra.getCanDeactivate(e.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MITRA.CHECK_DEACTIVATE
        })
      )
    }
  }
}

export const deactivateStatusMitra = (param, payload) => {
  return async (dispatch) => {
    if (param) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: MITRA.DEACTIVATE_MITRA
        })
      )
      try {
        let res = await requestDelete(MITRA_URL.DEACTIVATE_MITRA + param, payload)
        dispatch(mitra.getCheckHasFarmer(res?.data?.has_farmer))
        dispatch(mitra.setMessage(res.data))
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: MITRA.DEACTIVATE_MITRA
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: MITRA.DEACTIVATE_MITRA
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getPageDataVerifiedCombobox = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MITRA.GET_ALL_FOR_COMBOBOX
      })
    )
    try {
      let res = await requestGet(MITRA_URL.GET_LIST_VERIF_COMBOBOX)
      dispatch(mitra.setCombobox(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MITRA.GET_ALL_FOR_COMBOBOX
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getTypeMitra = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: MITRA.GET_TYPE
      })
    )
    try {
      let res = await requestGet(MITRA_URL.GET_TYPE_MITRA)
      dispatch(mitra.setType(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MITRA.GET_TYPE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const updateImage = ({ id, data }) => {
  return async (dispatch) => {
    try {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: MITRA.UPDATE_IMAGE
        })
      )
      let res = await requestPut(MITRA_URL.UPDATE_IMAGE + id, null, data)
      dispatch(mitra.setMessage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MITRA.UPDATE_IMAGE
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: MITRA.UPDATE_IMAGE
        })
      )
    } catch (e) {
      dispatch(general.setError({ actionInitType: MITRA.UPDATE_IMAGE, e }))
    }
  }
}

export const syncErp = (url, code) => {
  return async (dispatch) => {
    let urlData = getUrlParsingSync(code)
    dispatch(
      general.finistUpdateErp({
        isFinishSyncErp: false,
        actionInitType: GENERAL.SYNC_ERP
      })
    )
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: GENERAL.SYNC_ERP }))
    try {
      let res = await requestPut(MASTER_URL.SYNC_ERP + url + urlData)
      dispatch(mitra.setMessage(res.data))
      dispatch(general.setLoadingGlobal({ loading: false, actionInitType: GENERAL.SYNC_ERP }))
      dispatch(
        general.finistUpdateErp({
          isFinishSyncErp: true,
          actionInitType: GENERAL.SYNC_ERP
        })
      )
    } catch (e) {
      dispatch(
        general.setError({
          customModal: swal(
            `Sinkronisasi data ${url === "farmer" ? "petani" : "mitra"} gagal`,
            "Silahkan coba lagi.",
            {
              icon: toAbsoluteUrl(ILLUSTRATION_ASSETS_V2.farmer.IL_FAILED_SEND_ERP)
            }
          ),
          actionInitType: GENERAL.SYNC_ERP
        })
      )
    }
  }
}

export const clearSyncErp = () => {
  return async (dispatch) => {
    dispatch(
      general.finistUpdateErp({
        isFinishSyncErp: false,
        actionInitType: GENERAL.SYNC_ERP
      })
    )
  }
}

export const getMitraForFilterOrder = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingStatus(param)
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MITRA.GET_LIST_PAGE
      })
    )
    try {
      let res = await requestGet(MITRA_URL.GET_LIST_MITRA_FOR_FILTER_ORDER + urlData)
      dispatch(mitra.getList(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: MITRA.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getMitraForFilterOrderPascaPlanting = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: MITRA.GET_LIST_PAGE
      })
    )
    try {
      let res = await requestGet(MITRA_URL.GET_LIST_MITRA_FOR_FILTER_ORDER)
      dispatch(mitra.getList(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: MITRA.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
