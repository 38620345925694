import React from "react"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { timeStampToDate } from "../../../helpers/DateHelper"
import { DetailOrderRowText } from "../DetailsOrder"
import { Skeleton } from "@material-ui/lab"
import { useSelector } from "react-redux"

export const SectionDetailOrderPlantInfo = ({ data }) => {
  const { General } = useSelector((state) => state)
  return (
    <>
      <div className={"col-xl-10"}>
        <h4>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={150} />
          ) : (
            "2. DATA TANAMAN"
          )}
        </h4>
        <RowModule customColumnCss={" mb-2 mt-4 col-6"}>
          <div className={"p-0"}>
            <DetailOrderRowText label={"Jenis Tanaman"} value={data?.plant_type_string ?? "-"} />
            <DetailOrderRowText label={"Varietas"} value={data?.variety_name ?? "-"} />
            <DetailOrderRowText
              label={"Tanggal Tanam"}
              value={
                data?.planting_date
                  ? timeStampToDate(data?.planting_date, "dd LLLL yyyy") ?? "-"
                  : "-"
              }
            />
          </div>
        </RowModule>
      </div>
    </>
  )
}
