import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../_metronic/layout"
import { Badge, Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { CardBody } from "../../../../_metronic/_partials/controls"
import { EmptyCard, Search, TableSkeleton, TableView } from "../../../../component"
import { sortCaret } from "../../../../_metronic/_helpers"
import { formatterRowComponent } from "../../../../component/atoms/Tabel"
import { PRIVILEGE_ENUM } from "../../../../config/privilegeCollection"
import { DetailSvgIcon, EditSvgIcon } from "../../../../component/atoms/Icons/SVG"
import { loadUserLogin } from "../../../service/userManagementAction"
import { timeStampToDate } from "../../../helpers/DateHelper"
import { useUrlParam } from "../../../hooks/useUrlParams"
import { useLocation } from "react-router"
import { useHistory } from "react-router-dom"
import { getUrlParsingPageFilter } from "../../../../config/endpointCollection"
import { useDispatch, useSelector } from "react-redux"
import { GetListFoHarvest } from "../../../../redux/actions/FieldOfficerAction"
import { linkWithCtrl } from "../../../helpers/Utils"
import { RoutesConstants } from "../../../../constants/RoutesConstants"
import { FIELD_OFFICER_ROLE_ENUM } from "../../../../constants/StringConstant"
import { useTranslation } from "react-i18next"

export const ListFoHarvest = () => {
  const { t } = useTranslation()
  const [dataList, setDataList] = useState([])
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [search, setSearch] = useState({ string_filter: "" })
  const [queryStringObj] = useState(undefined)
  const { FieldOfficer, General } = useSelector((state) => state)
  const subheader = useSubheader()
  const privileges = loadUserLogin().privilege
  const formRef = useRef()
  const urlParam = useUrlParam()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    subheader.setTitle("FO Harvest")
    subheader.setButton(undefined)
  }, [])

  useEffect(() => {
    if (FieldOfficer?.pageData) {
      setDataList(FieldOfficer.pageData)
    }
  }, [General, FieldOfficer])

  useEffect(() => {
    if (queryStringObj?.string_filter) {
      setSearch({ string_filter: queryStringObj?.string_filter })
      setIsActiveSearch(true)
    }
  }, [queryStringObj])

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...param,
          sort: param.sortField + "," + param.sortOrder,
          string_filter: search.string_filter
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...param,
          string_filter: search.string_filter
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [search]
  )

  function onSearch(value) {
    setSearch({ string_filter: value.string_filter })
    setIsActiveSearch(true)
    let param = {
      string_filter: value.string_filter
    }
    const url = urlParam.stringifyObj(param)
    history.push({ pathname: location.pathname, search: url })
  }

  function fetchData(param) {
    return dispatch(GetListFoHarvest(param))
  }

  function resetSearch() {
    urlParam.resetStringFilter()
    setSearch({ string_filter: "" })
  }

  const uiCode = (e) => {
    return <div className={"font-weight-bolder"}>{e?.code ?? "-"}</div>
  }

  const uiName = (e) => {
    return (
      <div>
        <div className={"font-weight-bolder"}>{e?.name ?? "-"}</div>
        <div>{e?.phone_number ?? "-"}</div>
      </div>
    )
  }

  const UiDate = (e) => {
    return (
      <div>
        <div>{timeStampToDate(e.approve_date, "dd-MM-yyyy") ?? "-"}</div>
        <div>{timeStampToDate(e.approve_date, "HH:mm:ss") ?? "-"}</div>
      </div>
    )
  }

  const uiStatus = (e) => {
    return (
      <Badge variant={e?.status_enum === "ACTIVE" ? "primary" : "secondary"}>
        {e.status_string ? e.status_string : "-"}
      </Badge>
    )
  }

  const UiActions = (e) => {
    return (
      <div>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_HARVEST) > 1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() =>
                history.push(
                  RoutesConstants.FIELD_OFFICER.DETAIL_FO(
                    FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_HARVEST,
                    e?.id
                  )
                )
              }>
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.EDIT_FIELD_OFFICER) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() => history.push(RoutesConstants.FIELD_OFFICER.EDIT_FO(e.id))}>
              <EditSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }

  const columnProperties = [
    {
      dataField: "fo_code",
      text: "ID FO",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "name",
      text: "NAMA",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      sort: true,
      sortCaret: sortCaret
    },
    {
      dataField: "full_address",
      text: "ALAMAT",
      headerStyle: () => {
        return { width: "45%" }
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiStatus
      }
    },
    {
      dataField: "createdDate",
      text: "TANGGAL DIBUAT",
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: UiDate
      },
      headerStyle: () => {
        return { width: "15%" }
      }
    },
    {
      dataField: "action",
      text: "Action",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: UiActions
      },
      headerStyle: () => {
        return { width: "12%" }
      }
    }
  ]

  return (
    <>
      <div className="filter-container">
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            isActiveSearch={isActiveSearch}
            placeholder={"Masukkan Nama FO"}
            onSubmitForm={onSearch}
            initialValues={search}
            name={"string_filter"}
            innerRef={formRef}
            trigerReset={resetSearch}
          />
        </div>
      </div>
      <Card>
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : dataList.length == 0 ? (
            <EmptyCard
              body={
                isActiveSearch
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state")
              }
            />
          ) : (
            ""
          )}
          <TableView
            dataTable={dataList ?? []}
            loadingGetData={General?.loading}
            callbackAfterPageChange={changePage}
            columnProperties={columnProperties}
            currentPage={FieldOfficer?.page?.currentPage ?? 0}
            currentElement={FieldOfficer?.page?.currentElement ?? 10}
            totalCount={FieldOfficer?.page?.totalCount ?? 0}
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_HARVEST)) {
                  if (e.target.cellIndex < 5) {
                    const url = RoutesConstants.FIELD_OFFICER.DETAIL_FO(
                      FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_HARVEST,
                      row?.id
                    )
                    if (e.ctrlKey) {
                      linkWithCtrl(url)
                    } else {
                      history.push(url)
                    }
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}
