import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useSubheader } from "../../../../../../_metronic/layout"
import * as Yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../../../../util/constStringCollection"
import { RoutesConstants } from "../../../../../../constants/RoutesConstants"
import { PreviewBulkErpForm } from "./PreviewBulkErpForm"
import { TabsInputComponent } from "../../../../../../component"
import { Trans, useTranslation } from "react-i18next"
import { Popup, PopupQuestion } from "../../../../../../component/atoms/Popup"
import { activitySendToErpBulk } from "../../../../../../redux/actions/AreaAction"
import { AREA } from "../../../../../../constants/InitTypeConstants"
import { ILUSTRATION_CONSTANS } from "../../../../../../constants/ImageConstants"
import SVG from "react-inlinesvg"

export const PreviewBulkErp = () => {
  const formRef = useRef()
  const subheader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { General, Area } = useSelector((state) => state)
  const history = useHistory()

  const [dataApprovalBulk, setDataApprovalBulk] = useState(undefined)
  const [initialValue] = useState({
    regional_id: ""
  })
  const [isValid, setIsValid] = useState(false)
  const [showPopupSubmit, setShowPopupSubmit] = useState(false)
  const [count, setCount] = useState(0)
  const [modalAfterSuccess, setModalAfterSuccess] = useState(false)

  const ValidationScheme = [
    Yup.object().shape({
      regional_id: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi))
    })
  ]

  const breadcrumbData = [
    {
      pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
      title: "Lahan"
    },
    {
      pathname: RoutesConstants.AREA.LIST_AREA_ACTIVITY("waiting"),
      title: "Laporan Aktivitas"
    },
    {
      title: "Bulk Approval"
    }
  ]

  useEffect(() => {
    subheader.setBreadcrumbs(breadcrumbData)
    if (Area.bulkList.length) {
      setDataApprovalBulk(Area.bulkList)
      setCount(Area.bulkList.length)
    } else {
      history.push(RoutesConstants.AREA.LIST_AREA_ACTIVITY("waiting"))
    }
  }, [])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === AREA.APPROVE_ACTIVITY_TO_ERP_BULK) {
        setModalAfterSuccess(true)
      }
    }
  }, [General])

  const btnSubmit = (
    <button
      type="button"
      disabled={!isValid}
      className="btn btn-primary"
      onClick={() => {
        setShowPopupSubmit(true)
      }}>
      {t("button.send_to_finance")}
    </button>
  )

  const btnCancel = (
    <button
      type="button"
      className="btn btn-secondary-info mr-4"
      onClick={() => history.push(RoutesConstants.AREA.LIST_AREA_ACTIVITY("waiting"))}>
      {t("button.cancel")}
    </button>
  )

  function BodyPopup() {
    return (
      <div className={"py-4 text-center"}>
        <Trans
          i18nKey={"messages.confirmation.activity_approve_to_erp_confirmation_bulk"}
          count={count}
        />
      </div>
    )
  }

  function onSubmitOrderApproveBulk() {
    let body = {
      regional_id: formRef.current.values.regional_id,
      activity_id: dataApprovalBulk.map((item) => item.id)
    }
    dispatch(activitySendToErpBulk(body))
    setShowPopupSubmit(false)
  }

  const bodyAfterSuccess = (
    <div className={"py-4 text-center"}>
      <SVG src={ILUSTRATION_CONSTANS.SUCCESS_FORM} />
      <div>{t("messages.response.activity_approve_to_erp_success")}</div>
    </div>
  )

  return (
    <>
      <PopupQuestion
        show={showPopupSubmit}
        title={t("messages.title.activity_send_to_erp")}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        onOk={onSubmitOrderApproveBulk}
        variantOkButton={"primary"}
        body={BodyPopup()}
        disable={false}
        onCancel={() => setShowPopupSubmit(false)}
      />
      <Popup
        show={modalAfterSuccess}
        bodyCustom={bodyAfterSuccess}
        textOk="Ok"
        onClick={() => {
          setModalAfterSuccess(false)
          history.push(RoutesConstants.AREA.LIST_AREA_ACTIVITY("waiting"))
        }}
      />

      <TabsInputComponent
        isEditTabs={false}
        steps={[null]}
        stepDescription={[null]}
        formInitialValues={initialValue}
        formId="createSo"
        innerRef={formRef}
        componentTab={[
          <PreviewBulkErpForm key={0} data={dataApprovalBulk} setIsValid={setIsValid} />
        ]}
        validationSchema={ValidationScheme}
      />
      <div className={"d-flex justify-content-end align-items-center"}>
        {btnCancel}
        {btnSubmit}
      </div>
    </>
  )
}
