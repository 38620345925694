import React, { Fragment, useEffect, useState } from "react"
import { ActivityDetailHeader } from "../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader.jsx"
import { ACTIVITY_ICONS } from "../../../../constants/ImageConstants.js"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { useTranslation } from "react-i18next"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { DetailText, RowText, TableView } from "../../../../component/index.jsx"
import { timeStampToDate } from "../../../helpers/DateHelper.js"
import { AREA } from "../../../../constants/InitTypeConstants.js"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { getDetailActivityReportMac } from "../../../../redux/actions/MacAction.jsx"
import { Popup } from "../../../../component/atoms/Popup/index.jsx"
import { formatterRowComponent } from "../../../../component/atoms/Tabel/index.jsx"
import { checkCommaInDataPic, toMeter } from "../../../helpers/TextHelper.js"
import { Skeleton } from "@material-ui/lab"

export function DetailActivityReportMac() {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { id } = useParams()

  const { t } = useTranslation()
  const { Mac, General } = useSelector((state) => state)
  const [dataDetail, setDataDetail] = useState()
  const [openPopupTableList, setOpenPopupTableList] = useState(false)
  const [dataListMember, setDataListMember] = useState([])

  useEffect(() => {
    if (id) {
      dispatch(getDetailActivityReportMac(id))
    }
  }, [id])

  useEffect(() => {
    const data = Mac?.data
    if (data) {
      setDataDetail(data)
    }
  }, [Mac])

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.MAC.LIST_PROFILE(),
        title: "MAC"
      },
      {
        pathname: RoutesConstants.MAC.ACTIVITY_REPORT_LIST(),
        title: t("sub_header.mac_activity_report")
      },
      {
        title: dataDetail?.request_detail?.request_code ?? ""
      }
    ])
  }, [dataDetail])

  const columnsPic = [
    {
      dataField: "name",
      text: t("label.team_name"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      headerStyle: () => {
        return { width: "25%" }
      }
    },
    {
      dataField: "member",
      text: t("label.member")
    }
  ]

  useEffect(() => {
    if (dataDetail?.activity_detail?.pic) {
      setDataListMember(dataDetail?.activity_detail?.pic)
    }
  }, [dataDetail])

  function dataPic(dataListMember) {
    return (
      <>
        {
          <div>
            {dataListMember.map((item, i) => (
              <>
                {i < 2 && (
                  <Fragment>{`${item.name} (${item.member})${
                    checkCommaInDataPic(i, dataListMember.length) ? "," : ""
                  } `}</Fragment>
                )}
              </>
            ))}
            {dataListMember.length > 2 && (
              <span
                onClick={() => setOpenPopupTableList(true)}
                className={"text-success font-weight-bolder  underline-hover cursor-pointer"}
                style={{ fontSize: 12, textDecoration: "underline" }}>
                +{dataListMember.length - 2} {t("label.others")}
              </span>
            )}
          </div>
        }
      </>
    )
  }
  function informationDetail() {
    return (
      <Card>
        <CardBody>
          <h3>
            {General.loading ? (
              <Skeleton animation="wave" variant={"text"} height={32} width={200} />
            ) : (
              t("label.mac_user_information").toUpperCase()
            )}
          </h3>
          <RowModule customColumnCss={"col-md-6 mt-4"}>
            <RowModule>
              <RowText
                label={t("label.mac_id_user")}
                value={
                  dataDetail?.information_detail?.user_merchant_code
                    ? dataDetail?.information_detail?.user_merchant_code
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("label.phone_number")}
                value={
                  dataDetail?.information_detail?.phone
                    ? dataDetail?.information_detail?.phone
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("label.mac_user_merchant_name")}
                value={
                  dataDetail?.information_detail?.user_merchant_name
                    ? dataDetail?.information_detail?.user_merchant_name
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("table_header.full_address_mac")}
                value={
                  dataDetail?.information_detail?.full_address
                    ? dataDetail?.information_detail?.full_address
                    : "-"
                }
              />
            </RowModule>
          </RowModule>
        </CardBody>
      </Card>
    )
  }

  function requestDetail() {
    return (
      <Card>
        <CardBody>
          <h3>
            {General.loading ? (
              <Skeleton animation="wave" variant={"text"} height={32} width={200} />
            ) : (
              t("label.request_detail").toUpperCase()
            )}
          </h3>
          <RowModule customColumnCss={"col-md-6 mt-4"}>
            <RowModule>
              <RowText
                label={t("label.request_id")}
                value={
                  dataDetail?.request_detail?.request_code
                    ? dataDetail?.request_detail?.request_code
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("menu_aside.area_location")}
                value={
                  dataDetail?.request_detail?.area_address
                    ? dataDetail?.request_detail?.area_address
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("label.type_schedule")}
                value={
                  dataDetail?.request_detail?.request_type
                    ? dataDetail?.request_detail?.request_type
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("label.product")}
                value={
                  dataDetail?.request_detail?.product_name &&
                  dataDetail?.request_detail?.product_name.length
                    ? dataDetail?.request_detail?.product_name.join(", ")
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("label.schedule_date")}
                value={
                  dataDetail?.request_detail?.request_date
                    ? timeStampToDate(dataDetail?.request_detail?.request_date, "dd-MM-yyyy")
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("label.note")}
                value={dataDetail?.request_detail?.note ? dataDetail?.request_detail?.note : "-"}
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("label.plant_type")}
                value={
                  dataDetail?.request_detail?.plant_type &&
                  dataDetail?.request_detail?.plant_type.length > 0
                    ? dataDetail?.request_detail?.plant_type.join(", ")
                    : "-"
                }
              />
            </RowModule>
          </RowModule>
        </CardBody>
      </Card>
    )
  }
  function activityDetail() {
    return (
      <Card>
        <CardBody>
          <h3>
            {General.loading ? (
              <Skeleton animation="wave" variant={"text"} height={32} width={200} />
            ) : (
              t("label.activity_detail").toUpperCase()
            )}
          </h3>
          <RowModule customColumnCss={"col-md-6 mt-4"}>
            <RowModule>
              <RowText
                label={t("label.submitted_by")}
                value={
                  dataDetail?.activity_detail?.submitted_by
                    ? dataDetail?.activity_detail?.submitted_by
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("label.activity_working_date")}
                value={
                  dataDetail?.activity_detail?.activity_date
                    ? timeStampToDate(dataDetail?.activity_detail?.activity_date, "dd-MM-yyyy")
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("label.submitted_time")}
                value={
                  dataDetail?.activity_detail?.submitted_date
                    ? timeStampToDate(
                        dataDetail?.activity_detail?.submitted_date,
                        "dd-MM-yyyy - HH:mm:ss"
                      )
                    : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("label.pilot_name")}
                value={
                  dataDetail?.activity_detail?.pic ? dataPic(dataDetail?.activity_detail?.pic) : "-"
                }
              />
            </RowModule>
            <RowModule>
              <RowText
                label={t("label.land_area_total")}
                value={toMeter(dataDetail?.activity_detail?.working_area) ?? "-"}
              />
            </RowModule>
          </RowModule>
          <h3>{t("label.activity_picture").toUpperCase()}</h3>
          <div className={"row mt-4"}>
            {dataDetail?.activity_detail?.activity_image &&
              dataDetail?.activity_detail?.activity_image?.length > 0 &&
              dataDetail?.activity_detail?.activity_image?.map((item, i) => (
                <div className={"col-md-3"} key={i}>
                  <DetailText
                    type="image"
                    value={item}
                    indexKey={i}
                    menu={AREA.ACTIVITY_SPRAYING}
                  />
                </div>
              ))}
          </div>
        </CardBody>
      </Card>
    )
  }
  function paymentDetail() {
    return (
      <Card>
        <CardBody>
          <h3>
            {General.loading ? (
              <Skeleton animation="wave" variant={"text"} height={32} width={200} />
            ) : (
              t("label.payment_receipt").toUpperCase()
            )}
          </h3>
          <RowModule customColumnCss={"col-md-6 mt-4"}>
            <RowModule>
              <RowText
                label={t("label.receipt_number")}
                value={
                  dataDetail?.payment_detail?.payment_number
                    ? dataDetail?.payment_detail?.payment_number
                    : "-"
                }
              />
            </RowModule>
          </RowModule>
          <div className={"row mt-2"}>
            {dataDetail?.payment_detail?.images &&
              dataDetail?.payment_detail?.images?.length > 0 &&
              dataDetail?.payment_detail?.images?.map((item, i) => (
                <div className={"col-md-3"} key={i}>
                  <DetailText
                    type="image"
                    value={item}
                    indexKey={i}
                    menu={AREA.ACTIVITY_SPRAYING}
                  />
                </div>
              ))}
          </div>
        </CardBody>
      </Card>
    )
  }

  function uiName(e) {
    return <div className={"font-weight-bolder"}>{e?.name ?? "-"}</div>
  }

  function bodyPopupTable() {
    return (
      <div className={"table_list_popup_schedule table_hidden_bottom"}>
        <TableView
          dataTable={dataListMember}
          loadingGetData={false}
          columnProperties={columnsPic}
        />
      </div>
    )
  }
  return (
    <div>
      <Popup
        contentClassName={"modal-main modal_detail_team_customs"}
        show={openPopupTableList}
        titleCustom={<h1>Anggota Tim</h1>}
        closeButton={true}
        showButton={false}
        onClick={() => setOpenPopupTableList(false)}
        body={bodyPopupTable()}
        size={"lg"}
        onCancel={() => {
          setOpenPopupTableList(false)
        }}
      />
      <ActivityDetailHeader
        icon={ACTIVITY_ICONS.IC_PENYEMPROTAN}
        title={t("label.spraying_activity_mac").toUpperCase()}
      />
      <div className={"d-flex flex-column"} style={{ gap: 16 }}>
        {informationDetail()}
        {requestDetail()}
        {activityDetail()}
        {paymentDetail()}
      </div>
    </div>
  )
}
