import React, { Suspense, useEffect } from "react"
import { Redirect, Switch } from "react-router-dom"
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout"
import { BuilderPage } from "./pages/BuilderPage"
import { DashboardPage } from "./pages/DashboardPage"
import { PrivilegeAll } from "./service/userManagementAction"
import { AccountEditPassword } from "./modules/Account/AccountEditPassword"
// import { PrintHeader } from "../component/molecule/Print/Print"
import { currentUrl, keepStateUrlParamActivity } from "./helpers/UrlParamHelper"
import { useDispatch, useSelector } from "react-redux"
import { resetUrlParam } from "../redux/actions/FilterAction"
import { CreateSubmissionPage } from "./modules/Submission/CertificationBlock/CreateSubmission/CreateSubmissionPage.jsx"

export default function BasePage() {
  const dispatch = useDispatch()
  const { UrlParam } = useSelector((state) => state)
  const routingFrom = PrivilegeAll()
  const url = currentUrl()

  useEffect(() => {
    const keepPage = keepStateUrlParamActivity(url)
    if (UrlParam.pageString !== url && keepPage) {
      dispatch(resetUrlParam())
    }
  }, [url])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {/*<PrintHeader />*/}
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/account/edit-password" component={AccountEditPassword} />
        <ContentRoute path="/submission/create" component={CreateSubmissionPage} />
        {routingFrom.map((value, index) => {
          return <ContentRoute key={index} path={value.url} component={value.component} />
        })}
        <Redirect to="/error/opppsss" />
      </Switch>
    </Suspense>
  )
}
