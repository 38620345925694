import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"

export const CalenderSvgIcon = () => {
  return (
    <span className="svg-icon svg-icon-lg svg-icon-inherit">
      <SVG src={toAbsoluteUrl("/media/svg/icons/Detail/calendar.svg")} />
    </span>
  )
}
