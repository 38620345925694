import React, { useEffect, useState } from "react"
import { BadgeStatus, CardDetail } from "../../../../component"
import { FlatIconAndText, FlatIconAndText2Row } from "../../../../component/atoms/FlaticonAndText"
import { Accordion } from "../../../../component/atoms/Expands/accordion"
import { Badge, Card } from "react-bootstrap"
import { CardBody } from "../../../../_metronic/_partials/controls"
import SVG from "react-inlinesvg"
import { useDispatch, useSelector } from "react-redux"
import { getAreas } from "../../../../redux/actions/FarmerAction"
import { Skeleton } from "@material-ui/lab"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"

export const DetailLahan = ({ id }) => {
  const { Farmer, General } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [listArea, setListArea] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(General.loading)
  }, [General.loading])
  useEffect(() => {
    dispatch(getAreas(id))
  }, [])

  useEffect(() => {
    setListArea(Farmer.areas)
  }, [Farmer.areas])

  const descriptionLeftArea = (data) => {
    return (
      <>
        <div className="d-flex flex-wrap mb-4">
          <FlatIconAndText
            text={data.land_area != null ? data.land_area + " m2" : "-"}
            svg={"/media/svg/icons/Detail/ic_field.svg"}
          />
          <FlatIconAndText
            text={
              data.plant_namelist_comma_separated != null
                ? data.plant_namelist_comma_separated + " - " + data.plant_varieties_name
                : "-"
            }
            svg={"/media/svg/icons/Detail/ic_paddy.svg"}
          />
        </div>
        {General.loading ? (
          <>
            <Skeleton animation="wave" variant={"text"} width={100} className={"mb-2"} />
            <Skeleton animation="wave" variant={"text"} width={100} className={"mb-2"} />
          </>
        ) : (
          <>
            <span className="font-weight-bold text-dark-50">
              {data?.address ?? "-"} - {data?.subdistrict_name ?? ""} - {data?.district_name ?? ""}
            </span>
            <span className="font-weight-bold text-dark-50">
              {data?.regency_name ?? ""} - {data?.province_name ?? ""}
            </span>
          </>
        )}
      </>
    )
  }

  const descriptionRightArea = (data) => {
    return (
      <>
        {data?.is_hst && (
          <div className="d-flex flex-wrap justify-content-around mb-1">
            <div>
              <div className="font-weight-bold mb-1">Tanggal Tanam</div>
              <Badge variant="primary">{data?.planting_date ? data?.planting_date : "-"}</Badge>
            </div>
            <div className="ml-10">
              <div className="font-weight-bold mb-1">Estimasi Panen</div>
              <Badge variant="danger">{data?.harvest_date ? data?.harvest_date : "-"}</Badge>
            </div>
          </div>
        )}
      </>
    )
  }

  const flatIconAndText2RowsArea = (data) => {
    return (
      <>
        {data?.is_hst ? (
          <>
            <FlatIconAndText2Row
              title="Kegiatan Pending"
              description={data?.pending_activity ?? "0"}
              svg={"/media/svg/icons/Detail/ic_calendar_date.svg"}
            />
            <FlatIconAndText2Row
              title="Kegiatan Selesai"
              description={data?.finish_activity ?? "0"}
              svg={"/media/svg/icons/Detail/ic_calendar_date.svg"}
            />
            <FlatIconAndText2Row
              title="Kegiatan Selanjutnya"
              description={data?.next_activity ?? "-"}
              svg={"/media/svg/icons/Detail/ic_calendar_date.svg"}
            />
          </>
        ) : null}
      </>
    )
  }

  const emptyArea = () => (
    <>
      <Card>
        <CardBody className="py-20 text-center">
          <SVG src={ILLUSTRATION_ASSETS_V2.farmer.IL_EMPTY_STATE_NO_DATA_AREA} />
          <h4 className="mt-12">Petani Belum Memiliki Data Lahan</h4>
        </CardBody>
      </Card>
    </>
  )

  return (
    <>
      {listArea.length > 0 ? (
        <Accordion
          items={listArea.map((e) => ({
            header: loading ? (
              <Skeleton animation="wave" variant={"rect"} width={100} />
            ) : (
              <div className="d-flex align-items-center">
                <h6 className="mb-0 pr-3">{e?.name != null ? e?.name : "Unamed"}</h6>
                <BadgeStatus
                  variant={e?.is_hst ? "primary" : "secondary"}
                  status={e?.is_hst ? "ACTIVE" : "INACTIVE"}
                />
              </div>
            ),
            content: (
              <CardDetail
                titleCardDetail={e?.name}
                descriptionLeft={descriptionLeftArea(e)}
                descriptionRight={descriptionRightArea(e)}
                flatIconAndText2Rows={flatIconAndText2RowsArea(e)}
              />
            )
          }))}
        />
      ) : (
        emptyArea()
      )}
    </>
  )
}
