import React, { useEffect } from "react"
import { CardBody, CardHeader, CardHeaderTitle } from "../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { DetailText, RowText } from "../../../../component"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchDistrictByRegencyId,
  fetchRegencyByProvinceId,
  fetchSubDistrictByDistrictId,
  getListRegionalAll
} from "../../../../redux/actions/MasterDataAction"
import { toStringDate } from "../../../helpers/DateHelper"

export const DetailInputFarmer = ({ formData, editStep }) => {
  const { MasterData, Mitralist } = useSelector((state) => state)
  const mitraName = Mitralist?.data?.filter((mitra) => mitra.id_mitra === formData?.mitraId)[0].name
  const bankName =
    formData?.farmerBankId === undefined
      ? ""
      : MasterData.bankList.filter((bank) => bank.id === formData?.farmerBankId)[0].name
  const regionalList = MasterData?.listRegionalAll ?? []
  const regional = regionalList?.find((e) => e?.id === formData?.regionalId)?.name ?? "-"
  const provinces = MasterData?.provinceList ?? []
  const province = provinces?.find((e) => e?.id === formData?.farmerProvince)?.name ?? "-"
  const regencies = MasterData?.regencyList ?? []
  const regency = regencies?.find((e) => e?.id === formData?.farmerRegency)?.name ?? "-"
  const districts = MasterData?.districtList ?? []
  const district = districts?.find((e) => e?.id === formData?.farmerDistrict)?.name ?? "-"
  const subDistricts = MasterData?.subDistrictList ?? []
  const subDistrict = subDistricts?.find((e) => e?.id === formData?.farmerSubDistrict)?.name ?? "-"

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRegencyByProvinceId(formData?.farmerProvince))
    dispatch(fetchDistrictByRegencyId(formData?.farmerRegency))
    dispatch(fetchSubDistrictByDistrictId(formData?.farmerDistrict))
    dispatch(getListRegionalAll())
  }, [formData])

  return (
    <>
      <CardBody>
        <CardHeaderTitle>{"REVIEW DATA PETANI".toUpperCase()}</CardHeaderTitle>
        <div className={"d-flex justify-content-between align-items-center pb-8 pt-16"}>
          <h4>DATA PETANI</h4>

          <a
            className={"link"}
            onClick={() => {
              editStep(0)
            }}>
            EDIT
          </a>
        </div>

        <RowModule customColumnCss={"col-md-6"}>
          <RowModule customColumnCss={"col-md-12 mb-2"}>
            <RowText label="Regional" value={regional} />
          </RowModule>
          <RowModule customColumnCss={"col-md-12 mb-2"}>
            <RowText label="Nomor Handphone" value={formData?.phoneNumber} />
          </RowModule>
          <RowModule customColumnCss={"col-md-12 mb-2"}>
            <RowText label="Nama Mitra" value={mitraName} />
          </RowModule>
          <RowText
            label="Jenis Kelamin"
            value={
              formData?.gender === "LAKI_LAKI"
                ? "Laki-laki"
                : formData?.gender === "PEREMPUAN"
                ? "Perempuan"
                : "-"
            }
          />
          <RowModule customColumnCss={"col-md-12 mb-2"}>
            <RowText label="Nama Petani" value={formData?.farmerName} />
          </RowModule>
          <RowModule customColumnCss={"col-md-12 mb-2"}>
            <RowText
              label="Alamat Domisili Petani"
              value={`${formData?.farmerAddress}, ${subDistrict} , ${district}, ${regency}, ${province}  `}
            />
          </RowModule>
          <RowModule customColumnCss={"col-md-12 mb-2"}>
            <RowText label="Tanggal Lahir" value={toStringDate(formData?.dob)} />
          </RowModule>
        </RowModule>
        <CardHeader />
        <div className={"pt-10"}>
          <div className={"d-flex justify-content-between align-items-center py-8"}>
            <h4>KTP & SWAFOTO DENGAN KTP</h4>

            <a
              className={"link"}
              onClick={() => {
                editStep(1)
              }}>
              EDIT
            </a>
          </div>
          <RowText label="No. KTP" value={formData?.nomorKtp} />
          <DetailText type="image-dashed" value={formData?.fotoKtp} />
          <DetailText type="image-dashed" value={formData?.swafoto} />
        </div>

        <CardHeader />
        <div className={"pt-10"}>
          <div className={"d-flex justify-content-between align-items-center py-8"}>
            <h4>AKUN BANK</h4>

            <a
              className={"link"}
              onClick={() => {
                editStep(2)
              }}>
              EDIT
            </a>
          </div>
          <RowText label="Nama Bank" value={bankName} />
          <RowText label="Nomor Rekening" value={formData?.farmerBankAccountNumber} />
          <RowText label="Nama Pemilik Rekening" value={formData?.farmerBankAccountName} />
          <CardHeader />
        </div>
      </CardBody>
    </>
  )
}
