import React, { useEffect, useState } from "react"
import { useFormikContext } from "formik"
import { TabsComponent, TextInput } from "../.."
import { Card } from "react-bootstrap"
import "./addressTab.css"
import { useComponentVisible } from "../../../app/hooks"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchDistrictByRegencyId,
  fetchProvince,
  fetchRegencyByProvinceId,
  fetchSubDistrictByDistrictId
} from "../../../redux/actions/MasterDataAction"

export const AddressTab = ({ name = "", placeholder = "", label = "", onChange = () => {} }) => {
  const dispatch = useDispatch()
  const { MasterData } = useSelector(state => state)
  const {
    ref: refAutoHideComponent,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false)
  const { values, setFieldValue } = useFormikContext()
  const provinceKey = `${name}Province`
  const regencyKey = `${name}Regency`
  const districtKey = `${name}District`
  const subDistrictKey = `${name}SubDistrict`
  const addressKey = `${name}Address`

  useEffect(() => {
    if (MasterData.provinceList.length === 0) {
      dispatch(fetchProvince())
    }
  }, [])

  useEffect(() => {
    onChange(values)
  }, [values])

  useEffect(() => {
    if (values[provinceKey]) {
      dispatch(fetchRegencyByProvinceId(values[provinceKey]))
    }
    setFieldValue(regencyKey, null)
  }, [values[provinceKey]])

  useEffect(() => {
    if (values[regencyKey]) {
      dispatch(fetchDistrictByRegencyId(values[regencyKey]))
    }
    setFieldValue(districtKey, null)
  }, [values[regencyKey]])

  useEffect(() => {
    if (values[districtKey]) {
      dispatch(fetchSubDistrictByDistrictId(values[districtKey]))
    }
    setFieldValue(subDistrictKey, null)
  }, [values[districtKey]])

  useEffect(() => {}, values)

  const [search, setSearch] = useState("")

  const onSearch = e => {
    setSearch(e?.target?.value)
  }

  const onFocus = () => {
    setIsComponentVisible(true)
  }

  const renderTabs = () => {
    const labels = ["Provinsi", "Kota", "Kecamatan", "Kelurahan"]
    const components = [
      <LocationList
        key={0}
        value={values[provinceKey]}
        items={MasterData.provinceList}
        onChange={selected => {
          if (selected?.id) {
            setFieldValue(provinceKey, selected?.id)
            setFieldValue(addressKey, selected?.name)
          }
        }}
      />,
      <LocationList
        key={1}
        value={values[regencyKey]}
        items={MasterData.regencyList}
        onChange={selected => {
          if (selected?.id) {
            setFieldValue(regencyKey, selected?.id)
            setFieldValue(addressKey, values[addressKey] + ", " + selected?.name)
          }
        }}
      />,
      <LocationList
        key={2}
        value={values[districtKey]}
        items={MasterData.districtList}
        onChange={selected => {
          if (selected?.id) {
            setFieldValue(districtKey, selected?.id)
            setFieldValue(addressKey, values[addressKey] + ", " + selected?.name)
          }
        }}
      />,
      <LocationList
        key={3}
        value={values[subDistrictKey]}
        items={MasterData.subDistrictList}
        onChange={selected => {
          if (selected?.id) {
            setFieldValue(subDistrictKey, selected?.id)
            setFieldValue(addressKey, values[addressKey] + ", " + selected?.name)
          }
        }}
      />
    ]

    const disabledTabs = [false, false, false, false]

    if (MasterData.regencyList.length == 0) {
      disabledTabs[1] = true
    }

    if (MasterData.districtList.length == 0) {
      disabledTabs[2] = true
    }

    if (MasterData.subDistrictList.length == 0) {
      disabledTabs[3] = true
    }

    return {
      labels,
      components,
      disabledTabs
    }
  }

  return (
    <>
      <div className="position-relative" ref={refAutoHideComponent}>
        <TextInput
          editable={true}
          name={addressKey}
          label={label}
          placeholder={placeholder}
          withoutFeedback={true}
          value={search}
          allowEmpty={true}
          onChange={onSearch}
          onFocus={onFocus}
          onKeyUp={e => {
            if (e?.keyCode === 13) {
              // start search
            }
          }}
        />

        {isComponentVisible && (
          <Card>
            <Card.Body className="p-0">
              <TabsComponent
                labelTab={renderTabs().labels}
                componentTab={renderTabs().components}
                disabledTabs={renderTabs().disabledTabs}
              />
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  )
}

const LocationList = ({ value, items = [], onChange = () => {} }) => {
  return (
    <>
      <Card className="max-h-200px overflow-auto">
        <Card.Body className="p-4">
          {items.map((e, i) => (
            <div
              key={i}
              className={
                (i !== 0 && i !== items.length - 1 ? "my-1" : "") +
                " cursor-pointer" +
                (value === e?.id ? " font-weight-bold text-primary" : "")
              }
              onClick={() => {
                onChange(e)
              }}>
              {e?.name}
            </div>
          ))}
        </Card.Body>
      </Card>
    </>
  )
}
