import { useTranslation } from "react-i18next"
import React, { useEffect, useState } from "react"
import { checkValue, convertDigits, toHectare, toMeter } from "../../../../../helpers/TextHelper.js"
import { formatterRowComponent } from "../../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../../_metronic/_helpers/index.js"
import { timeStampToDate } from "../../../../../helpers/DateHelper.js"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { CardBody } from "../../../../../../_metronic/_partials/controls/index.js"
import { EmptyCard, TableSkeleton, TableView } from "../../../../../../component/index.jsx"
import { useDispatch, useSelector } from "react-redux"
import {
  ICON_CONSTANS,
  ILLUSTRATION_ASSETS_V2
} from "../../../../../../constants/ImageConstants.js"
import { RoutesConstants } from "../../../../../../constants/RoutesConstants.js"
import { useHistory } from "react-router-dom"
import { DetailSvgIcon } from "../../../../../../component/atoms/Icons/SVG/index.jsx"
import { FloatingSelectArea } from "../../../../../../component/atoms/FloatingSelectArea/index.jsx"
import { PopupQuestion } from "../../../../../../component/atoms/Popup/index.jsx"
import {
  cancelSubmissionCertification,
  getSummaryDataCertification
} from "../../../../../../redux/actions/SubmissionAction.jsx"
import { SUBMISSION } from "../../../../../../constants/InitTypeConstants.js"
import { toast } from "react-toastify"
import SVG from "react-inlinesvg"
import { PRIVILEGE_ENUM } from "../../../../../../config/privilegeCollection.jsx"
import { DATE_TYPE_ENUM } from "../../../../../../constants/StringConstant.js"
import { useLocation } from "react-router"

export const CertificationBlockPending = ({
  changePage,
  data,
  activeFilter,
  privilege,
  fetchData
}) => {
  const { t } = useTranslation()
  const { General, Submission } = useSelector((state) => state)
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const [selectedRows, setSelectedRows] = useState([])

  const [openConfirmation, setOpenConfirmation] = useState(false)

  function uiCode(e) {
    return (
      <>
        <div className={"text-dark font-weight-bolder  font-size-lg"}>
          {checkValue(e?.area_code)}
        </div>
        <div className={"d-flex"}>
          <div>{checkValue(e?.sub_district_name)}</div>
        </div>
      </>
    )
  }

  function uiMitra(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {checkValue(e?.mitra_name)}
        </div>
        <div>{checkValue(e?.mitra_code)}</div>
      </div>
    )
  }

  function uiArea(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {toMeter(checkValue(e?.land_area_meter))}
        </div>
        <div>{toHectare(checkValue(e?.land_area_ha))}</div>
      </div>
    )
  }

  function uiVarietas(e) {
    return (
      <>
        <div>{checkValue(e?.variety_name)}</div>
      </>
    )
  }

  function uiFemaleDate(e) {
    return (
      <>
        <div>
          {e?.female_planting_date
            ? timeStampToDate(e?.female_planting_date, DATE_TYPE_ENUM.ISO_DATE_STRAP)
            : "-"}
        </div>
      </>
    )
  }

  function uiDate(e) {
    return (
      <div>
        <div>
          {e?.submitted_date
            ? timeStampToDate(e?.submitted_date, DATE_TYPE_ENUM.ISO_DATE_STRAP)
            : "-"}
        </div>
        <div>
          {e?.submitted_date ? timeStampToDate(e?.submitted_date, DATE_TYPE_ENUM.ISO_TIME_ID) : "-"}
        </div>
      </div>
    )
  }

  function uiCreateBy(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {checkValue(e?.submitted_by)}
        </div>
        <div>{checkValue(e?.submitted_role)}</div>
      </div>
    )
  }

  const uiAction = (e) => {
    return (
      <div>
        <OverlayTrigger overlay={<Tooltip id="products-detail-tooltip">Detail</Tooltip>}>
          <div
            onClick={() => {
              history.push(RoutesConstants.SUBMISSION.DETAIL_AREA_CERTIFICATION_PENDING(e?.area_id))
            }}
            className={"btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"}>
            <DetailSvgIcon />
          </div>
        </OverlayTrigger>
      </div>
    )
  }

  const columns = [
    {
      dataField: "area.name",
      text: t("table_header.area_name"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      },
      sortCaret: sortCaret
    },
    {
      dataField: "mitra",
      text: t("table_header.mitra"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitra
      }
    },
    {
      dataField: "area.landArea",
      text: t("table_header.area"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiArea
      },
      sortCaret: sortCaret
    },
    {
      dataField: "varietas",
      text: t("table_header.varietas"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiVarietas
      }
    },
    {
      dataField: "estimatedFemalePlantingDate",
      text: t("table_header.female_date"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiFemaleDate
      },
      sortCaret: sortCaret
    },
    {
      dataField: "createdDate",
      text: t("table_header.submission_date"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      },
      sortCaret: sortCaret
    },
    {
      dataField: "submitted_by",
      text: t("table_header.submitted_by"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCreateBy
      }
    },
    privilege.indexOf(PRIVILEGE_ENUM.CERTIFICATION_DETAIL_SUBMISSION) > -1 && {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  function checkTotalAreaMeter() {
    let total = 0
    selectedRows.map((item) => {
      total = Number(total) + Number(item.land_area_meter)
    })
    return total
  }

  function checkTotalAreaHa() {
    let total = 0
    selectedRows.map((item) => {
      total = Number(total) + Number(item.land_area_ha)
    })
    return convertDigits(total, 4)
  }

  const totalLandArea = (
    <div className={"d-flex align-items-center"}>
      ({toMeter(checkTotalAreaMeter())} / {toHectare(checkTotalAreaHa())})
    </div>
  )

  const handleRejectSubmission = () => {
    if (selectedRows.length) {
      const body = {
        area_ids: selectedRows.map((item) => item.area_id)
      }
      dispatch(cancelSubmissionCertification(body)).then(() => {
        dispatch(getSummaryDataCertification())
      })
    }
  }

  useEffect(() => {
    if (
      !General.loading &&
      General.actionInitType === SUBMISSION.CANCEL_SUBMISSION &&
      Submission.responseCancelSubmission
    ) {
      toast(Submission.responseCancelSubmission?.message, {
        position: "top-center",
        autoClose: 5000,
        style: {
          background: "#353535"
        },
        icon: <SVG src={ICON_CONSTANS.IC_BULLET_TICK} />,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "dark"
      })
      const result = location?.search
      fetchData(result)
    }
  }, [General, Submission.responseCancelSubmission])

  return (
    <div>
      {selectedRows.length > 0 && !openConfirmation && (
        <FloatingSelectArea
          totalArea={selectedRows.length}
          totalLandArea={totalLandArea}
          buttonlabel={t("label.cancel_submission")}
          withIcon={true}
          handleClick={() => {
            setOpenConfirmation(true)
          }}
          buttonColor={"danger"}
        />
      )}

      {/* Popup for approve */}
      <PopupQuestion
        show={openConfirmation}
        title={t("label.cancel_submission_certification")}
        body={t("messages.confirmation.cancel_submission")}
        textOk="Oke"
        variantOkButton={"danger"}
        onCancel={() => {
          setOpenConfirmation(false)
        }}
        onOk={() => {
          setOpenConfirmation(false)
          setSelectedRows([])
          handleRejectSubmission()
        }}
      />

      <Card>
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={8} row={10} />
          ) : data?.pageData?.length === 0 ? (
            <EmptyCard
              image={
                activeFilter
                  ? ILLUSTRATION_ASSETS_V2.IL_NOT_FOUND_DATA
                  : ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_DATA
              }
              body={
                activeFilter
                  ? t("messages.response.response_not_found_data")
                  : t("messages.response.empty_state", { name: "lahan" })
              }
              subtitle={activeFilter ? t("messages.response.sub_response_not_found_data") : ""}
            />
          ) : (
            ""
          )}
          <TableView
            dataTable={data?.pageData ?? []}
            loadingGetData={false}
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            currentPage={data?.page?.currentPage}
            currentElement={data?.page?.currentElement}
            totalCount={data?.page?.totalCount}
            rowClasses={() => {
              return "cursor-pointer"
            }}
            keyField={"area_id"}
            selectable={privilege.indexOf(PRIVILEGE_ENUM.CERTIFICATION_CANCEL_SUBMISSION) > -1}
            onSelect={(row, isSelected) => {
              let rows = JSON.parse(JSON.stringify(selectedRows))
              if (isSelected) {
                rows.push(row)
              } else {
                rows = rows.filter((e) => e?.area_id !== row?.area_id)
              }
              setSelectedRows(rows)
            }}
            onSelectAll={(isSelect, rows) => {
              let rowses = JSON.parse(JSON.stringify(selectedRows))
              if (isSelect) {
                if (rows?.length > 0) {
                  rows?.map((e) => {
                    rowses.push(e)
                  })
                }
              } else {
                if (rows?.length > 0) {
                  let rowsesNew = rowses
                  rows?.map((row) => {
                    const removeIndex = rowses.findIndex((e) => e?.area_id === row?.area_id)
                    // remove object
                    rowsesNew.splice(removeIndex, 1)
                  })
                  rowses = rowsesNew
                }
              }
              setSelectedRows(rowses)
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}
