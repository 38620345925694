import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import React, { useEffect, useState } from "react"
import { Card, CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { SelectInput, TextAreaInput, TextInput } from "../../../../../component/index.jsx"
import { getVariantAll } from "../../../../../redux/actions/MasterDataAction.jsx"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { ICON_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { Button } from "react-bootstrap"

export const FormTreatment = ({ plantTypeId, isReadOnly, setIsValid, setFormValues, data }) => {
  const dispatch = useDispatch()
  const { values: formValues, errors, setErrors, touched } = useFormikContext()
  const [pesticideList, setPesticideList] = useState([])

  const [countPesticide, setCountPesticide] = useState(1)
  const { General, MasterData } = useSelector((state) => state)

  useEffect(() => {
    //   edit
    if (data) {
      setCountPesticide(data?.variant_list.length)
    }
  }, [data])

  useEffect(() => {
    if (
      formValues?.treatment_name &&
      formValues?.hst_start !== "" &&
      formValues?.hst_end !== "" &&
      formValues?.description &&
      formValues?.list_pesticide?.length > 0 &&
      !errors.hasOwnProperty("list_pesticide")
    ) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [formValues, errors])

  useEffect(() => {
    dispatch(getVariantAll(plantTypeId))
  }, [plantTypeId])

  useEffect(() => {
    if (MasterData.variantList) {
      setPesticideList(
        MasterData.variantList.map((value) => ({
          label: value.name,
          value: value.id,
          isDisabled: false
        }))
      )
    }
  }, [MasterData.variantList])

  useEffect(() => {
    let newPesticideList = pesticideList
    newPesticideList.map((item) => {
      if (
        formValues["list_pesticide"].filter((value) => value?.pesticide_id === item?.value).length >
        0
      ) {
        item.isDisabled = true
      } else {
        item.isDisabled = false
      }
    })
    setPesticideList(newPesticideList)
  }, [formValues, countPesticide])

  const handleAddPesticide = () => {
    setCountPesticide(countPesticide + 1)
  }
  const handleRemovePesticide = (index) => {
    let newFormValues = formValues["list_pesticide"]
    newFormValues.splice(index, 1)
    setFormValues["list_pesticide"] = newFormValues
    setCountPesticide(countPesticide - 1)
    let newError = errors?.list_pesticide
    newError.splice(index, 1)
    setErrors(newError)
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <h4 className="card-label mb-8">DETAIL TREATMENT</h4>
          <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
            <TextInput
              editable={!isReadOnly}
              label="Nama Treatment"
              required={true}
              name="treatment_name"
              placeholder="Input nama treatment"
              type={"text"}
            />
          </RowModule>

          <label className="mt-5 mb-3">
            HST <span className={"text-danger"}>*</span>
          </label>
          <RowModule customColumnCss="mb-5 col-lg-6 col-sm-6 col-md-6">
            <TextInput
              editable={!isReadOnly}
              label="Dari"
              name="hst_start"
              placeholder="Input HST awal"
              type={"text"}
            />
            <TextInput
              editable={!isReadOnly}
              label="Ke"
              name="hst_end"
              placeholder="Input HST akhir"
              type={"text"}
            />
          </RowModule>

          <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
            <TextAreaInput
              editable={isReadOnly}
              label="Deskripsi"
              required={true}
              name="description"
              placeholder="Input deskripsi treatment"
              type={"text"}
            />
          </RowModule>
        </CardBody>
        <div className={"divider"} style={{ marginTop: "32px", marginBottom: "50px" }}></div>
        <CardBody className={"border-bottom-0"}>
          <h4 className="card-label mb-8">DETAIL PESTISIDA</h4>
          {[...Array(countPesticide)].map((x, i) => (
            <div
              key={i}
              style={{
                padding: "28px 24px",
                background: "#F8F8F8",
                borderRadius: "6px",
                border: "1px solid #D1D1D1",
                marginBottom: "32px"
              }}>
              <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
                <SelectInput
                  label={"Nama Pestisida " + (i + 1)}
                  required={true}
                  name={`list_pesticide.${i}.pesticide_id`}
                  editable={!isReadOnly}
                  placeholder="Pilih salah satu"
                  options={pesticideList}
                  withoutFeedback={false}
                  clearNow={true}
                  value={formValues["list_pesticide"][i]?.pesticide_id}
                  isLoading={General.loadingComponent}
                />
                <div className={"color-red"}>
                  {touched[`list_pesticide.${i}.pesticide_id`] &&
                    errors?.list_pesticide?.length &&
                    errors?.list_pesticide[i]?.pesticide_id &&
                    errors?.list_pesticide[i]?.pesticide_id}
                </div>
              </RowModule>
              <RowModule customColumnCss="mb-5 col-lg-12 col-sm-12 col-md-12">
                <TextInput
                  editable={!isReadOnly}
                  required={true}
                  label="Jumlah Dosis"
                  name={`list_pesticide.${i}.total_dose`}
                  placeholder="Input jumlah dosis"
                  type={"text"}
                  value={formValues["list_pesticide"][i]?.total_dose ?? ""}
                />
                <div className={"color-red"}>
                  {touched?.list_pesticide?.length &&
                    touched?.list_pesticide[i]?.total_dose &&
                    errors?.list_pesticide?.length &&
                    errors?.list_pesticide[i]?.total_dose &&
                    errors?.list_pesticide[i]?.total_dose}
                </div>
              </RowModule>
              <div className={"d-flex justify-content-end"}>
                <Button
                  variant={"link"}
                  disabled={i === 0 && countPesticide === 1 ? true : false}
                  onClick={() => handleRemovePesticide(i)}
                  className={`flex align-items-center text-decoration-none`}>
                  <img src={ICON_CONSTANS.IC_REMOVE} alt="remove" />
                  <span className={"color-red ml-4 capitalize"}>Hapus</span>
                </Button>
              </div>
            </div>
          ))}
          <div className={"d-flex justify-content-end align-items-center"}>
            <div
              onClick={() => handleAddPesticide()}
              className="cursor-pointer d-flex align-items-center">
              <SVG
                width={14}
                height={14}
                src={toAbsoluteUrl("/media/svg/icons/Menu/ic_plus_on.svg")}
                className="mr-4"
              />
              <span style={{ fontSize: "14px", color: "#5EC8F2", textDecoration: "underline" }}>
                Tambah Pestisida
              </span>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
