import React, { useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../_metronic/layout"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card } from "react-bootstrap"
import { CardBody } from "../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import {
  DetailText,
  InlineText,
  NoteView,
  RegionSelect,
  RowText,
  Status,
  TableSkeleton
} from "../../../../component"
import BootstrapTable from "react-bootstrap-table-next"
import { formatterRowComponent } from "../../../../component/atoms/Tabel"
import { Popup, PopupQuestion } from "../../../../component/atoms/Popup"
import {
  activitySendToErp,
  confirmationSeasonPlanting,
  getDetailHarvest,
  updateAreaActivityImage
} from "../../../../redux/actions/AreaAction"
import { timeStampToDate } from "../../../helpers/DateHelper"
import { checkValue, toMeter, toRupiah } from "../../../helpers/TextHelper"
import { AREA } from "../../../../constants/InitTypeConstants"
import { RoutesConstants } from "../../../../constants/RoutesConstants"
import { PRIVILEGE_ENUM } from "../../../../config/privilegeCollection"
import { ACTIVITY_ICONS, ILUSTRATION_CONSTANS } from "../../../../constants/ImageConstants"
import { loadUserLogin } from "../../../service/userManagementAction"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import swal from "sweetalert"
import { ActivityDetailHeader } from "../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader"
import { useTranslation } from "react-i18next"
import * as Yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../../util/constStringCollection"
import { Form, Formik } from "formik"
import { ACTIVITY_METHODE_ENUM, ACTIVITY_STATUS_ENUM, HARVEST_TYPE_ENUM } from "../../../../constants/StringConstant"
import { ErpNumber } from "../../../../component/atoms/ErpNumber/ErpNumber"

export const HarvestDetail = ({
  match: {
    params: { status, id, activity_id }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const privileges = loadUserLogin().privilege
  const { t } = useTranslation()
  const formRef = useRef()

  const { MasterData, General, Area } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [popUpInfoIndex, setPopUpInfoIndex] = useState(0)

  const [dataBuyer, setDataBuyer] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [popupContent, setPopupContent] = useState({})
  const [idDocument, setIdDocument] = useState(null)
  const [prefixMenu, setPrefixMenu] = useState(null)
  const [showModalDialog, setShowModalDialog] = useState(false)
  const [swalMessage, setSwalMessage] = useState("")
  const [swalIcon, setSwallIcon] = useState("")
  const [showModalDialogErp, setShowModalDialogErp] = useState(false)
  const [showModalDialogRegion, setShowModalDialogRegion] = useState(false)
  const [initialValue] = useState({
    regional_id: ""
  })
  const [isValid, setIsValid] = useState(false)
  const [regionalValue, setRegionalValue] = useState("")

  const validationSchema = Yup.object().shape({
    regional_id: Yup.string().required(getStringErrorMassage("Regional ", FORM_NEED.harusDiIsi))
  })

  const btnConfirmPlantingSeason = (
    <Button className={"btn btn-primary"} onClick={() => setShowModalDialog(true)}>
      {t("button.confirmation_planting_season")}
    </Button>
  )
  const btnApprove = (
    <Button className={"btn btn-primary"} onClick={() => setShowModalDialogRegion(true)}>
      {t("button.send_to_finance")}
    </Button>
  )

  useEffect(() => {
    dispatch(getDetailHarvest(activity_id)).then()
  }, [])

  useEffect(() => {
    if (Area.data) {
      setDetail(Area.data)
    }
  }, [Area])

  useEffect(() => {
    let newStatus
    if (status === "active") {
      newStatus = "teregistrasi"
    }
    if (detail?.activity_detail_response?.activity_methode_enum === ACTIVITY_METHODE_ENUM.MANUAL) {
      if (
        detail?.status_activity_enum === "APPROVED" &&
        privileges.indexOf(PRIVILEGE_ENUM.HARVEST_CONFIRMATION) > -1
      ) {
        subHeader.setButton(btnConfirmPlantingSeason)
      } else {
        subHeader.setButton(null)
      }
    } else {
      if (
        detail?.status_activity_enum === ACTIVITY_STATUS_ENUM.WAITING ||
        (detail?.status_activity_enum === ACTIVITY_STATUS_ENUM.NEED_BA &&
          privileges.indexOf(PRIVILEGE_ENUM.HARVEST_CONFIRMATION) > -1)
      ) {
        subHeader.setButton(btnApprove)
      } else if (
        detail?.status_activity_enum === "APPROVED" &&
        privileges.indexOf(PRIVILEGE_ENUM.HARVEST_CONFIRMATION) > -1
      ) {
        subHeader.setButton(btnConfirmPlantingSeason)
      } else {
        subHeader.setButton(null)
      }
    }
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
        title: "Lahan"
      },
      {
        pathname: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
        title: newStatus
      },
      {
        pathname: "/lahan/list/" + status + "/" + id,
        title: detail?.area_information?.area_name
      },
      {
        title: "Panen"
      }
    ])
  }, [detail])

  useEffect(() => {
    if (detail) {
      handleDataBuyer()
    }
  }, [detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (General.actionInitType === "UPLOAD") {
        const indexIdDocument = idDocument ?? 0

        const imageId =
          prefixMenu && prefixMenu === AREA.ACTIVITY_HARVEST_ESTIMATED
            ? detail?.harvest_estimate?.image_and_id_activity[indexIdDocument]?.id
            : detail?.activity_detail_response?.image_and_id_activity[indexIdDocument]?.id

        const data = {
          id: imageId,
          url: MasterData.urlResponseUpload
        }

        dispatch(updateAreaActivityImage(data))
      } else if (General.actionInitType === AREA.UPDATE_ACTIVITY_IMAGE) {
        dispatch(getDetailHarvest(activity_id))
      } else if (General.actionInitType === AREA.PLANTING_SEASON_CONFIRMATION) {
        swal({
          text: swalMessage,
          icon: swalIcon
        })
        dispatch(getDetailHarvest(activity_id))
      } else if (
        General.loading === false &&
        General.actionInitType === AREA.APPROVE_ACTIVITY_TO_ERP
      ) {
        setShowModalDialogErp(false)
        swal({
          icon: ILUSTRATION_CONSTANS.SUCCESS_FORM,
          text: t("messages.response.activity_approve_to_erp_success")
        })
        dispatch(getDetailHarvest(activity_id))
      }
    }
  }, [General])
  const titleActivity = (
    <ActivityDetailHeader
      title={"AkTIVITAS PANEN"}
      icon={ACTIVITY_ICONS.IC_PANEN}
      rightContent={
        detail?.activity_detail_response?.activity_methode_enum !==
          ACTIVITY_METHODE_ENUM.MANUAL && (
          <Status
            variant={detail?.status_activity_enum}
            text={
              detail?.status_activity_string === "Pending to ERP"
                ? "Pending To Finance"
                : detail?.status_activity_string
            }
          />
        )
      }
      bottomContent={
        (detail?.status_activity_enum === ACTIVITY_STATUS_ENUM.APPROVED ||
          detail?.status_activity_enum === ACTIVITY_STATUS_ENUM.COMPLETED) && (
          <ErpNumber
            noFaktur={detail?.no_faktur}
            noOrderSj={detail?.no_order_sj}
            noOrderSpk={detail?.no_order_spk}
            noOrderErp={detail?.no_order_erp}
            status={detail?.activity_type_enum}
          />
        )
      }
    />
  )

  const openPopup = (content) => {
    setPopupContent(content)
    setShowPopup(true)
  }

  const bodyPopup = (
    <RowModule customColumnCss={"col-md-12 mb-2"}>
      <RowModule>
        <InlineText
          type={"between"}
          title={"ID Alsintan"}
          value={popupContent?.machine_code ?? "-"}
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Nama Pemilik"}
          value={
            popupContent?.supplier_enum === "THIRD_PARTY" ||
            popupContent?.supplier_enum === "OTHER_FARMER" ||
            popupContent?.supplier_enum === "OTHER_MITRA" ||
            popupContent?.supplier_enum === "OTHER_VENDOR"
              ? "Pihak ke-3" + " - " + popupContent?.supplier_third_party_string
              : popupContent?.supplier_name ?? "-"
          }
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Pemilik Alsintan"}
          value={popupContent?.owner_name ?? "-"}
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Nama Alsintan"}
          value={popupContent?.machine_name ?? "-"}
        />
      </RowModule>
      <RowModule>
        <InlineText
          type={"between"}
          title={"Nomor Mesin"}
          value={
            popupContent?.engine_number === null || popupContent?.engine_number === ""
              ? "-"
              : popupContent?.engine_number
          }
        />
      </RowModule>
    </RowModule>
  )

  const overviewPerbedaan = (
    <Card className={"mb-4"}>
      <CardBody>
        <NoteView
          variant={detail?.status_activity_enum === "NEED_BA" ? "warning" : "completed"}
          title={
            detail?.status_activity_enum === "NEED_BA"
              ? "Terdapat perbedaan luas lahan yang dikerjakan alsintan dengan luas lahan manual. Harap membuat berita acara."
              : detail?.have_ba
              ? "Berita acara untuk perbedaan luas pengerjaan menggunakan alsintan telah dibuat."
              : ""
          }
        />
        <h4 className={"mb-6 text-uppercase"}>OVERVIEW PERBEDAAN LUAS PENGERJAAN</h4>
        <RowModule customColumnCss={"col-md-12 mb-2"}>
          <RowText
            label={"Luas Lahan (Manual)"}
            value={
              detail?.area_information?.area_land_manual
                ? toMeter(detail?.area_information?.area_land_manual)
                : "-"
            }
          />
          <RowText
            label={"Luas Lahan Dikerjakan (Alsintan)"}
            value={
              detail?.activity_detail_response?.worked_area
                ? toMeter(detail?.activity_detail_response?.worked_area)
                : "-"
            }
          />
          <RowText
            label={"Alasan"}
            value={
              detail?.overview_different?.reason ? `${detail?.overview_different?.reason}` : "-"
            }
          />
        </RowModule>
        <h5 className={"mb-4"}>Bukti Terupload</h5>
        <div className={"row"}>
          {detail?.overview_different?.images &&
            detail?.overview_different?.images?.map((item, index) => {
              return (
                <div className={"col-md-3"} key={index}>
                  <DetailText
                    type="image"
                    value={item}
                    indexKey={index}
                    menu={AREA.ACTIVITY_HARVEST}
                    setIdDocument={setIdDocument}
                  />
                </div>
              )
            })}
        </div>
      </CardBody>
    </Card>
  )

  const informasiLahan = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>INFORMASI LAHAN</h4>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText label={"Lahan"} value={detail?.area_information?.area_name ?? "-"} />
          <RowText
            label={"Luas Lahan (Manual)"}
            value={
              detail?.area_information?.area_land_manual
                ? toMeter(detail?.area_information?.area_land_manual)
                : "-"
            }
          />
          <RowText
            label={"Petani"}
            value={
              detail?.area_information?.farmer_name
                ? `${detail?.area_information?.farmer_name} (${detail?.area_information?.farmer_code})`
                : "-"
            }
            hasIcon={detail?.area_information?.has_kur}
            icon={detail?.area_information?.bank_image}
          />
          <RowText
            label={"Luas Lahan (Polygon)"}
            value={
              detail?.area_information?.area_land_polygon
                ? toMeter(detail?.area_information?.area_land_polygon)
                : "-"
            }
          />
          <RowText
            label={"Mitra"}
            value={
              detail?.area_information?.mitra_name
                ? `${detail?.area_information?.mitra_name} (${detail?.area_information?.mitra_code})`
                : "-"
            }
          />
          <RowText
            label={"Jenis Tanaman"}
            value={detail?.activity_detail_response?.plant_type_name ?? "-"}
          />
          <RowText label={"Lokasi Lahan"} value={detail?.area_information?.full_address ?? "-"} />
          <RowText
            label={"Varietas Tanaman"}
            value={detail?.area_information?.variety_name ?? "-"}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  const detailActivitas = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 mt-6 text-uppercase"}>Detail Aktivitas</h4>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText
            label={"Submitted by"}
            value={detail?.activity_detail_response?.submitted_by ?? "-"}
          />
          <RowText
            label={"HST"}
            value={
              isNaN(detail?.activity_detail_response?.hst)
                ? "-"
                : `${detail?.activity_detail_response?.hst} HST`
            }
          />
          <RowText
            label={"Submitted time"}
            value={
              detail?.activity_detail_response?.submitted_date
                ? timeStampToDate(
                    detail?.activity_detail_response?.submitted_date,
                    "dd-MM-yyyy - HH:mm:ss"
                  )
                : "-"
            }
          />
          <RowText
            label={"Metode Panen"}
            value={checkValue(detail?.activity_detail_response?.activity_methode_string)}
          />
          <RowText
            label={"Requested by"}
            value={detail?.activity_detail_response?.request_by_string ?? "-"}
          />
          {detail?.activity_detail_response?.activity_methode_enum !== "MANUAL" ? (
            <RowText
              label={"Luas Lahan Dikerjakan (Alsintan)"}
              value={
                detail?.activity_detail_response?.worked_area
                  ? toMeter(detail?.activity_detail_response?.worked_area)
                  : "-"
              }
            />
          ) : (
            <></>
          )}
          <RowText
            label={"Tanggal Aktivitas"}
            value={detail?.start_date + " s/d " + detail?.end_date}
          />
        </RowModule>

        {detail?.activity_detail_response?.activity_methode_enum !== "MANUAL" ? null : detail?.tools?.map((item, index) => {
          return (
            <Card className={"mb-10"} bg={"secondary"} key={index}>
              <CardBody>
                <h5 className={"mb-6"}>Alsintan {index + 1}</h5>
                <RowModule customColumnCss={"col-md-6 mb-2"}>
                  <RowText
                    label={"Pemilik Alsintan"}
                    value={
                      item?.supplier_enum === "THIRD_PARTY" ||
                      item?.supplier_enum === "OTHER_FARMER" ||
                      item?.supplier_enum === "OTHER_MITRA" ||
                      item?.supplier_enum === "OTHER_VENDOR"
                        ? "Pihak ke-3" + " - " + item?.supplier_third_party_string
                        : item?.supplier_name ?? "-"
                    }
                  />
                  <RowText
                    label={"Operator"}
                    value={
                      item?.operators && item.operators.length
                        ? item.operators.map((e) => e.operator_name).join(", ")
                        : "-"
                    }
                  />
                  <RowText label={"Nama Pemilik Alsintan"} value={item?.owner_name ?? "-"} />
                  {item?.use_implement ? (
                    <RowText label={"Implement"} value={item?.implement_name ?? "-"} />
                  ) : (
                    <></>
                  )}
                  <RowText
                    label={"Alsintan"}
                    value={item?.machine_name ? item?.machine_name : "-"}
                  />
                </RowModule>
                <a
                  className="hover"
                  style={{
                    textDecorationLine: "underline",
                    color: "#5EC8F2"
                  }}
                  onClick={() => {
                    openPopup(item)
                  }}>
                  Lihat Detail
                </a>
              </CardBody>
            </Card>
          )
        })}

        <div className={"my-10"}>
          <h4 className={"mb-6"}>CATATAN</h4>
          <div className={"row mb-4"}>
            {detail?.activity_detail_response?.image_and_id_activity &&
              detail?.activity_detail_response?.image_and_id_activity?.map((item, index) => {
                return (
                  <div className={"col-md-3"} key={index}>
                    <DetailText
                      type="image"
                      value={item.url}
                      indexKey={index}
                      menu={AREA.ACTIVITY_HARVEST}
                      setIdDocument={setIdDocument}
                    />
                  </div>
                )
              })}
          </div>
          <span className="font-weight-bold text-dark-50 font-size-sm">
            {detail?.activity_detail_response?.note ?? "-"}
          </span>
        </div>
      </CardBody>
    </Card>
  )

  const estimated = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6"}>DATA ESTIMASI PANEN</h4>
        <RowModule customColumnCss={"col-md-6 mb-2"}>
          <RowText
            label={"Requested by"}
            value={detail?.harvest_estimate?.request_by_string ?? "-"}
          />
          <RowText
            label={"Estimasi Panen"}
            value={
              detail?.harvest_estimate?.start_date + " s/d " + detail?.harvest_estimate?.end_date
            }
          />
          <RowText
            label={"Tanggal Survey"}
            value={
              detail?.harvest_estimate?.survey_date ? detail?.harvest_estimate?.survey_date : "-"
            }
          />
          <RowText
            label={"HST"}
            value={
              isNaN(detail?.harvest_estimate?.hst_harvest)
                ? "-"
                : `${detail?.harvest_estimate?.hst_harvest} HST`
            }
          />
          <RowText
            label={"Estimasi Luas Lahan Dikerjakan (Alsintan)"}
            value={
              detail?.harvest_estimate?.worked_area
                ? toMeter(detail?.harvest_estimate?.worked_area)
                : "-"
            }
          />
        </RowModule>
        <div className={"my-10"}>
          <h4 className={"mb-6"}>CATATAN</h4>
          <div className={"row mb-4"}>
            {detail?.harvest_estimate?.image_and_id_activity &&
              detail?.harvest_estimate?.image_and_id_activity?.map((item, index) => {
                return (
                  <div className={"col-md-3"} key={index}>
                    <DetailText
                      type="image"
                      value={item.url}
                      indexkey={index}
                      menu={AREA.ACTIVITY_HARVEST_ESTIMATED}
                      setIdDocument={setIdDocument}
                      setPrefixMenu={setPrefixMenu}
                    />
                  </div>
                )
              })}
          </div>
          <span className="font-weight-bold text-dark-50 font-size-sm">
            {detail?.harvest_estimate?.note ?? "-"}
          </span>
        </div>
      </CardBody>
    </Card>
  )

  const columnsHarvest = [
    {
      dataField: "no",
      text: "No",
      headerClasses: "bg-gray"
    },
    {
      dataField: "parameter",
      text: "Parameter",
      headerClasses: "bg-gray"
    },
    detail?.harvest_estimate ?
    {
      dataField: "harvest_estimate",
      text: "Estimasi Panen",
      headerClasses: "bg-gray"
    } : {headerClasses: "bg-gray"},
    {
      dataField: "harvest",
      text: "Realisasi Panen",
      headerClasses: "bg-gray"
    }
  ]

  const dataHarvest = (type) => {
    let index = 0
    let data = [
      {
        no: "1",
        parameter: "Umur Panen",
        harvest_estimate: checkValue(detail?.hst_harvest) + " HST",
        harvest: checkValue(detail?.hst_harvest) + " HST"
      },
      {
        no: "2",
        parameter: "Kadar Air",
        harvest_estimate: "-",
        harvest: checkValue(detail?.water_contain) + "%"
      }
    ]

    if (type == "Jagung") {
      data.push({
        no: "3",
        parameter: "Biji Pecah",
        harvest_estimate: "-",
        harvest: checkValue(detail?.broken_seed) + "%"
      })
      index = 1
    }
    data.push({
      no: 3 + index,
      parameter: "Kotoran",
      harvest_estimate: "-",
      harvest: detail?.waste ? detail?.waste + "%" : "-"
    })
    data.push({
      no: 4 + index,
      parameter: "Bobot",
      harvest_estimate: checkValue(detail?.harvest_estimate?.weight) + " Kg",
      harvest: checkValue(detail?.weight) + " Kg"
    })

    return data
  }

  const harvest = (
    <>
      {General.loading ? (
        <TableSkeleton column={4} row={3} />
      ) : (
        <Card className={"mb-4"}>
          <CardBody>
            <h4 className={"mb-6"}>DATA PANEN</h4>
            <div className={"border rounded table-small-padding"}>
              <BootstrapTable
                condensed
                bordered={false}
                columns={columnsHarvest}
                keyField={"no"}
                data={dataHarvest(detail?.plant_name)}
              />
            </div>
          </CardBody>
          <div className="separator separator-solid" />
        </Card>
      )}
    </>
  )

  const uiTotal = ({ total_price }) => {
    return (
      <>
        <div className={"d-flex"}>
          <span className={"mr-8"}>{toRupiah(total_price) ?? "-"}</span>
        </div>
      </>
    )
  }
  const uiDetail = ({ no }) => {
    return (
      <>
        <div className={"d-flex"}>
          <a
            className={"link"}
            onClick={() => {
              setShowModal(true)
              setPopUpInfoIndex(no - 1)
            }}>
            Detail
          </a>
        </div>
      </>
    )
  }

  const uiWeight = (e) => {
    return <>{e?.weight ? e?.weight + " Kg" : "-"}</>
  }
  const uiPrice = (e) => {
    return <>{toRupiah(e?.price_per_kg) ?? "-"}</>
  }
  const uiName = (e) => {
    return <>{e?.buyer_id.name ?? "-"}</>
  }
  const uiType = (e) => {
    return <>{typeName(e?.buyer_id)}</>
  }

  const typeName = (data) => {
    if (data?.type) {
      if (data?.is_self_buying) {
        return "Petani"
      } else {
        if (data?.type == "INDIVIDUAL") {
          return "Perseorangan"
        } else if (data?.type == "FACTORY") {
          return "Pabrik"
        } else {
          return "-"
        }
      }
    } else {
      return "-"
    }
  }
  const columnsBuyer = [
    {
      dataField: "no",
      text: "No",
      headerClasses: "bg-gray"
    },
    {
      dataField: "name",
      text: "Nama",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      }
    },
    {
      dataField: "type",
      text: "Tipe",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiType
      }
    },
    {
      dataField: "weight",
      text: "Bobot",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiWeight
      }
    },
    {
      dataField: "price_per_kg",
      text: "Harga/Kg",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiPrice
      }
    },
    {
      dataField: "total_price",
      text: "Total",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiTotal
      }
    },
    {
      dataField: "total_price",
      text: "",
      headerClasses: "bg-gray",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDetail
      }
    }
  ]

  const handleDataBuyer = () => {
    let data = []
    detail?.buyer_list?.map((item, index) => {
      return data.push({
        ...item,
        no: index + 1
      })
    })
    setDataBuyer(data)
  }

  const buyer = (
    <>
      {General.loading ? (
        <TableSkeleton column={4} row={3} />
      ) : (
        <Card className={"mb-4"}>
          <CardBody>
            <h4 className={"mb-6"}>DATA PEMBELI</h4>
            {dataBuyer.length === 1 && dataBuyer[0].buyer_id.is_self_buying && (
              <NoteView variant={"success"} title={"Semua hasil panen dibeli oleh petani lahan."} />
            )}
            <div className={"border rounded table-small-padding"}>
              <BootstrapTable
                condensed
                bordered={false}
                columns={columnsBuyer}
                keyField={"no"}
                data={dataBuyer}
              />
            </div>
          </CardBody>
        </Card>
      )}
    </>
  )

  const modalBody = (index) => {
    return (
      <>
        <div className={"mb-10 px-6"}>
          <h5 className={"mb-6"}>Data Pembeli</h5>
          <RowModule customColumnCss={"col-md-12 mb-1"}>
            <RowModule>
              <InlineText
                type={"between"}
                title={"Tipe Pembeli"}
                value={typeName(dataBuyer[index]?.buyer_id) ?? "-"}
              />
            </RowModule>
            <RowModule>
              <InlineText
                type={"between"}
                title={"Nama Pembeli"}
                value={dataBuyer[index]?.buyer_id?.name ?? "-"}
              />
            </RowModule>
            <RowModule>
              <InlineText
                type={"between"}
                title={"No Telepon"}
                value={dataBuyer[index]?.buyer_id?.no_telp ?? "-"}
              />
            </RowModule>
            <RowModule>
              <InlineText
                type={"between"}
                title={"Alamat"}
                value={dataBuyer[index]?.buyer_id?.address ?? "-"}
              />
            </RowModule>
          </RowModule>
        </div>
        <div className={"px-6"}>
          <h5 className={"mb-6"}>Data Hasil Panen Terjual</h5>
          <RowModule customColumnCss={"col-md-12 mb-2"}>
            {typeName(dataBuyer[index]?.buyer_id) !== "Petani" && (
              <RowModule>
                <InlineText
                  type={"between"}
                  title={"Harga Per Kg"}
                  value={toRupiah(dataBuyer[index]?.price_per_kg) ?? "-"}
                />
              </RowModule>
            )}
            <RowModule>
              <InlineText
                type={"between"}
                title={"Bobot"}
                value={dataBuyer[index]?.weight ? dataBuyer[index]?.weight + " Kg" : "-"}
              />
            </RowModule>
            <RowModule>
              <InlineText
                type={"between"}
                title={"Jumlah Karung"}
                value={dataBuyer[index]?.total_sacks ? dataBuyer[index]?.total_sacks + " Sak" : "-"}
              />
            </RowModule>
            {typeName(dataBuyer[index]?.buyer_id) !== "Petani" && (
              <RowModule>
                <InlineText
                  type={"between"}
                  title={"Total Harga"}
                  value={toRupiah(dataBuyer[index]?.total_price) ?? "-"}
                />
              </RowModule>
            )}
          </RowModule>
        </div>
      </>
    )
  }

  const modulPopUpInfoDetail = (
    <>
      <Popup
        show={showModal}
        title={"Data Pembeli " + (popUpInfoIndex + 1)}
        bodyCustom={modalBody(popUpInfoIndex)}
        textButton="Ok"
        onClick={() => {
          setShowModal(false)
        }}
        type={"nonImage"}
      />
    </>
  )

  return (
    <>
      {modulPopUpInfoDetail}
      <PopupQuestion
        onCancel={() => setShowModalDialogRegion(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.activity_select_regional")}
        onOk={() => {
          setShowModalDialogRegion(false)
          setShowModalDialogErp(true)
        }}
        disable={!isValid}
        show={showModalDialogRegion}
        bodyCustom={
          <Formik
            enableReinitialize
            initialValues={initialValue}
            innerRef={formRef}
            validationSchema={validationSchema}>
            {() => (
              <Form id={"formRegion"} className={"form"}>
                <div className={"mt-4"}>
                  <RegionSelect setIsValid={setIsValid} setRegionalValue={setRegionalValue} />
                </div>
              </Form>
            )}
          </Formik>
        }
      />
      <PopupQuestion
        onCancel={() => setShowModalDialogErp(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.activity_send_to_erp")}
        onOk={() => {
          dispatch(
            activitySendToErp(activity_id, {
              regional_id: regionalValue
            })
          )
          setShowModalDialogErp(false)
        }}
        show={showModalDialogErp}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {t("messages.confirmation.activity_approve_to_erp_confirmation")}
            </p>
          </RowModule>
        }
      />
      <PopupQuestion
        onCancel={() => setShowModalDialog(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.planting_season_confirmation")}
        onOk={() => {
          dispatch(confirmationSeasonPlanting(detail?.activity_detail_response?.area_id))
          setShowModalDialog(false)
          setSwalMessage("Musim tanam berhasil dikonfirmasi.")
          setSwallIcon(toAbsoluteUrl(ILUSTRATION_CONSTANS.PLANTING_SUCCESS))
        }}
        show={showModalDialog}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {detail.have_ba
                ? t("messages.confirmation.planting_season_confirmation_need_ba")
                : t("messages.confirmation.planting_season_confirmation")}
            </p>
          </RowModule>
        }
      />
      {titleActivity}
      {detail?.have_ba ? overviewPerbedaan : null}
      {informasiLahan}
      {detailActivitas}
      {detail?.harvest_type ? null : estimated}
      {detail?.harvest_type ? null : harvest}
      {detail?.harvest_type === HARVEST_TYPE_ENUM.FREE_MARKET && buyer}

      <Popup
        show={showPopup}
        title={`DETAIL ALSINTAN ${
          popupContent?.supplier_name ? popupContent?.supplier_name?.toUpperCase() : ""
        }`}
        bodyCustom={bodyPopup}
        textButton="Oke"
        onClick={() => {
          setShowPopup((Popup) => {
            !Popup
          })
        }}
      />
    </>
  )
}
