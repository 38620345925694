import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { useHistory } from "react-router-dom"
import { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../_metronic/_helpers/index.js"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { checkValue, toMeter } from "../../../../helpers/TextHelper.js"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { EmptyCard, TableSkeleton, TableView } from "../../../../../component/index.jsx"
import { PRIVILEGE_ENUM } from "../../../../../config/privilegeCollection.jsx"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { linkWithCtrl } from "../../../../helpers/Utils.js"
import React from "react"

export const SprayingPascaTanamScheduled = ({ changePage, activeFilter, data }) => {
  const { t } = useTranslation()
  const { General } = useSelector((state) => state)
  const params = useParams()
  const privileges = loadUserLogin().privilege
  const history = useHistory()

  const columns = [
    {
      dataField: "plantingSeason.area.name",
      text: t("table_header.area_name"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      },
      headerStyle: () => {
        return { width: "20%" }
      },
      sortCaret: sortCaret
    },
    {
      dataField: "mitra",
      text: t("table_header.mitra"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitra
      },
      sortCaret: sortCaret
    },
    {
      dataField: "farmer",
      text: t("table_header.farmer"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiFarmer
      },
      sortCaret: sortCaret
    },
    {
      dataField: "activity_name",
      text: t("table_header.activity_type"),
      sort: false,
      formatter: formatterRowComponent,
      sortCaret: sortCaret,
      headerStyle: () => {
        return { width: "20%" }
      },
      formatExtraData: {
        externalStyle: uiActivity
      }
    },
    {
      dataField: "scheduleDate",
      text: t("table_header.activity_date"),
      sort: true,
      sortCaret: sortCaret,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      }
    },
    {
      dataField: "modifiedDate",
      text: t("table_header.scheduled_date"),
      sort: true,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiScheduling
      },
      sortCaret: sortCaret
    },
    {
      dataField: "scheduled_by",
      text: t("table_header.scheduled_by"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiUser
      },
      sortCaret: sortCaret
    }
  ]

  function uiScheduling(e) {
    return (
      <div>
        <div>{e?.created_date ? timeStampToDate(e?.created_date, "dd-MM-yyyy") : "-"}</div>
        <div>{e?.created_date ? timeStampToDate(e?.created_date, "HH:mm:ss") : "-"}</div>
      </div>
    )
  }

  function uiUser(e) {
    return (
      <>
        <div className={"text-dark font-weight-bolder  font-size-lg"}>
          {checkValue(e?.scheduler_name)}
        </div>
        <div>{checkValue(e?.scheduler_role)}</div>
      </>
    )
  }

  function uiCode(e) {
    return (
      <>
        <div className={"text-dark font-weight-bolder  font-size-lg"}>
          {checkValue(e?.area_name)}
        </div>
        <div className={"d-flex"}>
          <div>{checkValue(e?.sub_district)},&nbsp;</div>
          <div>{toMeter(e?.land_area)}</div>
        </div>
      </>
    )
  }

  function uiDate(e) {
    return (
      <div>
        <div>{e?.activity_date ? timeStampToDate(e?.activity_date, "dd-MM-yyyy") : "-"}</div>
        <div>{checkValue(e?.hst)} HST</div>
      </div>
    )
  }

  function uiMitra(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {checkValue(e?.mitra_name)}
        </div>
        <div>{checkValue(e?.mitra_code)}</div>
      </div>
    )
  }

  function uiFarmer(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder  font-size-lg mb-1"}>
          {checkValue(e?.farmer_name)}
        </div>
        <div>{checkValue(e?.farmer_code)}</div>
      </div>
    )
  }

  function uiActivity(e) {
    return (
      <div>
        <div className={"text-dark font-weight-bolder font-size-lg"}>
          {checkValue(e?.activity_name)}
        </div>
      </div>
    )
  }

  return (
    <div>
      <Card>
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={6} row={10} />
          ) : data?.pageData?.length === 0 ? (
            <EmptyCard
              body={
                activeFilter
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state", { name: "penyemprotan" })
              }
            />
          ) : (
            ""
          )}
          <TableView
            dataTable={data?.pageData ?? []}
            loadingGetData={false}
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            currentPage={data?.page?.currentPage}
            currentElement={data?.page?.currentElement}
            totalCount={data?.page?.totalCount}
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_ORDER)) {
                  const url = RoutesConstants.ORDER.DETAIL_PASCA_PLANTING(params?.tab, row?.id)
                  if (e.ctrlKey) {
                    linkWithCtrl(url)
                  } else {
                    history.push(url)
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}
