import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { CardBody } from "../../../../_metronic/_partials/controls/index.js"
import { EmptyCard, RowText } from "../../../../component/index.jsx"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"
import TableView, { formatterRowComponent } from "../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../_metronic/_helpers/index.js"
import { DeleteSvgIcon, EditSvgIcon } from "../../../../component/atoms/Icons/SVG/index.jsx"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteSubRegionalActions,
  getDetailRegional
} from "../../../../redux/actions/MasterDataAction.jsx"
import { Link } from "react-router-dom"
import { useParams } from "react-router"
import { PopupQuestion } from "../../../../component/atoms/Popup/index.jsx"
import { MASTER_DATA } from "../../../../constants/InitTypeConstants.js"
import swal from "sweetalert"

export function DetailRegionalPage() {
  const { t } = useTranslation()
  const { MasterData, General } = useSelector((state) => state)

  const subheader = useSubheader()
  const dispatch = useDispatch()
  const { id } = useParams()

  const [dataDetail, setDataDetail] = useState()
  const [listDataSubRegional, setListDataSubRegional] = useState([])
  const [showPopupDelete, setShowPopupDelete] = useState(false)
  const [dataDelete, setDataDelete] = useState(undefined)

  useEffect(() => {
    if (dataDetail?.list_sub_regional) {
      setListDataSubRegional(dataDetail?.list_sub_regional)
    }
  }, [dataDetail])

  useEffect(() => {
    if (MasterData?.detailRegional) {
      setDataDetail(MasterData.detailRegional)
    }
  }, [MasterData])

  useEffect(() => {
    fetchData()
  }, [id])

  function fetchData() {
    if (id) {
      dispatch(getDetailRegional(id))
    }
  }

  useEffect(() => {
    subheader.setButton(buttonEditRegional())
    subheader.setBreadcrumbs([
      {
        pathname: RoutesConstants.MASTER_DATA.REGIONAL.PAGE(),
        title: t("regional")
      },
      {
        title: dataDetail?.regional_name || ""
      }
    ])
  }, [dataDetail])

  const columns = [
    {
      dataField: "code",
      text: t("label.id_sub_regional"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.code ?? "-"}</div>
        )
      },
      sortCaret: sortCaret
    },
    {
      dataField: "code",
      text: t("label.sub_regional_name"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.name ?? "-"}</div>
        )
      },
      sortCaret: sortCaret
    },
    {
      dataField: "code",
      text: t("label.district_or_city"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div className="text-dark  mb-1 font-size-lg">{e?.regency_list ?? "-"}</div>
        )
      },
      sortCaret: sortCaret
    },
    {
      dataField: "action",
      text: "Action",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  useEffect(() => {
    if (
      General.isFinishUpdateOrDelete &&
      General.actionInitType === MASTER_DATA.DELETE_SUB_REGIONAL
    ) {
      swal({
        text: "Sub regional berhasil dihapus",
        icon: ILLUSTRATION_ASSETS_V2.IL_SUCCESS_DELETE,
        buttons: {
          okey: { text: "Ok", className: "sweet-success" }
        }
      })
      fetchData()
      setShowPopupDelete(false)
    }
  }, [General])
  function onClickDelete(e) {
    setShowPopupDelete(true)
    setDataDelete(e)
  }

  function onSubmitDeleteRegional() {
    if (dataDelete?.id) {
      dispatch(deleteSubRegionalActions(dataDelete?.id)).then()
    }
  }

  function buttonEditRegional() {
    if (id) {
      return (
        <Link to={RoutesConstants.MASTER_DATA.REGIONAL.EDIT_REGIONAL(id)}>
          <button className={"btn btn-outline-primary btn-white-regional"}>
            {t("label.edit_data_regional")}
          </button>
        </Link>
      )
    }
  }

  function uiAction(e) {
    return (
      <div className={"d-flex"}>
        <Link to={RoutesConstants.MASTER_DATA.REGIONAL.EDIT_SUB_REGIONAL(e?.id, id)}>
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <div className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3">
              <EditSvgIcon />
            </div>
          </OverlayTrigger>
        </Link>

        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Hapus</Tooltip>}>
          <div
            onClick={() => onClickDelete(e)}
            className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3">
            <DeleteSvgIcon />
          </div>
        </OverlayTrigger>
      </div>
    )
  }

  function componentDeletePopup() {
    return (
      <div className={"text-center"} style={{ textAlign: "center" }}>
        Apakah anda yakin ingin menghapus <strong>{dataDelete?.name}</strong>? Tindakan ini akan
        berpengaruh kepada semua data yang terkait dengan sub regional ini.
      </div>
    )
  }
  return (
    <div>
      <PopupQuestion
        textCancel={t("button.cancel")}
        textOk={t("label.delete_sub_regional")}
        variantOkButton={"danger"}
        onOk={onSubmitDeleteRegional}
        onCancel={() => setShowPopupDelete(false)}
        title={t("label.delete_sub_regional").toUpperCase()}
        show={showPopupDelete}
        bodyCustom={componentDeletePopup()}
      />
      <Card>
        <CardBody>
          <h1>{dataDetail?.regional_name || "-"}</h1>
          <div className={"d-flex"} style={{ gap: "10%", marginTop: 24 }}>
            <RowText label={t("label.regional_id")} value={dataDetail?.regional_code || "-"} />
            <RowText label={t("label.province")} value={dataDetail?.province_name || "-"} />
            <RowText
              label={t("label.sub_regional_amount")}
              value={dataDetail?.total_sub_regional || "0"}
            />
          </div>
        </CardBody>
      </Card>
      <div style={{ marginTop: 32 }}>
        <Card>
          <CardBody>
            <div>
              <div className={"d-flex align-items-center justify-content-between"}>
                <h1>{t("label.sub_regional")}</h1>
                <Link to={RoutesConstants.MASTER_DATA.REGIONAL.CREATE_SUB_REGIONAL(id)}>
                  <button className="btn btn-primary">{t("label.add_sub_regional")}</button>
                </Link>
              </div>
              <div className={"mt-5"}>
                {listDataSubRegional.length > 0 ? (
                  <TableView
                    showingTotalPage={false}
                    columnProperties={columns}
                    dataTable={listDataSubRegional}
                    ignoreSinglePage
                  />
                ) : (
                  <EmptyCard
                    image={ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_NO_DATA}
                    body={t("messages.response.empty_state", { name: t("label.sub_regional") })}
                  />
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}
