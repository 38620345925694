import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { SelectInput, TextInput } from "../../../../component"
import React, { useEffect, useState } from "react"
import { fetchBanks } from "../../../../redux/actions/MasterDataAction"
import { ErrorMessage, useFormikContext } from "formik"

export const AkunBank = ({ isReadOnly, setDisableBtn }) => {
  const { values: formValues } = useFormikContext()
  const { MasterData } = useSelector(state => state)

  const dispatch = useDispatch()

  const [farmerBankId, setFarmerBankId] = useState(null)
  const [bankList, setBankList] = useState([])

  const renderError = message => (
    <p className="help is-danger">
      <b>{message}</b>
    </p>
  )

  useEffect(() => {
    dispatch(fetchBanks())
  }, [])

  useEffect(() => {
    if (MasterData.bankList.length !== 0) {
      setBankList(
        MasterData.bankList.map(value => ({
          label: value.name,
          value: value.id
        }))
      )
    }
  }, [MasterData.bankList])

  function onChangeBank(e) {
    setFarmerBankId(e)
  }

  useEffect(() => {
    if (
      formValues?.farmerBankId &&
      (!formValues?.farmerBankAccountName || !formValues?.farmerBankAccountNumber)
    ) {
      setDisableBtn(false)
    } else if (
      !formValues?.farmerBankId &&
      !formValues?.farmerBankAccountName &&
      !formValues?.farmerBankAccountNumber
    ) {
      setDisableBtn(true)
    } else {
      setDisableBtn(true)
    }
  }, [formValues])

  return (
    <Card>
      <CardHeader title={"Akun Bank Petani".toUpperCase()} />
      <CardBody>
        <RowModule>
          <b>Apabila bank telah dipilih maka nomor rekening & nama pemilik akun harus diisi</b>
        </RowModule>
        <RowModule>
          <>
            <SelectInput
              name="farmerBankId"
              onChange={onChangeBank}
              editable={!isReadOnly}
              label="Nama Bank"
              placeholder="Pilih Salah Satu"
              value={farmerBankId}
              options={bankList}
            />

            <ErrorMessage name="farmerBankId" render={renderError} />
          </>

          <>
            <TextInput
              editable={!isReadOnly}
              value={!formValues?.farmerBankId && ""}
              disabled={formValues?.farmerBankId ? false : true}
              label="Nomor Rekening"
              name="farmerBankAccountNumber"
              placeholder="Input Nomor Rekening"
            />
          </>
        </RowModule>

        <RowModule>
          <>
            <TextInput
              editable={!isReadOnly}
              value={!formValues?.farmerBankId && ""}
              disabled={formValues?.farmerBankId ? false : true}
              label="Nama Pemilik Akun Bank"
              name="farmerBankAccountName"
              placeholder="Input Nama Pemilik Akun Bank"
            />
          </>
        </RowModule>
      </CardBody>
    </Card>
  )
}
