import React from "react"
import TableView, { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../../_metronic/_helpers/index.js"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { EmptyCard, TableSkeleton } from "../../../../../component/index.jsx"
import { PRIVILEGE_ENUM } from "../../../../../config/privilegeCollection.jsx"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { linkWithCtrl } from "../../../../helpers/Utils.js"
import { useSelector } from "react-redux"
import { getUrlParsingPageFilter } from "../../../../../config/endpointCollection.jsx"
import { useLocation } from "react-router-dom"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { useTranslation } from "react-i18next"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../../constants/ImageConstants.js"
import { DetailSvgIcon, EditSvgIcon } from "../../../../../component/atoms/Icons/SVG/index.jsx"

export function FarmerTabActive({
  data,
  changePage,
  history,
  general,
  initialFilterTable,
  isFilter,
  status
}) {
  const privileges = loadUserLogin().privilege
  const { General } = useSelector((state) => state)
  const { t } = useTranslation()
  const location = useLocation()

  const columns = [
    {
      dataField: "code",
      text: t("label.farmer_id"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div className="text-dark font-weight-bolder mb-1 font-size-lg">
            {e?.farmer_code || "-"}
          </div>
        )
      },
      sortCaret: sortCaret,
      sort: false,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "name",
      text: t("label.farmer_name"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiFarmerName
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "account.mitra.name",
      text: t("label.mitra_name"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiMitraName
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = getUrlParsingPageFilter({
          ...initialFilterTable,
          sort: field + "," + order
        })
        history.push({ pathname: location.pathname, search: param })
      }
    },
    {
      dataField: "address",
      text: t("label.address"),
      formatter: formatterRowComponent,
      sortCaret: sortCaret,
      formatExtraData: {
        externalStyle: (e) => <>{e?.full_address ? e?.full_address : "-"}</>
      },
      headerStyle: () => {
        return { width: "25%" }
      }
    },

    {
      dataField: "action",
      text: "Action",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  function uiAction(e) {
    return (
      <div>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FARMER_REGISTERED) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() => history.push(RoutesConstants.FARMER.DETAIL_FARMER("active", e?.id))}>
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.UPDATE_FARMER) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() => history.push("/farmer/action/" + status + "/edit/" + e.id)}>
              <EditSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }

  function uiMitraName(e) {
    return (
      <>
        <a className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.mitra_name || "-"}</a>
        <span className="text-muted font-weight-bold d-block">{e?.mitra_code || "-"}</span>
      </>
    )
  }

  function uiFarmerName(e) {
    return (
      <>
        <a className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.farmer_name || "-"}</a>
        {privileges.indexOf(PRIVILEGE_ENUM.FARMER_PHONE_NUMBER) > -1 && (
          <span className="text-muted font-weight-bold d-block">{e?.farmer_phone || "-"}</span>
        )}
      </>
    )
  }

  return (
    <>
      <Card className="card-shadowless">
        <CardBody>
          {General?.loading ? (
            <TableSkeleton column={5} row={10} />
          ) : data?.pageData?.length == 0 ? (
            <EmptyCard
              subtitle={isFilter ? t("messages.response.empty_search_subtitle") : ""}
              image={
                isFilter
                  ? ILLUSTRATION_ASSETS_V2.IL_NOT_FOUND_FARMER
                  : ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_FARMER
              }
              body={
                isFilter
                  ? t("messages.response.searching_not_found")
                  : t("messages.response.empty_state", { name: "petani" })
              }
            />
          ) : (
            ""
          )}
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={data?.pageData ?? []}
            currentPage={data?.page?.currentPage ?? 1}
            currentElement={data?.page?.currentElement ?? 10}
            totalCount={data?.page?.totalCount ?? 0}
            loadingGetData={general?.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_MITRA_REGISTERED) > -1) {
                  if (e.target.cellIndex < 4) {
                    const url = RoutesConstants.FARMER.DETAIL_FARMER("active", row?.id)
                    if (e.ctrlKey) {
                      linkWithCtrl(url)
                    } else {
                      history.push(url)
                    }
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}
