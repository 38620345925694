import React, { useCallback, useEffect, useRef, useState } from "react"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { Card, CardBody } from "../../../../_metronic/_partials/controls"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../component/atoms/Tabel"
import { PRIVILEGE_ENUM } from "../../../../config/privilegeCollection"
import { sortCaret } from "../../../../_metronic/_helpers"
import { Button } from "react-bootstrap"
import { loadUserLogin } from "../../../service/userManagementAction"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../../_metronic/layout"
import { CheckboxLabelButton, EmptyCard, Search, TableSkeleton } from "../../../../component"
import { getCategoryItemList, getPlantType } from "../../../../redux/actions/MasterDataAction"
import { useTranslation } from "react-i18next"

export const DetailBenih = ({
  history,
  match: {
    params: { subType }
  }
}) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const { t } = useTranslation()
  const { General, MasterData } = useSelector((state) => state)
  const [search, setSearch] = useState({
    string_filter: ""
  })
  const formRef = useRef()
  const dispatch = useDispatch()
  const [statusValue, setStatusValue] = useState([])
  const [isStatus, setIsStatus] = useState([])

  const changePage = useCallback(
    (param) => {
      let newParam = {
        ...search,
        ...param,
        plant_type_id: statusValue
      }
      if (param.sortField) {
        newParam = {
          ...newParam,
          sort: param.sortField + "," + param.sortOrder
        }
      }
      dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: newParam }))
    },
    [dispatch, search, statusValue]
  )

  useEffect(() => {
    dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: initialFilterTable }))
    dispatch(getPlantType())
  }, [])

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        pathname: "/master/category-item",
        title: "Kategori Item"
      },
      {
        title: "Benih"
      }
    ])
    if (privileges.indexOf(PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM) > -1) {
      subHeader.setButton(
        <Button
          variant="primary"
          onClick={() => {
            history.push("/master/category-item/seed/new/" + subType)
          }}>
          Tambah
        </Button>
      )
    } else {
      subHeader.setButton(null)
    }
    subHeader.setTitle("Benih")
  }, [])

  useEffect(() => {
    let param = {
      ...initialFilterTable,
      string_filter: search.string_filter,
      plant_type_id: statusValue
    }
    dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
  }, [statusValue])

  useEffect(() => {
    if (MasterData.plantType) {
      let newPlantType = []
      MasterData.plantType.map((item) => {
        let newtype = {
          label: item.name,
          enum: item.id,
          checked: false
        }
        newPlantType.push(newtype)
      })
      setIsStatus(newPlantType)
    }
  }, [MasterData.plantType])

  const uiDate = (e) => {
    return (
      <>
        <div className="mb-1">{e?.submitted_date ? e?.submitted_date : "-"}</div>
        <div>{e?.submitted_time ? e?.submitted_time : "-"}</div>
      </>
    )
  }

  const uiType = (e) => {
    return <>{e.plant_type_name ?? "-"}</>
  }

  const uiUnit = (e) => {
    return <>{e.unit_name ?? "-"}</>
  }

  const columns = [
    {
      dataField: "name",
      text: "NAMA VARIANT",
      formatter: formatterRowComponent
    },
    {
      dataField: "plantType",
      text: "TIPE TANAMAN",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiType
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          sort: field + "," + order
        }
        dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
      }
    },
    {
      dataField: "unit_name",
      text: "UNIT",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiUnit
      }
    },
    {
      dataField: "createdDate",
      text: "TANGGAL SUBMIT",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      },
      headerStyle: () => {
        return { width: "15%" }
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          sort: field + "," + order
        }
        dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
      }
    }
  ]

  const onSubmitForm = (values) => {
    let param = {
      ...initialFilterTable,
      string_filter: values.string_filter,
      plant_type_id: statusValue
    }
    setSearch({
      string_filter: values.string_filter
    })
    dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
  }

  const trigerReset = () => {
    setSearch({
      string_filter: ""
    })
    let param = {
      ...initialFilterTable,
      string_filter: "",
      plant_type_id: statusValue
    }
    dispatch(getCategoryItemList(subType.toUpperCase(), { pageReq: param }))
  }

  const handleStatus = (e) => {
    const { checked, value } = e.target
    if (checked) {
      setStatusValue(value)
    }
  }

  const onResetRadio = () => {
    setStatusValue("")
  }

  return (
    <>
      <div className="filter-container">
        <div className="position-relative d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            placeholder={"Masukkan nama variant"}
            onSubmitForm={onSubmitForm}
            initialValues={search}
            name={"string_filter"}
            innerRef={formRef}
            trigerReset={trigerReset}
          />
          <CheckboxLabelButton
            onCLickRadioReset={onResetRadio}
            type={"radio"}
            formInitialValues={statusValue}
            isStatus={isStatus}
            name={"plant_type_id"}
            innerRef={formRef}
            handleChange={handleStatus}
          />
        </div>
      </div>

      <Card>
        <RowModule>
          <Card className="card-shadowless">
            <CardBody>
              {General?.loading ? (
                <TableSkeleton column={4} row={10} />
              ) : MasterData?.pageData?.length == 0 ? (
                <EmptyCard
                  body={
                    search?.string_filter?.length > 0 || statusValue.length > 0
                      ? t("messages.response.data_not_found")
                      : t("messages.response.empty_state")
                  }
                />
              ) : (
                ""
              )}

              <TableView
                callbackAfterPageChange={changePage}
                columnProperties={columns}
                dataTable={MasterData.pageData}
                currentPage={MasterData.page.currentPage}
                currentElement={MasterData.page.currentElement}
                totalCount={MasterData.page.totalCount}
                loadingGetData={General.loading}
                rowClasses={() => {
                  return "cursor-default"
                }}
              />
            </CardBody>
          </Card>
        </RowModule>
      </Card>
    </>
  )
}
