import React from "react"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { DetailText } from "../../../../component/atoms/DetailText"
import { PRIVILEGE_ENUM } from "../../../../config/privilegeCollection"
import { loadUserLogin } from "../../../service/userManagementAction"
import { Skeleton } from "@material-ui/lab"
import { useSelector } from "react-redux"

export function CardDetailUsaha({
  mitraType,
  npwp,
  npwpImage,
  ktpImage,
  swafoto,
  suratTugas,
  kkImage,
  documentPerusahaan,
  structurOrganisasi,
  setStatusPopup,
  ktp,
  nama
}) {
  const privileges = loadUserLogin().privilege
  const { General } = useSelector((state) => state)
  return (
    <>
      <RowModule>
        <Card className={"card-custom"}>
          {General.loading ? (
            <CardBody>
              <div className={"row"}>
                {Array.from({ length: 3 }).map((item, index) => {
                  return (
                    <div className={"col-md-3"} key={index}>
                      <Skeleton animation="wave" variant={"text"} width={100} />
                      <Skeleton animation="wave" variant={"text"} width={100} />
                    </div>
                  )
                })}
              </div>
            </CardBody>
          ) : (
            <CardBody>
              <div className={"row"}>
                {mitraType === "INDIVIDUAL" && (
                  <div className={"col-md-3"}>
                    <div className={"color-gray space-1"}>Nama Sesuai KTP</div>
                    <div className={"text-dark font-weight-bolder mt-1 font-size-lg"}>
                      {nama ? nama : "-"}
                    </div>
                  </div>
                )}
                {privileges.indexOf(PRIVILEGE_ENUM.MITRA_KTP_NUMBER) > -1 &&
                  mitraType === "INDIVIDUAL" && (
                    <div className={"col-md-3"}>
                      <div className={"color-gray space-1"}>No KTP</div>
                      <div className={"text-dark font-weight-bolder mt-1 font-size-lg"}>
                        {ktp ? ktp : "-"}
                      </div>
                    </div>
                  )}
                <div className={"col-md-3"}>
                  <div className={"color-gray space-1"}>No NPWP</div>
                  <div className={"text-dark font-weight-bolder mt-1 font-size-lg"}>{npwp}</div>
                </div>
              </div>
            </CardBody>
          )}
        </Card>
      </RowModule>

      {mitraType === "INDIVIDUAL" && (
        <>
          <RowModule withoutSeparator={true} customColumnCss={"col-md-6"}>
            {privileges.indexOf(PRIVILEGE_ENUM.MITRA_KTP_PICTURE) > -1 && (
              <div onClick={() => setStatusPopup("KTP")}>
                <DetailText
                  title="KTP"
                  menu="KTP"
                  value={ktpImage}
                  type="image"
                  withoutSeparator={true}
                />
              </div>
            )}
            {privileges.indexOf(PRIVILEGE_ENUM.MITRA_KTP_SELFIE_PICTURE) > -1 && (
              <div onClick={() => setStatusPopup("SWAFOTO")}>
                <DetailText
                  title="SWAFOTO"
                  menu="SWAFOTO"
                  value={swafoto}
                  type="image"
                  withoutSeparator={true}
                />
              </div>
            )}
            <div onClick={() => setStatusPopup("BUSINESS_DOCUMENT")}>
              <DetailText
                title="KK"
                menu="BUSINESS_DOCUMENT"
                value={kkImage}
                type="image"
                withoutSeparator={true}
              />
            </div>
            <div onClick={() => setStatusPopup("OTHER_IMAGE")}>
              <DetailText
                title="NPWP"
                menu="OTHER_IMAGE"
                value={npwpImage}
                type="image"
                withoutSeparator={true}
              />
            </div>
          </RowModule>
        </>
      )}
      {mitraType === "GOVERNMENT_AGENCIES" && (
        <>
          <RowModule withoutSeparator={true} customColumnCss={"col-md-6"}>
            <div onClick={() => setStatusPopup("SWAFOTO")}>
              <DetailText
                title="SURAT TUGAS KERJA"
                menu="SWAFOTO"
                value={suratTugas}
                type="image"
                withoutSeparator={true}
              />
            </div>
            <div onClick={() => setStatusPopup("OTHER_IMAGE")}>
              <DetailText
                title="NPWP"
                menu="OTHER_IMAGE"
                value={npwpImage}
                type="image"
                withoutSeparator={true}
              />
            </div>
            <div onClick={() => setStatusPopup("BUSINESS_DOCUMENT")}>
              <DetailText
                title="STRUKTUR ORGANISASI"
                menu="BUSINESS_DOCUMENT"
                value={structurOrganisasi}
                type="image"
                withoutSeparator={true}
              />
            </div>
            {privileges.indexOf(PRIVILEGE_ENUM.MITRA_KTP_PICTURE) > -1 && (
              <div onClick={() => setStatusPopup("KTP")}>
                <DetailText
                  title="KTP PENANGGUNG JAWAB"
                  menu="KTP"
                  value={ktpImage}
                  type="image"
                  withoutSeparator={true}
                />
              </div>
            )}
          </RowModule>
        </>
      )}
      {mitraType === "PRIVATE_COMPANIES" && (
        <>
          <RowModule withoutSeparator={true} customColumnCss={"col-md-6"}>
            <div onClick={() => setStatusPopup("BUSINESS_DOCUMENT")}>
              <DetailText
                title="AKTA PENDIRIAN PERUSAHAAN"
                menu="BUSINESS_DOCUMENT"
                value={documentPerusahaan}
                type="image"
                withoutSeparator={true}
              />
            </div>
            <div onClick={() => setStatusPopup("OTHER_IMAGE")}>
              <DetailText
                title="NPWP"
                menu="OTHER_IMAGE"
                value={npwpImage}
                type="image"
                withoutSeparator={true}
              />
            </div>
            {privileges.indexOf(PRIVILEGE_ENUM.MITRA_KTP_PICTURE) > -1 && (
              <div onClick={() => setStatusPopup("KTP")}>
                <DetailText
                  title="KTP PENANGGUNG JAWAB"
                  menu="KTP"
                  value={ktpImage}
                  type="image"
                  withoutSeparator={true}
                />
              </div>
            )}
          </RowModule>
        </>
      )}
      {mitraType === "EDUCATIONAL_INSTITUTIONS" && (
        <>
          <RowModule withoutSeparator={true} customColumnCss={"col-md-6"}>
            <div onClick={() => setStatusPopup("BUSINESS_DOCUMENT")}>
              <DetailText
                title="STRUKTUR ORGANISASI"
                menu="BUSINESS_DOCUMENT"
                value={structurOrganisasi}
                type="image"
                withoutSeparator={true}
              />
            </div>
            <div onClick={() => setStatusPopup("OTHER_IMAGE")}>
              <DetailText
                title="NPWP"
                menu="OTHER_IMAGE"
                value={npwpImage}
                type="image"
                withoutSeparator={true}
              />
            </div>
            {privileges.indexOf(PRIVILEGE_ENUM.MITRA_KTP_PICTURE) > -1 && (
              <div onClick={() => setStatusPopup("KTP")}>
                <DetailText
                  title="KTP PENANGGUNG JAWAB"
                  menu="KTP"
                  value={ktpImage}
                  type="image"
                  withoutSeparator={true}
                />
              </div>
            )}
          </RowModule>
        </>
      )}
    </>
  )
}
