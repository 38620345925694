import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TabsInputComponent } from "../../../component"
import * as Yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../util/constStringCollection"
import swal from "sweetalert"
import { editPassword } from "../../../redux/actions/AccountAction"
import { useSubheader } from "../../../_metronic/layout"
import { DataEditPassword } from "./FormView/DataEditPassword"
import { ACCOUNT } from "../../../constants/InitTypeConstants.js"

export const AccountEditPassword = () => {
  const dispatch = useDispatch()
  const [isDisabled, setIsDisbaled] = useState(true)
  const { General } = useSelector((state) => state)
  const subHeader = useSubheader()
  const formRef = useRef()
  const [initialValue, setInitialValue] = useState({
    password: "",
    old_password: "",
    confirm_password: ""
  })
  const [swalMessage, setSwalMessage] = useState("")
  const stepsTitle = [null]
  const editPasswordFlow = [
    <DataEditPassword key={0} isReadOnly={false} setIsDisbaled={setIsDisbaled} />
  ]

  useEffect(() => {
    subHeader.setButton(buttonSubmited)
  }, [isDisabled])

  const stepsDescription = [null]

  useEffect(() => {
    setInitialValue((prevState) => ({
      ...prevState
    }))

    subHeader.setBreadcrumbs([
      {
        pathname: "/",
        title: "Account"
      },
      {
        title: "Edit Password"
      }
    ])
    subHeader.setButton(buttonSubmited)
  }, [])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete && General?.actionInitType === ACCOUNT.EDIT_PASSWORD) {
      swal("Success", swalMessage, {
        icon: "success"
      }).then((approve) => {
        if (approve) {
          location.reload()
        }
      })
    }
  }, [General.isFinishUpdateOrDelete])

  const buttonSubmited = (
    <button
      type="button"
      disabled={isDisabled}
      className={isDisabled ? "btn button-disabled" : "btn btn-primary"}
      onClick={() => {
        if (formRef.current && !isDisabled) {
          formRef.current.handleSubmit()
        }
      }}>
      Update
    </button>
  )

  const ValidationScheme = [
    Yup.object().shape({
      old_password: Yup.string().required(
        getStringErrorMassage("Password lama ", FORM_NEED.harusDiIsi)
      ),
      password: Yup.string()
        .required(getStringErrorMassage("Password Baru ", FORM_NEED.harusDiIsi))
        .min(8, "Password terlalu penddek, minimal 8 Digit")
        .max(20, "Maximal 20 Digit"),
      confirm_password: Yup.string()
        .required(getStringErrorMassage("Konfirmasi Password ", FORM_NEED.harusDiIsi))
        .oneOf([Yup.ref("password"), null], "Password Harus Sama")
        .min(8, "Minimal 8 Digit")
        .max(20, "Maximal 20 Digit")
    })
  ]

  function onSubmitForm(e) {
    const { values, submited } = e
    if (submited) {
      const formData = {
        password: values?.password,
        old_password: values?.old_password,
        confirm_password: values?.confirm_password
      }
      onClickSendValue(formData)
    }
  }

  function onClickSendValue(data) {
    setSwalMessage("Password berhasil diperbarui.!")

    swal({
      title: "Are you sure the data is correct?",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then((approve) => {
      if (approve) {
        dispatch(editPassword(data))
      }
    })
  }
  return (
    <>
      <TabsInputComponent
        isEditTabs={false}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="editPassword"
        innerRef={formRef}
        componentTab={editPasswordFlow}
        validationSchema={ValidationScheme}
        onSubmitForm={onSubmitForm}
      />
    </>
  )
}
