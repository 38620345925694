import { useSubheader } from "../../../../_metronic/layout"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { FilterCard, LocationMolecule, SelectInput } from "../../../../component"
import { initialFilterTable } from "../../../../component/atoms/Tabel"
import { getUrlParsingPageFilter } from "../../../../config/endpointCollection"
import { useLocation } from "react-router"
import { useUrlParam } from "../../../hooks/useUrlParams"
import { useDispatch, useSelector } from "react-redux"
import { clearDataDetail, getAllMitra } from "../../../../redux/actions/MitraAction"
import { SetFilterAction } from "../../../../redux/actions/FilterAction"
import { useTranslation } from "react-i18next"
import { getPageDataActiveKur } from "../../../../redux/actions/FarmerAction.jsx"
import { ListFarmerKur } from "../Terverifikasi/TabTerverifikasi/ListFarmerKur.jsx"
import { useHistory } from "react-router-dom"
import { getListRegionalSelected } from "../../../../redux/actions/MasterDataAction.jsx"
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { CheckboxAutoCompleted } from "../../../../component/atoms/CheckboxAutoCompleted.jsx"

export function FarmerListKur() {
  const subHeader = useSubheader()
  const formRef = useRef()
  const location = useLocation()
  const urlParams = useUrlParam()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()

  const { General, Farmer, Mitralist, MasterData } = useSelector((state) => state)

  const [search, setSearch] = useState({
    keyword: ""
  })
  const [initialValue, setInitialValue] = useState({
    ...initialFilterTable,
    province_id: "",
    regency_id: "",
    district_id: "",
    sub_district_id: "",
    mitra_status: ""
  })
  const [filterOn, setFilterOn] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [btnFilterDisable, setBtnFilterDisable] = useState(true)
  const [reset, setReset] = useState(false)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const isReadOnly = false
  const [mitraStatusSelect, setMitraStatusSelect] = useState()
  const [selectedRegional, setSelectedRegional] = useState([])
  const [listRegional, setListRegional] = useState([])
  const [selectedMitra, setSelectedMitra] = useState()
  const [listMitra, setListMitra] = useState([])
  const [selectedRegionalUser, setSelectedRegionalUser] = useState()
  const userLogin = JSON.parse(localStorage.getItem("userLogin"))

  useEffect(() => {
    subHeader.setTitle(t("sub_header.farmer_list_ca"))
    subHeader.setButton(false)
    return () => {
      dispatch(clearDataDetail())
    }
  }, [])

  useEffect(() => {
    if (userLogin?.regional_id) {
      setSelectedRegionalUser(userLogin?.regional_id)
    }
  }, [])

  useEffect(() => {
    if (listRegional && selectedRegional) {
      const data = listRegional.find((e) => e.value === selectedRegionalUser)
      if (data) {
        setSelectedRegional([data])
      }
    }
  }, [listRegional, selectedRegionalUser])

  useEffect(() => {
    if (Mitralist.data) {
      setListMitra(
        Mitralist.data.map((value) => ({
          label: value.name,
          value: value.id_mitra
        }))
      )
    }
  }, [Mitralist.data])

  useEffect(() => {
    dispatch(getListRegionalSelected())
    dispatch(getAllMitra())
  }, [])

  useEffect(() => {
    if (
      formRef.current.values.province_id ||
      formRef.current.values.mitra_status ||
      formRef.current.values.mitra_id ||
      selectedMitra ||
      (selectedRegional && selectedRegional.length)
    )
      setBtnFilterDisable(false)
    else setBtnFilterDisable(true)
  }, [formRef?.current?.values, mitraStatusSelect, selectedMitra, selectedRegional])

  useEffect(() => {
    if (MasterData?.listRegionalSelected) {
      setListRegional(
        MasterData.listRegionalSelected.map((item) => {
          return {
            label: item.name + " (" + item.code + ")",
            value: item.id
          }
        })
      )
    }
  }, [MasterData])

  function splitDataStringRegionalId(str) {
    if (str) {
      return str.split(",")
    } else {
      return ""
    }
  }

  function filterDataRegional(data) {
    return listRegional.filter((item) => data.includes(item.value)) || []
  }
  useEffect(() => {
    if (queryStringObj?.keyword) {
      setSearch({ keyword: queryStringObj?.keyword })
      setIsActiveSearch(true)
    }
    if (
      queryStringObj?.province_id ||
      queryStringObj?.regency_id ||
      queryStringObj?.district_id ||
      queryStringObj?.sub_district_id ||
      queryStringObj?.mitra_status ||
      queryStringObj?.regional_id ||
      queryStringObj?.mitra_id
    ) {
      if (userLogin?.regional_id) {
        if (
          queryStringObj?.province_id ||
          queryStringObj?.regency_id ||
          queryStringObj?.district_id ||
          queryStringObj?.sub_district_id ||
          queryStringObj?.mitra_status ||
          queryStringObj?.mitra_id
        ) {
          setFilterOn(true)
        } else {
          setFilterOn(false)
        }
      } else {
        setFilterOn(true)
      }
      const data = {
        ...initialFilterTable,
        keyword: queryStringObj?.keyword,
        province_id: queryStringObj?.province_id,
        regency_id: queryStringObj?.regency_id,
        district_id: queryStringObj?.district_id,
        sub_district_id: queryStringObj?.sub_district_id,
        mitra_status: queryStringObj?.mitra_status,
        mitra_id: queryStringObj?.mitra_id,
        regional_id: queryStringObj?.regional_id
      }

      if (data) {
        setInitialValue(data)
        setSelectedRegional(filterDataRegional(splitDataStringRegionalId(data.regional_id)))
        setSelectedMitra(data.mitra_id)
        setMitraStatusSelect(data?.mitra_status)
      }
    }
  }, [queryStringObj, dispatch, listRegional])
  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  const fetchData = (param) => {
    if (userLogin?.regional_id) {
      if (param === "" || param === null) param = param + "?regional_id=" + userLogin?.regional_id
    }
    return dispatch(getPageDataActiveKur(param))
  }

  // search
  const searchFunc = (values) => {
    setSearch({ keyword: values.keyword })
    setIsActiveSearch(true)
    let param = {
      ...initialFilterTable,
      ...initialValue,
      keyword: values.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  const handleReset = () => {
    let param = {
      ...initialFilterTable,
      ...initialValue,
      keyword: ""
    }
    setIsActiveSearch(false)
    const urlParam = getUrlParsingPageFilter(param)
    setSearch({ keyword: "" })
    history.push({ pathname: location.pathname, search: urlParam })
  }

  function checkRegionalSelected() {
    if (userLogin?.regional_id) {
      return userLogin?.regional_id
    } else {
      if (selectedRegional) {
        const data = selectedRegional.map((e) => {
          return e.value
        })
        if (data.length > 0) {
          return data.join(",")
        }
      }
    }
  }
  // filter
  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.regional")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <CheckboxAutoCompleted
              disable={!!selectedRegionalUser}
              options={listRegional ?? []}
              onChange={(e) => setSelectedRegional(e)}
              value={selectedRegional}
              label={t("label.regional_name")}
              placeholder={t("placeholder.insert_regional_name")}
            />
          </RowModule>
        </CardBody>
      </Card>

      <Card className="card-custom card-shadowless">
        <CardHeader title={t("label.mitra")} className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="regional_id"
              editable={!isReadOnly}
              label={t("label.mitra_name")}
              placeholder={t("placeholder.select_one")}
              options={listMitra}
              withoutFeedback={true}
              clearNow={true}
              value={selectedMitra}
              onChange={(e) => setSelectedMitra(e)}
            />
          </RowModule>
        </CardBody>
      </Card>
      <LocationMolecule
        resetLocation={reset}
        regionalId={checkRegionalSelected()}
        name="filter"
        withoutFeedback={true}
        isReadOnly={isReadOnly}
        addressTitle="Lokasi"
        removeHorizontalPadding={true}
      />
    </>
  )
  const onSubmitForm = (e) => {
    setReset(false)
    const { values, submited } = e
    if (submited === true) {
      let param = {
        ...initialFilterTable,
        province_id: values.province_id,
        regency_id: values.regency_id,
        district_id: values.district_id,
        sub_district_id: values.sub_district_id,
        keyword: search.keyword,
        mitra_status: values.mitra_status
      }
      if (param) {
        setInitialValue(param)
        const urlParam = getUrlParsingPageFilter(param)
        history.push({ pathname: location.pathname, search: urlParam })
        setShowFilter(false)
        setFilterOn(true)
      }
    }
  }
  useEffect(() => {
    if (selectedRegionalUser) {
      const param = {
        ...initialFilterTable,
        ...initialValue,
        regional_id: selectedRegionalUser,
        keyword: urlParams?.getUrlParamsObj?.keyword,
        province_id: urlParams?.getUrlParamsObj?.province_id,
        regency_id: urlParams?.getUrlParamsObj?.regency_id,
        district_id: urlParams?.getUrlParamsObj?.district_id,
        sub_district_id: urlParams?.getUrlParamsObj?.sub_district_id,
        mitra_status: urlParams?.getUrlParamsObj?.mitra_status,
        mitra_id: urlParams?.getUrlParamsObj?.mitra_id
      }
      if (param) {
        setInitialValue(param)
        const urlParam = getUrlParsingPageFilter(param)
        history.push({ pathname: location.pathname, search: urlParam })
      }
    }
  }, [selectedRegionalUser])

  const filterOnReset = () => {
    dispatch(SetFilterAction())
    setReset(true)
    setShowFilter(false)
    setFilterOn(false)
    let param = {
      ...initialFilterTable,
      province_id: "",
      regency_id: "",
      district_id: "",
      sub_district_id: "",
      keyword: search.keyword,
      mitra_status: "",
      regional_id: selectedRegionalUser ? selectedRegionalUser : ""
    }
    setInitialValue(param)
    setMitraStatusSelect("")
    setSelectedMitra("")
    setSelectedRegional([])
    setSelectedRegionalUser(userLogin?.regional_id || "")

    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  const handleOpenCloseFilter = (value) => {
    setShowFilter(value)
  }

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          keyword: search.keyword
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          keyword: search.keyword
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, search, initialValue]
  )

  return (
    <>
      <FilterCard
        // seacrh
        isActiveSearch={isActiveSearch}
        placeholder={t("placeholder.search_farmer_name_or_id")}
        onSubmitForm={searchFunc}
        initialValues={search}
        name={"keyword"}
        innerRef={formRef}
        trigerReset={handleReset}
        // filter
        filterInnerRef={formRef}
        filterHandleSubmit={onSubmitForm}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent}
        filterOnReset={filterOnReset}
        showFilter={showFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={filterOn}
        btnDisable={btnFilterDisable}></FilterCard>

      <ListFarmerKur
        data={Farmer}
        changePage={changePage}
        history={history}
        general={General}
        isActiveSearch={isActiveSearch}
        initialFilterTable={initialFilterTable}
        isFilter={filterOn}
      />
    </>
  )
}
