import { useTranslation } from "react-i18next"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { getDetailUser, resetDetailMac } from "../../../../redux/actions/MacAction.jsx"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { FlatIconAndText, TabsComponent } from "../../../../component/index.jsx"
import { Detail } from "./DetailComponent/Detail.jsx"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants.js"

export const UserDetail = ({
  match: {
    params: { id }
  }
}) => {
  const { t } = useTranslation()
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const [detail, setDetail] = useState({})
  const { Mac } = useSelector((state) => state)

  useEffect(() => {
    dispatch(getDetailUser(id))
    return () => {
      dispatch(resetDetailMac())
    }
  }, [])

  useEffect(() => {
    if (Mac?.detail) {
      setDetail(Mac?.detail)
    }
  }, [Mac?.detail])

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.MAC.LIST_PROFILE(),
        title: "MAC"
      },
      {
        pathname: RoutesConstants.MAC.LIST_USER(),
        title: "Pengguna"
      },
      {
        title: detail?.user_merchant_name ? detail?.user_merchant_name : "-"
      }
    ])
    subHeader.setTitle(null)
  }, [detail])

  const descriptionLeft = (
    <>
      <div className="d-flex flex-row">
        <div className="d-flex flex-wrap">
          <FlatIconAndText
            text={detail?.code ? detail?.code : "-"}
            svg={ICON_CONSTANS.IC_ACCOUNT}
          />
          <FlatIconAndText
            text={detail?.phone_number ? detail?.phone_number : "-"}
            svg={ICON_CONSTANS.IC_PHONE}
          />
        </div>
      </div>
    </>
  )

  const information = {
    title: t("label.mac_user_information"),
    data: [
      {
        label: t("label.user_name"),
        value: detail?.user_merchant_name ?? "-"
      },
      {
        label: t("label.nik"),
        value: detail?.nik ?? "-"
      },
      {
        label: t("label.npwp"),
        value: detail?.npwp ?? "-"
      },
      {
        label: t("label.phone"),
        value: detail?.phone_number ?? "-"
      },
      {
        label: t("label.full_address"),
        value: detail?.user_merchant_address ?? "-"
      }
    ]
  }

  const renderContents = () => {
    const labels = ["DETAIL PENGGUNA"]
    const components = [
      <Detail
        data={detail}
        descriptionLeft={descriptionLeft}
        informasiProfile={information}
        key={""}
      />
    ]

    return {
      labels,
      components
    }
  }

  return (
    <>
      <TabsComponent
        labelTab={renderContents().labels}
        componentTab={renderContents().components}
      />
    </>
  )
}
