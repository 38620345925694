import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { SelectInput, TextInput } from "../../../../component"
import React, { useEffect, useState } from "react"
import { fetchBanks } from "../../../../redux/actions/MasterDataAction"
import { useFormikContext } from "formik"

export const AkunBankGoverment = ({ isReadOnly }) => {
  const { values: formValues } = useFormikContext()

  const { MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [bankId, setBankId] = useState(formValues["bank_id"])
  const [bankList, setBankList] = useState([])

  useEffect(() => {
    dispatch(fetchBanks())
  }, [])

  useEffect(() => {
    if (formValues.hasOwnProperty("bank_id")) {
      setBankId(formValues["bank_id"])
    }
  }, [formValues["bank_id"]])

  useEffect(() => {
    if (MasterData.bankList.length !== 0) {
      setBankList(
        MasterData.bankList.map((value) => ({
          label: value.name,
          value: value.id
        }))
      )
    }
  }, [MasterData.bankList])

  function onChangeBank(e) {
    setBankId(e)
  }

  return (
    <Card>
      <CardHeader title={"Akun Bank Instansi".toUpperCase()} />
      <CardBody>
        <RowModule>
          <>
            <SelectInput
              name="bank_id"
              onChange={onChangeBank}
              editable={!isReadOnly}
              required
              label="Nama Bank"
              placeholder="Pilih nama bank"
              value={bankId}
              options={bankList}
            />
          </>

          <>
            <TextInput
              editable={!isReadOnly}
              required
              label="Nomor Rekening"
              name="bank_account_number"
              placeholder="Input Nomor Rekening"
              value={!formValues?.bank_id && ""}
            />
          </>
        </RowModule>

        <RowModule>
          <>
            <TextInput
              editable={!isReadOnly}
              label="Nama Pemilik Rekening"
              required
              name="bank_account_name"
              placeholder="Input Nama Pemilik Akun Bank"
              value={!formValues?.bank_id && ""}
            />
          </>
        </RowModule>
      </CardBody>
    </Card>
  )
}
