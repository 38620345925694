import React, { useEffect } from "react"
import { TabAssignMitra } from "./AssignMitraComponent/TabAssignMitra"
import { TabAssignList } from "./AssignMitraComponent/TabAssignList"
import { TabsComponent } from "../../../component"
import { useSubheader } from "../../../_metronic/layout"
import { loadUserLogin } from "../../service/userManagementAction"
import { PRIVILEGE_ENUM } from "../../../config/privilegeCollection"

export const AssignMitraWrapper = () => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  useEffect(() => {
    subHeader.setButton(<></>)
  }, [])

  const render = () => {
    const labels = ["ASSIGN"]
    const components = [<TabAssignMitra key={1} />]

    if (privileges.indexOf(PRIVILEGE_ENUM.LIST_FIELD_OFFICER_ASSIGN) > -1) {
      labels.push("LIST ASSIGN")
      components.push(<TabAssignList />)
    }

    return {
      labels,
      components
    }
  }
  return (
    <>
      <TabsComponent labelTab={render().labels} componentTab={render().components} />
    </>
  )
}
