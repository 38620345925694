import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import React, { useEffect } from "react"
import { TextInput } from "../../../../component"
import { useFormikContext } from "formik"

export const DataEditPassword = ({ isReadOnly, setIsDisbaled }) => {
  const { values } = useFormikContext()
  useEffect(() => {
    if (values?.password && values?.old_password && values?.confirm_password) {
      setIsDisbaled(false)
    }
  }, [values])

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Edit Password" />
        <CardBody>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Password Lama"
              name="old_password"
              placeholder="Input Password Lama Anda"
              type={"password"}
              withTogglePass={true}
            />
          </RowModule>

          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Password Baru"
              name="password"
              placeholder="Input Password Baru Anda"
              type={"password"}
              withTogglePass={true}
            />
          </RowModule>

          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Konfirmasi Password Baru"
              name="confirm_password"
              placeholder="Konfirmasi Password Baru Anda"
              type={"password"}
              withTogglePass={true}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}
