import React, { useEffect, useRef, useState } from "react"
import { CheckboxCustomGroup } from "../../../../component"
import { Card, CardBody } from "../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { useDispatch, useSelector } from "react-redux"
import { fetchPrivilege } from "../../../../redux/actions/RoleAction"
import { Collapse } from "react-collapse"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import { Skeleton } from "@material-ui/lab"

export const DataRole = ({ role, setInitialValue, initialValue, type }) => {
  const { Role, General } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [open, setOpen] = useState({})
  const [list, setList] = useState([])
  const itemsRef = useRef([])

  useEffect(() => {
    const param = type == "fo" ? type : "all"
    dispatch(fetchPrivilege(param))
  }, [])

  useEffect(() => {
    let getList = Role.listPrivilege
    let getOpen = {}
    getList.forEach((item) => {
      getOpen[item.parent_menu] = false
    })
    setList(getList)
    setOpen(getOpen)
  }, [Role.listPrivilege])

  useEffect(() => {
    let getList = Role.listPrivilege
    let setChecked = [...initialValue.privilege_list]
    let setParent = []
    getList.forEach((item, index) => {
      let addCheck = true
      let indeterminate = false
      item.enum_list.forEach((getItem) => {
        let index = setChecked.indexOf(getItem.privilege_enum)
        if (index < 0) {
          addCheck = false
        }
        if (index >= 0) {
          indeterminate = true
        }
      })
      if (addCheck) {
        setParent.push(item.parent_menu)
      }

      if (indeterminate == true && addCheck == false) {
        if (itemsRef.current[index]) {
          itemsRef.current[index].indeterminate = true
        }
      } else {
        if (itemsRef.current[index]) {
          itemsRef.current[index].indeterminate = false
        }
      }
    })
    setInitialValue((prevState) => ({
      ...prevState,
      privilege_header: setParent
    }))
  }, [Role.listPrivilege, initialValue.privilege_list])

  const parentCheck = (event, item) => {
    let setChecked = [...initialValue.privilege_list]
    if (event.target.checked) {
      item.enum_list.forEach((getItem) => {
        let index = setChecked.indexOf(getItem.privilege_enum)
        if (index < 0) {
          setChecked.push(getItem.privilege_enum)
        }
      })
    } else {
      item.enum_list.forEach((getItem) => {
        let index = setChecked.indexOf(getItem.privilege_enum)
        if (index >= 0) {
          setChecked.splice(index, 1)
        }
      })
    }
    setInitialValue((prevState) => ({
      ...prevState,
      privilege_list: setChecked
    }))
  }

  const childCheck = (event, value) => {
    let setChecked = [...initialValue.privilege_list]
    if (event.target.checked) {
      let index = setChecked.indexOf(value)
      if (index < 0) {
        setChecked.push(value)
      }
    } else {
      let index = setChecked.indexOf(value)
      if (index >= 0) {
        setChecked.splice(index, 1)
      }
    }

    setInitialValue((prevState) => ({
      ...prevState,
      privilege_list: setChecked
    }))
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          {General.loading ? (
            <Skeleton width={200} variant={"rect"} />
          ) : (
            <h4 className={"mb-8"} style={{ fontWeight: "bold" }}>
              {"Edit Role " + role}
            </h4>
          )}
          {General?.loading ? (
            <div className={"d-flex flex-column mt-10"} style={{ gap: 16 }}>
              {Array.from({ length: 3 }).map((_, i) => (
                <div key={i}>
                  <div className={"d-flex"} style={{ gap: 10 }}>
                    <Skeleton width={20} height={20} variant={"rect"} />
                    <Skeleton width={200} height={20} variant={"rect"} />
                  </div>
                  <div className={"ml-10 mt-10 d-flex flex-column"} style={{ gap: 16 }}>
                    {i === 0 &&
                      Array.from({ length: 5 }).map((_, i) => (
                        <div key={i} className={"d-flex"} style={{ gap: 10 }}>
                          <Skeleton width={20} height={20} variant={"rect"} />
                          <Skeleton width={200} height={20} variant={"rect"} />
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              {list?.length > 0 &&
                list?.map((item, idx) => (
                  <div className={"mb-8"} key={idx}>
                    <RowModule>
                      <CheckboxCustomGroup
                        key={idx}
                        label={item?.parent_menu}
                        name={"privilege_header"}
                        value={item?.parent_menu}
                        onChange={(e) => {
                          parentCheck(e, item)
                        }}
                        inneRef={(el) => (itemsRef.current[idx] = el)}
                      />
                      <div style={{ textAlign: "right" }}>
                        <a
                          onClick={() => {
                            setOpen({
                              ...open,
                              [item?.parent_menu]: !open[item?.parent_menu]
                            })
                          }}>
                          {open[item?.parent_menu] ? <ExpandLess /> : <ExpandMore />}
                        </a>
                      </div>
                    </RowModule>
                    <Collapse isOpened={open[item?.parent_menu]}>
                      <div className={"ml-12 mb-8"}>
                        {item.enum_list.map((items, id) => (
                          <CheckboxCustomGroup
                            key={id}
                            label={items?.label}
                            name={"privilege_list"}
                            value={items?.privilege_enum}
                            onChange={(e) => {
                              childCheck(e, items?.privilege_enum)
                            }}
                          />
                        ))}
                      </div>
                    </Collapse>
                    <hr />
                  </div>
                ))}
            </>
          )}
        </CardBody>
      </Card>
    </>
  )
}
