import React from "react"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import ReactPlayer from "react-player/youtube"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

export const DetailVideo = ({ data }) => {
  const { General } = useSelector((state) => state)
  // end map
  const render = () => {
    return (
      <>
        <RowModule>
          <Card className="card-custom">
            <CardBody>
              {General?.loading ? (
                <div>
                  <Skeleton variant={"rect"} height={700} width={"100%"} />
                </div>
              ) : (
                <>
                  {ReactPlayer.canPlay(data?.url) ? (
                    <RowModule>
                      <ReactPlayer url={data?.url} width="100%" height="577px" controls />
                    </RowModule>
                  ) : (
                    <div>
                      <Skeleton variant={"rect"} height={400} width={"100%"} />
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </RowModule>
        <RowModule>
          <Card className="card-custom">
            <CardBody>
              {General?.loading ? (
                <RowModule customColumnCss="col-lg-4 col-md-4 col-sm-4 mb-2">
                  {Array.from({ length: 3 }).map((_, i) => (
                    <div key={i}>
                      <Skeleton height={20} width={300} />
                      <div className={"mt-4"}>
                        <Skeleton height={50} width={300} />
                      </div>
                    </div>
                  ))}
                </RowModule>
              ) : (
                <RowModule customColumnCss="col-lg-4 col-md-4 col-sm-4 mb-2">
                  <div>
                    <p>
                      Judul Video
                      <br />
                      <br />
                      <b>{data?.title}</b>
                    </p>
                  </div>
                  <div>
                    <p>
                      Link Video
                      <br />
                      <br />
                      <b>{data?.url}</b>
                    </p>
                  </div>
                  <div>
                    <p>
                      Kategori Video
                      <br />
                      <br />
                      <b>{data?.category}</b>
                    </p>
                  </div>
                </RowModule>
              )}
            </CardBody>
          </Card>
        </RowModule>
      </>
    )
  }

  return render()
}
