import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"

export const CloseSvgIcon = () => {
  return (
    <span className="svg-icon svg-icon-lg svg-icon-primary">
      <SVG src={toAbsoluteUrl("/media/svg/icons/Detail/ic_bullet_close.svg")} />
    </span>
  )
}
