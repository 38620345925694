import { TableView } from "../../../component"
import React, { useEffect, useState } from "react"
import { formatterRowComponent } from "../../../component/atoms/Tabel"
import { toMeter } from "../../helpers/TextHelper"

export const TableDetailListApproveBulkPopup = ({ data }) => {
  const [dataList, setDataList] = useState([])

  useEffect(() => {
    if (data?.order_list) {
      setDataList(data?.order_list)
    }
  }, [data])
  const columns = [
    {
      dataField: "area_name",
      text: "NAMA LAHAN",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      }
    },
    {
      dataField: "area_land",
      text: "LUAS LAHAN",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiArea
      }
    },
    {
      dataField: "activity_type",
      text: "JENIS AKTIVITAS",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiActivity
      }
    },
    {
      dataField: "spraying_count",
      text: "JUMLAH PENYEMPROTAN"
    }
  ]

  function uiName(e) {
    return (
      <div>
        <div className={"font-weight-bolder"}>{e?.area_name ?? "-"}</div>
        <div>{e?.sub_district ?? "-"}</div>
      </div>
    )
  }

  function uiArea(e) {
    return (
      <div>
        <div>{e?.area_land ? toMeter(e?.area_land) : "-"}</div>
      </div>
    )
  }

  function uiActivity(e) {
    return (
      <div>
        <div className={"font-weight-bolder"}>{e?.activity_type ?? "-"}</div>
      </div>
    )
  }

  return (
    <div className={"table_list_popup_schedule table_hidden_bottom"}>
      <TableView dataTable={dataList} loadingGetData={false} columnProperties={columns} />
    </div>
  )
}
