import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import React, { useEffect, useState } from "react"
import { SelectInput, TextInput } from "../../../../component"
import { useDispatch, useSelector } from "react-redux"
import { getListRoleByParam } from "../../../../redux/actions/RoleAction"
import { useFormikContext } from "formik"
import { fetchProvince } from "../../../../redux/actions/MasterDataAction"

export const CreateUser = ({ isReadOnly, setIsValid }) => {
  const { MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const { values: formValues } = useFormikContext()
  const [stateRole, setStateRole] = useState()
  const { Role } = useSelector((state) => state)
  const [role, setRole] = useState(formValues["role"])
  let [proviceList, setProvinceList] = useState([])
  const [province, setProvince] = useState(formValues["province"])
  const { values } = useFormikContext()

  useEffect(() => {
    if (values?.name && values?.email && values?.role && values?.phone && values?.province_id) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [values])

  useEffect(() => {
    dispatch(getListRoleByParam("non_fo"))
  }, [])

  useEffect(() => {
    dispatch(fetchProvince())
  }, [])

  useEffect(() => {
    setProvinceList(
      MasterData.listRegionalSelected.map((value) => ({ label: value.name, value: value.id }))
    )
  }, [MasterData])

  useEffect(() => {
    const allString = { label: "All", value: "ALL" }
    proviceList.unshift(allString)
  }, [proviceList])

  useEffect(() => {
    if (Role.listRole) {
      setStateRole(Role.listRole.map((value) => ({ label: value.display_name, value: value.id })))
    }
  }, [Role.listRole])

  useEffect(() => {
    if (formValues.hasOwnProperty("role")) {
      setRole(formValues["role"])
    }
  }, [formValues["role"]])

  useEffect(() => {
    if (formValues.hasOwnProperty("province_id")) {
      setProvince(formValues["province_id"])
    }
  }, [formValues["province_id"]])

  const onChangeRole = (e) => {
    setRole(e)
  }
  const onChangeProvince = (e) => {
    setProvince(e)
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="INFORMASI USER" />
        <CardBody>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Nama User"
              name="name"
              placeholder="Tambahkan nama user"
              type={"text"}
            />
          </RowModule>

          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Email"
              name="email"
              placeholder="Contoh : user@maxxitani.id"
              type={"email"}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name="role"
              onChange={onChangeRole}
              editable={!isReadOnly}
              label="Role User"
              placeholder="Silahkan pilih role user"
              options={stateRole}
              withoutFeedback={false}
              clearNow={true}
              value={role}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name={"province_id"}
              onChange={onChangeProvince}
              editable={!isReadOnly}
              label="Regional"
              placeholder="Silahkan pilih salah satu"
              options={proviceList}
              withoutFeedback={false}
              clearNow={true}
              value={province}
            />
          </RowModule>

          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Nomor Hanphone"
              name="phone"
              placeholder="Tambahkan nomor handphone"
              type={"number"}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}
