import React from "react"
import { CardDetail } from "../../../../component"
import { FlatIconAndText } from "../../../../component/atoms/FlaticonAndText"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { Card } from "react-bootstrap"
import { CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import { loadUserLogin } from "../../../service/userManagementAction"
import { PRIVILEGE_ENUM } from "../../../../config/privilegeCollection"

export const DetailPetaniLead = ({ farmerData, status, descriptionLeft, flatIconAndText2Rows }) => {
  const privileges = loadUserLogin().privilege

  const mitraDesc = data => {
    return (
      <>
        <div className="d-flex flex-wrap mb-4">
          <FlatIconAndText
            text={data?.mitra_id ?? "-"}
            svg={"/media/svg/icons/Detail/ic_account.svg"}
          />
          {privileges.indexOf(PRIVILEGE_ENUM.MITRA_PHONE_NUMBER) > -1 && (
            <FlatIconAndText
              text={data?.mitra_phone ?? "-"}
              svg={"/media/svg/icons/Detail/ic_phone.svg"}
            />
          )}
        </div>
      </>
    )
  }

  return (
    <>
      {status === "rejected" && (
        <RowModule>
          <Card>
            <CardHeader title={"Alasan Penolakan"} className="mb-0 pb-2" />
            <CardBody>
              {!farmerData ? null : farmerData?.farmer_reject_enum?.length === 0 ? (
                <span>Alasan tidak ditemukan.</span>
              ) : (
                <ul>
                  {farmerData?.farmer_reject_enum?.map((e, i) => (
                    <li key={i}>{e?.enum_value}</li>
                  ))}
                </ul>
              )}
            </CardBody>
          </Card>
        </RowModule>
      )}
      <RowModule>
        <CardDetail
          titleCardDetail={farmerData != null ? farmerData.name : ""}
          descriptionLeft={descriptionLeft}
          flatIconAndText2Rows={flatIconAndText2Rows}
        />
      </RowModule>

      <RowModule>
        <CardDetail
          header={<h3 className="mb-0">Mitra</h3>}
          titleCardDetail={farmerData ? farmerData?.mitra_name ?? "-" : "-"}
          descriptionLeft={mitraDesc(farmerData)}
        />
      </RowModule>
    </>
  )
}
