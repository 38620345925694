import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls"
import { TextInput } from "../../../../component"
import { UploadComponent } from "../../../../component/atoms/UploadArea"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { resetUrlResponse, uploadAction } from "../../../../redux/actions/MasterDataAction"
import { useDispatch, useSelector } from "react-redux"
import { UploadIcon } from "../../../../component/atoms/Icons/SVG/upload"
import { UPLOAD } from "../../../../constants/InitTypeConstants"

export const DocumentMitra = ({ isReadOnly }) => {
  const { MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()

  const [urlKtp, setUrlKtp] = useState("")
  const [swafoto, setSwafoto] = useState("")
  const [kk, setKk] = useState("")
  const [npwp, setNpwp] = useState("")

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (MasterData.uploadType === UPLOAD.KTP) {
        setUrlKtp(MasterData.urlResponseUpload)
      } else if (MasterData.uploadType === UPLOAD.SWAFOTO) {
        setSwafoto(MasterData.urlResponseUpload)
      } else if (MasterData.uploadType === UPLOAD.BUSINESS_DOCUMENT) {
        setKk(MasterData.urlResponseUpload)
      } else if (MasterData.uploadType === UPLOAD.OTHER_IMAGE) {
        setNpwp(MasterData.urlResponseUpload)
      }
    }
    return () => {
      dispatch(resetUrlResponse())
    }
  }, [MasterData.urlResponseUpload, MasterData.uploadType])

  const placeholder = <UploadIcon />

  function onChangeFileKtp(e) {
    dispatch(uploadAction({ data: e, type: UPLOAD.KTP }))
  }

  function onChangeFileSwafoto(e) {
    dispatch(uploadAction({ data: e, type: UPLOAD.SWAFOTO }))
  }

  function onChangeFileKK(e) {
    dispatch(uploadAction({ data: e, type: UPLOAD.BUSINESS_DOCUMENT }))
  }

  function onChangeFileNpwp(e) {
    dispatch(uploadAction({ data: e, type: UPLOAD.OTHER_IMAGE }))
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <RowModule>
            <CardHeaderTitle className={"my-12"}>
              {"KTP & SWAFOTO MITRA".toUpperCase()}
            </CardHeaderTitle>
          </RowModule>
          <RowModule>
            <TextInput
              required
              editable={!isReadOnly}
              label="Nama Sesuai KTP"
              name="name"
              placeholder="Input nama sesuai KTP"
            />
          </RowModule>
          <RowModule>
            <TextInput
              required
              editable={!isReadOnly}
              label="Nomor KTP"
              type="number"
              name="ktp_no"
              placeholder="Input Nomor KTP"
            />
          </RowModule>
          <RowModule>
            <UploadComponent
              required
              urlParsing={urlKtp}
              name="ktp_pic_url"
              label="Foto KTP"
              editable={!isReadOnly}
              onChange={onChangeFileKtp}
              maxSize={2048000}
              maxSizeText="2 MB"
              placeholder={placeholder}
            />
          </RowModule>
          <RowModule>
            <UploadComponent
              urlParsing={swafoto}
              name="ktp_selfie_pic_url"
              required
              label="Swafoto Dengan KTP "
              editable={!isReadOnly}
              onChange={onChangeFileSwafoto}
              maxSize={2048000}
              maxSizeText="2 MB"
              placeholder={placeholder}
            />
          </RowModule>
          <RowModule>
            <UploadComponent
              required={true}
              urlParsing={kk}
              name="business_document_pic_url"
              label="KK"
              editable={!isReadOnly}
              onChange={onChangeFileKK}
              maxSize={2048000}
              maxSizeText="2 MB"
              placeholder={placeholder}
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              label="Nomor NPWP"
              type="number"
              name="npwp_no"
              placeholder="Input nomor NPWP"
            />
          </RowModule>
          <RowModule>
            <UploadComponent
              urlParsing={npwp}
              name="other_image"
              label="Foto NPWP"
              editable={!isReadOnly}
              onChange={onChangeFileNpwp}
              placeholder={placeholder}
              maxSize={2048000}
              maxSizeText="2 MB"
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}
