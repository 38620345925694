import axios from "axios"

import { ORDER_URL } from "../../constants/UrlConstants"
import { saveAs } from "file-saver"
import { objectString } from "../../app/helpers/TextHelper.js"

const END_POINT = process.env.REACT_APP_API_ENDPOINT
const req = axios.create({
  baseURL: `${END_POINT}`
})

export function loadUserLogin() {
  return JSON.parse(localStorage.getItem("userLogin"))
}

export function getUserToken() {
  return localStorage.getItem("jwt_token")
}

req.interceptors.request.use(
  config => {
    if (config.url !== "v1/account/login") {
      config.headers["Authorization"] = "Bearer " + getUserToken()
    }
    if (config.url === ORDER_URL.GET_CALENDAR_STATUS) {
      config.data = "aaaa"
    }

    config.headers["Content-Type"] = "application/json"
    config.headers["Accept"] = "application/json"
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

req.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export const requestPost = (url, body) => {
  return new Promise((resolve, reject) => {
    req
      .post(`${url}`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject({
          statusURL: error.response?.status,
          message: error.response?.data?.data
        })
      })
  })
}

export const requestGet = (url, params) => {
  return new Promise((resolve, reject) => {
    req
      .get(`${url}${params ? objectString(params) : ""}`)
      .then(response => {
        resolve(response.data)
      })
      .then(error => {
        reject(error)
      })
      .catch(error => {
        reject({
          statusURL: error.response?.status,
          message: error.response?.data?.data
        })
      })
  })
}

export const requestPut = (url, params, body) => {
  return new Promise((resolve, reject) => {
    req
      .put(`${url}${params ? objectString(params) : ""}`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject({
          statusURL: error.response.status,
          message: error.response?.data?.data
        })
      })
  })
}

export const requestPatch = (url, body) => {
  return new Promise((resolve, reject) => {
    req
      .patch(`${url}`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject({
          statusURL: error.response?.status,
          message: error.response?.data?.data
        })
      })
  })
}

export const requestDelete = (url, body) => {
  return new Promise((resolve, reject) => {
    req
      .delete(`${url}`, {
        data: body
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject({
          statusURL: error.response.status,
          message: error.response?.data?.data
        })
      })
  })
}

export const requestAnyGet = (url, params, headers) => {
  return new Promise((resolve, reject) => {
    req
      .get(
        `${url}${objectString(params)}`,
        headers && {
          headers: headers
        }
      )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const requestPostMultipart = url => {
  return new Promise((resolve, reject) => {
    req
      .post(`${url}`, params, {
        headers: {
          "Content-Type": "multipart/form-data" // Set the correct content type for form data
        }
      })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const requestDownloadXls = ({ url, body, fileName }) => {
  return new Promise((resolve, reject) => {
    fetch(END_POINT + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getUserToken()
      },
      body: JSON.stringify(body)
    })
      .then(response => response.blob())
      .then(blob => {
        saveAs(blob, `${fileName}.xls`)
        resolve(true)
      })
      .catch(error => {
        reject(error)
      })
  })
}
