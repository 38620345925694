export const EN_CONTENT = {
  button: {
    bulk_erp: "Bulk ERP ({{count}} selected)",
    cancel: "Cancel",
    confirmation_planting_season: "Confirmation of Planting Season",
    continue: "Continue",
    download_so_selected: "Download SO ({{selected}}) selected",
    oke: "Oke",
    previous: "previous",
    send_to_finance: "Send to Finance",
    submit: "Submit",
    approve: "Approve",
    reject: "Reject",
    add: "Add",
    next: "Lanjut",
    apply: "Apply"
  },
  content: {
    attachment_maximum_size: "The maximum accumulated file size is 15Mb.",
    attachment_maximum_file: "The maximum number of uploaded files is 10.",
    attachment_format_file: "File formats .pdf, .jpeg, .jpg, .png."
  },
  description: {},
  menu: {
    add: "Add",
    area: "Area",
    dashboard: "Dashboard",
    farmer: "Farmer",
    fo: "FO",
    mac: "MAC",
    master_data: "Master Data",
    mitra: "Mitra",
    order: "Order",
    setting: "Setting",
    submission: "Submission"
  },
  menu_aside: {
    area_location: "Area Location",
    area_terverifikasi: "Verified",
    area_pending: "Pending",
    area_reject: "Reject",
    area_unassigned: "Unassigned",
    area_lead: "Area Lead",
    area_activity_report: "Activity Report",
    area_activity_report_kur: "Activity Report KUR",
    area_sales_order: "Sales Order",
    all_schedule: "All Schedule",
    approved_schedule: "scheduled",
    schedule_pasca_planting: "Pasca Planting",
    schedule_pra_planting: "Pre Planting",
    mac_profile: "Profile",
    mac_user: "User",
    pending: "Pending",
    rescheduled: "Reschedule",
    spraying: "Spraying",
    regional: "Regional",
    pre_planting: "Pre Planting",
    sertification_and_block: "Sertifikasi & Blok"
  },
  sub_header: {
    activity_report: "Activity Report",
    activity_report_kur: "Activity Report Kur",
    title: "Sales Order",
    all_schedule: "All Schedule",
    mac_activity_report: "MAC Activity Report",
    mitra_list_ca: "Daftar Mitra Ca",
    farmer_list_ca: "Daftar Petani KUR",
    spraying_pre_planting: "Spraying Pre Planting",
    pre_planting: "Pre Planting",
    regional_information: "Regional Information",
    mitra_list: "List Mitra"
  },
  placeholder: {
    select_one: "Select One",
    select_regional: "Select Regional",
    insert_plant_type: "insert plant type",
    insert_mac_username_or_request_id: "Insert user name / request ID",
    input_reason: "Input Reason",
    search_activity_report: "Input user name / submission ID",
    insert_area_name: "Insert area name",
    insert_regional_id_or_name: "insert regional ID / Regional Name",
    select_team_drone: "Select Team Drone",
    insert_province: "Insert Province Name",
    search_mitra_name_or_id: "Cari ID mitra / nama mitra",
    search_farmer_name_or_id: "Cari ID petani / nama petani",
    insert_regional_name: "insert regional name",
    insert_sub_regional_name: "Input sub regional name",
    search_area_block: "Find area name / block"
  },
  label: {
    add_package: "add package",
    total_pesticide: "Jumlah Pestisida",
    package_status: "Package Status",
    total_treatment: "Total treatment",
    package_id: "Package ID",
    spraying_package: "Spraying package",
    detail_regional: "Detail Regional",
    farmer_id: "Farmer ID",
    farmer_list: "List Farmer",
    inactive: "inactive",
    rejection_reason: "rejection reason",
    regional_id: "ID regional",
    province_name: "Province Name",
    rejected_date: "Rejected Date",
    sub_regional: "Sub Regional",
    sub_regional_amount: "Total Sub Regional",
    reject: "rejected",
    active: "Active",
    edit_data_regional: "Edit Data Regional",
    district_or_city: "Kabupaten / kota",
    sub_regional_name: "sub regional name",
    add_sub_regional: "Add sub regional",
    id_sub_regional: "ID Sub Regional",
    address: "Address",
    mitra_id: "Mitra ID",
    detail_sub_regional: "Detail Sub Regional",
    mitra_type: "Mitra Type",
    activity: "Activity",
    activity_detail: "Activity Detail",
    activity_type: "Activity Type",
    activity_working_date: "Activity working date",
    activity_picture: "activity picture",
    area_name: "Area",
    area_information: "Area Information",
    submitted_date: "submitted date",
    area_address: "Area Address",
    activity_date: "Activity Date",
    area_location: "Area Location",
    action: "action",
    bulk_scheduling: "Bulk Approval",
    cabang: "Regional",
    company_name: "Company Name",
    company_phone: "Company Phone",
    company_full_address: "Company Full Address",
    company_email: "Company Email",
    company_document: "Company Document",
    category_item: "Category Item",
    created_date: "Created Date",
    canceled_by: "Canceled By",
    data_activity: "Data Activity",
    data_rescheduled: "Data Rescheduled",
    edit: "Edit",
    farmer: "Farmer",
    farmer_name: "Farmer Name",
    full_address: "Full Address",
    harvest_date: "Harvest Date",
    input_reason: "Input Reason",
    land_area_worked: "Worked Area of Land",
    land_area_total: "Total Land Area",
    land_conditions: "Land Condition",
    mitra: "Mitra",
    mitra_name: "Mitra Name",
    manual_area_size: "Area Size (Manual)",
    mac_profile_information: "Information Profile MAC",
    mac_user_information: "Information User MAC",
    mac_data_activity: "Data Activity",
    mac_data_rescheduled: "Data Rescheduled",
    mac_id_user: "ID User MAC",
    mac_user_name: "User Name MAC",
    mac_full_address: "Address User MAC",
    mac_data_scheduled: "Data Scheduled",
    scheduled_data: "Scheduled Data",
    member: "member",
    machine_owner: "Alsintan Owner",
    nominal: "Nominal",
    npwp: "Nomor NPWP",
    nik: "NIK",
    note: "Note",
    name: "Name",
    cp_name: "Narahubung",
    cp_phone: "Phone",
    order_id: "Order ID",
    overview_activity: "Overview Activity",
    overview_land_data: "Overview Area",
    operator: "Operator",
    others: "others",
    operator_name: "Operator Name",
    plant_data: "Plant Data",
    plant_type: "Plant type",
    plant_age: "Plant Age",
    province: "Province",
    product: "Product",
    planting_date: "Planting Date",
    plant_varieties: "Plant Varieties",
    phone_number: "Phone Number",
    pilot_name: "Pilot Name",
    pasca_planting: "Pasca Planting",
    regional: "regional",
    request_id: "request id",
    request_detail: "request detail",
    upload_so: "Upload SO",
    total_activity: "Total Activity",
    type_schedule: "Type Scheduled",
    warehouse: "Pesticide Warehouse",
    worker_area_size: "Worked area size (Alsintan)",
    pending: "Pending",
    payment_receipt: "payment receipt",
    receipt_number: "recipe number",
    phone: "Telephone Number",
    pic: "PIC",
    first_pic: "PIC First Scheduled",
    submit_spk: "SUBMIT SPK",
    select_date_range: "Select date range",
    status: "Status",
    supporting_photo: "Supporting Photo",
    scheduled: "Scheduled",
    scheduling: "Scheduling",
    scheduled_date: "Scheduled Date",
    scheduled_reason: "Scheduled Reason",
    first_scheduled_date: "First Schedule Date",
    scheduled_by: "Scheduled By",
    schedule_date: "Schedule Date activity",
    submitted_by: "Submitted By",
    submitted_time: "Submitted time",
    user_name: "User Name",
    spraying_pasca_planting: "Penyemprotan Pasca Tanam",
    spraying_activity: "Spraying Activity",
    spraying_activity_mac: "Spraying Activity MAC",
    spraying: "Spraying",
    team_drone: "Team Drone",
    team_name: "team name",
    reschedule: "Reschedule",
    rescheduled_date: "Tanggal Pengganti",
    rescheduled_reason: "Rescheduled Reason",
    reason_reject: "Reject Reason",
    reason_cancelation: "Feason for Cancellation",
    reject_by: "Reject By",
    overdue: "overdue",
    done: "done",
    canceled: "canceled",
    rejected: "rejected",
    rejected_by: "Rejected By",
    reset_date: "Reset date",
    apply: "Apply",
    mac_user_merchant_name: "MAC User Merchant name",
    add_plant_type: "Add Plant Type",
    edit_plant_type: "EDIT PLANT TYPE",
    insert_plant_type_name: "Insert plant type name",
    input_plant_name: "Insert plant name",
    add_regional: "Add Regional",
    edit_regional: "Edit Regional",
    varietas: "Varietas",
    regional_name: "Regional Name",
    list_province: "List Provinsi",
    insert_regional_name: "Insert Regional Name",
    spraying_schedule: "Spraying Schedule",
    preview: "Preview",
    spraying_activity_scheduling: "Penjadwalan Aktivitas Penyemprotan",
    review_and_submit: "Review & Submit",
    bulk_approval: "Bulk Approval",
    land_area: "Land Area",
    delete_sub_regional: "Delete Sub Regional",
    add_treatment: "Add Treatment",
    edit_treatment: "Edit Treatment",
    id_treatment: "ID Treatment",
    hst: "HST",
    submitted: "Submitted",
    received: "Received",
    submission_certification_block: "Submission Certification & Block",
    description: "Description",
    canot_more_than: "Total land must not exceed 100,000 m² (10 Ha)",
    total_area: "Total Area",
    female_planting_date: "Female Planting Date",
    see_map: "See Map",
    data_previous_plant: "Previous Crop Data",
    data_from_area_bigger:
      "Information is taken from Seeding Activation data from the largest land size.",
    land_history: "Land History",
    former_bero: "Former Bero",
    disertifikasi: "Disertifikasi",
    seed_class: "Seed Class",
    field_inspection: "Field Inspection",
    cancel_submission: "Cancel Submission",
    cancel_submission_certification: "CANCEL SUBMISSION CERTIFICATION",
    submission_download: "Download Submission"
  },
  messages: {
    alert: {
      invalid_file_type: "Invalid File Type",
      max_mb: "max {{size}} mb"
    },
    title: {
      change_status_package: "UBAH STATUS PAKET",

      activity_send_to_erp: "SEND ACTIVITIES TO ERP",
      activity_select_regional: "REGIONAL REPORTING ACTIVITIES",
      approve_schedule: "APPROVE SCHEDULE",
      cancel_schedule: "CANCEL SCHEDULE",
      cancel_reschedule: "CANCEL EDIT SCHEDULE",
      edit_order: "EDIT ORDER",
      reschedule_order: "RESCHEDULE ORDER",
      planting_season_confirmation: "PLANTING SEASON CONFIRMATION",
      reject_order: "REJECT ORDER",
      cancel_order: "ORDER CANCELATION",
      preview_information_regional: "Preview Information Regional"
    },
    confirmation: {
      edit_package:
        "Apakah anda yakin ingin memperbarui paket ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan paket ini.",
      change_status_package:
        "Apakah anda yakin ingin menonaktifkan paket ini? Tindakan ini akan berpengaruh kepada semua data yang terkait dengan paket ini.",
      edit_regional: "EDIT REGIONAL",
      create_regional:
        "The regional data that has been filled in will be entered into the regional list. Make sure the data entered is correct before continuing",
      submit_add_regional: "Make sure the regional data entered is correct before continuing.",
      activity_approve_to_erp_confirmation:
        "By agreeing to this activity, the available data will be sent to the ERP. Make sure all data is correct.",
      activity_approve_to_erp_confirmation_bulk:
        "The selected activity will be sent to the ERP system ({{count}} Activities). Make sure all the data selected is correct.",
      create_so:
        "The filled data will be sent to become SPK in the ERP. Make sure all the data entered is correct.",
      create_so_success: "SO success created.",
      order_spraying_cancel:
        "By canceling the form filling, the order will not be scheduled and all data that has been filled in will be lost.",
      order_edit_spraying_cancel:
        "By canceling the edit form, all data that has been filled in will be lost.",
      order_spraying_approve:
        "Approved orders will be included in the scheduling date. Make sure the data entered is correct.",
      order_edit: "After agreeing, the order data will change. Changed data:",
      planting_season_confirmation_need_ba:
        "Confirming the growing season, this growing season's activity will be moved to the Activity History tab. " +
        "Make sure the minutes have been made.",
      planting_season_confirmation:
        "Confirming the growing season, this growing season's activity will be moved to the Activity History tab.",
      team_edit_cancel:
        "By canceling the edit form, all data that has been filled in will be lost.",
      team_create_cancel:
        "By canceling the form filling, a new team will not be created and all data that has been filled in will be lost.",
      team_edit:
        "After agreeing, the drone team data will change. Make sure all data has been filled in correctly.",
      team_create:
        "By adding a drone team, the team can be scheduled for spraying activities. Make sure the data entered is correct.",
      team_create_with_schedule:
        "The edited team still has the spraying schedule and the new Team members will follow all existing schedules. Are you sure you want to change team members?",
      add_treatment:
        "The treatment data that has been filled in will be included in the related treatment list. Make sure the data entered is correct before continuing",
      edit_treatment:
        "Are you sure you want to renew this treatment? This action will affect all data related to this treatment.",
      cancel_submission:
        "Are you sure you want to cancel the selected land application? Canceled land will be returned to the Field Officer."
    },
    response: {
      area_unassigned_approve:
        "The unassign request is approved and the land status changes to unassigned.",
      area_unassigned_reject:
        "The unassign request is rejected and the land status remains registered.",
      activity_approve_to_erp_success: "Activity successfully sent to ERP.",
      broadcast_success_delete: "The broadcast message has been deleted successfully.",
      broadcast_create_success: "The broadcast message was sent successfully.",
      data_not_found: "Data not found",
      empty_state: "{{name}} data is empty",
      searching_not_found: "Your search was not found",

      erp_sync: "Farmer data synchronization to ERP successful.",
      farmer_rejected: "Farmers successfully rejected",
      farmer_approved: "Farmer successfully verified",
      farmer_edit_success: "Farmer data updated successfully",
      farmer_create_success: "Farmers successfully registered.",
      order_rejected: "Activity was successfully rejected.",
      order_cancel: "Activity canceled successfully.",
      order_success: "The activity was successfully scheduled.",
      success_approve_area: "Area verficication successfully",
      team_drone_change_success: "The drone team has been updated successfully.",
      team_drone_add_success: "The drone team has been successfully added.",
      success_create_plant_type: "Plant successfully added",
      success_create_regional: "Data regional created successfully",
      success_edit_regional: "Data regional updated successfully",
      success_edit_sub_regional: "Edit sub regional success.",
      response_not_found_data: "Your search was not found",
      sub_response_not_found_data: "Please check the filters or keywords and try again.",
      success_download_submission: "Download of certification application was successful."
    }
  },
  profile: {
    label_profile: "User Profile",
    label_language: "Select Language"
  },
  sales_order: {
    title: "Sales Order",
    table_label_order_id: "Order ID",
    table_label_area_name: "Area Name",
    table_label_farmer: "Farmer",
    table_label_activity_type: "Activity Type",
    table_label_created_date: "Created Date",
    table_label_no_spk: "No SPK",
    order_information: "ORDER INFORMATION",
    title_detail_spk: "DETAIL SURAT PERINTAH KERJA"
  },
  table_header: {
    area_name: "Area Name",
    block_name: "Block Name",
    activity_type: "Activity Type",
    created_date: "Created Date",
    farmer: "Farmer",
    mitra: "Mitra",
    planting_type: "Planting Type",
    status: "Status",
    request_id: "Reuest Id",
    user_mac: "User MAC",
    land_area: "Land Area",
    full_address_mac: "MAC FULL ADDRESS",
    plant_type: "plant type",
    plant_age: "Umur Tanaman",
    request_date: "Request Date",
    activity_date: "Activity Date",
    sub_regional_amount: "sub regional amount",
    overdue_date: "overdue date",
    plant_name: "plant name",
    user: "user",
    canceled_date: "cancel date",
    reject_date: "reject date",
    profile_mac: "MAC PROFILE",
    scheduled_by: "Scheduled by",
    scheduled_date: "Scheduled date",
    submit_by: "Diajukan Oleh",
    canceled_by: "canceled by",
    reject_by: "reject by",
    regional_id: "Regional ID",
    regional_name: "Regional Name",
    province: "Province",
    area: "Area",
    varietas: "Variety",
    female_date: "Female Planting Date",
    submission_date: "Submitted Date",
    submitted_by: "Submitted By"
  }
}
