import React, { useEffect, useState } from "react"
import { useSubheader } from "../../../_metronic/layout"
import { useLocation, useParams } from "react-router"
import { CardBody, CardHeader } from "../../../_metronic/_partials/controls"
import { Button, Card, Form } from "react-bootstrap"
import { SectionDetailOrderHeader } from "./sectionDetail/SectionDetailOrderHeader"
import { SectionDetailOrderAreaInformation } from "./sectionDetail/SectionDetailOrderAreaInformation"
import { SectionDetailOrderPlantInfo } from "./sectionDetail/SectionDetailOrderPlantInfo"
import { SectionDetailOrderActivity } from "./sectionDetail/SectionDetailOrderActivity"
import { useDispatch, useSelector } from "react-redux"
import {
  ApproveRejectOrder,
  CancelOrderActions,
  GetCancelEnumList,
  GetDetailOrder,
  GetRejectReasonList,
  RejectOrderPackage
} from "../../../redux/actions/OrderAction"
import { ButtonDropdown } from "../../../component"
import { ICON_CONSTANS, ILUSTRATION_CONSTANS } from "../../../constants/ImageConstants"
import { SectionDetailOrderReschedule } from "./sectionDetail/SectionDetailOrderReschedule"
import { PopupQuestion } from "../../../component/atoms/Popup"
import swal from "sweetalert"
import SVG from "react-inlinesvg"
import { useHistory } from "react-router-dom"
import { ORDER } from "../../../constants/InitTypeConstants"
import { RowModule } from "../../../component/atoms/RowModule/RowModule.jsx"
import { RoutesConstants } from "../../../constants/RoutesConstants"
import { Tooltip } from "@material-ui/core"
import { PRIVILEGE_ENUM } from "../../../config/privilegeCollection"
import { loadUserLogin } from "../../service/userManagementAction"
import { ORDER_STATUS_ENUM, ROUTING_STATUS } from "../../../constants/StringConstant"
import { useTranslation } from "react-i18next"
import { Skeleton } from "@material-ui/lab"

export const DetailsOrder = () => {
  const { t } = useTranslation()
  const { Order, General } = useSelector((state) => state)
  const [data, setData] = useState(undefined)
  const [showPopupRejectCancel, setShowPopupRejectCancel] = useState(false)
  const [selected, setSelected] = useState(new Set())
  const [checkedSelectReason, setCheckedSelectReason] = useState([])
  const [reasonOtherString, setReasonOtherString] = useState(null)
  const [isDisableBtnReject, setIsDisableBtnReject] = useState(true)
  const [listCancelEnum, setListCancelEnum] = useState([])
  const [listRejectEnum, setListRejectEnum] = useState([])
  const [listRejectCancelCard, setListRejectCancelCard] = useState([])
  const [listCheckBox, setListCheckbox] = useState([])

  const subheader = useSubheader()
  const param = useParams()
  const id = param?.id
  const status = param?.status
  const dispatch = useDispatch()
  const history = useHistory()
  const privileges = loadUserLogin().privilege
  const location = useLocation()

  useEffect(() => {
    if (status === ORDER_STATUS_ENUM.APPROVED) {
      setListCheckbox(listCancelEnum)
    } else if (status === ORDER_STATUS_ENUM.PENDING) {
      setListCheckbox(listRejectEnum)
    }
  }, [status, listCancelEnum, listRejectEnum, location])
  useEffect(() => {
    dispatch(GetDetailOrder(id))
    if (status === ORDER_STATUS_ENUM.APPROVED) {
      dispatch(GetCancelEnumList())
    } else if (status === ORDER_STATUS_ENUM.PENDING) {
      dispatch(GetRejectReasonList())
    }
  }, [status])

  useEffect(() => {
    if (
      (General.isFinishUpdateOrDelete && General.actionInitType === ORDER.REJECT_ORDER) ||
      (General.isFinishUpdateOrDelete && General.actionInitType === ORDER.CANCEL_ORDER)
    ) {
      swal({
        text:
          General.actionInitType === ORDER.CANCEL_ORDER
            ? t("messages.response.order_cancel")
            : t("messages.response.order_rejected"),
        icon: ILUSTRATION_CONSTANS.SUCCESS_PLACEHOLDER,
        buttons: {
          detail: { text: "Lihat Detail", className: "sweet-outline-success" },
          okey: { text: "Ok", className: "sweet-success" }
        }
      }).then((value) => {
        switch (value) {
          case "detail":
            if (General.actionInitType === ORDER.CANCEL_ORDER) {
              history.push(RoutesConstants.ORDER.DETAIL(ORDER_STATUS_ENUM.CANCELED, id))
            } else {
              history.push(RoutesConstants.ORDER.DETAIL(ORDER_STATUS_ENUM.REJECTED, id))
            }
            break
          default:
            break
        }
      })
      history.push(RoutesConstants.ORDER.LIST_BY_STATUS("all"))
    }
  }, [General])

  useEffect(() => {
    if (checkedSelectReason.length > 0) {
      if (checkedSelectReason.find((result) => result === "OTHER")) {
        if (reasonOtherString) {
          setIsDisableBtnReject(false)
        } else {
          setIsDisableBtnReject(true)
        }
      } else {
        setIsDisableBtnReject(false)
      }
    } else {
      setIsDisableBtnReject(true)
    }
  }, [checkedSelectReason, reasonOtherString])

  useEffect(() => {
    if (Order?.data) {
      setData(Order.data)
    }
    if (Order?.reasonList) {
      const data = [...Order.reasonList].reverse()
      if (data) {
        setListRejectEnum(data)
      }
    }
    if (Order?.cancelEnumList) {
      const data = [...Order.cancelEnumList].reverse()
      if (data && status === ORDER_STATUS_ENUM.APPROVED) {
        setListCancelEnum(data)
      }
    }
  }, [Order])

  useEffect(() => {
    if (
      data?.cancellation_reason &&
      data?.cancellation_reason.length &&
      status === ORDER_STATUS_ENUM.CANCELED
    ) {
      setListRejectCancelCard(data.cancellation_reason)
    } else if (
      data?.reject_reason &&
      data?.reject_reason.length &&
      status === ORDER_STATUS_ENUM.REJECTED
    ) {
      setListRejectCancelCard(data.reject_reason)
    }
  }, [data, Order, status])

  const onClickReject = () => {
    setShowPopupRejectCancel(true)
  }

  const ButtonActionSubheader = (
    <div className={"d-flex align-items-center"} style={{ gap: 8 }}>
      {data?.status_enum !== ORDER_STATUS_ENUM.RESCHEDULED &&
        privileges.indexOf(PRIVILEGE_ENUM.APPROVAL_ORDER) > 1 && (
          <Button onClick={onClickReject} className={"btn btn-danger"}>
            Reject
          </Button>
        )}

      {privileges.indexOf(PRIVILEGE_ENUM.APPROVAL_ORDER) > 1 && (
        <Button
          className={"btn btn-primary"}
          onClick={() => history.push(RoutesConstants.ORDER.APPROVAL(status, id))}>
          Approve
        </Button>
      )}
    </div>
  )

  const listDropdown = []
  const listDropdownCancel = []
  if (privileges.indexOf(PRIVILEGE_ENUM.EDIT_ORDER) > 1) {
    listDropdown.push({
      title: "Edit Aktivitas",
      icon: ICON_CONSTANS.IC_EDIT,
      action: () => history.push(RoutesConstants.ORDER.APPROVAL("approved", id)),
      showed: true
    })
  }
  if (privileges.indexOf(PRIVILEGE_ENUM.CANCEL_ORDER) > 1) {
    listDropdown.push({
      title: "Batalkan Aktivitas",
      icon: ICON_CONSTANS.IC_SUSPEND,
      showed: true,
      action: () => {
        setShowPopupRejectCancel(true)
      }
    })
    listDropdownCancel.push({
      title: "Batalkan Aktivitas",
      icon: ICON_CONSTANS.IC_SUSPEND,
      showed: true,
      action: () => {
        setShowPopupRejectCancel(true)
      }
    })
  }

  const ButtonEditAndCancel = <ButtonDropdown title={"Action"} dropdownList={listDropdown} />
  const ButtonCancel = <ButtonDropdown title={"Action"} dropdownList={listDropdownCancel} />

  const checkStatus = () => {
    if (data?.status_string === ROUTING_STATUS.SCHEDULE_STRING.PENDING) {
      return t("menu_aside.pending")
    } else if (data?.status_string === ROUTING_STATUS.SCHEDULE_STRING.RESCHEDULE) {
      return t("menu_aside.rescheduled")
    } else if (data?.status_string === ROUTING_STATUS.SCHEDULE_STRING.APPROVE) {
      return t("menu_aside.approved_schedule")
    } else {
      return t("menu_aside.all_schedule")
    }
  }
  const checkStatusRouting = () => {
    if (data?.status_string === ROUTING_STATUS.SCHEDULE_STRING.PENDING) {
      return ROUTING_STATUS.SCHEDULE_STRING.PENDING
    } else if (data?.status_string === ROUTING_STATUS.SCHEDULE_STRING.RESCHEDULE) {
      return ROUTING_STATUS.SCHEDULE_STRING.RESCHEDULE
    } else if (data?.status_string === ROUTING_STATUS.SCHEDULE_STRING.APPROVE) {
      return ROUTING_STATUS.SCHEDULE_STRING.APPROVE
    } else if (data?.status_string === ROUTING_STATUS.SCHEDULE_STRING.REJECT) {
      return ROUTING_STATUS.SCHEDULE_STRING.ALL
    } else {
      return ROUTING_STATUS.SCHEDULE_STRING.ALL
    }
  }
  const breadcrumbData = [
    {
      pathname: RoutesConstants.ORDER.LIST_BY_STATUS("all"),
      title: t("menu.order")
    },
    {
      pathname:
        data?.status_enum === ORDER_STATUS_ENUM.PENDING
          ? RoutesConstants.ORDER.LIST_PENDING_BY_TAB("pre-planting")
          : RoutesConstants.ORDER.LIST_BY_STATUS(checkStatusRouting()),
      title: checkStatus()
    },
    {
      title: data?.area_name
    }
  ]

  useEffect(() => {
    const data = Array.from(selected)
    const value = data.map((item) => {
      return item?.enum_value
    })
    setCheckedSelectReason(value)
  }, [selected])

  const onRejectOrder = () => {
    if (status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED) {
      const data = {
        cancel_enum_list: checkedSelectReason,
        cancel_reason: reasonOtherString ?? null
      }
      dispatch(CancelOrderActions(id, data))
    } else {
      if (!data?.is_additional) {
        const data = {
          rejection_enum_list: checkedSelectReason,
          rejection_reason: reasonOtherString ?? null
        }
        dispatch(RejectOrderPackage(id, data, ORDER.REJECT_ORDER))
      } else {
        const data = {
          regional_id: null,
          team_id: null,
          spraying_date_list: null,
          rejection_enum_list: checkedSelectReason,
          rejection_reason: reasonOtherString ?? null
        }
        dispatch(ApproveRejectOrder(id, data, ORDER.REJECT_ORDER))
      }
    }
  }
  const onChangeCheckBox = (value) => {
    const newSet = new Set(selected)
    if (newSet.has(value)) newSet.delete(value)
    else newSet.add(value)
    setSelected(newSet)
  }
  const OrderRejectCancelComponents = (
    <Form>
      {listCheckBox && (
        <>
          {listCheckBox.map((e, i) => (
            <Form.Check
              key={e?.enum_value}
              id={e?.enum_value}
              className={
                (i === 0 ? "mb-2" : i === listCheckBox.length - 1 ? "mt-2" : "my-2") +
                " cursor-pointer"
              }
              type="checkbox"
              label={e?.enum_string}
              checked={
                checkedSelectReason.find((result) => result === e?.enum_value) === e?.enum_value
              }
              onChange={() => onChangeCheckBox(e)}
            />
          ))}
          <div className={"mt-4"}>
            {checkedSelectReason.find((result) => result === "OTHER") && (
              <textarea
                rows={3}
                className="form-control"
                value={reasonOtherString}
                onChange={(e) => {
                  setReasonOtherString(e.target.value)
                }}
                placeholder="Input alasan"
              />
            )}
          </div>
        </>
      )}
    </Form>
  )

  useEffect(() => {
    if (data) {
      subheader.setBreadcrumbs(breadcrumbData)
      if (
        data?.status_enum === ORDER_STATUS_ENUM.PENDING ||
        data?.status_enum === ORDER_STATUS_ENUM.RESCHEDULED
      ) {
        subheader.setButton(ButtonActionSubheader)
      } else if (data?.status_enum === ORDER_STATUS_ENUM.APPROVED) {
        subheader.setButton(
          data?.reschedule_detail?.reschedule_date ? ButtonCancel : ButtonEditAndCancel
        )
      } else {
        subheader.setButton(null)
      }
    }
  }, [data])

  function checkActivitySection(item) {
    if (item.status_enum === ORDER_STATUS_ENUM.PENDING && item?.is_additional) {
      return true
    } else if (
      item.status_enum === ORDER_STATUS_ENUM.PENDING &&
      item?.is_have_package &&
      !item?.is_additional
    ) {
      return false
    } else if (item.status_enum === ORDER_STATUS_ENUM.REJECTED && !item.is_additional) {
      return false
    } else {
      return true
    }
  }

  return (
    <>
      <PopupQuestion
        show={showPopupRejectCancel}
        title={status === ORDER_STATUS_ENUM.APPROVED ? "ORDER CANCELATION" : "REJECT ORDER"}
        textCancel={"Batal"}
        textOk={status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED ? "Submit" : "Reject"}
        onOk={onRejectOrder}
        variantOkButton={isDisableBtnReject ? "primary" : "danger"}
        body={OrderRejectCancelComponents}
        disable={isDisableBtnReject}
        onCancel={() => setShowPopupRejectCancel(false)}
      />

      <>
        {listRejectCancelCard &&
          (status === ORDER_STATUS_ENUM.CANCELED || status === ORDER_STATUS_ENUM.REJECTED) && (
            <div className={"mb-10"}>
              <RowModule key={"card-rejectReason"}>
                <Card className={"reject-list-card"}>
                  <CardHeader
                    title={
                      status === ORDER_STATUS_ENUM.CANCELED ? "Alasan Pembatalan" : "Alasan Penolakan"
                    }
                    className="mb-0 pb-2"
                  />
                  <CardBody>
                    {!listRejectCancelCard ? null : listRejectCancelCard?.length === 0 ? (
                      <span>Alasan tidak ditemukan.</span>
                    ) : (
                      <ul>
                        {listRejectCancelCard.map((e) => (
                          <li key={e}>{e}</li>
                        ))}
                      </ul>
                    )}
                  </CardBody>
                </Card>
              </RowModule>
            </div>
          )}
        <Card>
          <CardBody>
            <div className={"row justify-content-center py-15 grid"} style={{ gap: 48 }}>
              <SectionDetailOrderHeader data={data} />
              <SectionDetailOrderAreaInformation data={data} />
              {!data?.is_before_planting && <SectionDetailOrderPlantInfo data={data} />}
              {data && checkActivitySection(data) && <SectionDetailOrderActivity data={data} />}
              {data?.reschedule_detail?.reschedule_date && (
                <SectionDetailOrderReschedule data={data} />
              )}
            </div>
          </CardBody>
        </Card>
      </>
    </>
  )
}

export const DetailOrderRowText = ({
  label,
  value,
  showTooltip,
  tooltipMessage,
  tooltipMessageRight = false
}) => {
  const { General } = useSelector((state) => state)
  return (
    <div className={"mt-4 mb-4"}>
      {General.loading ? (
        <RowModule>
          <Skeleton animation="wave" variant={"text"} width={100} />
          <div className={"d-flex w-full"}>
            <Skeleton animation="wave" variant={"text"} width={20} className={"mx-6"} />
            <Skeleton animation="wave" variant={"text"} width={100} />
          </div>
        </RowModule>
      ) : (
        <>
          <RowModule>
            <div className={"d-flex"}>
              <div className={"mr-2"}>{label}</div>
              {showTooltip && (
                <Tooltip title={tooltipMessage} placement="bottom">
                  <div className={"cursor-pointer"}>
                    <SVG src={ICON_CONSTANS.IC_INFORMATION} />
                  </div>
                </Tooltip>
              )}
            </div>

            <div className={"d-flex"}>
              <div className={"mr-2"}>:</div>
              <div style={{ fontWeight: 600 }}>{value}</div>
            </div>
          </RowModule>
          {tooltipMessageRight && (
            <RowModule>
              <div className={"d-flex"}></div>
              <div className={"d-flex"}>
                <div className={"mr-2"}></div>
                <SVG src={ICON_CONSTANS.IC_INFORMATION} width={32} />
                <div className={"ml-1"}>Tanggal awal terjadwal {tooltipMessageRight}</div>
              </div>
            </RowModule>
          )}
        </>
      )}
    </div>
  )
}
