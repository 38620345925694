export const ILUSTRATION_CONSTANS = {
  SUCCESS: "/media/svg/icons/Menu/ic_success.svg",
  SUCCESS_FAILED_HARVEST: "/media/svg/icons/Menu/success_failed_harvest.svg",
  FAIL: "/media/svg/icons/Menu/Illustration-delete-propose.svg",
  EMPTY: "/media/svg/icons/Menu/farmer-lahan.svg",
  PLANTING_SUCCESS: "/media/svg/icons/Menu/planting_date_added.svg",
  AREA_SUCCESS: "/media/svg/icons/Menu/lahan-success.svg",
  AREA_REJECT: "/media/svg/icons/Menu/lahan-reject.svg",
  BROADCAST_SUCCESS_DELETE: "/media/svg/icons/Menu/order_success.svg",
  BROADCAST_SUCCESS_CREATE: "/media/svg/icons/Menu/support_sent.svg",
  ILLUSTRATION_SUCCESS: "/media/svg/icons/Menu/Ilustrasi-success.svg",
  ORDER_SUCCESS: "/media/svg/icons/Menu/order_success.svg",
  FARMER_REJECTED: "/media/svg/icons/Menu/farmer_rejected.svg",
  SUCCESS_PLACEHOLDER: "/media/svg/icons/Menu/success_placeholder_plant.svg",
  SUCCESS_PLACEHOLDER_LOCATION: "/media/svg/icons/Menu/succss_placeholder_location.svg",
  SUCCESS_PLACEHOLDER_PLANT: "/media/svg/icons/Menu/success_placeholder_plant.svg",
  SUCCESS_PLACEHOLDER_ORDER: "/media/svg/icons/Menu/success_placeholder_order.svg",
  IL_SUCCESS: "/media/svg/icons/Menu/Ilustrasi-success.svg",
  CHANGE_ORDER: "/media/svg/icons/Detail/ic_change_order.svg",
  SUCCESS_FORM: "/media/svg/icons/Menu/success_form.svg"
}

export const ILLUSTRATION_ASSETS_V2 = {
  IL_EMPTY_STATE: "/media/illustrations/il_empty_state.desktop.svg",
  IL_EMPTY_STATE_NO_DATA: "/media/illustrations/il_empty_state_no_data.svg",
  IL_EMPTY_STATE_FARMER: "/media/illustrations/il_empty_state_farmer.svg",
  IL_SEARCH_EMPTY_STATE: "/media/illustrations/il_search_empty_state.svg",
  IL_NOT_FOUND_FARMER: "/media/illustrations/il_not_found_farmer.svg",
  IL_POSITIVE_DIPERBARUI: "/media/illustrations/il_positive_diperbarui.svg",
  IL_SUCCESS_DELETE: "/media/illustrations/il_success_delete.svg",
  IL_NOT_FOUND_REGIONAL: "/media/illustrations/il_not_found_regional.svg",
  IL_SUCCESS_ADD: "/media/illustrations/il_success_tambah.svg",
  IL_POSITIVE_ADD: "/media/illustrations/il_positive_add.svg",
  IL_NOT_FOUND_DESKTOP: "/media/illustrations/il_not_found_desktop.svg",
  IL_EMPTY_DESKTOP: "/media/illustrations/il_empty_desktop.svg",
  IL_EMPTY_STATE_DATA: "/media/illustrations/il_empty_state_data.svg",
  IL_NOT_FOUND_DATA: "/media/illustrations/il_not_found_data.svg",
  IL_SUCCESS_CREATE_SUBMISSION: "/media/illustrations/il_success_create_submission.svg",
  IL_DIRECTION: "/media/illustrations/il_direction.png",
  farmer: {
    IL_EMPTY_STATE: "/media/illustrations/farmer/Empty_State_Desktop.svg",
    IL_EMPTY_STATE_NO_DATA: "/media/illustrations/farmer/empty_desktop_tidak_ada_data_petani.svg",
    IL_EMPTY_STATE_NO_DATA_AREA: "/media/illustrations/farmer/empty_tidak_ada_data_lahan.svg",
    IL_FAILED_SEND_ERP: "/media/illustrations/farmer/negative_send_to_ERP.svg",
    IL_SUCCESS_SEND_ERP: "/media/illustrations/farmer/positive_send_to_ERP.svg",
    IL_SUCCESS_CHANGE_MITRA: "/media/illustrations/farmer/Positive_mitra_diganti.svg",
    IL_INVALID_CHANGE_STATUS: "/media/illustrations/farmer/Positive_Petani_tidak_dapat_diganti.svg",
    IL_SUCCESS_FARMER_NONACTIVE:
      "/media/illustrations/farmer/Positive_Petani_berhasil_dinontaktifkan.svg",
    IL_SUCCESS_REJECT: "/media/illustrations/farmer/Positive_petani_berhasil_ditolak.svg",
    IL_SUCCESS_APPROVE: "/media/illustrations/farmer/Positive_petani_berhasil_diverifikasi.svg",
    IL_CANOT_CHANGE: "/media/illustrations/farmer/Positive_Petani_tidak_dapat_diganti.svg",
    IL_SUCCESS_UPDATED: "/media/illustrations/farmer/Positive_petani_diperbarui.svg",
    IL_SUCCESS_CREATED: "/media/illustrations/farmer/Positive_petani_ditambah.svg"
  },

  mitra: {
    IL_EMPTY_STATE: "/media/illustrations/mitra/Empty_State_mitra.svg",
    IL_EMPTY_STATE_NO_DATA: "/media/illustrations/mitra/empty_desktop_tidak_ada_data_mitra.svg",
    IL_FAILED_SEND_ERP: "/media/illustrations/mitra/negative_send_to_ERP.svg",
    IL_SUCCESS_SEND_ERP: "/media/illustrations/mitra/positive_send_to_ERP.svg",
    IL_EMPTY_STATE_LOG: "/media/illustrations/mitra/empty_belum_ada_log.svg",
    IL_EMPTY_STATE_LOG_NO_DATA: "/media/illustrations/mitra/empty_pencarian_riwayat_log.svg",
    IL_SUCCES_MITRA_NONACTIVE:
      "/media/illustrations/mitra/Positive_Mitra_berhasil_dinontaktifkan.svg",
    IL_SUCCESS_REJECT: "/media/illustrations/mitra/Positive_mitra_berhasil_ditolak.svg",
    IL_SUCCESS_APPROVE: "/media/illustrations/mitra/Positive_mitra_berhasil_diverifikasi.svg",
    IL_CANNOT_CHANGE: "/media/illustrations/mitra/Positive_MItra_tidak_dapat_diganti.svg",
    IL_SUCCESS_UPDATED: "/media/illustrations/mitra/Positive_MItra_diperbarui.svg",
    IL_SUCCESS_CREATED: "/media/illustrations/mitra/Positive_mitra_ditambah.svg"
  }
}

export const ICON_CONSTANS = {
  ANGLE_ARROW: "/media/svg/icons/Menu/ic_angle_arrow.svg",
  IC_FARMER_GRAY: "/media/svg/icons/Menu/ic_farmer_gray.svg",
  IC_MAP_GRAY: "/media/svg/icons/Menu/ic_map_gray.svg",
  IC_PADDY_GRAY: "/media/svg/icons/Menu/ic_paddy_gray.svg",
  IC_CORN_GRAY: "/media/svg/icons/Menu/ic_corn.svg",
  IC_PLANTING_GRAY: "/media/svg/icons/Menu/ic_planting_gray.svg",
  IC_ORDER_ALL: "/media/svg/icons/Menu/ic_task_alt.svg",
  IC_ORDER_PASCA_PLANTING: "/media/svg/icons/Menu/ic_pra-tanam.svg",
  IC_TASK: "/media/svg/icons/Menu/ic_task.svg",
  IC_ORDER_APPROVED: "/media/svg/icons/Menu/ic_bullet_tick_order.svg",
  IC_ORDER_PENDING: "/media/svg/icons/Menu/ic_clock.svg",
  IC_ORDER_RESCHEDULE: "/media/svg/icons/Menu/ic_reschedule.svg",
  IC_INFORMATION: "/media/svg/icons/Menu/ic_information.svg",
  IC_EDIT: "/media/svg/icons/Detail/action/ic_edit.svg",
  IC_SUSPEND: "/media/svg/icons/Menu/ic_suspend.svg",
  IC_INFO_REGION: "/media/svg/icons/Detail/ic_information.svg",
  IC_FO: "/media/svg/icons/Menu/ic_field_officer.svg",
  IC_ROUNDED_FIELD: "/media/svg/icons/Menu/ic_rounded_field.svg",
  IC_PLUS: "/media/svg/icons/Menu/ic_plus_on.svg",
  IC_PLUS_PRIMARY: "/media/svg/icons/Menu/ic_plus.svg",
  IC_ROUNDED_MAP_PIN: "/media/svg/icons/Menu/ic_rounded_field.svg",
  IC_ROUNDED_LOCATION: "/media/svg/icons/Menu/ic_rounded_location.svg",
  IC_ROUNDED_AREA: "/media/svg/icons/Menu/ic_rounded_area.svg",
  IC_ROUNDED_PLANT_SPRAYING: "/media/svg/icons/Menu/ic_rounded_plant_spraying.svg",
  IC_BULLET_TICK: "/media/svg/icons/Menu/ic_bullet_tick.svg",
  IC_BULLET_CLOSE: "/media/svg/icons/Menu/ic_bullet_close.svg",
  IC_BULLET_REMOVE: "/media/svg/icons/Detail/ic_bullet_close_gray.svg",
  IC_BULLET_CLOSE_OUTLINE: "/media/svg/icons/Menu/ic_bullet_close_outline.svg",
  IC_NOTE_VIEW_SUCCESS: "/media/svg/icons/NoteView/ic_note_view_success.svg",
  IC_NOTE_VIEW_INFO: "/media/svg/icons/NoteView/ic_note_view_info.svg",
  IC_NOTE_VIEW_COMPLETED: "/media/svg/icons/NoteView/ic_note_view_completed.svg",
  IC_NOTE_VIEW_PRIMARY: "/media/svg/icons/NoteView/ic_note_view_primary.svg",
  IC_NOTE_VIEW_DANGER: "/media/svg/icons/NoteView/ic_note_view_danger.svg",
  IC_NOTE_VIEW_WARNING: "/media/svg/icons/NoteView/ic_note_view_warning.svg",
  IC_INCOME: "/media/svg/icons/Menu/ic_income.svg",
  IC_MENU_SO: "/media/svg/icons/Menu/ic_menu_so.svg",
  IC_UPLOAD_CLOUD: "/media/svg/icons/Menu/ic_upload_cloud.svg",
  IC_PROFILE_MAC: "/media/svg/icons/Menu/ic_profile_mac.svg",
  IC_USER_MAC: "/media/svg/icons/Menu/ic_user_mac.svg",
  IC_DETAIL_PROFILE_MAC: "/media/svg/icons/Detail/ic_profile.svg",
  IC_PLANT_SPRAYING: "/media/svg/icons/Menu/ic_plant_spraying.svg",
  IC_ACCOUNT: "/media/svg/icons/Detail/ic_account.svg",
  IC_PHONE: "/media/svg/icons/Detail/ic_phone.svg",
  IC_MITRA_SMALL: "/media/svg/icons/Detail/ic_mitra_small.svg",
  IC_DRONE_SPRAYING: "/media/svg/icons/Menu/ic_drone_spraying.svg",
  IC_PIN_LOCATION: "/media/svg/icons/Menu/ic_pin_location.svg",
  IC_ADDED: "/media/svg/icons/Menu/ic_added.svg",
  IC_CLOSE: "/media/svg/icons/Menu/close-icon.svg",
  SUB_MENU_ADD: {
    ADD_FARMER: "/media/svg/icons/Menu/ic_farmer.svg",
    ADD_MITRA: "/media/svg/icons/Menu/ic_mitra.svg",
    ADD_FO: "/media/svg/icons/Menu/ic_field_officer.svg",
    ADD_USER_CMS: "/media/svg/icons/Menu/ic_account_cms.svg"
  },
  IC_PIN_LOCATION_2: "/media/svg/icons/Menu/ic_pin_location_2.svg",
  IC_MENU_CALENDAR: "/media/svg/icons/Menu/ic_menu_calendar.svg",
  IC_REGIONAL: "/media/svg/icons/Menu/ic_regional.svg",
  IC_PACKAGE: "/media/svg/icons/Menu/ic_package.svg",
  IC_REMOVE: "/media/svg/icons/Menu/ic_delete.svg",
  IC_SUBMISSION_AND_BLOCK: "/media/svg/icons/Menu/ic_sub_regional.svg",
  IC_BACK: "/media/svg/icons/Detail/ic_arrow_line_left.svg",
  IC_LOCATION: "/media/svg/icons/Detail/ic_location.svg",
  IC_MITRA: "/media/svg/icons/Detail/ic_mitra_gray.svg",
  IC_MITRA_2: "/media/svg/icons/Detail/ic_mitra.svg",
  IC_CALENDAR: "/media/svg/icons/Detail/ic_calendar_gray.svg",
  IC_MARKER_TRANSPARENT: "/media/svg/icons/Detail/marker-transparan.png",
  IC_ARROW_EXPAND: "/media/svg/icons/Detail/ic_arrow_left.svg",
  IC_ARROW_BACK: "/media/svg/icons/Detail/ic_arrow_left_gray.svg",
  IC_AREA_WHITE: "/media/svg/icons/Detail/ic_area_white.svg",
  IC_ARROW_RIGHT_WHITE: "/media/svg/icons/Detail/ic_arrow_right_white.svg",
  IC_SUB_REGIONAL: "/media/svg/icons/Detail/ic_sub-regional.svg",
  IC_CALENDER: "/media/svg/icons/Detail/ic_calendar.svg",
  IC_INFO: "/media/svg/icons/Detail/ic_nothing.svg",
  IC_WARNING: "/media/svg/icons/Detail/ic_warning.svg",
  IC_MAP_WHITE: "/media/svg/icons/Map/ic_map_white.svg",
  IC_LOADING: "/media/gif/loader.gif"
}

export const ACTIVITY_ICONS = {
  IC_ESTIMASI_PANEN: "/media/svg/icons/Detail/activity/ic_estimasi_panen.svg",
  IC_OLAH_TANAH: "/media/svg/icons/Detail/activity/ic_olah_tanah.svg",
  IC_PANEN: "/media/svg/icons/Detail/activity/ic_panen.svg",
  IC_PENANAMAN: "/media/svg/icons/Detail/activity/ic_penanaman.svg",
  IC_PENYEMPROTAN: "/media/svg/icons/Detail/activity/ic_penyemprotan.svg",
  IC_SEMAI: "/media/svg/icons/Detail/activity/ic_semai.svg",
  IC_PEMUPUKAN: "/media/svg/icons/Detail/activity/ic_pemupukan.svg",
  IC_HARVEST_FAILURE: "/media/svg/icons/Detail/activity/ic_harvest_failure.svg",
  IC_LAHAN_PEMBENIHAN: "/media/svg/icons/Detail/activity/ic_lahan_pembenihan.svg"
}

export const LOGOS_CONSTANT = {
  MAI: "/media/logos/logo-mai.png"
}
