import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import React, { forwardRef, useEffect, useState } from "react"
import { CalenderSvgIcon } from "../../../../../component/atoms/Icons/SVG/calender.jsx"
import {
  getCalenderStatus,
  resetCalenderStatus
} from "../../../../../redux/actions/OrderAction.jsx"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { ROUTING_STATUS } from "../../../../../constants/StringConstant.js"
import { Skeleton } from "@material-ui/lab"
import DatePicker from "react-datepicker"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import { ICON_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { Card, CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { checkValue, toMeter } from "../../../../helpers/TextHelper.js"
import { useTranslation } from "react-i18next"
import { ApprovalDetailHeader } from "../../PascaTanam/Form/ApprovalDetailHeader.jsx"
import { ApprovalTeamDronePrePlanting } from "./ApprovalTeamDronePrePlanting.jsx"

export const ApprovalFormPrePlanting = ({
  status,
  setIsValid,
  initialValue,
  id,
  btnSubmit,
  btnCancel,
  data,
  detailTeam
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values: formValues } = useFormikContext()
  const { General, Order } = useSelector((state) => state)
  const [teamSelected, setTeamSelected] = useState([undefined])
  const [selectedDate, setSelectedDate] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(new Date())
  const [selectedDate1Before, setSelectedDate1Before] = useState("")
  const [calendarStatus, setCalendarStatus] = useState([])
  const [spraying, setSpraying] = useState([])
  const [indexPending, setIndexPending] = useState(null)
  const [dataHeader, setDataHeader] = useState([])

  const CustomSelectDatePicker = forwardRef(({ value, onClick }, ref) => (
    <div className={"date-picker-custom-order"}>
      <input
        className="form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder={"Pilih tanggal penyemprotan"}></input>
      <CalenderSvgIcon />
    </div>
  ))

  useEffect(() => {
    if (teamSelected && teamSelected.length) {
      let newStartDate = selectedMonth
      newStartDate.setDate(1)
      let newEndDate = new Date(newStartDate)
      newEndDate.setDate(1)
      newEndDate.setMonth(newEndDate.getMonth() + 1)
      dispatch(
        getCalenderStatus({
          start_date: timeStampToDate(newStartDate, "yyyy-MM-dd"),
          end_date: timeStampToDate(newEndDate, "yyyy-MM-dd"),
          team_id_list: teamSelected
        })
      )
    }
    return () => {
      dispatch(resetCalenderStatus())
    }
  }, [formValues, selectedDate, teamSelected, selectedMonth])

  useEffect(() => {
    if (Order.calendarStatus.length > 0) {
      setCalendarStatus(Order.calendarStatus.map((item) => timeStampToDate(item, "dd-MM-yyyy")))
    }
  }, [Order.calendarStatus])

  useEffect(() => {
    if (status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED) {
      let newSpraying = []
      for (let i = 0; i < 1; i++) {
        newSpraying.push(i)
      }
      setSpraying(newSpraying)
    } else {
      if (data?.spraying_count) {
        let newSraying = []
        for (let i = 0; i < data?.spraying_count; i++) {
          newSraying.push(i)
        }
        setSpraying(newSraying)
      }
    }
  }, [data])

  useEffect(() => {
    if (selectedDate) {
      formValues["spraying_date_list"] = selectedDate
    }
    if (
      formValues["team_id"] &&
      !teamSelected.includes(undefined) &&
      (indexPending === null || formValues["spraying_date_list"][indexPending])
    ) {
      if (status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED) {
        if (
          JSON.stringify(detailTeam) === JSON.stringify(formValues["team_id"]) &&
          timeStampToDate(data?.previous_order_date, "dd-MM-yyyy") ===
            timeStampToDate(formValues["spraying_date_list"][0], "dd-MM-yyyy")
        ) {
          setIsValid(true)
        } else setIsValid(false)
      } else {
        setIsValid(false)
      }
    } else {
      setIsValid(true)
    }
  }, [formValues, selectedDate, status, teamSelected])

  // autofield + 14
  useEffect(() => {
    if (selectedDate[0]) {
      if (selectedDate[0] != selectedDate1Before) {
        const newSelectedDate = [...selectedDate]
        spraying.map((item, index) => {
          let date = new Date(newSelectedDate[0])
          newSelectedDate[index] = new Date(date.setDate(date.getDate() + index * 14))
        })
        setSelectedDate(newSelectedDate)
        setSelectedDate1Before(newSelectedDate[0])
      }
    }
  }, [selectedDate[0]])

  useEffect(() => {
    if (status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED) {
      if (data?.order_list) {
        const dataDate = data?.order_list.map((item) => {
          return new Date(item.activity_date)
        })
        setSelectedDate(dataDate)
      }
    }
  }, [data])

  useEffect(() => {
    if (status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED) {
      if (initialValue.team_id) {
        setTeamSelected(initialValue.team_id)
      }
    }
  }, [initialValue, status])

  const datePicker = (index) => {
    if (indexPending === null) {
      setIndexPending(index)
    }
    return (
      <div className={"mb-8"}>
        {General.loading ? (
          <Skeleton animation="wave" variant={"rect"} height={32} width={250} />
        ) : (
          <>
            {status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED ||
            data?.is_additional_order ? (
              <label>
                Tanggal Penyemprotan <span style={{ color: "red" }}>*</span>
              </label>
            ) : (
              <label>
                Tanggal Penyemprotan ke {index + 1}{" "}
                {index === 0 ? <span style={{ color: "red" }}>*</span> : ""}
              </label>
            )}
          </>
        )}

        {General.loading ? (
          <Skeleton animation="wave" variant={"rect"} height={40} />
        ) : (
          <DatePicker
            selected={selectedDate[index]}
            onChange={(date) => {
              const newArray = [...selectedDate]
              newArray[index] = date
              setSelectedDate(newArray)
            }}
            monthsShown={1}
            dayClassName={(date) =>
              calendarStatus.indexOf(timeStampToDate(date, "dd-MM-yyyy")) > -1
                ? "haveSchedule"
                : undefined
            }
            onMonthChange={(date) => setSelectedMonth(date)}
            customInput={<CustomSelectDatePicker index={index} />}
            dateFormat="dd MMMM yyyy"
            disabled={status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED ? true : false}
            wrapperClassName={
              status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED ? "disabled" : ""
            }
          />
        )}

        {data?.is_additional_order && status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING ? (
          <div className="mt-2 d-flex align-items-center">
            <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_INFO_REGION)} />
            <span className={"ml-2 mb-0 text-black-50"}>
              Tanggal Pengajuan Aktivitas:{" "}
              {timeStampToDate(data?.activity_submission_date, "dd MMMM yyyy")}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    )
  }

  const dataHeaderPrePlanting = [
    {
      title: t("menu_aside.area_location"),
      value: data?.full_address ? data.full_address : "-"
    },
    {
      title: t("label.land_area"),
      value: data?.area_land_manual ? toMeter(checkValue(data?.area_land_manual)) : "-"
    },
    {
      title: "Alasan Pengajuan",
      value: data?.reason ? data?.reason : "-"
    }
  ]

  const detailHeader = [
    {
      title: t("label.area_location"),
      value: checkValue(data?.full_address)
    },
    {
      title: t("label.plant_age"),
      value: `${checkValue(data?.hst)} HST`
    },
    {
      title: t("label.scheduled_reason"),
      value: `${checkValue(data?.reason)}`
    }
  ]

  useEffect(() => {
    if (data?.is_before_planting) {
      setDataHeader(dataHeaderPrePlanting)
    } else {
      setDataHeader(detailHeader)
    }
  }, [data, id])

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <ApprovalDetailHeader
            area_name={data?.area_name}
            activity_name={data?.activity_name}
            title={t("label.overview_land_data")}
            detailHeader={dataHeader}
          />
          <ApprovalTeamDronePrePlanting
            title={t("label.team_drone")}
            status={status}
            teamSelected={teamSelected}
            setTeamSelected={setTeamSelected}
          />
          <h4 className="mb-6 mt-6">
            {General.loading ? (
              <Skeleton animation="wave" variant={"rect"} height={32} width={250} />
            ) : (
              "Penjadwalan Aktivitas Penyemprotan"
            )}
          </h4>
          {data?.order_list &&
            data?.order_list.length &&
            data?.spraying_count &&
            Array.from({ length: data?.spraying_count }).map((item, index) => datePicker(index))}

          <div className={"d-flex justify-content-end align-items-center"}>
            {btnCancel}
            {btnSubmit}
          </div>
        </CardBody>
      </Card>
    </>
  )
}
