import { useDispatch, useSelector } from "react-redux"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { getPageData } from "../../../redux/actions/VideoAction"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../component/atoms/Tabel"
import { Card, CardBody } from "../../../_metronic/_partials/controls"
import { useSubheader } from "../../../_metronic/layout"
import { sortCaret } from "../../../_metronic/_helpers"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { DetailSvgIcon, EditSvgIcon } from "../../../component/atoms/Icons/SVG"
import { EmptyCard, Search, TableSkeleton } from "../../../component"
import { Popup } from "../../../component/atoms/Popup"
import { loadUserLogin } from "../../service/userManagementAction"
import { PRIVILEGE_ENUM } from "../../../config/privilegeCollection"
import { linkWithCtrl } from "../../helpers/Utils"
import { useTranslation } from "react-i18next"

export const ListVideo = ({
  history,
  match: {
    params: { reqName }
  }
}) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { General, Video } = useSelector((state) => state)
  const formRef = useRef()
  const [search, setSearch] = useState({
    string_filter: ""
  })
  const [isFilter, setIsFilter] = useState(false)
  const [showPopupError, setShowPopupError] = useState(false)

  useEffect(() => {
    subHeader.setButton(btnCreate)
    subHeader.setTitle("Manage Video Tutorial")
  }, [])

  useEffect(() => {
    dispatch(getPageData({ pageReq: initialFilterTable }))
  }, [dispatch, reqName])

  const changePage = useCallback(
    (param) => {
      let newParam = {
        ...search,
        ...param
      }
      dispatch(getPageData({ pageReq: newParam }))
    },
    [dispatch, search]
  )

  const seacrhFunc = (values) => {
    let param = {
      ...initialFilterTable,
      string_filter: values.string_filter
    }
    setSearch({
      string_filter: values.string_filter
    })
    setIsFilter(true)
    dispatch(getPageData({ pageReq: param }))
  }

  const handleResetSearch = () => {
    setSearch({
      string_filter: ""
    })
    setIsFilter(false)
    let param = {
      ...initialFilterTable,
      string_filter: ""
    }
    dispatch(getPageData({ pageReq: param }))
  }

  useEffect(() => {
    if (isFilter) {
      if (Video.pageData.length === 0) {
        setShowPopupError(true)
      }
    }
  }, [Video.pageData])

  const uiTitle = (e) => {
    return (
      <>
        <span className="text-dark font-weight-bolder mb-1 font-size-lg elipsis-1">{e.title}</span>
      </>
    )
  }

  const uiAction = (e) => {
    return (
      <>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_VIDEO) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-detail-tooltip">Detail</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-1"
              onClick={() => history.push("/video/list/" + e.id)}>
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.UPDATE_VIDEO) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-1"
              onClick={() => history.push("/video/action/edit/" + e.id)}>
              <EditSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </>
    )
  }

  const columns = [
    {
      dataField: "title",
      text: "JUDUL",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiTitle
      },
      sortCaret: sortCaret,
      sort: true,
      headerStyle: () => {
        return { width: "60%" }
      },
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          sort: field + "," + order
        }
        dispatch(getPageData({ pageReq: param }))
      }
    },
    {
      dataField: "category",
      text: "KATEGORI",
      formatter: formatterRowComponent,
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          sort: field + "," + order
        }
        dispatch(getPageData({ pageReq: param }))
      }
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  const btnCreate = (
    <>
      {privileges.indexOf(PRIVILEGE_ENUM.INSERT_VIDEO) > -1 && (
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            history.push(`/video/action/create`)
          }}>
          Tambah Video
        </button>
      )}
    </>
  )

  return (
    <div>
      <Popup
        type={"empty"}
        body={<span>Data kosong</span>}
        centered={true}
        persistent={true}
        show={showPopupError}
        onClick={() => {
          setShowPopupError(false)
        }}
      />
      <div className="filter-container">
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            // seacrh
            placeholder={"Masukkan Judul Video"}
            onSubmitForm={seacrhFunc}
            initialValues={search}
            name={"string_filter"}
            innerRef={formRef}
            trigerReset={handleResetSearch}
          />
        </div>
      </div>
      <Card>
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={3} row={10} />
          ) : Video.pageData?.length === 0 ? (
            <EmptyCard body={t("messages.response.empty_state")} />
          ) : (
            ""
          )}
          <br />
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={Video.pageData}
            currentPage={Video.page.currentPage}
            currentElement={Video.page.currentElement}
            totalCount={Video.page.totalCount}
            loadingGetData={General.loading}
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (e.target.cellIndex < 2) {
                  const url = "/video/list/" + row.id
                  if (e.ctrlKey) {
                    linkWithCtrl(url)
                  } else {
                    history.push(url)
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}
