import { ActivityDetailHeader } from "../../../../../component/molecule/ActivityDetailHeader/ActivityDetailHeader.jsx"
import { useTranslation } from "react-i18next"
import { useHtmlClassService, useSubheader } from "../../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { RoutesConstants } from "../../../../../constants/RoutesConstants.js"
import { Button, Card, Col, Row } from "react-bootstrap"
import { CloudDownloadOutlined } from "@material-ui/icons"
import { DATE_TYPE_ENUM, ROUTING_STATUS } from "../../../../../constants/StringConstant.js"
import { checkValue, findFirst, toHectare, toMeter } from "../../../../helpers/TextHelper.js"
import {
  clearDEtailDownloadSubmission,
  getDetailCertificationBlock,
  getDetailDownloadSubmission
} from "../../../../../redux/actions/SubmissionAction.jsx"
import { ACTIVITY_ICONS, ICON_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { RowText, Status } from "../../../../../component/index.jsx"
import { CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { Skeleton } from "@material-ui/lab"
import BootstrapTable from "react-bootstrap-table-next"
import { ImageWithModal } from "../../../../../component/atoms/ImageWithModal/index.jsx"
import { PriviewMap } from "../../../../../component/atoms/PriviewMap/index.jsx"
import { ModalMap } from "../../../../../component/atoms/ModalMap/index.jsx"
import { ApplicationSeedCertificationFormDownload } from "../../../../../component/molecule/Print/ApplicationSeedCertificationFormDownload.jsx"
import { toast } from "react-toastify"
import SVG from "react-inlinesvg"
import { PRIVILEGE_ENUM } from "../../../../../config/privilegeCollection.jsx"
import { loadUserLogin } from "../../../../service/userManagementAction.js"

export const DetailCertificationBlock = ({
  match: {
    params: { status, id }
  }
}) => {
  const { t } = useTranslation()
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const uiService = useHtmlClassService()
  const { Submission, General } = useSelector((state) => state)
  const [detail, setDetail] = useState({})
  const [openMap, setOpenMap] = useState(false)
  const firstIndex = 0
  const privileges = loadUserLogin().privilege
  const [createdBy, setCreatedBy] = useState(null)
  const [createdDate, setCreatedDate] = useState(null)
  const [breadcrumbsRouting, setBreadcrumbsRouting] = useState(null)
  const [breadcrumbsTittle, setBreadcrumbsTittle] = useState(null)
  const [statusStyle, setStatusStyle] = useState(null)

  useEffect(() => {
    dispatch(getDetailCertificationBlock(id))
    uiService.setExitFullPage()

    return () => {
      dispatch(clearDEtailDownloadSubmission())
    }
  }, [])

  useEffect(() => {
    if (privileges.indexOf(PRIVILEGE_ENUM.CERTIFICATION_CREATE_SUBMISSION) > -1) {
      subHeader.setButton(
        <Button
          onClick={() => {
            downloadSubmission()
          }}>
          <Row>
            <Col md={"auto"}>
              <CloudDownloadOutlined />
            </Col>
            <Col md={"auto"}>{t("label.submission_download")}</Col>
          </Row>
        </Button>
      )
    }
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS(
          ROUTING_STATUS.CERTIFICATION_STATUS_STRING.PENDING
        ),
        title: t("menu.submission")
      },
      {
        pathname: RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS(
          ROUTING_STATUS.CERTIFICATION_STATUS_STRING.PENDING
        ),
        title: t("menu_aside.sertification_and_block")
      },
      {
        pathname: RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS(breadcrumbsRouting),
        title: breadcrumbsTittle
      },
      {
        title: checkValue(detail?.header?.block_code)
      }
    ])
  }, [detail])

  useEffect(() => {
    if (Submission?.data) setDetail(Submission?.data)
  }, [Submission?.data])

  useEffect(() => {
    if (status === ROUTING_STATUS.CERTIFICATION_STATUS_STRING.SUBMITTED) {
      return (
        setCreatedBy(t("table_header.created_by")),
        setCreatedDate(t("table_header.created_date")),
        setBreadcrumbsRouting(ROUTING_STATUS.CERTIFICATION_STATUS_STRING.SUBMITTED),
        setBreadcrumbsTittle(t("label.submitted")),
        setStatusStyle(ROUTING_STATUS.STATUS_ENUM.SUBMITTED)
      )
    } else if (status === ROUTING_STATUS.CERTIFICATION_STATUS_STRING.REJECTED) {
      return (
        setCreatedBy(t("table_header.reject_by")),
        setCreatedDate(t("table_header.reject_date")),
        setBreadcrumbsRouting(ROUTING_STATUS.CERTIFICATION_STATUS_STRING.REJECTED),
        setBreadcrumbsTittle(t("label.rejected")),
        setStatusStyle(ROUTING_STATUS.STATUS_ENUM.REJECTED)
      )
    } else {
      return (
        setCreatedBy(t("table_header.received_by")),
        setCreatedDate(t("table_header.received_date")),
        setBreadcrumbsRouting(ROUTING_STATUS.CERTIFICATION_STATUS_STRING.APPROVED),
        setBreadcrumbsTittle(t("label.received")),
        setStatusStyle(ROUTING_STATUS.STATUS_ENUM.COMPLETED)
      )
    }
  }, [status])

  const columnsArea = [
    {
      dataField: "no",
      text: "No",
      headerClasses: "bg-gray"
    },
    {
      dataField: "area_code",
      text: "Nama Lahan",
      headerClasses: "bg-gray"
    },
    {
      dataField: "farmer_name",
      text: "Nama Petani",
      headerClasses: "bg-gray"
    },
    {
      dataField: "land_area",
      text: "Luas Lahan",
      headerClasses: "bg-gray"
    }
  ]

  const dataArea = detail?.area_information?.areas?.map((item, index) => {
    return {
      no: index + 1,
      area_code: checkValue(item.area_code),
      farmer_name: checkValue(item.farmer_name),
      land_area: (
        <div>
          {toMeter(checkValue(item.land_area_meter))} ({toHectare(checkValue(item.land_area_ha))})
        </div>
      )
    }
  })

  const noteFo = (
    <div>
      <Card className={"mb-4 p-2 bg-danger-subtle"}>
        <CardBody className={"border-bottom border-secondary border-2 py-4 w-full"}>
          <h4 className={"bold"}>{t("label.note_fo_qa")}</h4>
          <span className={"w-full"}></span>
        </CardBody>
        <CardBody>
          <div>
            {detail?.header?.note
              ? detail?.header?.note
              : t("messages.alert.note_fo", { block: detail?.header?.block_code })}
          </div>
        </CardBody>
      </Card>
    </div>
  )

  const customBottom = (
    <Card className={"mt-6"} bg={"secondary"}>
      <CardBody className={"px-12 py-4"}>
        <RowModule customColumnCss={"col-md-6"} customSeparator={"my-0"}>
          <RowText label={createdBy} value={checkValue(detail?.header?.submitted_by)} />
          <RowText
            label={createdDate}
            value={checkValue(
              timeStampToDate(detail?.header?.submitted_date, DATE_TYPE_ENUM.ISO_DATETIME_STRAP)
            )}
          />
        </RowModule>
      </CardBody>
    </Card>
  )
  const contentBottom = (
    <div className={"w-auto mr-2"}>
      <Row>
        <Col className={"mr-4"} md={"auto"}>{`Nomor : ${
          detail?.header?.request_number ? detail?.header?.request_number : "-"
        }`}</Col>
        {detail?.header?.status_enum !== ROUTING_STATUS.STATUS_ENUM.SUBMITTED ? (
          <Row>
            <Col className={"border-right border-left"} md={"auto"}>{`No. Berkas : ${
              detail?.header?.doc_number ? detail?.header?.doc_number : "-"
            }`}</Col>
            <Col md={"auto"}>{`No. Induk : ${
              detail?.header?.base_number ? detail?.header?.base_number : "-"
            }`}</Col>
          </Row>
        ) : (
          <div></div>
        )}
      </Row>
    </div>
  )
  const tittleActivity = (
    <ActivityDetailHeader
      title={detail?.header?.block_code}
      icon={ACTIVITY_ICONS.IC_LAHAN_PEMBENIHAN}
      rightContent={<Status variant={statusStyle} text={breadcrumbsTittle} />}
      bottomContent={contentBottom}
      cardBottom={customBottom}
    />
  )

  const landArea = (
    <div>
      {toMeter(checkValue(detail?.area_information?.land_area_meter))}&nbsp; (
      {toHectare(checkValue(detail?.area_information?.land_area_ha))})
    </div>
  )

  const areaInformation = (
    <Card className={"mb-4"}>
      <CardBody className={"h-auto"}>
        <h4 className={"mb-8 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.area_information")
          )}
        </h4>
        <RowModule>
          <RowModule>
            <div>
              <RowText
                label={t("label.farmer")}
                value={checkValue(detail?.area_information?.farmer)}
              />
              <RowText
                label={t("label.mitra")}
                value={checkValue(detail?.area_information?.mitra)}
              />
              <RowText
                label={t("label.total_area")}
                value={checkValue(detail?.area_information?.total_area) + " lahan"}
              />
              <RowText label={t("label.block_area")} value={landArea} />
            </div>
          </RowModule>
          <div
            className={
              "row h-full w-full shadow-sm p-0 bg-body-tertiary rounded-lg overflow-hidden"
            }
            onClick={() => setOpenMap(true)}>
            <div className={"row mx-0 w-full h-full position-relative"}>
              {detail?.area_information?.areas?.length > 0 && (
                <PriviewMap
                  centroid={detail?.area_information?.centroid}
                  areas={detail?.area_information?.areas}
                />
              )}
            </div>
            <div className={"row row-cols-1 bottom-0 position-absolute p-6 bg-white w-full"}>
              <div className={"text-dark-50 txt-12 mb-2"}>{t("label.block_location")}</div>
              <div className={"font-weight-bolder txt-14 d-flex align-items-center text-dark"}>
                {checkValue(detail?.area_information?.location)}
              </div>
            </div>
          </div>
        </RowModule>
      </CardBody>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.list_area")
          )}
        </h4>
        <div className={"border rounded table-small-padding"}>
          <BootstrapTable
            condensed
            bordered={false}
            columns={columnsArea}
            keyField={"no"}
            data={dataArea ?? []}
          />
        </div>
      </CardBody>
    </Card>
  )

  const plantInformation = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.plant_data")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-6"} customSeparator={"my-0"}>
          <RowText
            label={t("label.plant_type")}
            value={checkValue(detail?.plant_data?.plant_type)}
          />
          <RowText
            label={t("label.seed_class")}
            value={checkValue(detail?.plant_data?.seed_class)}
          />
          <RowText
            label={t("label.varietas")}
            value={checkValue(detail?.plant_data?.variety_name)}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  const plantingInformation = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.planting_date_data")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-6"} customSeparator={"my-0"}>
          <RowText
            label={t("label.planting_male_date")}
            value={checkValue(
              timeStampToDate(
                detail?.planting_date_data?.male_planting_date,
                DATE_TYPE_ENUM.ISO_DATE_ID
              )
            )}
          />
          <RowText
            label={t("label.planting_female_date")}
            value={checkValue(
              timeStampToDate(
                detail?.planting_date_data?.female_planting_date,
                DATE_TYPE_ENUM.ISO_DATE_ID
              )
            )}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  const lastPlantInformation = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.data_previous_plant")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-6"} customSeparator={"my-0"}>
          <RowText
            label={t("label.land_history")}
            value={checkValue(detail?.last_planting_data?.land_history)}
          />
          <RowText
            label={t("label.harvest_date")}
            value={checkValue(
              timeStampToDate(detail?.last_planting_data?.harvest_date, DATE_TYPE_ENUM.ISO_DATE_ID)
            )}
          />
          <RowText
            label={t("label.former_bero")}
            value={
              checkValue(detail?.last_planting_data?.bero) +
              (detail?.last_planting_data?.bero ? " Bulan" : "")
            }
          />
          <RowText
            label={t("label.disertifikasi")}
            value={detail?.last_planting_data?.certificate ? "Ya" : "Tidak"}
          />
          <RowText
            label={t("label.plant_type")}
            value={checkValue(detail?.last_planting_data?.plant_type)}
          />
          <RowText
            label={t("label.seed_class")}
            value={checkValue(detail?.last_planting_data?.seed_class)}
          />
          <RowText
            label={t("label.varietas")}
            value={checkValue(detail?.last_planting_data?.variety_name)}
          />
          <RowText
            label={t("label.field_inspection")}
            value={checkValue(detail?.last_planting_data?.field_check)}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  const seedSourceInformation = (
    <Card className={"mb-4"}>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.seed_source_data")
          )}
        </h4>
        <RowModule customColumnCss={"col-md-6"} customSeparator={"my-0"}>
          <RowText
            label={t("label.base_seed_source")}
            value={checkValue(detail?.seed_source?.seed_source_name)}
          />
          <RowText
            label={t("label.total_seed")}
            value={checkValue(detail?.seed_source?.seed_total) + " Kg"}
          />
          <RowText
            label={t("label.seed_class")}
            value={checkValue(detail?.seed_source?.seed_class)}
          />
        </RowModule>
        <Card className={"mt-6"} bg={"secondary"}>
          <CardBody className={"px-12 py-4"}>
            <h4 className={"mb-6"}>
              {General.loading ? (
                <Skeleton animation="wave" variant={"text"} height={32} width={200} />
              ) : (
                t("label.female_seed")
              )}
            </h4>
            <RowModule customColumnCss={"col-md-6"} customSeparator={"my-0"}>
              <RowText
                label={t("label.base_seed_name")}
                value={checkValue(detail?.seed_source?.female_seed?.base_name)}
              />
              <RowText
                label={t("label.no_lot")}
                value={checkValue(detail?.seed_source?.female_seed?.lot_number)}
              />
              <RowText
                label={t("label.count_seed")}
                value={checkValue(detail?.seed_source?.female_seed?.seed_total)}
              />
              <RowText
                label={t("label.label_number")}
                value={checkValue(detail?.seed_source?.female_seed?.label_number)}
              />
            </RowModule>
            <div className={"text-dark-50 txt-12 mb-2"}>Foto Label</div>
            <RowModule customColumnCss={"col-md-1 mb-2"}>
              {detail?.seed_source?.female_seed?.images.map((item, index) => {
                return (
                  <ImageWithModal
                    value={item}
                    key={index}
                    className={""}
                    titleModal={"Foto Label"}
                    imageType={"rectangle"}
                  />
                )
              })}
            </RowModule>
          </CardBody>
        </Card>
        <Card className={"mt-6"} bg={"secondary"}>
          <CardBody className={"px-12 py-4"}>
            <h4 className={"mb-6"}>
              {General.loading ? (
                <Skeleton animation="wave" variant={"text"} height={32} width={200} />
              ) : (
                t("label.male_seed")
              )}
            </h4>
            <RowModule customColumnCss={"col-md-6"} customSeparator={"my-0"}>
              <RowText
                label={t("label.base_seed_name")}
                value={checkValue(detail?.seed_source?.male_seed?.base_name)}
              />
              <RowText
                label={t("label.no_lot")}
                value={checkValue(detail?.seed_source?.male_seed?.lot_number)}
              />
              <RowText
                label={t("label.count_seed")}
                value={checkValue(detail?.seed_source?.male_seed?.seed_total)}
              />
              <RowText
                label={t("label.label_number")}
                value={checkValue(detail?.seed_source?.male_seed?.label_number)}
              />
            </RowModule>
            <div className={"text-dark-50 txt-12 mb-2"}>Foto Label</div>
            <RowModule customColumnCss={"col-md-1 mb-2"}>
              {detail?.seed_source?.male_seed?.images.map((item, index) => {
                return (
                  <ImageWithModal
                    value={item}
                    key={index}
                    className={""}
                    titleModal={"Foto Label"}
                    imageType={"rectangle"}
                  />
                )
              })}
            </RowModule>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  )

  const modalMap = (
    <ModalMap
      open={openMap}
      setOpenMap={setOpenMap}
      areas={detail?.area_information?.areas}
      centroid={detail?.area_information?.centroid}
      hideDate={true}
    />
  )

  const locationImages = (
    <Card>
      <CardBody>
        <h4 className={"mb-6 text-uppercase"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={200} />
          ) : (
            t("label.location_image")
          )}
        </h4>

        <RowModule customColumnCss={"col-md-1 mb-2"}>
          <ImageWithModal
            value={detail?.location_images}
            className={""}
            titleModal={t("label.location_image")}
            imageType={"rectangle"}
          />
        </RowModule>
      </CardBody>
    </Card>
  )

  const downloadSubmission = () => {
    const body = {
      block_ids: [id.toString()]
    }
    dispatch(getDetailDownloadSubmission(body))
  }

  useEffect(() => {
    const prevTitle = document.title
    if (Submission?.detailDownloadSubmission?.length > 0) {
      setTimeout(() => {
        document.title = `${findFirst(Submission.detailDownloadSubmission).block_code}`
        window.print()
        setTimeout(() => {
          toast(t("messages.response.success_download_submission"), {
            position: "top-center",
            autoClose: 5000,
            style: {
              background: "#353535"
            },
            icon: <SVG src={ICON_CONSTANS.IC_BULLET_TICK} />,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark"
          })
          dispatch(clearDEtailDownloadSubmission())
        }, 1000)
      }, 5000)
    }
    return () => {
      document.title = prevTitle
    }
  }, [Submission?.detailDownloadSubmission])

  return (
    <>
      {Submission?.detailDownloadSubmission.length > 0 && (
        <ApplicationSeedCertificationFormDownload
          data={Submission?.detailDownloadSubmission[firstIndex]}
        />
      )}
      <div className={"d-print-none"}>
        {detail?.header?.status_enum === ROUTING_STATUS.STATUS_ENUM.REJECTED && noteFo}
        {openMap && detail?.area_information?.areas?.length > 0 && modalMap}
        {tittleActivity}
        {areaInformation}
        {plantInformation}
        {plantingInformation}
        {lastPlantInformation}
        {seedSourceInformation}
        {locationImages}
      </div>
    </>
  )
}