import React, { useCallback, useEffect } from "react"
import { Badge, Card } from "react-bootstrap"
import { CardBody } from "../../../../_metronic/_partials/controls"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../component/atoms/Tabel"
import { useDispatch, useSelector } from "react-redux"
import { getFarmerByMitra } from "../../../../redux/actions/FarmerAction"
import { sortCaret } from "../../../../_metronic/_helpers"
import { loadUserLogin } from "../../../service/userManagementAction"
import { PRIVILEGE_ENUM } from "../../../../config/privilegeCollection"
import { getUrlParsingPageFilter } from "../../../../config/endpointCollection"
import { EmptyCard, TableSkeleton } from "../../../../component/index.jsx"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"
import { useTranslation } from "react-i18next"

export function CardDetailFarmer({ id }) {
  const privileges = loadUserLogin().privilege
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { General, Farmer } = useSelector((state) => state)
  useEffect(() => {
    if (id) {
      const urlParam = getUrlParsingPageFilter({
        ...initialFilterTable
      })
      dispatch(getFarmerByMitra({ id: id, pageReq: urlParam }))
    }
  }, [id])

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : ""
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...param
        })
      }
      dispatch(getFarmerByMitra({ id: id, pageReq: urlParam }))
    },
    [dispatch]
  )

  const uiStatus = (e) => {
    if (e.status === "PENDING") {
      return <Badge variant="warning">{e.status}</Badge>
    } else if (e.status === "REJECTED") {
      return <Badge variant="danger">{e.status}</Badge>
    } else if (e.status === "ACTIVE") {
      return <Badge variant="primary">{e.status}</Badge>
    } else if (e.status === "INACTIVE") {
      return <Badge variant="secondary">{e.status}</Badge>
    } else if (e.status === "VERIFIED") {
      return <Badge variant="success">{e.status}</Badge>
    }
  }
  const uiPetani = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.code ?? "-"}</p>
      </>
    )
  }
  const uiName = (e) => {
    return (
      <>
        <a className="text-dark font-weight-bolder mb-1 font-size-lg">{e.name ?? "-"}</a>
        {privileges.indexOf(PRIVILEGE_ENUM.FARMER_PHONE_NUMBER) > -1 && (
          <span className="text-muted font-weight-bold d-block">{e.phone ?? "-"}</span>
        )}
      </>
    )
  }

  function uiKur(e) {
    return (
      <div className={"kur-bni list"}>
        {e?.has_kur && e?.bank_image ? <img alt={"bni"} src={e?.bank_image} /> : <>-</>}
      </div>
    )
  }

  const columns = [
    {
      dataField: "code",
      text: "ID PETANI",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiPetani
      }
    },
    {
      dataField: "name",
      text: "NAMA",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      }
    },
    {
      dataField: "address",
      text: "ALAMAT",
      formatter: formatterRowComponent
    },

    {
      dataField: "status",
      text: "STATUS",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiStatus
      }
    },
    {
      dataField: "hasKur",
      text: "KUR",
      formatter: formatterRowComponent,
      sortCaret: sortCaret,
      sort: true,
      formatExtraData: {
        externalStyle: uiKur
      },
      headerStyle: () => {
        return { width: "13%" }
      },
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          sort: field + "," + order
        }
        const urlParam = getUrlParsingPageFilter({
          ...param
        })
        dispatch(getFarmerByMitra({ id: id, pageReq: urlParam }))
      }
    }
  ]

  return (
    <>
      {General.loading ? (
        <Card>
          <TableSkeleton column={5} row={10} />
        </Card>
      ) : Farmer?.pageData?.length == 0 ? (
        <EmptyCard
          image={ILLUSTRATION_ASSETS_V2.farmer.IL_EMPTY_STATE}
          body={t("messages.response.empty_state", { name: "petani" })}
        />
      ) : (
        ""
      )}

      <Card>
        <CardBody>
          {Farmer.pageData.length > 0 && (
            <TableView
              callbackAfterPageChange={changePage}
              columnProperties={columns}
              dataTable={Farmer.pageData}
              currentPage={Farmer.page.currentPage}
              currentElement={Farmer.page.currentElement}
              totalCount={Farmer.page.totalCount}
              loadingGetData={General.loading}
            />
          )}
        </CardBody>
      </Card>
    </>
  )
}
