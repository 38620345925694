import React, { useState } from "react"
import { Form, Formik } from "formik"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import SVG from "react-inlinesvg"

export const CheckboxLabelButton = ({
  field,
  name,
  type = "checkbox",
  formId,
  handleChange,
  isStatus,
  innerRef,
  formInitialValues,
  handleSubmit,
  onCLickRadioReset,
  ...props
}) => {
  const [tempValue, setTempValue] = useState("")
  const click = e => {
    if (e.target.value === tempValue) {
      onCLickRadioReset(true)
      setTempValue("")
      e.target.checked = false
    } else {
      setTempValue(e.target.value)
    }
  }

  return (
    <Formik
      enableReinitialize
      innerRef={innerRef}
      initialValues={formInitialValues}
      onSubmit={handleSubmit}>
      {() => (
        <Form id={formId} className="form d-flex flex-wrap">
          {isStatus?.map((item, i) => (
            <label key={i} className="label-container mx-2 my-2 btn-filter">
              {type === "checkbox" ? (
                <input
                  checked={item.checked}
                  type={type}
                  onChange={handleChange}
                  name={item.enum}
                  value={item.enum}
                  {...field}
                  {...props}
                />
              ) : (
                <input
                  onClick={click}
                  type={type}
                  onChange={handleChange}
                  name={name}
                  value={item.enum}
                  {...field}
                  {...props}
                />
              )}

              <div className={"label-filter d-flex align-items-center"}>
                {item.label}
                {item.checked && (
                  <SVG
                    className={"ml-2 icon-checkbox-label"}
                    src={toAbsoluteUrl("/media/svg/icons/Detail/ic_bullet_close_green.svg")}
                  />
                )}
              </div>
              <span className="checkmark"></span>
            </label>
          ))}
        </Form>
      )}
    </Formik>
  )
}
