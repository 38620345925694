import React from "react"
import { CardBody } from "../../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { RowText } from "../../../../../component"
import { Card } from "react-bootstrap"
import { CardDetailHeader } from "../../../../../component/atoms/CardDetail/CardDetailHeader"
import { ICON_CONSTANS } from "../../../../../constants/ImageConstants"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

export const Detail = ({ data, descriptionLeft, informasiProfile }) => {
  const { General } = useSelector((state) => state)
  return (
    <>
      <RowModule>
        <CardDetailHeader
          titleCardDetail={data?.name != null ? data?.name : ""}
          descriptionLeft={descriptionLeft}
          svgImage={ICON_CONSTANS.IC_DETAIL_PROFILE_MAC}
        />
      </RowModule>
      <Card>
        <CardBody>
          <h4 className={"mb-8 text-capitalize font-weight-bolder"}>
            {General.loading ? (
              <Skeleton animation="wave" variant={"text"} height={32} width={200} />
            ) : (
              informasiProfile?.title
            )}
          </h4>
          <RowModule customColumnCss={"col-md-6 mb-2"}>
            {informasiProfile?.data?.map((item, index) => {
              return (
                <RowModule key={index}>
                  <RowText label={item?.label} value={item?.value} />
                </RowModule>
              )
            })}
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}
