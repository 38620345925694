import React from "react"
import { loadUserLogin } from "../../../../service/userManagementAction"
import { formatterRowComponent } from "../../../../../component/atoms/Tabel"
import { PRIVILEGE_ENUM } from "../../../../../config/privilegeCollection"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { DetailSvgIcon, EditSvgIcon } from "../../../../../component/atoms/Icons/SVG"
import { CardBody } from "../../../../../_metronic/_partials/controls"
import { EmptyCard, TableSkeleton, TableView } from "../../../../../component"
import { linkWithCtrl } from "../../../../helpers/Utils"
import { RoutesConstants } from "../../../../../constants/RoutesConstants"
import { useTranslation } from "react-i18next"

export const ListTeamDrone = ({ history, data, changePage, general, isActiveSearch }) => {
  const privileges = loadUserLogin().privilege
  const { t } = useTranslation()

  const uiAction = (e) => {
    return (
      <div>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FARMER_REGISTERED) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <div
              onClick={() => {
                history.push("/field-officer/agronomist/team/detail/" + e?.id)
              }}
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3">
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
        {privileges.indexOf(PRIVILEGE_ENUM.UPDATE_FARMER) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
            <div
              onClick={() => {
                history.push(RoutesConstants.FIELD_OFFICER.EDIT_TEAM_DRONE("edit", e?.id))
              }}
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3">
              <EditSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </div>
    )
  }

  const uiDesc = (e) => {
    return <div>{e?.description.length ? e?.description : "-"}</div>
  }

  const colums = [
    {
      dataField: "team_name",
      text: "NAMA TIM",
      formatter: formatterRowComponent,
      headerStyle: () => {
        return { width: "15%" }
      }
    },
    {
      dataField: "team_member",
      text: "ANGGOTA TIM",
      formatter: formatterRowComponent
    },
    {
      dataField: "description",
      text: "DESKRIPSI",
      formatter: formatterRowComponent,
      headerStyle: () => {
        return { width: "20%" }
      },
      formatExtraData: {
        externalStyle: uiDesc
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      },
      headerStyle: () => {
        return { width: "15%" }
      }
    }
  ]

  return (
    <>
      <Card>
        <CardBody>
          <div className={"d-flex justify-content-end mb-10"}>
            <button
              className={"btn btn-primary"}
              onClick={() => history.push(RoutesConstants.FIELD_OFFICER.CREATE_TEAM("new"))}>
              Tambah Tim
            </button>
          </div>
          {general.loading ? (
            <TableSkeleton column={4} row={10} />
          ) : data?.pageData.length == 0 ? (
            <EmptyCard
              body={
                isActiveSearch
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state")
              }
            />
          ) : (
            ""
          )}
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={colums}
            dataTable={data?.listParam === "TEAM_DRONE" ? data?.pageData : []}
            currentPage={data?.page?.currentPage ?? 1}
            currentElement={data?.page?.currentElement ?? 10}
            totalCount={data?.page?.totalCount ?? 0}
            loadingGetData={general.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.ORDER_DETAIL)) {
                  if (e.target.cellIndex < 3) {
                    const url = "/field-officer/agronomist/team/detail/" + row?.id
                    if (e.ctrlKey) {
                      linkWithCtrl(url)
                    } else {
                      history.push(url)
                    }
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}
