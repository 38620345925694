import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import { ORDER_STATUS_ENUM, ROUTING_STATUS } from "../../../../constants/StringConstant.js"
import { clearFinishUpdateOrDelete } from "../../../../redux/actions/MasterDataAction.jsx"
import * as Yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../../util/constStringCollection.js"
import { timeStampToDate } from "../../../helpers/DateHelper.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/index.js"
import { ILUSTRATION_CONSTANS } from "../../../../constants/ImageConstants.js"
import { PopupQuestion } from "../../../../component/atoms/Popup/index.jsx"
import { TabsInputComponent } from "../../../../component/index.jsx"
import { MacApprovalForm } from "./Form/MacApprovalForm.jsx"
import {
  ApproveSpraying,
  EditSpraying,
  getDetailSprayingMac,
  RescheduleSpraying
} from "../../../../redux/actions/MacAction.jsx"
import { Skeleton } from "@material-ui/lab"

export const MacSprayingApproval = ({
  history,
  match: {
    params: { status, id }
  }
}) => {
  const { t } = useTranslation()
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { General, Order, Mac } = useSelector((state) => state)
  const formRef = useRef()
  const [isValid, setIsValid] = useState(true)
  const [detail, setDetail] = useState("")
  const stepsTitle = [null]
  const stepsDescription = [null]
  const [initialValue, setInitialValue] = useState({
    team_id: [undefined],
    spraying_date: "",
    rejection_enum_list: [],
    rejection_reason: ""
  })
  const [swalMessage, setSwalMessage] = useState("")
  const [showModalDialog, setShowModalDialog] = useState(false)
  const [showModalDialogEdit, setShowModalDialogEdit] = useState(false)
  const [showModalDialogCancel, setShowModalDialogCancel] = useState(false)
  const [showModalSuccess, setShowModalSuccess] = useState(false)
  const [newTeam, setNewTeam] = useState([])
  const [prevTeam, setPrevTeam] = useState([])
  const [detailTeam, setDetailTeam] = useState([])

  const buttonSubmited = (
    <>
      {General.loading ? (
        <Skeleton animation="wave" variant={"text"} height={50} width={100} className={"mx-2"} />
      ) : (
        <button
          type="button"
          disabled={isValid}
          className="btn btn-primary"
          onClick={() => {
            if (detail.status_enum === ORDER_STATUS_ENUM.APPROVED) {
              findNewTeam()
              findPrevTeam()
              setShowModalDialogEdit(true)
            } else {
              setShowModalDialog(true)
            }
          }}>
          Submit
        </button>
      )}
    </>
  )

  const buttonCancel = (
    <>
      {General.loading ? (
        <Skeleton animation="wave" variant={"text"} height={50} width={100} className={"mx-2"} />
      ) : (
        <button
          type="button"
          className="btn btn-secondary-info mr-4"
          onClick={() => setShowModalDialogCancel(true)}>
          Cancel
        </button>
      )}
    </>
  )

  const approvalFlow = [
    <MacApprovalForm
      key={1}
      status={status}
      data={detail}
      isReadOnly={false}
      setIsValid={setIsValid}
      initialValue={initialValue}
      setFormValues={(e) => {
        setInitialValue(e)
      }}
      id={id}
      btnSubmit={buttonSubmited}
      btnCancel={buttonCancel}
      detailTeam={detailTeam}
    />
  ]

  useEffect(() => {
    if (id) {
      dispatch(getDetailSprayingMac(id))
    }
  }, [id, status])

  useEffect(() => {
    if (Mac?.detail) {
      setDetail(Mac?.detail)
    }
  }, [Mac?.detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (swalMessage) {
        setShowModalSuccess(true)
        dispatch(clearFinishUpdateOrDelete())
      }
    }
  }, [General.isFinishUpdateOrDelete])

  const ValidationScheme = [
    Yup.object().shape({
      team_id: Yup.array().min(1).required(getStringErrorMassage("Team ", FORM_NEED.harusDiIsi))
    })
  ]

  useEffect(() => {
    if (detail) {
      subHeader.setButton(false)
      if (id) {
        subHeader.setBreadcrumbs([
          {
            pathname: RoutesConstants.MAC.LIST_PROFILE(),
            title: t("menu.mac")
          },
          {
            pathname: RoutesConstants.MAC.LIST_SPRAYING(ROUTING_STATUS.MAC_SPRAYING_STRING.PENDING),
            title: t("label.spraying")
          },
          {
            pathname: RoutesConstants.MAC.LIST_SPRAYING(ROUTING_STATUS.MAC_SPRAYING_STRING.PENDING),
            title: checkStatus()
          },
          {
            pathname: RoutesConstants.MAC.SPRAYING_DETAIL(status, id),
            title: detail?.schedule_code
          },
          {
            title:
              detail?.status_enum === ORDER_STATUS_ENUM.APPROVED
                ? t("label.edit")
                : t("label.scheduling")
          }
        ])
      }

      if (detail?.status_enum === ORDER_STATUS_ENUM.APPROVED) {
        setInitialValue((prevState) => ({
          ...prevState,
          team_id: detail?.activity_detail?.pic
            ? detail?.activity_detail?.pic.map((item) => item?.id)
            : [],
          spraying_date: [detail?.activity_detail?.schedule_date],
          rejection_enum_list: [],
          rejection_reason: ""
        }))
        setDetailTeam(
          detail?.activity_detail?.pic.length
            ? detail?.activity_detail?.pic.map((item) => item?.id)
            : []
        )
      } else if (detail?.status_enum === ORDER_STATUS_ENUM.RESCHEDULED) {
        setInitialValue((prevState) => ({
          ...prevState,
          team_id: detail?.activity_detail?.pic
            ? detail?.activity_detail?.pic.map((item) => item?.id)
            : [],
          spraying_date: [detail?.reschedule_detail?.reschedule_date],
          rejection_enum_list: [],
          rejection_reason: ""
        }))
      }
    }
  }, [detail, id, status])

  const checkStatus = () => {
    if (detail?.status_enum === ORDER_STATUS_ENUM.PENDING) {
      return t("menu_aside.pending")
    } else if (detail?.status_enum === ORDER_STATUS_ENUM.RESCHEDULED) {
      return t("menu_aside.rescheduled")
    } else {
      return t("menu_aside.approved_schedule")
    }
  }
  const findNewTeam = () => {
    if (Order?.droneTeam) {
      let teammList = Order?.droneTeam
      let teamSelect = formRef?.current?.values?.team_id
      let newTeamList = teamSelect.map(
        (item) => teammList.filter((value) => item === value.id)[0].name
      )
      setNewTeam(newTeamList)
    }
  }

  const findPrevTeam = () => {
    if (Order?.droneTeam) {
      let teammList = Order?.droneTeam
      let teamSelect = detailTeam
      let newTeamList = teamSelect.map(
        (item) => teammList.filter((value) => item === value.id)[0].name
      )
      setPrevTeam(newTeamList)
    }
  }

  const popupBodyEdit = (
    <>
      <RowModule>
        <p className={"swal-text mb-0 p-4"}>{t("messages.confirmation.order_edit")}</p>
      </RowModule>

      <div className="row px-4">
        <div className="col-5 text-center">
          <p>
            <b>Data Lama</b>
          </p>
        </div>
        <div className="col-2 text-center">
          <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.CHANGE_ORDER)} />
        </div>
        <div className="col-5 text-center">
          <p>
            <b>Data Baru</b>
          </p>
        </div>
      </div>
      {JSON.stringify(detailTeam) !== JSON.stringify(formRef?.current?.values?.team_id) ? (
        <div className="row px-4">
          <div className="col-5 text-center">
            <p>{prevTeam.join(", ")}</p>
          </div>
          <div className="col-2 text-center"></div>
          <div className="col-5 text-center">
            <p>{newTeam.join(", ")}</p>
          </div>
        </div>
      ) : (
        ""
      )}
      {detail?.status_enum === ORDER_STATUS_ENUM.APPROVED ? (
        timeStampToDate(detail?.activity_detail?.schedule_date, "dd MMMM yyyy") !==
        timeStampToDate(formRef?.current?.values?.spraying_date, "dd MMMM yyyy") ? (
          <div className="row px-4">
            <div className="col-5 text-center">
              <p>{timeStampToDate(detail?.activity_detail?.schedule_date, "dd MMMM yyyy")}</p>
            </div>
            <div className="col-2 text-center"></div>
            <div className="col-5 text-center">
              <p>{timeStampToDate(formRef?.current?.values?.spraying_date, "dd MMMM yyyy")}</p>
            </div>
          </div>
        ) : (
          ""
        )
      ) : timeStampToDate(detail?.reschedule_detail?.reschedule_date, "dd MMMM yyyy") !==
        timeStampToDate(formRef?.current?.values?.spraying_date, "dd MMMM yyyy") ? (
        <div className="row px-4">
          <div className="col-5 text-center">
            <p>{timeStampToDate(detail?.reschedule_detail?.reschedule_date, "dd MMMM yyyy")}</p>
          </div>
          <div className="col-2 text-center"></div>
          <div className="col-5 text-center">
            <p>{timeStampToDate(formRef?.current?.values?.spraying_date, "dd MMMM yyyy")}</p>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )

  function onSubmitForm(e) {
    setShowModalDialog(false)
    setShowModalDialogEdit(false)
    const { values, submited } = e
    setSwalMessage(t("messages.response.order_success"))

    if (submited) {
      const formData = {
        team_id_list: values.team_id,
        spraying_date: timeStampToDate(values.spraying_date, "yyyy-MM-dd"),
        rejection_enum_list: [],
        rejection_reason: ""
      }
      if (status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED) {
        dispatch(EditSpraying(id, formData))
      } else if (status.toUpperCase() === ORDER_STATUS_ENUM.RESCHEDULED) {
        dispatch(RescheduleSpraying(id, formData))
      } else {
        dispatch(ApproveSpraying(id, formData))
      }
    }
  }

  return (
    <>
      {/*popup approved*/}
      <PopupQuestion
        disable={isValid}
        onCancel={() => setShowModalDialog(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={t("messages.title.approve_schedule")}
        onOk={() => formRef.current.handleSubmit()}
        show={showModalDialog}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>{t("messages.confirmation.order_spraying_approve")}</p>
          </RowModule>
        }
      />
      {/*popup edit order*/}
      <PopupQuestion
        disable={isValid}
        onCancel={() => setShowModalDialogEdit(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={
          detail?.status_enum === ORDER_STATUS_ENUM.APPROVED
            ? t("messages.title.edit_order")
            : t("messages.title.reschedule_order")
        }
        onOk={() => {
          setShowModalDialogEdit(false)
          formRef.current.handleSubmit()
        }}
        show={showModalDialogEdit}
        bodyCustom={popupBodyEdit}
      />
      {/*popup success*/}
      <PopupQuestion
        disable={isValid}
        onCancel={() =>
          history.push(
            RoutesConstants.MAC.SPRAYING_DETAIL(ROUTING_STATUS.MAC_SPRAYING_STRING.APPROVED, id)
          )
        }
        textCancel={t("button.see_detail")}
        textOk={t("button.oke")}
        onOk={() =>
          history.push(RoutesConstants.MAC.LIST_SPRAYING(ROUTING_STATUS.MAC_SPRAYING_STRING.PENDING))
        }
        show={showModalSuccess}
        bodyCustom={
          <div className="d-flex flex-column align-items-center">
            <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS_PLACEHOLDER_ORDER)} />
            <p className={"swal-text text-center"}>{Mac?.message}</p>
          </div>
        }
      />
      {/*popup cancel*/}
      <PopupQuestion
        onCancel={() => setShowModalDialogCancel(false)}
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        title={
          detail?.status_enum === ORDER_STATUS_ENUM.APPROVED
            ? t("messages.title.cancel_reschedule")
            : t("messages.title.cancel_schedule")
        }
        onOk={() => history.push(RoutesConstants.MAC.SPRAYING_DETAIL(status, id))}
        show={showModalDialogCancel}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {detail?.status_enum === ORDER_STATUS_ENUM.APPROVED
                ? t("messages.confirmation.order_edit_spraying_cancel")
                : t("messages.confirmation.order_spraying_cancel")}
            </p>
          </RowModule>
        }
      />
      <TabsInputComponent
        isEditTabs={false}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="approvalOrder"
        innerRef={formRef}
        componentTab={approvalFlow}
        validationSchema={ValidationScheme}
        onSubmitForm={onSubmitForm}
      />
    </>
  )
}
