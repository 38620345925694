import React from "react"
import TableView, { formatterRowComponent } from "../../../../../component/atoms/Tabel/index.jsx"
import { Card, CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { ApproveSvgIcon } from "../../../../../component/atoms/Icons/SVG/index.jsx"
import { sortCaret } from "../../../../../_metronic/_helpers/index.js"
import { getAreaPendingUnassignList } from "../../../../../redux/actions/AreaAction.jsx"
import { useDispatch, useSelector } from "react-redux"
import { loadUserLogin } from "../../../../service/userManagementAction.js"
import { PRIVILEGE_ENUM } from "../../../../../config/privilegeCollection.jsx"
import { linkWithCtrl } from "../../../../helpers/Utils.js"
import { toThousandConvert } from "../../../../helpers/TextHelper.js"
import { EmptyCard, TableSkeleton } from "../../../../../component/index.jsx"
import { useTranslation } from "react-i18next"

export const AreaListPendingUnassign = ({
  data,
  general,
  changePage,
  history,
  initialFilterTable,
  initialValue,
  isFilter,
  isActiveSearch
}) => {
  const dispatch = useDispatch()
  const privileges = loadUserLogin().privilege
  const { t } = useTranslation()
  const { General } = useSelector((state) => state)

  const uiCode = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.area_code ?? "-"}</p>
      </>
    )
  }

  const uiName = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg">{e.farmer_name ?? "-"}</p>
        <span className="text-muted font-weight-bold d-block">{e.farmer_phone ?? "-"}</span>
      </>
    )
  }
  const uiAction = () => {
    return (
      <>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_AREA_PENDING_UNASSIGNED) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Approval</Tooltip>}>
            <div className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
              <ApproveSvgIcon />
            </div>
          </OverlayTrigger>
        )}
      </>
    )
  }
  const uiLuas = (e) => {
    return (
      <>
        <span>{toThousandConvert(e.area_land) ?? "-"}</span>
      </>
    )
  }

  const uiReason = (e) => {
    return (
      <ul className="mb-0">
        <li>{e.reject_reason ? e.reject_reason : "-"}</li>
      </ul>
    )
  }

  const linkDetail = (e) => {
    const url = "/lahan/list/pending/" + e.area_id + "/pending-unassigned"
    if (e.ctrlKey) {
      linkWithCtrl(url)
    } else {
      history.push(url)
    }
  }

  const uiDate = (e) => {
    const submittedTIme = e.submitted_date.split(" ")

    return (
      <>
        <div className="mb-1">{e.submitted_date ? submittedTIme[0] : "-"}</div>
        <div>{e.submitted_date ? submittedTIme[1] : "-"}</div>
      </>
    )
  }

  const headerLuas = (
    <>
      Luas (<span className={"text-lowercase"}>m</span>
      <sup>2</sup>)
    </>
  )

  const columns = [
    {
      dataField: "area_code",
      text: "Nama Lahan",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "body.farmer.name",
      text: "Petani",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      sort: false
    },
    {
      dataField: "landArea",
      text: headerLuas,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiLuas
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          ...initialValue,
          sort: field + "," + order
        }
        dispatch(getAreaPendingUnassignList({ pageReq: param }))
      }
    },
    {
      dataField: "reject_reason",
      text: "Unassignment Reason",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiReason
      },
      sortCaret: sortCaret
    },
    {
      dataField: "modifiedDate",
      text: "Tanggal Submit",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiDate
      },
      sortCaret: sortCaret,
      sort: true,
      onSort: (field, order) => {
        let param = {
          ...initialFilterTable,
          ...initialValue,
          sort: field + "," + order
        }
        dispatch(getAreaPendingUnassignList({ pageReq: param }))
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction,
        onClickEvent: linkDetail
      }
    }
  ]

  return (
    <>
      <Card className="card-shadowless">
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={7} row={10} />
          ) : data?.pageData?.length == 0 ? (
            <EmptyCard
              body={
                isFilter || isActiveSearch
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state", { name: "lahan" })
              }
            />
          ) : (
            ""
          )}
          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={data.pageData}
            currentPage={data.page.currentPage}
            currentElement={data.page.currentElement}
            totalCount={data.page.totalCount}
            loadingGetData={general.loading}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_AREA_PENDING_UNASSIGNED) > -1)
                  linkDetail(row)
              }
            }}
          />
        </CardBody>
      </Card>
    </>
  )
}
