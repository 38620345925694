import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"

export const UploadIcon = () => {
  return (
    <div className="upload-area">
      <SVG src={toAbsoluteUrl("/media/svg/icons/Menu/upload.svg")} />
      <h4>
        Drag files here of click to upload, or <span>browse.</span>
      </h4>
    </div>
  )
}

export const AddImage = () => {
  return (
    <div className="upload-area">
      <SVG src={toAbsoluteUrl("/media/svg/icons/Menu/ic_add_image.svg")} />
      <span className={"text-center"}>Add image here</span>
    </div>
  )
}

export const ErrorSizeUploadIcon = ({ maxSize }) => {
  return (
    <div className="upload-area">
      <SVG src={toAbsoluteUrl("/media/svg/icons/Menu/ic_danger.svg")} />
      <h4>
        File yang diupload lebih dari {maxSize}
        <br />
        <span>Ganti File</span>
      </h4>
    </div>
  )
}

export const MultipleUploadSizeError = () => {
  return (
    <>
      <div className="upload-area">
        <SVG src={toAbsoluteUrl("/media/svg/icons/Menu/ic_danger.svg")} />
        <h4>Melebihi akumulasi maksimal ukuran file</h4>
      </div>
    </>
  )
}

export const ErrorSizeLength = () => {
  return (
    <div className="upload-area">
      <SVG src={toAbsoluteUrl("/media/svg/icons/Menu/ic_danger.svg")} />
      <h4>Jumlah file yang di uopload melebihi maksimal.</h4>
    </div>
  )
}
