import React from "react"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import SVG from "react-inlinesvg"

export const Arrow = ({ direktion }) => {
  return (
    <>
      {direktion === "up" ? (
        <SVG src={toAbsoluteUrl("/media/svg/icons/Detail/ic_arrow_up.svg")} />
      ) : (
        <SVG src={toAbsoluteUrl("/media/svg/icons/Detail/ic_arrow_down.svg")} />
      )}
    </>
  )
}
