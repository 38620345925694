import React from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"

export const EditSvgIcon = () => {
  return (
    <span className="svg-icon svg-icon-md svg-icon-secondary">
      <SVG src={toAbsoluteUrl("/media/svg/icons/Menu/edit.svg")} />
    </span>
  )
}
