import { Card, CardBody, CardHeaderTitle } from "../../../../_metronic/_partials/controls"
import { AddressMolecule, RadioButtonGroup, SelectInput, TextInput } from "../../../../component"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAllMitra } from "../../../../redux/actions/MitraAction"
import { renderLabelRequired } from "../../../helpers/FormikHelper"
import { getListRegionalAll } from "../../../../redux/actions/MasterDataAction.jsx"
import { getUserRegional } from "../../../service/userManagementAction.js"
import { useFormikContext } from "formik"

export const DataFarmer = ({ isReadOnly, onKeyDown, id }) => {
  const { Mitralist, MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [mitraList, setMitraList] = useState([])
  const [mitraId, setMitraId] = useState(null)
  const [gender, setGender] = useState("")

  const [regionalList, setRegionalList] = useState([])
  const [regionalId, setRegionalId] = useState(null)
  const [isHaveRegional, setIsHaveRegional] = useState(false)
  const [isChangeRegional, setIsChangeRegional] = useState(false)

  const { values: formValues } = useFormikContext()

  useEffect(() => {
    dispatch(getAllMitra())
    dispatch(getListRegionalAll())
  }, [dispatch])

  useEffect(() => {
    const userRegional = getUserRegional()
    if (userRegional !== "null") {
      formValues["regionalId"] = userRegional
      setRegionalId(userRegional)
      setIsHaveRegional(true)
    }
  }, [regionalList])

  useEffect(() => {
    if (Mitralist.data != undefined) {
      setMitraList(
        Mitralist.data.map((value) => ({
          label: value.name,
          value: value.id_mitra
        }))
      )
    }
  }, [Mitralist.data])

  useEffect(() => {
    if (MasterData.listRegionalAll != undefined) {
      setRegionalList(
        MasterData.listRegionalAll.map((value) => ({
          label: value.name + " (" + value.code + ")",
          value: value.id
        }))
      )
    }
  }, [MasterData.listRegionalAll])

  useEffect(() => {
    if (formValues.regionalId) {
      setRegionalId(formValues.regionalId)
    }
  }, [formValues, dispatch])

  useEffect(() => {
    if (formValues.mitraId) {
      setMitraId(formValues.mitraId)
    }
  }, [formValues, dispatch, mitraList])

  function onChangeMitra(e) {
    setMitraId(e)
  }

  function onChangeRegional(e) {
    setRegionalId(e)
    setIsChangeRegional(true)
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <RowModule>
            <CardHeaderTitle className={"my-12"}>{"Detail petani".toUpperCase()}</CardHeaderTitle>
          </RowModule>
          <RowModule>
            <SelectInput
              name="regionalId"
              required
              onChange={onChangeRegional}
              editable={isHaveRegional ? false : true}
              label={"Regional"}
              placeholder="Pilih Salah Satu"
              value={regionalId}
              options={regionalList}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name="mitraId"
              required
              onChange={onChangeMitra}
              editable={id ? false : !isReadOnly}
              label={"Nama Mitra"}
              placeholder="Pilih Salah Satu"
              value={mitraId}
              options={mitraList}
            />
          </RowModule>
          <RowModule>
            <TextInput
              onKeyDown={onKeyDown}
              editable={!isReadOnly}
              label={renderLabelRequired("Nama Petani")}
              name="farmerName"
              placeholder="Input Nama Petani"
            />
          </RowModule>
          <RowModule>
            <TextInput
              required
              editable={!isReadOnly}
              label={"Tanggal Lahir"}
              name="dob"
              type="date"
              placeholder={"mm-dd-yyyy"}
            />
          </RowModule>
          <RowModule>
            <TextInput
              editable={!isReadOnly}
              required
              label={"Nomor Handphone"}
              name="phoneNumber"
              placeholder="Input Nomor Handphone"
            />
          </RowModule>
          <label>{renderLabelRequired("Jenis Kelamin")}</label>
          <RowModule withoutSeparator={true}>
            <RadioButtonGroup
              label="Laki-laki"
              name="gender"
              value="LAKI_LAKI"
              editable={true}
              withoutFeedback={true}
              checked={gender === "LAKI_LAKI"}
              chooseAtBack={true}
              onClick={() => setGender("LAKI_LAKI")}
            />
            <RadioButtonGroup
              label="Perempuan"
              name="gender"
              value="PEREMPUAN"
              editable={true}
              withoutFeedback={true}
              checked={gender === "PEREMPUAN"}
              chooseAtBack={true}
              onClick={() => setGender("PEREMPUAN")}
            />
          </RowModule>
        </CardBody>
      </Card>

      <AddressMolecule
        name={"farmer"}
        isReadOnly={isReadOnly}
        addressTitle={"Alamat Domisili Petani".toUpperCase()}
        byRegional={true}
        regionalId={regionalId}
        isChangeRegional={isChangeRegional}
      />
    </>
  )
}
