import React, { Fragment, useState } from "react"
import { timeStampToDate } from "../../../helpers/DateHelper"
import { Popup, PopupImage } from "../../../../component/atoms/Popup"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import SVG from "react-inlinesvg"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"
import { checkCommaInDataPic } from "../../../helpers/TextHelper.js"
import { TableView } from "../../../../component/index.jsx"
import { formatterRowComponent } from "../../../../component/atoms/Tabel/index.jsx"
import { useTranslation } from "react-i18next"

export const SectionDetailOrderReschedule = ({ data }) => {
  const [previewImage, setPreviewImage] = useState(undefined)
  const [openPopupTableList, setOpenPopupTableList] = useState(false)
  const [dataPopUpPic, setDataPopUpPic] = useState([])
  const { General } = useSelector((state) => state)
  const { t } = useTranslation()

  const dataPicRescheduled = () => {
    return (
      <>
        {data?.reschedule_detail?.pic.length ? (
          <div>
            {data?.reschedule_detail?.pic?.map((item, i) => (
              <>
                {i < 2 && (
                  <Fragment>{`${item?.name} (${item?.member.map((mem, i) => {
                    if (i === item?.member?.length - 1) {
                      if (item?.member?.length === 1) return mem
                      else return " " + mem
                    } else return mem
                  })})${
                    checkCommaInDataPic(i, data?.reschedule_detail?.pic?.length) ? "," : ""
                  } `}</Fragment>
                )}
              </>
            ))}
            {data?.reschedule_detail?.pic?.length > 2 && (
              <span
                onClick={() => {
                  setOpenPopupTableList(true)
                  setDataPopUpPic(data?.reschedule_detail?.pic)
                }}
                className={"text-success font-weight-bolder  underline-hover cursor-pointer"}
                style={{ fontSize: 12, textDecoration: "underline" }}>
                +{data?.reschedule_detail?.pic?.length - 2} Lainnya
              </span>
            )}
          </div>
        ) : (
          "-"
        )}
      </>
    )
  }

  const dataList = [
    {
      label: t("label.scheduled_date"),
      value: timeStampToDate(data?.reschedule_detail?.reschedule_date, "dd LLLL yyyy")
    },
    { label: t("label.pic"), value: dataPicRescheduled() },
    { label: t("label.rescheduled_reason"), value: data?.reschedule_detail?.reason ?? "-" }
  ]

  const uiName = (e) => {
    return <div className={"font-weight-bolder"}>{e?.name ?? "-"}</div>
  }

  const uiAnggota = (e) => {
    return (
      <Fragment className={"font-weight-bolder"}>
        {e?.member.map((item, i) => {
          if (i === e?.member.length - 1) return item
          else return item + ", "
        })}
      </Fragment>
    )
  }

  const columnsPic = [
    {
      dataField: "name",
      text: "NAMA TIM",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      headerStyle: () => {
        return { width: "25%" }
      }
    },
    {
      dataField: "member",
      text: "ANGGOTA",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAnggota
      }
    }
  ]

  function bodyPopupTable() {
    return (
      <div className={"table_list_popup_schedule table_hidden_bottom"}>
        <TableView dataTable={dataPopUpPic} loadingGetData={false} columnProperties={columnsPic} />
      </div>
    )
  }

  return (
    <>
      {/*popup team*/}
      <Popup
        contentClassName={"modal-main modal_detail_team_customs"}
        show={openPopupTableList}
        titleCustom={<h1>Anggota Tim</h1>}
        closeButton={true}
        showButton={false}
        onClick={() => setOpenPopupTableList(false)}
        body={bodyPopupTable()}
        size={"lg"}
        onCancel={() => {
          setOpenPopupTableList(false)
        }}
      />
      <PopupImage
        show={!!previewImage}
        images={previewImage}
        type={"image-preview"}
        onCancel={() => setPreviewImage(null)}
      />
      <div className={"col-xl-10"}>
        <h4>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={150} />
          ) : data?.is_before_planting ? (
            "3. DATA JADWAL ULANG"
          ) : (
            "4. DATA JADWAL ULANG"
          )}
        </h4>
        <RowModule customColumnCss={" mb-2 mt-4 col-6"}>
          <div className={"p-0"}>
            {dataList.map((item, i) => (
              <>
                {item?.value && (
                  <div className={"mt-4 mb-4"} key={i}>
                    <RowModule>
                      <div className={"d-flex"}>
                        <div className={"mr-2"}>{item.label}</div>
                      </div>
                      <div className={"d-flex flex-column"}>
                        <div className={"d-flex"}>
                          <div className={"mr-2"}>:</div>
                          <div style={{ fontWeight: 600 }}>{item.value}</div>
                        </div>
                        {item.label === t("label.scheduled_date") && (
                          <div className={"d-flex"}>
                            <div className={"mr-2"}></div>
                            <SVG src={ICON_CONSTANS.IC_INFORMATION} width={32} />
                            <div className={"ml-1"}>
                              Dijadwalkan ulang oleh {data?.reschedule_detail?.reschedule_by} (
                              {data?.reschedule_detail?.reschedule_by_role})
                            </div>
                          </div>
                        )}
                      </div>
                    </RowModule>
                  </div>
                )}
              </>
            ))}
            {data.reschedule_detail.images && (
              <>
                <div>{t("label.supporting_photo")}</div>
                <div className={"d-flex align-items-center mt-4"} style={{ gap: 10 }}>
                  {data.reschedule_detail.images &&
                    data.reschedule_detail.images.map((item, i) => (
                      <div
                        key={i}
                        style={{
                          width: 128,
                          height: 128
                        }}>
                        <img
                          onClick={() => setPreviewImage(item)}
                          alt={item}
                          src={item}
                          key={i}
                          className={"h-100 w-100 rounded"}
                        />
                      </div>
                    ))}
                </div>
              </>
            )}
          </div>
        </RowModule>
      </div>
    </>
  )
}
