import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../_metronic/layout"
import { ApprovalForm } from "./Form/ApprovalForm"
import * as Yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../util/constStringCollection"
import { TabsInputComponent } from "../../../component"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import {
  ApproveRejectOrder,
  EditOrder,
  getDetailHeader,
  getDetailHeaderApproval,
  GetDetailHeaderEditOrder,
  RescheduleOrder
} from "../../../redux/actions/OrderAction"
import { PopupQuestion } from "../../../component/atoms/Popup"
import { Card } from "../../../_metronic/_partials/controls"
import { RowModule } from "../../../component/atoms/RowModule/RowModule.jsx"
import { ILUSTRATION_CONSTANS } from "../../../constants/ImageConstants"
import { timeStampToDate } from "../../helpers/DateHelper"
import SVG from "react-inlinesvg"
import { clearFinishUpdateOrDelete } from "../../../redux/actions/MasterDataAction"
import { RoutesConstants } from "../../../constants/RoutesConstants"
import { ORDER } from "../../../constants/InitTypeConstants"
import { ORDER_STATUS_ENUM } from "../../../constants/StringConstant"
import { useTranslation } from "react-i18next"

export default function ApprovalOrder({
  history,
  match: {
    params: { status, id }
  }
}) {
  const { t } = useTranslation()
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { General, Order } = useSelector(state => state)
  const formRef = useRef()
  const [isValid, setIsValid] = useState(true)
  const [detail, setDetail] = useState("")
  const stepsTitle = [null]
  const stepsDescription = [null]
  const [initialValue, setInitialValue] = useState({
    team_id: [],
    spraying_date_list: [],
    rejection_enum_list: [],
    rejection_reason: ""
  })
  const [swalMessage, setSwalMessage] = useState("")
  const [showModalDialog, setShowModalDialog] = useState(false)
  const [showModalDialogEdit, setShowModalDialogEdit] = useState(false)
  const [showModalDialogCancel, setShowModalDialogCancel] = useState(false)
  const [showModalSuccess, setShowModalSuccess] = useState(false)
  const [newTeam, setNewTeam] = useState([])
  const [prevTeam, setPrevTeam] = useState([])
  const [detailTeam, setDetailTeam] = useState([])

  const buttonSubmited = (
    <button
      type="button"
      disabled={isValid}
      className="btn btn-primary"
      onClick={() => {
        if (
          status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED ||
          status.toUpperCase() === ORDER_STATUS_ENUM.RESCHEDULED
        ) {
          findNewTeam()
          findPrevTeam()
          setShowModalDialogEdit(true)
        } else {
          setShowModalDialog(true)
        }
      }}>
      Submit
    </button>
  )

  const buttonCancel = (
    <button
      type="button"
      className="btn btn-secondary-info mr-4"
      onClick={() => setShowModalDialogCancel(true)}>
      Cancel
    </button>
  )

  const createEditFlow = [
    <ApprovalForm
      key={1}
      status={status}
      data={detail}
      isReadOnly={false}
      setIsValid={setIsValid}
      initialValue={initialValue}
      setFormValues={e => {
        setInitialValue(e)
      }}
      id={id}
      btnSubmit={buttonSubmited}
      btnCancel={buttonCancel}
      detailTeam={detailTeam}
    />
  ]

  useEffect(() => {
    if (id) {
      if (status === ORDER_STATUS_ENUM.RESCHEDULED) {
        dispatch(getDetailHeaderApproval(id))
      } else if (status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED) {
        dispatch(GetDetailHeaderEditOrder(id))
      } else {
        dispatch(getDetailHeader(id))
      }
    }
  }, [id, status])

  useEffect(() => {
    if (Order.detail) {
      setDetail(Order.detail)
    }
  }, [Order.detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (swalMessage) {
        setShowModalSuccess(true)
        dispatch(clearFinishUpdateOrDelete())
      }
    }
  }, [General.isFinishUpdateOrDelete])

  const ValidationScheme = [
    Yup.object().shape({
      team_id: Yup.array()
        .min(1)
        .required(getStringErrorMassage("Team ", FORM_NEED.harusDiIsi))
    })
  ]

  function onSubmitForm(e) {
    setShowModalDialog(false)
    setShowModalDialogEdit(false)
    const { values, submited } = e
    setSwalMessage(t("messages.response.order_success"))

    if (submited) {
      const spraying = []
      values.spraying_date_list.map((item, index) => {
        if (item) {
          let data = {
            seq: index + 1,
            date: timeStampToDate(item, "yyyy-MM-dd")
          }
          spraying.push(data)
        }
      })
      const formData = {
        team_id_list: values.team_id,
        spraying_date_list: spraying,
        rejection_enum_list: [],
        rejection_reason: ""
      }
      if (status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED) {
        dispatch(EditOrder(id, formData))
      } else if (status.toUpperCase() === ORDER_STATUS_ENUM.RESCHEDULED) {
        dispatch(RescheduleOrder(id, formData))
      } else {
        dispatch(ApproveRejectOrder(id, formData, ORDER.APPROVE_ORDER))
      }
    }
  }

  useEffect(() => {
    if (detail) {
      subHeader.setButton(false)
      if (id) {
        subHeader.setBreadcrumbs([
          {
            pathname: RoutesConstants.ORDER.LIST_BY_STATUS("all"),
            title: t("menu.order")
          },
          {
            pathname:
              status === "PENDING"
                ? RoutesConstants.ORDER.LIST_PENDING_BY_TAB("pre-planting")
                : RoutesConstants.ORDER.LIST_BY_STATUS(status),
            title: checkStatus()
          },
          {
            pathname: RoutesConstants.ORDER.DETAIL(status, id),
            title: detail.area_name
          },
          {
            title: status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED ? "Edit" : "Approval"
          }
        ])
      }
      if (
        status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED ||
        status.toUpperCase() === ORDER_STATUS_ENUM.RESCHEDULED
      ) {
        setInitialValue(prevState => ({
          ...prevState,
          team_id: detail?.team_list ? detail?.team_list.map(item => item?.team_id) : [],
          spraying_date_list: [detail.previous_order_date],
          rejection_enum_list: [],
          rejection_reason: ""
        }))
        setDetailTeam(detail?.team_list ? detail?.team_list.map(item => item?.team_id) : [])
      }
    }
  }, [detail, id, status])

  const checkStatus = () => {
    if (status === ORDER_STATUS_ENUM.PENDING) {
      return t("menu_aside.pending")
    } else if (status === ORDER_STATUS_ENUM.RESCHEDULED) {
      return t("menu_aside.rescheduled")
    } else {
      return t("menu_aside.approved_schedule")
    }
  }
  const findNewTeam = () => {
    if (Order?.droneTeam) {
      let teammList = Order?.droneTeam
      let teamSelect = formRef?.current?.values?.team_id
      let newTeamList = teamSelect.map(item => teammList.filter(value => item === value.id)[0].name)
      setNewTeam(newTeamList)
    }
  }

  const findPrevTeam = () => {
    if (Order?.droneTeam) {
      let teammList = Order?.droneTeam
      let teamSelect = detailTeam
      let newTeamList = teamSelect.map(item => teammList.filter(value => item === value.id)[0].name)
      setPrevTeam(newTeamList)
    }
  }

  const popupBodyEdit = (
    <>
      <RowModule>
        <p className={"swal-text mb-0 p-4"}>{t("messages.confirmation.order_edit")}</p>
      </RowModule>

      <div className="row px-4">
        <div className="col-5 text-center">
          <p>
            <b>Data Lama</b>
          </p>
        </div>
        <div className="col-2 text-center">
          <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.CHANGE_ORDER)} />
        </div>
        <div className="col-5 text-center">
          <p>
            <b>Data Baru</b>
          </p>
        </div>
      </div>
      {JSON.stringify(detailTeam) !== JSON.stringify(formRef?.current?.values?.team_id) ? (
        <div className="row px-4">
          <div className="col-5 text-center">
            <p>{prevTeam.join(", ")}</p>
          </div>
          <div className="col-2 text-center"></div>
          <div className="col-5 text-center">
            <p>{newTeam.join(", ")}</p>
          </div>
        </div>
      ) : (
        ""
      )}
      {status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED ? (
        timeStampToDate(detail?.previous_order_date, "dd MMMM yyyy") !==
        timeStampToDate(formRef?.current?.values?.spraying_date_list[0], "dd MMMM yyyy") ? (
          <div className="row px-4">
            <div className="col-5 text-center">
              <p>{timeStampToDate(detail?.previous_order_date, "dd MMMM yyyy")}</p>
            </div>
            <div className="col-2 text-center"></div>
            <div className="col-5 text-center">
              <p>
                {timeStampToDate(formRef?.current?.values?.spraying_date_list[0], "dd MMMM yyyy")}
              </p>
            </div>
          </div>
        ) : (
          ""
        )
      ) : (
        <div className="row px-4">
          <div className="col-5 text-center">
            <p>{timeStampToDate(detail?.previous_order_date, "dd MMMM yyyy")}</p>
          </div>
          <div className="col-2 text-center"></div>
          <div className="col-5 text-center">
            <p>
              {timeStampToDate(formRef?.current?.values?.spraying_date_list[0], "dd MMMM yyyy")}
            </p>
          </div>
        </div>
      )}
    </>
  )

  return (
    <>
      {/*popup approve*/}
      <PopupQuestion
        disable={isValid}
        onCancel={() => setShowModalDialog(false)}
        textCancel={"Batal"}
        textOk={"Lanjut"}
        title={"APPROVE ORDER"}
        onOk={() => formRef.current.handleSubmit()}
        show={showModalDialog}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>{t("messages.confirmation.order_spraying_approve")}</p>
          </RowModule>
        }
      />
      {/*popup edit order*/}
      <PopupQuestion
        disable={isValid}
        onCancel={() => setShowModalDialogEdit(false)}
        textCancel={"Batal"}
        textOk={"Lanjut"}
        title={status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED ? "EDIT ORDER" : "RESCHEDULE ORDER"}
        onOk={() => formRef.current.handleSubmit()}
        show={showModalDialogEdit}
        bodyCustom={popupBodyEdit}
      />
      {/*popup success*/}
      <PopupQuestion
        disable={isValid}
        onCancel={() =>
          history.push(
            RoutesConstants.ORDER.DETAIL(
              ORDER_STATUS_ENUM.APPROVED,
              status.toUpperCase() === ORDER_STATUS_ENUM.PENDING ? Order?.message : id
            )
          )
        }
        textCancel={"Lihat Detail"}
        textOk={"Oke"}
        onOk={() => history.push(RoutesConstants.ORDER.LIST_PENDING_BY_TAB("pre-planting"))}
        show={showModalSuccess}
        bodyCustom={
          <Card>
            <div className="d-flex flex-column align-items-center">
              <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS_PLACEHOLDER)} />
              <p className={"swal-text text-center"}>{t("messages.response.order_success")}</p>
            </div>
          </Card>
        }
      />
      {/*popup cancel*/}
      <PopupQuestion
        onCancel={() => setShowModalDialogCancel(false)}
        textCancel={"Batal"}
        textOk={"Lanjut"}
        title={
          status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED
            ? "CANCEL EDIT ORDER"
            : "CANCEL APPROVE ORDER"
        }
        onOk={() => history.push(RoutesConstants.ORDER.DETAIL(status.toUpperCase(), id))}
        show={showModalDialogCancel}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED
                ? t("messages.confirmation.order_edit_spraying_cancel")
                : t("messages.confirmation.order_spraying_cancel")}
            </p>
          </RowModule>
        }
      />
      <TabsInputComponent
        isEditTabs={false}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="approvalOrder"
        innerRef={formRef}
        componentTab={createEditFlow}
        validationSchema={ValidationScheme}
        onSubmitForm={onSubmitForm}
      />
    </>
  )
}
