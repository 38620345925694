import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import React, { forwardRef, useEffect, useState } from "react"
import { CalenderSvgIcon } from "../../../../../component/atoms/Icons/SVG/calender.jsx"
import { getCalenderStatus } from "../../../../../redux/actions/OrderAction.jsx"
import { timeStampToDate } from "../../../../helpers/DateHelper.js"
import { ORDER_STATUS_ENUM } from "../../../../../constants/StringConstant.js"
import DatePicker from "react-datepicker"
import { Card, CardBody } from "../../../../../_metronic/_partials/controls/index.js"
import { MacApprovalDetailHeader } from "./MacApprovalDetailHeader.jsx"
import { MacApprovalTeamDrone } from "./MacApprovalTeamDrone.jsx"
import { useTranslation } from "react-i18next"
import { Skeleton } from "@material-ui/lab"
import { toMeter } from "../../../../helpers/TextHelper.js"
import { ICON_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers/index.js"
import SVG from "react-inlinesvg"

export const MacApprovalForm = ({
  status,
  setIsValid,
  initialValue,
  id,
  btnSubmit,
  btnCancel,
  data,
  detailTeam
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { values: formValues } = useFormikContext()
  const { General, Order } = useSelector((state) => state)
  const [teamSelected, setTeamSelected] = useState([undefined])
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedMonth, setSelectedMonth] = useState(new Date())
  const [calendarStatus, setCalendarStatus] = useState([])
  const [dataHeader, setDataHeader] = useState([])

  const CustomSelectDatePicker = forwardRef(({ value, onClick }, ref) => (
    <div className={"date-picker-custom-order"}>
      <input
        className="form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder={"Pilih tanggal penyemprotan"}></input>
      <CalenderSvgIcon />
    </div>
  ))

  useEffect(() => {
    if (teamSelected && teamSelected.length) {
      let newStartDate = selectedMonth
      newStartDate.setDate(1)
      let newEndDate = new Date(newStartDate)
      newEndDate.setDate(1)
      newEndDate.setMonth(newEndDate.getMonth() + 1)
      dispatch(
        getCalenderStatus({
          start_date: timeStampToDate(newStartDate, "yyyy-MM-dd"),
          end_date: timeStampToDate(newEndDate, "yyyy-MM-dd"),
          team_id_list: teamSelected
        })
      )
    }
  }, [formValues, selectedDate, teamSelected, selectedMonth])

  useEffect(() => {
    if (Order?.calendarStatus?.length > 0) {
      setCalendarStatus(Order?.calendarStatus.map((item) => timeStampToDate(item, "dd-MM-yyyy")))
    }
  }, [Order?.calendarStatus])

  useEffect(() => {
    if (selectedDate) {
      formValues["spraying_date"] = selectedDate
    }
    if (
      formValues["team_id"][0]?.length &&
      !teamSelected.includes(undefined) &&
      formValues["spraying_date"]
    ) {
      if (data.status_enum === ORDER_STATUS_ENUM.APPROVED) {
        if (JSON.stringify(detailTeam) === JSON.stringify(formValues["team_id"])) {
          setIsValid(true)
        } else {
          if (formValues["team_id"].length === 1 && formValues["team_id"][0] === "") {
            setIsValid(true)
          } else {
            setIsValid(false)
          }
        }
      } else {
        setIsValid(false)
      }
    } else {
      setIsValid(true)
    }
  }, [formValues, selectedDate, status, teamSelected])

  useEffect(() => {
    if (status.toUpperCase() === ORDER_STATUS_ENUM.PENDING) {
      if (data?.request_detail?.request_activity_date)
        setSelectedDate(new Date(data?.request_detail?.request_activity_date))
    } else if (status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED) {
      if (data?.activity_detail?.schedule_date)
        setSelectedDate(new Date(data?.activity_detail?.schedule_date))
    } else if (status.toUpperCase() === ORDER_STATUS_ENUM.RESCHEDULED) {
      if (data?.reschedule_detail?.reschedule_date)
        setSelectedDate(new Date(data?.reschedule_detail?.reschedule_date))
    }
  }, [data, status])

  useEffect(() => {
    if (status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED) {
      if (initialValue.team_id) {
        setTeamSelected(initialValue.team_id)
      }
    }
  }, [initialValue, status])

  const datePicker = () => {
    return (
      <>
        <h4 className="mb-6">
          {General.loading ? (
            <Skeleton animation="wave" variant={"rect"} height={32} width={250} />
          ) : (
            "Penjadwalan Aktivitas Penyemprotan"
          )}
        </h4>
        <div className={"mb-8"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} width={200} className={"mb-4"} />
          ) : (
            <label>
              Tanggal Penyemprotan <span style={{ color: "red" }}>*</span>
            </label>
          )}
          {General.loading ? (
            <Skeleton animation="wave" variant={"rect"} height={40} />
          ) : (
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                setSelectedDate(date)
              }}
              monthsShown={1}
              dayClassName={(date) =>
                calendarStatus.indexOf(timeStampToDate(date, "dd-MM-yyyy")) > -1
                  ? "haveSchedule"
                  : undefined
              }
              onMonthChange={(date) => setSelectedMonth(date)}
              customInput={<CustomSelectDatePicker />}
              dateFormat="dd MMMM yyyy"
              disabled={data?.status_enum === ORDER_STATUS_ENUM.APPROVED ? true : false}
              wrapperClassName={data?.status_enum === ORDER_STATUS_ENUM.APPROVED ? "disabled" : ""}
            />
          )}
          {General.loading || General.loadingComponent ? (
            <Skeleton animation="wave" variant={"text"} width={200} />
          ) : (
            data?.status_enum === ORDER_STATUS_ENUM.PENDING && (
              <div className="mt-2 d-flex align-items-center">
                <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_INFO_REGION)} />
                <span className={"ml-2 mb-0"}>
                  Tanggal Pengajuan Aktivitas:{" "}
                  {timeStampToDate(data?.request_detail?.request_activity_date, "dd MMMM yyyy")}
                </span>
              </div>
            )
          )}
        </div>
      </>
    )
  }

  const detailHeader = [
    {
      title: t("label.plant_type"),
      value:
        data?.request_detail?.plant_type?.length > 0
          ? data?.request_detail?.plant_type.map((item, index) => {
              if (index != data?.request_detail?.plant_type.length - 1) {
                return item + ", "
              } else {
                return item
              }
            })
          : "-"
    },
    {
      title: t("label.land_area_total"),
      value: toMeter(data?.request_detail?.land_area) ?? "-"
    },
    {
      title: t("label.area_location"),
      value: data?.request_detail?.full_address ?? "-"
    },
    {
      title: t("label.product"),
      value:
        data?.request_detail?.product_name?.length > 0
          ? data?.request_detail?.product_name.map((item, index) => {
              if (index != data?.request_detail?.product_name.length - 1) {
                return item + ", "
              } else {
                return item
              }
            })
          : "-"
    },
    {
      title: t("label.note"),
      value: data?.request_detail?.note ?? "-"
    }
  ]

  useEffect(() => {
    setDataHeader(detailHeader)
  }, [data, id])

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <MacApprovalDetailHeader
            activity_name={t("label.spraying")}
            title={t("label.overview_activity")}
            detailHeader={dataHeader}
          />
          <MacApprovalTeamDrone
            title={"Tim Drone"}
            status={status}
            teamSelected={teamSelected}
            setTeamSelected={setTeamSelected}
          />
          {datePicker()}
          <div className={"d-flex justify-content-end align-items-center"}>
            {btnCancel}
            {btnSubmit}
          </div>
        </CardBody>
      </Card>
    </>
  )
}
