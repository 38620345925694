import React, { Fragment, useEffect, useRef, useState } from "react"
import {
  Circle,
  GoogleMap,
  InfoWindow,
  Marker,
  Polygon,
  useLoadScript
} from "@react-google-maps/api"
import { coordinateCenterPolygon, GoogleMapKey } from "../../../helpers/MapHelper"
import { Badge } from "react-bootstrap"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { MapInfo } from "../../../../component"
import { STATUS_AREA } from "../../../../constants/InitTypeConstants"
import { VARIABLE_CONSTANT } from "../../../../constants/VariableConstant"

export const AreaMap = ({
  onLoadMap,
  zoom,
  area,
  handleInfo,
  history,
  handleCenter,
  radius,
  changeZoom,
  strokeOpacity,
  legendMap,
  isFilter
}) => {
  const [zoomed, setZoomed] = useState()
  const containerStyle = {
    width: "100%",
    height: "568px"
  }

  const { isLoaded } = useLoadScript({
    // Enter your own Google Maps API key
    googleMapsApiKey: GoogleMapKey()
  })

  const [center, setCenter] = useState({})
  const [currentLocation, setCurrentLocation] = useState({})
  const mapRef = useRef(null)
  const [showInfo, setShowInfo] = useState(false)

  useEffect(() => {
    changeZoom(zoomed, currentLocation)
  }, [zoomed])

  const onMapLoad = (map) => {
    navigator?.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
      const pos = { lat, lng }
      setCurrentLocation(pos)
      setCenter(pos)
      onLoadMap(pos)
      changeZoom(zoomed, currentLocation)
    })
    mapRef.current = map
    google.maps.event.addListener(map, "bounds_changed", () => {
      setZoomed(map.zoom)
    })
  }

  const [circle, setCircle] = useState({
    lat: VARIABLE_CONSTANT.DEFAULT_LATITUDE,
    lng: VARIABLE_CONSTANT.DEFAULT_LANGITUDE
  })

  useEffect(() => {
    setCircle(currentLocation)
  }, [currentLocation])

  useEffect(() => {
    let allArea = []
    area.map((a) => {
      allArea.push(a.marker)
    })
    if (isFilter) {
      setCenter(coordinateCenterPolygon(allArea))
    } else {
      setCenter(allArea)
    }
  }, [area.length, isFilter])

  const handleDetail = (e, id) => {
    e.stopPropagation()
    if (id.status === STATUS_AREA.ACTIVE || id.status === STATUS_AREA.REGISTERED) {
      history.push("/lahan/list/active/" + id.area_id)
    }
    if (id.status === STATUS_AREA.PENDING || id.status === STATUS_AREA.REVIEW) {
      history.push("/lahan/list/pending/" + id.area_id)
    }
    if (id.status === STATUS_AREA.UNASSIGNED_REVIEW || id.status === STATUS_AREA.ASSIGN_REVIEW) {
      history.push("/lahan/list/pending/" + id.area_id + "/pending-unassigned")
    }
    if (id.status === STATUS_AREA.REJECTED) {
      history.push("/lahan/list/reject/" + id.area_id)
    }
    if (id.status === STATUS_AREA.UNASSIGNED) {
      history.push("/lahan/list-unassign/unassign/" + id.area_id)
    }
    if (id.status === STATUS_AREA.LEAD_AREA) {
      history.push("/lahan/list/lead/" + id.area_lead_id)
    }
  }

  const handleCenterChanged = () => {
    if (!mapRef.current) return
    const newPos = mapRef.current.getCenter().toJSON()
    setCircle(newPos)
    setCurrentLocation(newPos)
    handleCenter(newPos)
  }

  const poligonStyle = (color) => {
    return {
      fillColor: "#" + color,
      fillOpacity: 0.7,
      strokeColor: "white",
      strokeOpacity: 1,
      strokeWeight: 0.4,
      clickable: false,
      draggable: false,
      editable: false,
      geodesic: false,
      zIndex: 1
    }
  }

  const circleOptions = {
    strokeColor: "#80ff45",
    strokeOpacity: strokeOpacity,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 1
  }

  const infoStyle = {
    background: `white`,
    padding: 12,
    borderRadius: 4,
    textAlign: "center"
  }

  const marker = {
    terverifikasi: "/media/svg/icons/Detail/marker-terverifikasi.svg",
    lead: "/media/svg/icons/Detail/marker-lead.svg",
    pending: "/media/svg/icons/Detail/marker-pending.svg",
    reject: "/media/svg/icons/Detail/marker-reject.svg",
    unassign: "/media/svg/icons/Detail/marker-unassign.svg"
  }

  const handleClick = () => {
    setShowInfo(!showInfo)
  }

  const renderMap = () => {
    return (
      <>
        <Fragment>
          <MapInfo
            show={showInfo}
            data={legendMap}
            title={"Informasi Warna Lahan"}
            handleClick={handleClick}
          />
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onLoad={(map) => onMapLoad(map)}
            onDragEnd={handleCenterChanged}
            mapTypeId={"satellite"}>
            <Circle center={circle} options={circleOptions} radius={radius} />
            {area.map((place, index) => {
              return (
                <>
                  <Marker
                    key={place.area_id + "-" + index}
                    icon={
                      place.status === STATUS_AREA.ACTIVE
                        ? toAbsoluteUrl(marker.terverifikasi)
                        : place.status === STATUS_AREA.PENDING
                        ? toAbsoluteUrl(marker.pending)
                        : place.status === STATUS_AREA.UNASSIGNED_REVIEW
                        ? toAbsoluteUrl(marker.pending)
                        : place.status === STATUS_AREA.REJECTED
                        ? toAbsoluteUrl(marker.reject)
                        : place.status === STATUS_AREA.LEAD_AREA
                        ? toAbsoluteUrl(marker.lead)
                        : place.status === STATUS_AREA.UNASSIGNED
                        ? toAbsoluteUrl(marker.unassign)
                        : toAbsoluteUrl(marker.terverifikasi)
                    }
                    onClick={() =>
                      place.status === STATUS_AREA.LEAD_AREA
                        ? handleInfo(place.area_lead_id)
                        : handleInfo(place.area_id)
                    }
                    position={coordinateCenterPolygon(place.coordinates)}>
                    {place.info_marker === true && (
                      <InfoWindow
                        onCloseClick={() =>
                          place.status === STATUS_AREA.LEAD_AREA
                            ? handleInfo(place.area_lead_id)
                            : handleInfo(place.area_id)
                        }
                        position={coordinateCenterPolygon(place.coordinates)}>
                        <div style={infoStyle}>
                          <h6 className="text-center mb-2">
                            {place.sub_district_name ? place.sub_district_name : "-"},{" "}
                            {place.district_name ? place.district_name : "-"}
                          </h6>
                          <p className="text-center mb-6 font-10 space-1">
                            ({place.area_name ? place.area_name : " - "})
                          </p>
                          <p className="text-center color-gray mb-4 font-10">
                            Luas Lahan :{" "}
                            <span className={"color-black"}>
                              {place.area_land ? place.area_land : "-"} m2
                            </span>
                          </p>
                          {place.varietas_name && (
                            <p className="text-center color-gray mb-4 font-10">
                              Jenis Tanaman :{" "}
                              <span className={"color-black"}>
                                {place.varietas_name ? place.varietas_name : "Belum ada tanaman"},{" "}
                                {place.plant_type_name ? place.plant_type_name : "-"}
                              </span>
                            </p>
                          )}
                          {place.submitted_date && (
                            <p className="text-center color-gray mb-4 font-10">
                              Submit Date :{" "}
                              <span className={"color-black"}>{place.submitted_date}</span>
                            </p>
                          )}
                          {place.submitted_by && (
                            <p className="text-center color-gray mb-4 font-10">
                              Submit By :{" "}
                              <span className={"color-black"}>{place.submitted_by}</span>
                            </p>
                          )}
                          <Badge
                            className="cursor-pointer my-auto"
                            onClick={(e) => handleDetail(e, place)}
                            variant="primary">
                            {"Lihat Detail Lahan"}
                          </Badge>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                  <Polygon
                    key={index}
                    paths={place.coordinates}
                    options={poligonStyle(place.color)}
                  />
                </>
              )
            })}
          </GoogleMap>
        </Fragment>
      </>
    )
  }
  return isLoaded ? renderMap() : null
}
