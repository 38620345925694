import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../component/atoms/Tabel/index.jsx"
import { sortCaret } from "../../../../_metronic/_helpers/index.js"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { CardBody } from "../../../../_metronic/_partials/controls/index.js"
import { EmptyCard, Search, TableSkeleton } from "../../../../component/index.jsx"
import { DetailSvgIcon, EditSvgIcon } from "../../../../component/atoms/Icons/SVG/index.jsx"
import { getUrlParsingPageFilter } from "../../../../config/endpointCollection.jsx"
import { Link, useHistory } from "react-router-dom"
import { useLocation } from "react-router"
import { useUrlParam } from "../../../hooks/useUrlParams.jsx"
import {
  getListRegionalMasterData,
  setSelectedRegionalEdit
} from "../../../../redux/actions/MasterDataAction.jsx"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { Popup } from "../../../../component/atoms/Popup/index.jsx"
import { Close } from "@material-ui/icons"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"
import { PRIVILEGE_ENUM } from "../../../../config/privilegeCollection.jsx"
import { loadUserLogin } from "../../../service/userManagementAction.js"

export function MasterDataRegionalPage() {
  const { t } = useTranslation()
  const { MasterData, General } = useSelector((state) => state)

  const privileges = loadUserLogin().privilege
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const subHeader = useSubheader()
  const urlParams = useUrlParam()

  const [dataList, setDataList] = useState([])
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [search, setSearch] = useState({ keyword: "" })
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const [showPopupProvince, setShowPopupProvince] = useState(undefined)
  const [dataDetail, setDataDetail] = useState(undefined)

  useEffect(() => {
    const result = location?.search
    setQueryStringObj(urlParams.getUrlParamsObj)
    dispatch(getListRegionalMasterData(result))
  }, [location.search, urlParams.getUrlParamsObj])

  useEffect(() => {
    if (queryStringObj?.keyword) {
      setIsActiveSearch(true)
      setSearch({ keyword: queryStringObj?.keyword })
    }
  }, [queryStringObj])

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...param,
          sort: param.sortField + "," + param.sortOrder,
          keyword: search.keyword
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...param,
          keyword: search.keyword
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, search]
  )

  const columns = [
    {
      dataField: "code",
      text: t("table_header.regional_id"),
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.code ?? "-"}</div>
        )
      },
      sortCaret: sortCaret
    },
    {
      dataField: "name",
      text: t("label.regional_name"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => (
          <div className="text-dark font-weight-bolder mb-1 font-size-lg">{e.name}</div>
        )
      },
      sortCaret: sortCaret,
      sort: true
    },
    {
      dataField: "province",
      text: t("table_header.province"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiProvince
      },
      sortCaret: sortCaret,
      headerStyle: () => {
        return { width: "35%" }
      },
      sort: false
    },
    {
      dataField: "total_sub_regional",
      text: t("table_header.sub_regional_amount"),
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: (e) => <div>{e?.total_sub_regional || "-"}</div>
      },
      sortCaret: sortCaret,
      sort: false
    },

    {
      dataField: "action",
      text: "Action",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction
      }
    }
  ]

  useEffect(() => {
    setDataList(MasterData?.pageData)
  }, [MasterData])

  useEffect(() => {
    subHeader.setTitle(t("menu_aside.regional"))
    subHeader.setButton(buttonAdd())
  }, [])

  function buttonAdd() {
    return (
      <Link to={RoutesConstants.MASTER_DATA.REGIONAL.ADD_REGIONAL()}>
        <button type="button" className="btn btn-primary">
          {t("label.add_regional")}
        </button>
      </Link>
    )
  }

  function checkProvinceList(e) {
    if (e && e.length > 0) {
      const data = e.map((item) => item.name).slice(0, 5)
      return data.join(", ")
    } else {
      return ""
    }
  }

  const handleResetSearch = () => {
    let param = {
      ...initialFilterTable,
      keyword: ""
    }
    setSearch({ keyword: "" })
    setIsActiveSearch(false)
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }
  const searchFunc = (values) => {
    setSearch({ keyword: values.keyword })
    setIsActiveSearch(true)
    let param = {
      ...initialFilterTable,
      keyword: values.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  function onClickDetailProvince(e) {
    setDataDetail(e)
    setShowPopupProvince(true)
  }
  function uiProvince(e) {
    return (
      <div>
        <div>{checkProvinceList(e.province_list)}</div>
        {e?.province_list && e?.province_list.length > 5 && (
          <div className={"text-info underline-hover"} onClick={() => onClickDetailProvince(e)}>
            +{e?.province_list.length - 5} Lainnya
          </div>
        )}
      </div>
    )
  }

  function onClickEdit(e) {
    dispatch(setSelectedRegionalEdit(e))
    history.push(RoutesConstants.MASTER_DATA.REGIONAL.EDIT_REGIONAL(e.id))
  }

  function uiAction(e) {
    return (
      <div className={"d-flex"}>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_FARMER_REGISTERED) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <div
              className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3"
              onClick={() =>
                history.push(RoutesConstants.MASTER_DATA.REGIONAL.DETAIL_REGIONAL(e?.id))
              }>
              <DetailSvgIcon />
            </div>
          </OverlayTrigger>
        )}
        <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Edit</Tooltip>}>
          <div
            onClick={() => onClickEdit(e)}
            className="btn btn-icon btn-detail btn-hover-secondary btn-sm mx-3">
            <EditSvgIcon />
          </div>
        </OverlayTrigger>
      </div>
    )
  }

  function bodyPopup() {
    return (
      <div>
        <div className={"d-flex justify-content-between align-items-center"}>
          <h3>{dataDetail?.name}</h3>
          <div style={{ cursor: "pointer" }} onClick={() => setShowPopupProvince(false)}>
            <Close fontSize={"large"} />
          </div>
        </div>
        {dataDetail && (
          <div>
            <div
              className={"text-start mt-4"}
              style={{ textAlign: "start", maxHeight: "60vh", overflowY: "auto" }}>
              <div className={"border-bottom d-flex justify-content-start pb-4 pt-4"}>
                <div className={"text-uppercase font-weight-bolder  "} style={{ color: "#808080" }}>
                  {t("label.province")}
                </div>
              </div>
              {dataDetail?.province_list.map((item, i) => {
                return (
                  <div
                    key={i}
                    className={
                      "border-bottom d-flex font-weight-bolder justify-content-start pb-4 pt-4"
                    }>
                    <div>{item.name}</div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    )
  }
  return (
    <div>
      <Popup
        showButton={false}
        onCancel={() => setShowPopupProvince(false)}
        body={bodyPopup()}
        show={showPopupProvince}
      />
      <div className={"filter-container"}>
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            isActiveSearch={isActiveSearch}
            placeholder={t("placeholder.insert_regional_id_or_name")}
            onSubmitForm={searchFunc}
            initialValues={search}
            name={"keyword"}
            trigerReset={handleResetSearch}
          />
        </div>
      </div>
      <Card>
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={4} row={10} />
          ) : MasterData?.pageData.length === 0 ? (
            <EmptyCard
              subtitle={isActiveSearch ? t("messages.response.empty_search_subtitle") : ""}
              image={
                isActiveSearch
                  ? ILLUSTRATION_ASSETS_V2.IL_NOT_FOUND_REGIONAL
                  : ILLUSTRATION_ASSETS_V2.IL_EMPTY_STATE_FARMER
              }
              body={
                isActiveSearch
                  ? t("messages.response.searching_not_found")
                  : t("messages.response.empty_state", { name: t("label.regional") })
              }
            />
          ) : (
            ""
          )}
          <TableView
            dataTable={dataList}
            loadingGetData={General?.loading}
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            currentPage={MasterData?.page?.currentPage ?? 1}
            currentElement={MasterData?.page?.currentElement ?? 10}
            totalCount={MasterData?.page?.totalCount ?? 0}
            ignoreSinglePage
            rowClasses={() => {
              return "cursor-pointer"
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}
