import React, { useEffect, useRef, useState } from "react"
import { Image, Modal, Spinner } from "react-bootstrap"
import { RowModule } from "../RowModule/RowModule.jsx"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import Draggable from "react-draggable"
import { clearUploadAction, uploadAction } from "../../../redux/actions/MasterDataAction"
import { useDispatch, useSelector } from "react-redux"
import Jimp from "jimp"
import { UPLOAD } from "../../../constants/InitTypeConstants"

export const PopupImage = ({
  show = false,
  title,
  titleCustom,
  images,
  type,
  indexCurrentCarousel,
  centered = true,
  responsiveCarousel = null,
  customRightArrow = null,
  customLeftArrow = null,
  customDot = null,
  onCancel = () => {},
  size = "lg",
  onChangeSlide = () => {}
}) => {
  const minScale = 1
  const maxScale = 4
  const zoomStep = 1
  const defaultScale = minScale
  const { General } = useSelector(state => state)
  const [isShown, setIsShown] = useState(false)
  const [imageCarousel, setImageCarousel] = useState([])
  const [loading, setLoading] = useState(true)

  const [scale, setScale] = useState(defaultScale)
  const [rotate, setRotate] = useState(0)
  const [version, setVersion] = useState(0)
  const [changeAble, setChangeAble] = useState(false)
  const isDraggable = scale > 1
  const draggableEntity = useRef()
  const dispatch = useDispatch()
  const [image, setImage] = useState(null)
  const [error, setError] = useState(false)
  const [transformedImage, setTransformedImage] = useState(null)
  const [degrees, setDegrees] = useState(0)

  const loadImage = async () => {
    images += "?" + new Date().getTime()

    Jimp.read(images)
      .then(async imageRead => {
        setImage(imageRead)
        const transformedImageRead = await imageRead.getBase64Async(Jimp.MIME_JPEG)
        setTransformedImage(transformedImageRead)
      })
      .catch(() => {
        setError(true)
        setLoading(false)
      })
  }

  const loadImageCarousel = async () => {
    const arrsTransformedImage = []
    const arrsImage = []

    let newIndex = indexCurrentCarousel

    for (let i = 0; i < images.length; i++) {
      if (images.length === newIndex) {
        newIndex = 0
      }

      let url = images[newIndex]["urlDocument"]
      let imageRead = null
      let transformedImageRead = null

      if (url) {
        url += "?" + new Date().getTime()
        imageRead = await Jimp.read(url)
        imageRead.quality(50)

        transformedImageRead = await imageRead.getBase64Async(Jimp.MIME_JPEG)
      }

      arrsImage.push(imageRead)
      arrsTransformedImage.push(transformedImageRead)
      newIndex++
    }

    setImageCarousel(arrsImage)
    setTransformedImage(arrsTransformedImage)
  }

  useEffect(() => {
    if (type === "image" || type === "image-dashed") {
      setLoading(true)
      loadImage()
    } else if (type === "carousel" && images && images.length > 0) {
      setLoading(true)
      loadImageCarousel()
    }
  }, [images])

  useEffect(() => {
    if (image && degrees !== 0 && type === "image") {
      const transformImage = async () => {
        await image.rotate(degrees)

        const transformedImageRead = await image.getBase64Async(Jimp.MIME_PNG)
        setTransformedImage(transformedImageRead)
      }

      setLoading(true)
      transformImage()
    } else if (imageCarousel && degrees !== 0 && type === "carousel") {
      const transformImageCarousel = async () => {
        let indexNow = 0

        await imageCarousel[indexNow].rotate(degrees)
        const transformedImageRead = await imageCarousel[indexNow].getBase64Async(Jimp.MIME_JPEG)

        setTransformedImage(existingItems => {
          return existingItems.map((item, j) => {
            return j === indexNow ? transformedImageRead : item
          })
        })
      }

      setLoading(true)
      transformImageCarousel()
    }
  }, [rotate, image, imageCarousel])

  useEffect(() => {
    if (transformedImage) {
      setLoading(false)
    }
  }, [transformedImage])

  useEffect(() => {
    if (indexCurrentCarousel) {
      setDegrees(0)
      setRotate(0)
    }
  }, [indexCurrentCarousel])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete && General.actionInitType === "UPLOAD") {
      return onCancel()
    }
  }, [General.isFinishUpdateOrDelete])

  useEffect(() => {
    dispatch(clearUploadAction())
  }, [])

  useEffect(() => {
    if (rotate === 0 || loading) {
      setChangeAble(false)
    } else {
      setChangeAble(true)
    }
  }, [rotate, loading])

  const box = {
    background: "#FEFEFE",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05), 0px 1px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "6px",
    padding: "6px",
    cursor: "pointer",
    width: "32px",
    marginRight: "15px"
  }

  const zoomIn = () => {
    setScale(() => {
      const newScale = scale + zoomStep
      return newScale <= maxScale ? newScale : maxScale
    })
  }

  const zoomOut = () => {
    setScale(() => {
      const newScale = scale - zoomStep
      return newScale >= minScale ? newScale : minScale
    })

    draggableEntity.current.state.y = 0
    draggableEntity.current.state.x = 0
  }

  const rotateLeft = () => {
    setRotate(rotate === -270 ? 0 : rotate - 90)
    setDegrees(degrees === 270 ? 0 : 90)
  }

  const rotateRight = () => {
    setRotate(rotate === 270 ? 0 : rotate + 90)
    setDegrees(degrees === 270 ? 0 : -90)
  }

  const reset = async () => {
    setLoading(true)
    if (type === "image") {
      loadImage()
    } else {
      loadImageCarousel()
    }
    setChangeAble(false)
    setScale(defaultScale)
    setDegrees(0)
    setRotate(0)
    setVersion(version + 1)
  }

  function saveImage() {
    if (transformedImage) {
      let file
      if (type === "image") {
        file = DataURIToBlob(transformedImage)
      } else {
        file = DataURIToBlob(transformedImage[0])
      }
      dispatch(uploadAction({ data: file, type: UPLOAD.AVATAR, isEdit: true }))
    }
  }

  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",")
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])

    const mimeString = splitDataURI[0].split(":")[1].split(";")[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ia], { type: mimeString })
  }

  const navButton = (
    <div className="d-flex flex-row " style={{ width: "100%" }}>
      <div className="d-flex flex-row w-100 align-items-center">
        <Image
          key={"icon-zoomIn"}
          src={toAbsoluteUrl("/media/svg/icons/Detail/zoom_in.svg")}
          style={box}
          className={scale === maxScale ? "disable" : ""}
          onClick={zoomIn}
        />
        <Image
          key={"icon-zoomOut"}
          src={toAbsoluteUrl("/media/svg/icons/Detail/zoom_out.svg")}
          style={box}
          className={scale === minScale ? "disable" : ""}
          onClick={zoomOut}
        />
        <Image
          key={"icon-rotateLeft"}
          src={toAbsoluteUrl("/media/svg/icons/Detail/ic_rotate_left.svg")}
          style={box}
          onClick={rotateLeft}
        />
        <Image
          key={"icon-rotateRight"}
          src={toAbsoluteUrl("/media/svg/icons/Detail/ic_rotate_right.svg")}
          style={box}
          onClick={rotateRight}
        />
        <Image
          key={"icon-reset"}
          src={toAbsoluteUrl("/media/svg/icons/Detail/ic_reset.svg")}
          style={box}
          className={scale > minScale ? "" : rotate != 0 ? "" : "disable"}
          onClick={reset}
        />
      </div>
      <button
        className={changeAble ? "btn btn-primary" : "btn button-disabled"}
        onClick={saveImage}
        disabled={changeAble ? false : true}>
        Save
      </button>
    </div>
  )

  return (
    <Modal
      show={show}
      size={size}
      centered={centered}
      onHide={onCancel}
      contentClassName="modal-image">
      <Modal.Header closeButton={true} onHide={onCancel}>
        <Modal.Title>{title ? <h6 className="mb-0">{title}</h6> : titleCustom}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {type === "image" || type === "image-dashed" ? (
          <>
            <RowModule key={`${type}-1`} withoutSeparator={true}>
              <div className={"img-box text-center"}>
                {loading ? (
                  <Spinner animation="border" />
                ) : error ? (
                  <p className="text-center">
                    Terjadi Masalah Internet Anda. Silahkan muat ulang website atau buka kembali
                    popup gambar ini
                  </p>
                ) : (
                  <Draggable
                    disabled={!isDraggable}
                    key={version}
                    rounded="true"
                    ref={draggableEntity}>
                    <div style={isDraggable ? { cursor: "move" } : null}>
                      <img
                        alt={"image blank"}
                        style={{
                          transform: `scale(${scale})`
                        }}
                        draggable="false"
                        src={transformedImage ?? toAbsoluteUrl("/media/users/blank.png")}
                        className={"images-card w-100"}
                      />
                    </div>
                  </Draggable>
                )}
              </div>
            </RowModule>
            <RowModule key={`${type}-2`} withoutSeparator={true}>
              {!loading && !error && transformedImage && navButton}
            </RowModule>
          </>
        ) : type === "carousel" ? (
          <>
            <RowModule key={`${type}-imgBox`} withoutSeparator={true}>
              <div className="img-box text-center" onMouseLeave={() => setIsShown(false)}>
                {loading ? (
                  <Spinner animation="border" />
                ) : error ? (
                  <p className="text-center">
                    Terjadi Masalah Internet Anda. Silahkan muat ulang website atau buka kembali
                    popup gambar ini
                  </p>
                ) : transformedImage && transformedImage.length > 0 ? (
                  <Carousel
                    afterChange={(previousSlide, { currentSlide }) => {
                      onChangeSlide(previousSlide, currentSlide)
                    }}
                    swipeable={true}
                    draggable={false}
                    arrows={
                      transformedImage[0]
                        ? rotate === 0 && transformedImage.length > 1 && isShown
                        : true
                    }
                    showDots={
                      transformedImage[0] ? rotate === 0 && transformedImage.length > 1 : true
                    }
                    responsive={responsiveCarousel}
                    infinite={true}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    partialVisible={true}
                    renderArrowsWhenDisabled={true}
                    focusOnSelect={true}
                    customRightArrow={customRightArrow ?? null}
                    customLeftArrow={customLeftArrow ?? null}
                    customDot={customDot ?? null}>
                    {transformedImage.map((item, idx) => {
                      return (
                        <div key={idx} className={"img-box"}>
                          <Draggable disabled={!isDraggable} key={version}>
                            <div style={isDraggable ? { cursor: "move" } : null}>
                              <img
                                src={item ? item : toAbsoluteUrl("/media/users/blank.png")}
                                width="100%"
                                onMouseEnter={() => setIsShown(true)}
                                // style={{
                                //   transform: `scale(${scale}) rotate(${degrees}deg)`
                                // }}
                                style={{
                                  transform: `scale(${scale})`
                                }}
                                draggable="false"
                              />
                            </div>
                          </Draggable>
                        </div>
                      )
                    })}
                  </Carousel>
                ) : (
                  <Image src={toAbsoluteUrl("/media/users/blank.png")} width="100%" />
                )}
              </div>
            </RowModule>
            <RowModule key={`${type}-navButton`} withoutSeparator={true}>
              {!loading &&
                !error &&
                transformedImage &&
                transformedImage.length > 0 &&
                transformedImage[0] &&
                navButton}
            </RowModule>
          </>
        ) : type === "image-preview" ? (
          <img
            alt={"image"}
            src={images}
            width="100%"
            style={{
              transform: `scale(${scale})`
            }}
            draggable="false"
          />
        ) : null}
      </Modal.Body>
    </Modal>
  )
}
