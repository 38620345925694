import { useDispatch, useSelector } from "react-redux"
import { FlatIconAndText, Submited, TabLog, TabsComponent } from "../../../component"
import React, { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { getDetailLead, removeLead } from "../../../redux/actions/FarmerAction"
import { useSubheader } from "../../../_metronic/layout"
import { PopupQuestion } from "../../../component/atoms/Popup"
import { DetailPetaniLead } from "./DetailComponent/DetailPetaniLead"
import swal from "sweetalert"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { ENUM_LOG } from "../../../constants/InitTypeConstants"
import { loadUserLogin } from "../../service/userManagementAction"
import { PRIVILEGE_ENUM } from "../../../config/privilegeCollection"

export const FarmerDetailLead = ({
  history,
  match: {
    params: { id }
  }
}) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const [farmerData, setFarmerData] = useState()

  const dispatch = useDispatch()
  const { Farmer, General } = useSelector(state => state)
  const [showPopupRemove, setShowPopupRemove] = useState(false)

  useEffect(() => {
    dispatch(getDetailLead(id))
  }, [dispatch, id])

  useEffect(() => {
    subHeader.setTitle("Calon Petani Detail")

    subHeader.setBreadcrumbs([
      {
        pathname: "/farmer/list/lead",
        title: "Petani"
      },
      {
        pathname: "/farmer/list/lead",
        title: "Calon Petani"
      },
      {
        title: farmerData?.name
      }
    ])

    subHeader.setButton(
      <Button
        variant="danger"
        onClick={() => {
          setShowPopupRemove(true)
        }}>
        Delete
      </Button>
    )
  }, [farmerData])

  useEffect(() => {
    if (Farmer.data !== undefined) {
      setFarmerData(Farmer.data)
    }
  }, [Farmer.data])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      swal({
        text: "Data calon petani berhasil dihapus",
        icon: toAbsoluteUrl("/media/svg/icons/Menu/Illustration-delete.svg")
      })
      history.push("/farmer/list/lead")
    }
  }, [General.isFinishUpdateOrDelete])

  const descriptionLeft = (
    <>
      <div className="d-flex flex-wrap mb-4">
        <FlatIconAndText
          text={farmerData?.code ? farmerData?.code : "-"}
          svg={"/media/svg/icons/Detail/ic_farmer.svg"}
        />
        {privileges.indexOf(PRIVILEGE_ENUM.FARMER_KTP_NUMBER) > -1 && (
          <FlatIconAndText
            text={farmerData?.ktp_no ? farmerData?.ktp_no : "-"}
            svg={"/media/svg/icons/Detail/ic_account.svg"}
          />
        )}
        <FlatIconAndText
          text={farmerData?.gender === "PEREMPUAN" ? "Perempuan" : "Laki-Laki"}
          svg={"/media/svg/icons/Detail/ic_gender.svg"}
        />
        <FlatIconAndText
          text={farmerData?.dob ? farmerData?.dob : "-"}
          svg={"/media/svg/icons/Detail/ic_calendar.svg"}
        />
        {privileges.indexOf(PRIVILEGE_ENUM.FARMER_PHONE_NUMBER) > -1 && (
          <FlatIconAndText
            text={farmerData?.phone_number ? farmerData?.phone_number : "-"}
            svg={"/media/svg/icons/Detail/ic_phone.svg"}
          />
        )}
      </div>
      <span className="font-weight-bold text-dark-50">
        {farmerData?.address ? farmerData.address : "-"},{" "}
        {farmerData?.subdistrict_name ? farmerData.subdistrict_name : "-"},{" "}
        {farmerData?.district_name ? farmerData.district_name : "-"}
      </span>
      <span className="font-weight-bold text-dark-50">
        {farmerData?.regency_name ? farmerData.regency_name : "-"},{" "}
        {farmerData?.province_name ? farmerData.province_name : "-"}{" "}
        {farmerData?.postal_code ? farmerData.postal_code : "-"}
      </span>
    </>
  )

  const flatIconAndText2Rows = (
    <>
      <Submited
        name={farmerData?.created_by ? farmerData.created_by : "-"}
        time={farmerData?.created_date ? farmerData.created_date : "-"}
      />
    </>
  )

  const farmerRemove = async id => {
    dispatch(removeLead(id))
  }

  const renderContents = () => {
    const labels = ["DETAIL PETANI", "LOG"]
    const components = [
      <DetailPetaniLead
        key={0}
        farmerData={farmerData}
        descriptionLeft={descriptionLeft}
        flatIconAndText2Rows={flatIconAndText2Rows}
      />,
      <TabLog key={1} historyTabEnum={ENUM_LOG.FARMER} id={id} />
    ]

    return {
      labels,
      components
    }
  }

  return (
    <>
      {/* Popup for remove */}
      <PopupQuestion
        show={showPopupRemove}
        title="HAPUS CALON PETANI"
        bodyCustom={
          <span className={"text-center"}>
            Data Calon Petani yang dihapus akan{" "}
            <span className="font-weight-bold">hilang dari database Maxxi Tani</span>.
          </span>
        }
        textOk="Delete"
        onCancel={() => {
          setShowPopupRemove(false)
        }}
        onOk={() => {
          farmerRemove(id)
          setShowPopupRemove(false)
        }}
      />

      <TabsComponent
        labelTab={renderContents().labels}
        componentTab={renderContents().components}
      />
    </>
  )
}
