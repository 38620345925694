import React, { useEffect, useState } from "react"
import { Field } from "formik"
import { Card, FieldFeedbackLabel } from "../../../_metronic/_partials/controls"
import {
  ErrorSizeLength,
  ErrorSizeUploadIcon,
  MultipleUploadSizeError
} from "../../../component/atoms/Icons/SVG/upload"
import Dropzone from "react-dropzone"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { Image } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { PopupTable } from "../Popup"
import readXlsxFile from "read-excel-file"
import { RemoveSvgIcon } from "../Icons/SVG/Remove"
import { FileList } from "../FileList/FileList"
import { bytesToMegaBytes } from "../../../app/helpers/TextHelper"
import { VARIABLE_CONSTANT } from "../../../constants/VariableConstant"
import { UPLOAD } from "../../../constants/InitTypeConstants"
import { clearUploadAction } from "../../../redux/actions/MasterDataAction"
import { CardImage } from "../CardImage"
import { CircularProgress } from "@material-ui/core"
import { ICON_CONSTANS } from "../../../constants/ImageConstants.js"

const getFieldStyleClasses = (touched, errors, editable, sizeError) => {
  let isError = false
  let isValid = false
  if (editable) {
    isValid = touched && !errors
    if (touched) {
      isError = errors
    }
  }
  if (sizeError) {
    return "dropzone-have-error"
  }
  if (isValid) {
    return "dropzone-have-value"
  }
  if (isError) {
    return "dropzone-have-error"
  }
  return "dropzone"
}

const UploadArea = ({
  name,
  placeholder,
  label,
  subLabel,
  form,
  field,
  customFeedbackLabel,
  editable = true,
  urlParsing,
  onChange,
  className,
  typeUpload,
  onDelete,
  columns,
  title_prev,
  isError,
  changeComponent = null,
  maxSize = null, //in Byte
  maxSizeText = "",
  fileList,
  onDeleteFileList,
  required,
  maxFile,
  loading
}) => {
  if (placeholder === undefined || placeholder === null)
    placeholder = "Drag files here of click to upload " + label

  const [selectedFiles, setSelectedFiles] = useState(null)
  const [nameFiles, setNameFiles] = useState(null)
  const [sizeFiles, setSizeFiles] = useState(null)
  const [sizeError, setSizeError] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [prevData, setPrevData] = useState([])
  const [multipleFiles, setMultipleFiles] = useState([])
  const [totalSize, setTotalSize] = useState([])
  const [stringTotal, setStringTotal] = useState("0")

  const dispatch = useDispatch()

  useEffect(() => {
    if (urlParsing !== "" && urlParsing !== undefined && urlParsing !== null) {
      if (field.value !== urlParsing) {
        form.setFieldValue(field.name, urlParsing)
        setSelectedFiles(urlParsing)
      }
    } else {
      urlParsing = ""
    }
  }, [urlParsing])

  useEffect(() => {
    if (isError) {
      form.setFieldValue(field.name, null)
      form.setFieldValue(field.value, null)
    }
  }, [isError])

  useEffect(() => {
    if (changeComponent) {
      setSizeError(false)
      form.setFieldValue(field.name, null)
      form.setFieldValue(field.value, null)
    }
  }, [changeComponent])

  function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024

    if (Math.abs(bytes) < thresh) {
      return bytes + " B"
    }

    const units = si
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"]
    let u = -1
    const r = 10 ** dp

    do {
      bytes /= thresh
      ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(dp) + " " + units[u]
  }

  function onDeleted() {
    form.setFieldValue(field.name, "")
    form.setFieldValue(field.value, "")
  }

  const handleDeleteUpload = (type) => {
    dispatch(clearUploadAction(type))
    setSelectedFiles("")
    form.setFieldTouched(field.name, false, false)
  }

  function onDrop(files) {
    if (files.length > 0 && maxSize && files[0]?.size > maxSize) {
      setSizeError(true)
      return false
    }
    setSizeError(false)
    form.touched[field.name] = true
    form.setFieldValue(field.name, files[0].path)

    if (typeUpload === "files") {
      let fileFilterMultiple = [...multipleFiles]
      let total = [...totalSize]

      for (let i = 0; i < files.length; i++) {
        const newData = files[i]
        const newSize = files[i]?.size
        fileFilterMultiple.push(newData)
        total.push(newSize)
      }

      setMultipleFiles(fileFilterMultiple.reverse())
      setTotalSize(total)
      setStringTotal(sum)
    }

    if (typeUpload === "image-list") {
      let fileFilterMultiple = []
      for (let i = 0; i < files.length; i++) {
        const newData = files[i]
        fileFilterMultiple.push(newData)
        if (i >= maxFile) {
          break
        } else if (fileList.length + i >= maxFile - 1) {
          break
        }
      }
      setMultipleFiles(fileFilterMultiple)
      onChange(fileFilterMultiple)
    }

    if (typeUpload !== "files" && typeUpload !== "image-list") {
      if (files.length > 0) {
        setNameFiles(files[0]?.name)
        setSizeFiles(humanFileSize(files[0]?.size))
        if (typeUpload === "excel") {
          readXlsxFile(files[0]).then((rows) => {
            setPrevData(rows)
            onChange(files[0], rows)
          })
        } else {
          onChange(files[0])
        }
      }
    }
  }

  const imageStyle = {
    width: "auto",
    margin: "0 auto",
    maxWidth: "100%",
    maxHeight: "150px"
  }

  const imageStyleExcel = {
    margin: "0 auto",
    width: "50px"
  }

  const imageStyleDeleteExcel = {
    margin: "0 auto",
    width: "25px",
    top: "10px",
    position: "relative"
  }

  const dropzoneNew = {
    cursor: "default",
    padding: "3em"
  }

  useEffect(() => {
    if (loading) {
      setSelectedFiles(ICON_CONSTANS.IC_LOADING)
    } else {
      if (selectedFiles !== field.value) {
        setSelectedFiles(field.value)
      }
    }
  }, [field.value, loading])

  let fileSize = []
  if (typeUpload === "files" || typeUpload === "image-list") {
    fileList.map((item) => {
      fileSize.push(item.size)
    })
  }

  let sum = 0
  useEffect(() => {
    for (let i = 0; i < fileSize.length; i++) {
      sum += fileSize[i]
    }
    setStringTotal(sum)
  }, [fileSize])

  useEffect(() => {
    if (multipleFiles.length > 0) {
      if (typeUpload === "files") {
        onChange(multipleFiles, stringTotal)
      }
    }
  }, [multipleFiles, stringTotal, sum])

  return (
    <>
      {label && (
        <label>
          {label}
          {subLabel && <span style={{ color: "gray" }}>&nbsp;{subLabel}</span>}{" "}
          {required && <span style={{ color: "red" }}>*</span>}
        </label>
      )}
      {selectedFiles && typeUpload === "excel" ? (
        <div className="dropzone" style={dropzoneNew}>
          <div className="selected-file">
            <Card>
              {selectedFiles === "/media/gif/loader.gif" ? (
                <Image
                  style={imageStyle}
                  name={name}
                  src={selectedFiles ? selectedFiles : toAbsoluteUrl("/media/users/blank.png")}
                  rounded
                />
              ) : (
                <div className="pt-3">
                  <div className="row">
                    <div className="col-sm-2 col-3">
                      <Image
                        style={imageStyleExcel}
                        name={name}
                        src={toAbsoluteUrl("/media/logos/document.png")}
                        rounded
                      />
                    </div>
                    <div className="col-sm-8 col-6">
                      <Dropzone
                        accept={
                          typeUpload === "excel"
                            ? "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            : "image/jpeg, image/png, image/gif, image/bmp"
                        }
                        onDrop={onDrop}
                        disabled={!editable}
                        multiple={false}>
                        {({ getInputProps }) => (
                          <section>
                            <input {...getInputProps()} />
                            <div className="text-left">
                              <h4>{nameFiles}</h4>
                              <p className="text-muted">{sizeFiles}</p>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                    <div
                      className="col-sm-2 col-3 cursor-pointer"
                      onClick={() => {
                        onDeleted()
                        onDelete()
                        setSelectedFiles(null)
                      }}>
                      <Image
                        style={imageStyleDeleteExcel}
                        name={name}
                        src={toAbsoluteUrl("/media/logos/delete.png")}
                        rounded
                        className="hover-zoom"
                      />
                    </div>
                  </div>
                </div>
              )}
            </Card>

            <div className="col-sm-12 text-center">
              <p style={{ color: "#5EC8F2", cursor: "pointer" }} onClick={() => setShowPopup(true)}>
                <u>Lihat Preview File</u>
              </p>
            </div>
          </div>

          <aside className="selected-file-wrapper"></aside>
          <PopupTable
            show={showPopup}
            title={title_prev}
            closeButton={true}
            autoFocus={false}
            onCancel={() => {
              setShowPopup(false)
            }}
            dataTable={prevData && prevData.filter((item, idx) => (idx > 0 ? item : null))}
            columns={columns}
          />
        </div>
      ) : typeUpload === "files" ? (
        <>
          {fileList.length !== 10 && (
            <>
              <Dropzone
                accept={"image/jpeg, image/png, image/gif, image/bmp, application/pdf"}
                onDrop={onDrop}
                disabled={!editable}
                multiple={true}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div
                      {...getRootProps({
                        className:
                          getFieldStyleClasses(
                            form.touched[field.name],
                            form.errors[field.name],
                            editable,
                            sizeError
                          ) +
                          " " +
                          className
                      })}>
                      {sizeError ? (
                        <ErrorSizeUploadIcon maxSize={maxSizeText} />
                      ) : stringTotal > VARIABLE_CONSTANT.MAX_SIZE_UPLOAD ? (
                        <MultipleUploadSizeError />
                      ) : fileList.length > 10 ? (
                        <ErrorSizeLength maxLength={10} />
                      ) : (
                        <>
                          {placeholder}
                          <input {...getInputProps()} multiple={"multiple"} />
                        </>
                      )}
                    </div>
                    <aside className="selected-file-wrapper"></aside>
                  </section>
                )}
              </Dropzone>
              {editable && (
                <FieldFeedbackLabel
                  error={form.errors[field.name]}
                  label={label}
                  customFeedbackLabel={customFeedbackLabel}
                  onChange={onChange}
                />
              )}

              <p className={"pt-3"}>
                {bytesToMegaBytes(stringTotal)} / {VARIABLE_CONSTANT.MAX_SIZE_TEXT} terpakai
              </p>
            </>
          )}
          <div className={"mt-10 d-flex flex-column"} style={{ gap: 15 }}>
            {fileList && (
              <>
                {fileList.map((item, i) => (
                  <>
                    <FileList
                      onDelete={() => onDeleteFileList(i)}
                      name={item?.name}
                      size={item?.size}
                      key={i}
                      type={item?.type}
                    />
                  </>
                ))}
              </>
            )}
          </div>
        </>
      ) : typeUpload === "pdf" ? (
        <>
          <>
            <Dropzone
              accept={"application/pdf"}
              onDrop={onDrop}
              disabled={!editable}
              multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    {...getRootProps({
                      className:
                        getFieldStyleClasses(
                          form.touched[field.name],
                          form.errors[field.name],
                          editable,
                          sizeError
                        ) +
                        " " +
                        className
                    })}>
                    {sizeError ? (
                      <ErrorSizeUploadIcon maxSize={maxSizeText} />
                    ) : (
                      <>{fileList.length === 0 && placeholder}</>
                    )}
                    <input {...getInputProps()} />
                  </div>
                  <aside className="selected-file-wrapper"></aside>
                </section>
              )}
            </Dropzone>
            <div className={"file-preview"}>
              {fileList.length > 0 && (
                <div className={"d-flex flex-column"} style={{ gap: 15 }}>
                  {fileList.map((item, i) => (
                    <>
                      <FileList
                        onDelete={() => onDeleteFileList(i)}
                        name={item?.name}
                        size={item?.size}
                        key={i}
                        type={item?.type}
                      />
                    </>
                  ))}
                </div>
              )}
            </div>
            {editable && (
              <FieldFeedbackLabel
                error={form.errors[field.name]}
                label={label}
                customFeedbackLabel={customFeedbackLabel}
                onChange={onChange}
              />
            )}
          </>
        </>
      ) : typeUpload === "image-list" ? (
        <>
          <div className={"d-flex flex-wrap"} style={{ gap: 15 }}>
            {fileList && (
              <>
                {fileList.map((item, i) => (
                  <div key={i}>
                    <CardImage
                      onDelete={() => onDeleteFileList(i)}
                      name={item}
                      key={i}
                      imageUrl={item}
                    />
                  </div>
                ))}
              </>
            )}
            {fileList.length < maxFile && (
              <div className={"add-image-list "}>
                {loading ? (
                  <div className={"w-full h-full d-flex justify-content-center align-items-center"}>
                    <CircularProgress variant="indeterminate" size={30} color={"primary"} />
                  </div>
                ) : (
                  <Dropzone
                    accept={"image/jpeg, image/png, image/gif, image/bmp"}
                    onDrop={onDrop}
                    disabled={!editable}
                    multiple={true}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps({
                            className:
                              getFieldStyleClasses(
                                form.touched[field.name],
                                form.errors[field.name],
                                editable,
                                sizeError
                              ) +
                              " " +
                              className
                          })}>
                          {sizeError ? (
                            <ErrorSizeUploadIcon maxSize={maxSizeText} />
                          ) : stringTotal > VARIABLE_CONSTANT.MAX_SIZE_UPLOAD ? (
                            <MultipleUploadSizeError />
                          ) : fileList.length > maxFile ? (
                            <ErrorSizeLength maxLength={maxFile} />
                          ) : (
                            <>
                              {placeholder}
                              <input {...getInputProps()} multiple={"multiple"} />
                            </>
                          )}
                        </div>
                        <aside className="selected-file-wrapper"></aside>
                      </section>
                    )}
                  </Dropzone>
                )}
                {editable && (
                  <FieldFeedbackLabel
                    error={form.errors[field.name]}
                    label={label}
                    customFeedbackLabel={customFeedbackLabel}
                    onChange={onChange}
                  />
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {selectedFiles ? (
            <a
              className={"remove-upload"}
              onClick={() => {
                onDeleted()
                handleDeleteUpload(typeUpload !== "excel" && `${UPLOAD.AVATAR}`)
              }}>
              <RemoveSvgIcon />
            </a>
          ) : (
            ""
          )}
          <Dropzone
            accept={
              typeUpload === "excel"
                ? "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                : "image/jpeg, image/png, image/gif, image/bmp"
            }
            onDrop={onDrop}
            disabled={!editable}
            multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps({
                    className:
                      getFieldStyleClasses(
                        form.touched[field.name],
                        form.errors[field.name],
                        editable,
                        sizeError
                      ) +
                      " " +
                      className
                  })}>
                  <input {...getInputProps()} />
                  {selectedFiles ? (
                    <div className="selected-file">
                      <Card className={"withoud-shadow mb-0"}>
                        <Image
                          style={imageStyle}
                          name={name}
                          src={
                            selectedFiles ? selectedFiles : toAbsoluteUrl("/media/users/blank.png")
                          }
                        />
                        <a className={"mt-4 color-blue underline mb-0"}>Ganti Foto</a>
                      </Card>
                    </div>
                  ) : sizeError ? (
                    <ErrorSizeUploadIcon maxSize={maxSizeText} />
                  ) : (
                    placeholder
                  )}
                </div>
                <aside className="selected-file-wrapper"></aside>
              </section>
            )}
          </Dropzone>
          {editable && (
            <FieldFeedbackLabel
              error={form.errors[field.name]}
              touched={form.touched[field.name]}
              label={label}
              customFeedbackLabel={customFeedbackLabel}
              onChange={onChange}
            />
          )}
        </>
      )}
    </>
  )
}

export const UploadComponent = ({
  name,
  onDeleteFileList,
  placeholder,
  label,
  subLabel,
  urlParsing,
  editable,
  onChange,
  className,
  typeUpload = "image",
  onDelete,
  columns,
  title_prev,
  isError = false,
  changeComponent,
  maxSize = null,
  maxSizeText = "",
  fileList,
  required,
  maxFile,
  isLoading = null // dipakai jika component lebih dari 1 supaya loading tidak nabrak dengan yang lain (seperti di buat pengajuan sertifikasi)
}) => {
  const { General } = useSelector((state) => state)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isLoading === true || isLoading === false) {
      setLoading(isLoading)
    } else {
      setLoading(General.loading)
    }
  }, [General, isLoading])

  const disabledInput = () => {
    if (editable) {
      return !loading
    }
    return editable
  }

  return (
    <>
      {" "}
      <Field
        fileList={fileList}
        onDeleteFileList={onDeleteFileList}
        name={name}
        label={label}
        subLabel={subLabel}
        component={UploadArea}
        placeholder={placeholder}
        urlParsing={urlParsing}
        editable={disabledInput}
        onChange={onChange}
        type="file"
        className={className}
        typeUpload={typeUpload}
        onDelete={onDelete}
        columns={columns}
        title_prev={title_prev}
        isError={isError}
        changeComponent={changeComponent}
        maxSize={maxSize}
        maxSizeText={maxSizeText}
        required={required}
        maxFile={maxFile}
        loading={loading}
      />
    </>
  )
}
