import React, { useCallback, useEffect, useRef, useState } from "react"
import { Card } from "react-bootstrap"
import { Skeleton } from "@material-ui/lab"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { useLocation, useParams } from "react-router"
import { useUrlParam } from "../../../hooks/useUrlParams.jsx"
import { initialFilterTable } from "../../../../component/atoms/Tabel/index.jsx"
import { getMitraForFilterOrderPascaPlanting } from "../../../../redux/actions/MitraAction.jsx"
import { getFarmerForFilterOrderPascaPlanting } from "../../../../redux/actions/FarmerAction.jsx"
import { getSubDistrictForFilterOrderPascaPlanting } from "../../../../redux/actions/MasterDataAction.jsx"
import {
  getListPrePlantingSchedule,
  getSummaryCardSprayingPrePlanting,
  ResetListInitDataFormApproveBulk
} from "../../../../redux/actions/OrderAction.jsx"
import { ROUTING_STATUS } from "../../../../constants/StringConstant.js"
import { getUrlParsingPageFilter } from "../../../../config/endpointCollection.jsx"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { CardBody, CardHeader } from "../../../../_metronic/_partials/controls/index.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { FilterCard, SelectInput, TabsWithIcon } from "../../../../component/index.jsx"
import { TabPrePlantingPending } from "./TabPrePlanting/TabPrePlantingPending.jsx"
import { useHistory } from "react-router-dom"
import { TabPrePlantingScheduled } from "./TabPrePlanting/TabPrePlantingScheduled.jsx"
import { TabPrePlantingOverdue } from "./TabPrePlanting/TabPrePlantingOverdue.jsx"
import { TabPrePlantingDone } from "./TabPrePlanting/TabPrePlantingDone.jsx"
import { TabPrePlantingCanceled } from "./TabPrePlanting/TabPrePlantingCanceled.jsx"
import { TabPrePlantingReject } from "./TabPrePlanting/TabPrePlantingReject.jsx"

export function PrePlantingSprayingPage() {
  const subheader = useSubheader()
  const formRef = useRef()
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const { tab } = useParams()
  const { General, Order, Mitra, Farmer, MasterData } = useSelector((state) => state)

  const location = useLocation()
  const history = useHistory()
  const urlParams = useUrlParam()

  const [listDataSummary, setListDataSummary] = useState([])
  const [tabActive, setTabActive] = useState(checkTabActive(tab))
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const [isActiveFilterEmptyState, setIsActiveFilterEmptyState] = useState(false)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [isActiveFilter, setIsActiveFilter] = useState(false)
  const isReadOnly = false

  const [dataListScheduling, setDataListScheduling] = useState(null)
  const [btnFilterDisable, setBtnFilterDisable] = useState(false)
  const [mitraFilterList, setMitraFilterList] = useState([])
  const [mitraFilterValue, setMitraFilterValue] = useState("")
  const [farmerFilterList, setFarmerFilterList] = useState([])
  const [farmerFilterValue, setFarmerFilterValue] = useState("")
  const [locationFilterList, setLocationFilterList] = useState([])
  const [locationFilterValue, setLocationFilterValue] = useState("")

  const [initialValue, setInitialValue] = useState({
    ...initialFilterTable,
    farmer_id: "",
    mitra_id: "",
    sub_district_id: ""
  })

  useEffect(() => {
    if (isActiveFilter) {
      setIsActiveFilterEmptyState(true)
    } else if (isActiveSearch) {
      setIsActiveFilterEmptyState(true)
    } else {
      setIsActiveFilterEmptyState(false)
    }
  }, [isActiveSearch, isActiveFilter, location])

  useEffect(() => {
    subheader.setTitle(t(`sub_header.spraying_pre_planting`))
    subheader.setButton(null)
    dispatch(ResetListInitDataFormApproveBulk())

    // filter
    dispatch(getMitraForFilterOrderPascaPlanting())
    dispatch(getFarmerForFilterOrderPascaPlanting())
    dispatch(getSubDistrictForFilterOrderPascaPlanting())
  }, [])

  useEffect(() => {
    setDataListScheduling(Order)
  }, [Order?.pageData])

  useEffect(() => {
    if (Mitra?.list) {
      setMitraFilterList(Mitra?.list.map((value) => ({ label: value?.name, value: value?.id })))
    }
  }, [Mitra?.list])

  useEffect(() => {
    if (Farmer?.list) {
      setFarmerFilterList(Farmer?.list.map((value) => ({ label: value?.name, value: value?.id })))
    }
  }, [Farmer?.list])

  useEffect(() => {
    if (MasterData?.subDistrictList) {
      setLocationFilterList(
        MasterData?.subDistrictList.map((value) => ({
          label: value?.name,
          value: value?.id
        }))
      )
    }
  }, [MasterData?.subDistrictList])

  useEffect(() => {
    if (
      formRef.current.values.mitra_id ||
      formRef.current.values.farmer_id ||
      formRef.current.values.sub_district_id
    ) {
      setBtnFilterDisable(false)
    } else setBtnFilterDisable(true)
  }, [formRef.current, mitraFilterValue, farmerFilterValue, locationFilterValue, initialValue])

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location?.search])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  useEffect(() => {
    const data = Order?.summary
    const dataList = [
      { label: "label.pending", value: data?.pending ?? 0 },
      { label: "label.scheduled", value: data?.scheduled ?? 0 },
      { label: "label.overdue", value: data?.overdue ?? 0 },
      { label: "label.done", value: data?.done ?? 0 },
      { label: "label.canceled", value: data?.canceled ?? 0 },
      { label: "label.rejected", value: data?.rejected ?? 0 }
    ]
    setListDataSummary(dataList)
  }, [Order?.summary])

  useEffect(() => {
    checkQueryParamOnPageLoad()
  }, [queryStringObj])

  useEffect(() => {
    setTabActive(checkTabActive(tab))
  }, [tab])

  function fetchData(param) {
    dispatch(getListPrePlantingSchedule(checkStatusByActiveTab(tabActive), param))
    dispatch(getSummaryCardSprayingPrePlanting())
  }

  function checkQueryParamOnPageLoad() {
    if (queryStringObj?.keyword) {
      setSearchValue(queryStringObj?.keyword)
      setIsActiveSearch(true)
    }
    if (queryStringObj?.farmer_id || queryStringObj?.mitra_id || queryStringObj?.sub_district_id) {
      const data = {
        ...initialFilterTable,
        keyword: queryStringObj?.keyword,
        farmer_id: queryStringObj?.farmer_id,
        mitra_id: queryStringObj?.mitra_id,
        sub_district_id: queryStringObj?.sub_district_id
      }
      if (data) {
        setIsActiveFilter(true)
        setInitialValue(data)
        setMitraFilterValue(queryStringObj?.mitra_id)
        setFarmerFilterValue(queryStringObj?.farmer_id)
        setLocationFilterValue(queryStringObj?.sub_district_id)
      }
    }
  }

  function checkTabActive(tab) {
    if (tab) {
      switch (tab) {
        case ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED:
          return 1
        case ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.OVERDUE:
          return 2
        case ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.DONE:
          return 3
        case ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.CANCEL:
          return 4
        case ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.REJECT:
          return 5
        case ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING:
          return 0
      }
    } else {
      return 0
    }
  }

  function getTabsContents() {
    const labelList = [
      { label: t("label.pending") },
      { label: t("label.scheduled") },
      { label: t("label.overdue") },
      { label: t("label.done") },
      { label: t("label.canceled") },
      { label: t("label.rejected") }
    ]
    const componentList = [
      <TabPrePlantingPending
        key={0}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListScheduling}
        status={checkStatusByActiveTab(tabActive)}
      />,
      <TabPrePlantingScheduled
        key={1}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListScheduling}
        status={checkStatusByActiveTab(tabActive)}
      />,
      <TabPrePlantingOverdue
        key={2}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListScheduling}
        status={checkStatusByActiveTab(tabActive)}
      />,
      <TabPrePlantingDone
        key={3}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListScheduling}
        status={checkStatusByActiveTab(tabActive)}
      />,
      <TabPrePlantingCanceled
        key={4}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListScheduling}
        status={checkStatusByActiveTab(tabActive)}
      />,
      <TabPrePlantingReject
        key={5}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListScheduling}
        status={checkStatusByActiveTab(tabActive)}
      />
    ]

    return {
      labels: labelList,
      contents: componentList
    }
  }

  function onSearch(values) {
    setSearchValue(values?.keyword)
    setIsActiveSearch(true)
    let param = {
      ...initialFilterTable,
      ...initialValue,
      keyword: values?.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  function handleOpenCloseFilter(value) {
    setIsShowFilter(value)
  }

  function handleResetSearch() {
    setSearchValue("")
    setIsActiveSearch(false)
    const param = {
      ...initialFilterTable,
      ...initialValue,
      keyword: ""
    }
    const urlParam = getUrlParsingPageFilter(param)

    history.push({ search: urlParam })
  }

  function onResetFilter() {
    setIsShowFilter(false)
    setIsActiveFilter(false)

    let param = {
      ...initialFilterTable,
      farmer_id: "",
      mitra_id: "",
      sub_district_id: "",
      keyword: searchValue
    }
    setInitialValue({
      ...initialFilterTable,
      farmer_id: "",
      mitra_id: "",
      sub_district_id: ""
    })
    setMitraFilterValue("")
    setFarmerFilterValue("")
    setLocationFilterValue("")
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ search: urlParam })
  }

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          keyword: searchValue
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...initialValue,
          ...param,
          keyword: searchValue
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, searchValue, initialValue]
  )

  function onSubmitFilter(e) {
    const { values, submited } = e
    if (submited) {
      setIsActiveFilter(true)
      setIsShowFilter(false)
      const paramData = {
        ...initialFilterTable,
        keyword: searchValue,
        mitra_id: values.mitra_id,
        farmer_id: values.farmer_id,
        sub_district_id: values.sub_district_id
      }
      if (paramData) {
        setInitialValue(paramData)
        const urlParam = getUrlParsingPageFilter(paramData)
        history.push({ search: urlParam })
      }
    }
  }

  function changeTabs(data) {
    const urlParam = getUrlParsingPageFilter({
      ...initialValue,
      keyword: searchValue
    })
    history.push(
      RoutesConstants.ORDER.LIST_PRE_PLANTING_BY_STATUS(checkStatusByActiveTab(data) + urlParam)
    )
  }

  function checkStatusByActiveTab(data) {
    switch (data) {
      case 1:
        return ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED
      case 2:
        return ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.OVERDUE
      case 3:
        return ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.DONE
      case 4:
        return ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.CANCEL
      case 5:
        return ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.REJECT
      case 0:
        return ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING
    }
  }

  const filterComponent = (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Mitra" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="mitra_id"
              editable={!isReadOnly}
              label="Nama Mitra"
              placeholder="Pilih Salah Satu"
              options={mitraFilterList}
              withoutFeedback={true}
              isClearable={true}
              value={mitraFilterValue}
              onChange={(e) => setMitraFilterValue(e)}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Petani" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="farmer_id"
              editable={!isReadOnly}
              label="Nama Petani"
              placeholder="Pilih Salah Satu"
              options={farmerFilterList}
              withoutFeedback={true}
              isClearable={true}
              value={farmerFilterValue}
              onChange={(e) => setFarmerFilterValue(e)}
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className="card-custom card-shadowless">
        <CardHeader title="Kelurahan" className="px-0" />
        <CardBody className="px-0">
          <RowModule>
            <SelectInput
              name="sub_district_id"
              editable={!isReadOnly}
              label="Kelurahan"
              placeholder="Pilih Salah Satu"
              options={locationFilterList}
              withoutFeedback={true}
              isClearable={true}
              value={locationFilterValue}
              onChange={(e) => setLocationFilterValue(e)}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )

  function summaryCard() {
    return (
      <div className={"mb-10"}>
        <Card>
          {General?.loadingComponent ? (
            <div className={"d-flex p-10 align-items-center justify-content-between"}>
              {Array.from({ length: 6 }).map((item, index) => {
                return (
                  <div key={index}>
                    <Skeleton animation="wave" variant={"text"} width={100} />
                    <Skeleton animation="wave" variant={"text"} height={50} width={60} />
                  </div>
                )
              })}
            </div>
          ) : (
            <div className={"d-flex p-10 align-items-center justify-content-between"}>
              {listDataSummary.map((item, i) => (
                <div key={i}>
                  <div className={"text-black-50 mb-2"}>{t(item.label)}</div>
                  <h1>{item.value}</h1>
                </div>
              ))}
            </div>
          )}
        </Card>
      </div>
    )
  }

  return (
    <div className={"d-flex flex-column"}>
      {summaryCard()}
      <FilterCard
        // SEARCH
        isActiveSearch={isActiveSearch}
        placeholder={t("placeholder.insert_area_name")}
        onSubmitForm={onSearch}
        initialValues={{ keyword: searchValue }}
        name={"keyword"}
        innerRef={formRef}
        trigerReset={handleResetSearch}
        // filter
        filterInnerRef={formRef}
        filterHandleSubmit={onSubmitFilter}
        filterFormInitialValues={initialValue}
        filterComponent={filterComponent}
        filterOnReset={onResetFilter}
        showFilter={isShowFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={isActiveFilter}
        btnDisable={btnFilterDisable}></FilterCard>
      <RowModule>
        <TabsWithIcon
          labelTab={getTabsContents().labels}
          componentTab={getTabsContents().contents}
          changeTab={changeTabs}
          initValue={tabActive}
        />
      </RowModule>
    </div>
  )
}
