import { BROADCAST } from "../../constants/InitTypeConstants"
import { GeneralSlice } from "../reducers/General"
import { BroadcastSlice } from "../reducers/Broadcast"
import { getUrlParsingPageFilter } from "../../config/endpointCollection"
import { requestDelete, requestGet, requestPost } from "../../config"
import { BROADCAST_URL, MASTER_URL } from "../../constants/UrlConstants"

const { actions: general } = GeneralSlice
const { actions: broadcast } = BroadcastSlice

export const getListBroadcast = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: BROADCAST.GET_LIST_BROADCAST
      })
    )
    dispatch(
      broadcast.setPageData({
        pageData: [],
        page: {
          totalCount: 0,
          currentElement: 0,
          currentPage: 0
        }
      })
    )
    try {
      let res = await requestGet(BROADCAST_URL.GET_LIST_BROADCAST + urlData)
      dispatch(
        broadcast.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: BROADCAST.GET_LIST_BROADCAST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailBroadcast = (id) => {
  return async (dispatch) => {
    general.setLoading({
      loading: true,
      actionInitType: BROADCAST.GET_DETAIL_BROADCAST
    })
    dispatch(broadcast.getPage(null))
    try {
      let res = await requestGet(BROADCAST_URL.GET_DETAIL_BROADCAST + id)
      dispatch(broadcast.getPage(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: BROADCAST.GET_DETAIL_BROADCAST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const removeBroadcast = (id) => {
  return async (dispatch) => {
    if (id) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: BROADCAST.DELETE_BROADCAST
        })
      )
      try {
        await requestDelete(BROADCAST_URL.DELETE_BROADCAST + id)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: BROADCAST.DELETE_BROADCAST
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: BROADCAST.DELETE_BROADCAST
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getAppList = () => {
  return async (dispatch) => {
    dispatch(
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: BROADCAST.GET_APP_LIST
        })
      )
    )
    try {
      let res = await requestGet(BROADCAST_URL.GET_APP_LIST)
      dispatch(
        broadcast.setPageData({
          pageData: res.data
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: BROADCAST.GET_APP_LIST
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
export const createBroadcast = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: BROADCAST.CREATE_BROADCAST
        })
      )
      try {
        await requestPost(BROADCAST_URL.CREATE_BROADCAST, data)
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: BROADCAST.CREATE_BROADCAST
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: BROADCAST.CREATE_BROADCAST
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

let main = []
export const uploadUrlAttachemnt = ({ data, type, lenght }) => {
  const newFormData = new FormData()
  newFormData.append("file", data)
  newFormData.append("category", type)
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: BROADCAST.UPLOAD_MULTIPLE_BROADCAST
      })
    )
    dispatch(broadcast.setUrlAttachment({ url: null, uploadType: type }))
    try {
      let res = await requestPost(MASTER_URL.UPLOAD_PHOTO, newFormData)
      const count = res.data

      main.push(count)
      const countUploaded = main.length

      dispatch(broadcast.setUrlAttachment({ url: res.data, uploadType: type }))
      if (countUploaded === lenght) {
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: BROADCAST.UPLOAD_MULTIPLE_BROADCAST
          })
        )
      }
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const clearBroadcast = () => {
  return async (dispatch) => {
    dispatch(broadcast.setClear({}))
  }
}
