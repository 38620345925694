import React from "react"
import SVG from "react-inlinesvg"
import { Form, Formik } from "formik"
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls"
import { Button } from "react-bootstrap"

import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import Fade from "react-reveal/Fade"

export const Filter = ({
  filterInnerRef,
  filterHandleSubmit,
  filterFormInitialValues,
  filterComponent,
  filterOnReset,
  showFilter,
  handleOpenCloseFilter,
  filterOn,
  disabled = false,
  noneOverflow,
  btnDisable
}) => {
  const handleSubmit = values => {
    filterHandleSubmit({ values: values, submited: true })
  }

  return (
    <div className={`filter-right ${showFilter ? "open" : ""}`}>
      <Paper
        className={` ${disabled ? "filter-card_disabled" : "filter-card"}  ${
          filterOn ? "active" : ""
        }`}
        onClick={() => (disabled == false ? handleOpenCloseFilter(true) : null)}>
        <IconButton className={"filter-icon"} aria-label="Search">
          <SVG
            src={
              disabled
                ? toAbsoluteUrl("/media/svg/icons/Menu/ic_filter_white.svg")
                : toAbsoluteUrl("/media/svg/icons/Menu/ic_filter.svg")
            }
          />
        </IconButton>
        <h4>Filter</h4>
        {filterOn && <div className="flag-orange ml-4"></div>}
      </Paper>
      {filterOn && (
        <Button variant={"link"} className="reset-filter ml-6" onClick={filterOnReset} type="reset">
          Reset Filter
        </Button>
      )}

      {/* {
                showFilter ? */}

      <Fade right when={showFilter}>
        <Formik
          enableReinitialize
          innerRef={filterInnerRef}
          onSubmit={handleSubmit}
          initialValues={filterFormInitialValues}>
          <Form>
            <Card className={`card-custom filter-abs ${showFilter ? "active" : ""}`}>
              <CardHeader>
                <div className="d-flex flex-wrap justify-content-between align-items-center">
                  <h3 className="card-label">Filter</h3>
                  <IconButton
                    className={"filter-icon"}
                    aria-label="Search"
                    onClick={() => handleOpenCloseFilter(false)}>
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Menu/close-icon.svg")} />
                  </IconButton>
                </div>
              </CardHeader>
              <CardBody className={`filter-body ${noneOverflow ? "overflow-none" : ""}`}>
                {filterComponent}
              </CardBody>
              <CardFooter className="d-flex justify-content-between align-items-center">
                <Button
                  className="font-weight-bold btn-block my-0 mr-2"
                  onClick={filterOnReset}
                  type="reset"
                  variant={"secondary-info"}>
                  Reset
                </Button>
                <Button
                  className="font-weight-bold btn-block my-0 ml-2"
                  type={"submit"}
                  variant={"primary"}
                  disabled={btnDisable}>
                  Terapkan
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </Formik>
      </Fade>
      {/* : null
            } */}
    </div>
  )
}
