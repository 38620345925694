import React from "react"
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls"
import { DetailText } from "../../../component"
import { RowModule } from "../../../component/atoms/RowModule/RowModule.jsx"

export const PreviewFieldOfficer = ({ formData }) => {
  return (
    <Card>
      <CardHeader title="REVIEW DATA FO" />
      <CardBody>
        <RowModule>
          <Card>
            <CardHeader title="DATA FO" />
            <CardBody>
              <DetailText title="Nama" value={formData?.name ?? "-"} />
              <DetailText title="No. Handphone" value={formData?.phone_number ?? "-"} />
              <DetailText title="Email" value={formData?.email ?? "-"} />
              <DetailText title="Role" value={formData?.roleFO?.display_name ?? "-"} />
              <DetailText
                title="Alamat"
                type="address"
                value={
                  formData?.address +
                  ", " +
                  formData?.postal_code +
                  ", " +
                  formData?.sub_district_name +
                  ", " +
                  formData?.district_name +
                  ", " +
                  formData?.regency_name +
                  ", " +
                  formData?.province_name
                }
              />
            </CardBody>
          </Card>
        </RowModule>
        <RowModule>
          <Card>
            <CardHeader title="FOTO PROFILE" />
            <CardBody>
              <DetailText type="image" value={formData?.avatar} />
            </CardBody>
          </Card>
        </RowModule>
      </CardBody>
    </Card>
  )
}
