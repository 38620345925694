import React, { useCallback, useEffect, useState } from "react"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../component/atoms/Tabel"
import { getPageDataByFo } from "../../../../redux/actions/MitraAction"
import { sortCaret, toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { TextInput } from "../../../../component/atoms/TextInput"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import IconButton from "@material-ui/core/IconButton"
import Paper from "@material-ui/core/Paper"
import SVG from "react-inlinesvg"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { EmptyCard, TableSkeleton } from "../../../../component/index.jsx"
import { useTranslation } from "react-i18next"
import useDebounce from "../../../hooks/useDebounce.jsx"

export const MitraList = ({ isBulk = false, fo, onSelect = () => {}, onClickRow = () => {} }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { General, Mitra } = useSelector((state) => state, shallowEqual)
  const [selectedRows, setSelectedRows] = useState([])
  const [search, setSearch] = useState({
    string_filter: ""
  })

  const debounceValue = useDebounce(search, 500)

  useEffect(() => {
    if (fo) {
      handleResetSearch()
      const param = {
        ...initialFilterTable,
        id: fo
      }
      dispatch(getPageDataByFo({ pageReq: param }))
    }
  }, [fo])

  useEffect(() => {
    if (fo) {
      const params = {
        ...initialFilterTable,
        id: fo,
        string_filter: debounceValue.string_filter
      }
      dispatch(getPageDataByFo({ pageReq: params }))
    }
  }, [debounceValue])

  const changePage = useCallback(
    (param) => {
      const params = {
        id: fo,
        string_filter: debounceValue.string_filter,
        ...param
      }
      dispatch(getPageDataByFo({ pageReq: params }))
    },
    [dispatch, debounceValue]
  )

  useEffect(() => {
    if (onSelect) {
      onSelect(selectedRows)
    }
  }, [selectedRows])

  const uiName = (e) => {
    return (
      <>
        <a className="text-dark font-weight-bolder mb-1 font-size-lg">{e.mitra_name}</a>
        <span className="text-muted font-weight-bold d-block">{e.mitra_phone}</span>
      </>
    )
  }

  const uiCode = (e) => {
    return (
      <>
        <a className="text-dark font-weight-bolder mb-1 font-size-lg">{e.mitra_code}</a>
      </>
    )
  }

  const columns = [
    {
      dataField: "mitra_code",
      text: "ID MITRA",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      },
      sortCaret: sortCaret
    },
    {
      dataField: "mitra_name",
      text: "NAMA & TELP",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      },
      sortCaret: sortCaret
    },
    {
      dataField: "mitra_address",
      text: "ALAMAT",
      formatter: formatterRowComponent,
      sortCaret: sortCaret
    }
  ]

  const handleResetSearch = () => {
    setSearch({
      string_filter: ""
    })
  }
  return (
    <>
      <div className="border rounded pt-3 pb-3">
        <div className="filter-container mb-4 ml-2 mr-2">
          <RowModule withoutSeparator={true} customColumnCss="col-lg-6 col-sm-6 col-md-6">
            <Paper className={"search-atom col-lg-6 col-sm-6 col-md-6"}>
              <div style={{ width: "100%" }}>
                <TextInput
                  className={"search-atom-input"}
                  editable={true}
                  name={"search"}
                  placeholder={"Cari Nama"}
                  withoutFeedback={true}
                  value={search.string_filter}
                  allowEmpty={true}
                  onChangeValue={(e) => setSearch({ string_filter: e })}
                />
              </div>
              <IconButton type={"submit"} className={"search-atom-button"} aria-label="Search">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Menu/ic_search.svg")} />
              </IconButton>
            </Paper>
          </RowModule>
        </div>
        {General.loading ? (
          <TableSkeleton column={3} row={10} />
        ) : Mitra.pageData?.length == 0 ? (
          <EmptyCard
            body={
              search ? t("messages.response.data_not_found") : t("messages.response.empty_state")
            }
          />
        ) : (
          ""
        )}
        <TableView
          callbackAfterPageChange={changePage}
          columnProperties={columns}
          dataTable={Mitra.pageData ?? []}
          currentPage={Mitra.page.currentPage}
          currentElement={Mitra.page.currentElement ?? 10}
          totalCount={Mitra.page.totalCount ?? 0}
          loadingGetData={General.loadingComponent}
          keyField="mitra_id"
          rowClasses={() => {
            return "cursor-pointer"
          }}
          rowEvents={{
            onClick: (e, row) => {
              if (onClickRow) {
                onClickRow(row)
              }
            }
          }}
          selectable={isBulk}
          onSelect={(row, isSelected) => {
            let rows = JSON.parse(JSON.stringify(selectedRows))
            if (isSelected) {
              rows.push(row)
            } else {
              rows = rows.filter((e) => e?.mitra_id !== row?.mitra_id)
            }

            setSelectedRows(rows)
          }}
          onSelectAll={(isSelect, rows) => {
            let rowses = JSON.parse(JSON.stringify(selectedRows))
            if (isSelect) {
              if (rows?.length > 0) {
                rows?.map((e) => {
                  rowses.push(e)
                })
              }
            } else {
              if (rows?.length > 0) {
                let rowsesNew = rowses
                rows?.map((row) => {
                  const removeIndex = rowses.findIndex((e) => e?.mitra_id === row?.mitra_id)
                  rowsesNew.splice(removeIndex, 1)
                })
                rowses = rowsesNew
              }
            }
            setSelectedRows(rowses)
          }}
        />
      </div>
    </>
  )
}
