import React, { Fragment } from "react"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { toThousandConvert } from "../../../helpers/TextHelper"
import { DetailOrderRowText } from "../DetailsOrder"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

export const SectionDetailOrderAreaInformation = ({ data }) => {
  const { General } = useSelector((state) => state)
  return (
    <>
      <div className={"col-xl-10"}>
        <h4>
          {General.loading ? (
            <Skeleton animation="wave" variant={"text"} height={32} width={150} />
          ) : (
            "1. INFORMASI LAHAN"
          )}
        </h4>
        <RowModule customColumnCss={" mb-2 mt-4 col-6"}>
          <div className={"p-0"}>
            <DetailOrderRowText label={"Lahan"} value={data?.area_name} />
            <DetailOrderRowText
              label={"Petani"}
              value={(data?.farmer_name ?? " - ") + ` (${data?.farmer_code ?? " - "})`}
            />
            <DetailOrderRowText
              label={"Mitra"}
              value={(data?.mitra_name ?? " - ") + ` (${data?.mitra_code ?? " - "})`}
            />
            <DetailOrderRowText label={"Lokasi Lahan"} value={data?.full_address ?? "-"} />
          </div>
          <div className={"p-0"}>
            <DetailOrderRowText
              label={"Luas Lahan (Manual)"}
              value={
                data?.area_land_manual ? toThousandConvert(data?.area_land_manual) + " m²" : "-"
              }
            />
            <DetailOrderRowText
              label={"Luas Lahan (Polygon)"}
              value={
                data?.area_land_polygon ? toThousandConvert(data?.area_land_polygon) + " m²" : "-"
              }
            />
          </div>
        </RowModule>
      </div>
    </>
  )
}
