import React from "react"
import { DetailText } from "../../../../../component"
export const Document = ({ document }) => {
  return (
    <div className={"row"}>
      {document.map((item, index) => {
        return (
          <div className={"col-md-6 col-sm-12"} key={index}>
            <DetailText
              title={item?.label}
              menu={item?.label}
              value={item?.url}
              type="image"
              withoutSeparator={true}
            />
          </div>
        )
      })}
    </div>
  )
}
