import axios from "../../../service/baseAxiosAction"
import { ENDPOINT } from "../../../../config/endpointCollection"

export const LOGIN_URL = "api/auth/login"
export const REGISTER_URL = "api/auth/register"
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password"

export const ME_URL = "api/me"

export function login(email, password) {
  const logins = axios.post(ENDPOINT.userSetting.login, { email, password })
  return logins
}

export function checkUser() {}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password })
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email })
}
