import { useTranslation } from "react-i18next"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import { ROUTING_STATUS } from "../../../../constants/StringConstant.js"
import { clearFinishUpdateOrDelete } from "../../../../redux/actions/MasterDataAction.jsx"
import * as Yup from "yup"
import { FORM_NEED, getStringErrorMassage } from "../../../../util/constStringCollection.js"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/index.js"
import { ILUSTRATION_CONSTANS } from "../../../../constants/ImageConstants.js"
import { timeStampToDate } from "../../../helpers/DateHelper.js"
import { PopupQuestion } from "../../../../component/atoms/Popup/index.jsx"
import { TabsInputComponent } from "../../../../component/index.jsx"
import {
  ApproveRejectOrder,
  EditOrder,
  getDetailHeader,
  GetDetailHeaderEditOrder
} from "../../../../redux/actions/OrderAction.jsx"
import { ORDER } from "../../../../constants/InitTypeConstants.js"
import { Card } from "../../../../_metronic/_partials/controls/index.js"
import { ApprovalForm } from "./Form/ApprovalForm.jsx"
import { checkValue } from "../../../helpers/TextHelper.js"

export const ApprovalPascaTanam = ({
  history,
  match: {
    params: { status, id }
  }
}) => {
  const { t } = useTranslation()
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { General, Order } = useSelector((state) => state)
  const formRef = useRef()
  const [isValid, setIsValid] = useState(true)
  const [detail, setDetail] = useState("")
  const stepsTitle = [null]
  const stepsDescription = [null]
  const [initialValue, setInitialValue] = useState({
    team_id: [],
    spraying_date_list: [],
    rejection_enum_list: [],
    rejection_reason: ""
  })
  const [swalMessage, setSwalMessage] = useState("")
  const [showModalDialog, setShowModalDialog] = useState(false)
  const [showModalDialogEdit, setShowModalDialogEdit] = useState(false)
  const [showModalDialogCancel, setShowModalDialogCancel] = useState(false)
  const [showModalSuccess, setShowModalSuccess] = useState(false)
  const [newTeam, setNewTeam] = useState([])
  const [prevTeam, setPrevTeam] = useState([])
  const [detailTeam, setDetailTeam] = useState([])

  const buttonSubmited = (
    <button
      type="button"
      disabled={isValid}
      className="btn btn-primary"
      onClick={() => {
        if (status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED) {
          findNewTeam()
          findPrevTeam()
          setShowModalDialogEdit(true)
        } else {
          setShowModalDialog(true)
        }
      }}>
      Submit
    </button>
  )

  const buttonCancel = (
    <button
      type="button"
      className="btn btn-secondary-info mr-4"
      onClick={() => setShowModalDialogCancel(true)}>
      Cancel
    </button>
  )

  const createEditFlow = [
    <ApprovalForm
      key={1}
      status={status}
      data={detail}
      isReadOnly={false}
      setIsValid={setIsValid}
      initialValue={initialValue}
      setFormValues={(e) => {
        setInitialValue(e)
      }}
      id={id}
      btnSubmit={buttonSubmited}
      btnCancel={buttonCancel}
      detailTeam={detailTeam}
    />
  ]

  useEffect(() => {
    if (id) {
      if (status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED) {
        dispatch(GetDetailHeaderEditOrder(id))
      } else {
        dispatch(getDetailHeader(id))
      }
    }
  }, [id, status])

  useEffect(() => {
    if (Order.detail) {
      setDetail(Order.detail)
    }
  }, [Order.detail])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (swalMessage) {
        setShowModalSuccess(true)
        dispatch(clearFinishUpdateOrDelete())
      }
    }
  }, [General.isFinishUpdateOrDelete])

  const ValidationScheme = [
    Yup.object().shape({
      team_id: Yup.array().min(1).required(getStringErrorMassage("Team ", FORM_NEED.harusDiIsi))
    })
  ]

  function onSubmitForm(e) {
    setShowModalDialog(false)
    setShowModalDialogEdit(false)
    const { values, submited } = e
    setSwalMessage(t("messages.response.order_success"))

    if (submited) {
      const spraying = []
      values.spraying_date_list.map((item, index) => {
        if (item) {
          let data = {
            seq: index + 1,
            date: timeStampToDate(item, "yyyy-MM-dd")
          }
          spraying.push(data)
        }
      })
      const formData = {
        team_id_list: values.team_id,
        spraying_date_list: spraying,
        rejection_enum_list: [],
        rejection_reason: ""
      }
      if (status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED) {
        dispatch(EditOrder(id, formData))
      } else {
        dispatch(ApproveRejectOrder(id, formData, ORDER.APPROVE_ORDER))
      }
    }
  }

  useEffect(() => {
    if (detail) {
      subHeader.setButton(false)
      if (id) {
        subHeader.setBreadcrumbs([
          {
            pathname: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(
              ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING
            ),
            title: t("label.scheduling")
          },
          {
            pathname: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(
              ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING
            ),
            title: t("label.pasca_planting")
          },
          {
            pathname: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(status),
            title: checkValue(detail?.status_string)
          },
          {
            pathname: RoutesConstants.ORDER.DETAIL_PASCA_PLANTING(status, id),
            title: detail.area_name
          },
          {
            title:
              status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED ? "Edit" : "Penjadwalan"
          }
        ])
      }
      if (status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED) {
        setInitialValue((prevState) => ({
          ...prevState,
          team_id: detail?.team_list ? detail?.team_list.map((item) => item?.team_id) : [],
          spraying_date_list: [detail.previous_order_date],
          rejection_enum_list: [],
          rejection_reason: ""
        }))
        setDetailTeam(detail?.team_list ? detail?.team_list.map((item) => item?.team_id) : [])
      }
    }
  }, [detail, id, status])
  const findNewTeam = () => {
    if (Order?.droneTeam) {
      let teammList = Order?.droneTeam
      let teamSelect = formRef?.current?.values?.team_id
      let newTeamList = teamSelect.map(
        (item) => teammList.filter((value) => item === value.id)[0].name
      )
      setNewTeam(newTeamList)
    }
  }

  const findPrevTeam = () => {
    if (Order?.droneTeam) {
      let teammList = Order?.droneTeam
      let teamSelect = detailTeam
      let newTeamList = teamSelect.map(
        (item) => teammList.filter((value) => item === value.id)[0].name
      )
      setPrevTeam(newTeamList)
    }
  }

  const popupBodyEdit = (
    <>
      <RowModule>
        <p className={"swal-text mb-0 p-4"}>{t("messages.confirmation.order_edit")}</p>
      </RowModule>

      <div className="row px-4">
        <div className="col-5 text-center">
          <p>
            <b>Data Lama</b>
          </p>
        </div>
        <div className="col-2 text-center">
          <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.CHANGE_ORDER)} />
        </div>
        <div className="col-5 text-center">
          <p>
            <b>Data Baru</b>
          </p>
        </div>
      </div>
      {JSON.stringify(detailTeam) !== JSON.stringify(formRef?.current?.values?.team_id) ? (
        <div className="row px-4">
          <div className="col-5 text-center">
            <p>{prevTeam.join(", ")}</p>
          </div>
          <div className="col-2 text-center"></div>
          <div className="col-5 text-center">
            <p>{newTeam.join(", ")}</p>
          </div>
        </div>
      ) : (
        ""
      )}
      {status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED ? (
        timeStampToDate(detail?.previous_order_date, "dd MMMM yyyy") !==
        timeStampToDate(formRef?.current?.values?.spraying_date_list[0], "dd MMMM yyyy") ? (
          <div className="row px-4">
            <div className="col-5 text-center">
              <p>{timeStampToDate(detail?.previous_order_date, "dd MMMM yyyy")}</p>
            </div>
            <div className="col-2 text-center"></div>
            <div className="col-5 text-center">
              <p>
                {timeStampToDate(formRef?.current?.values?.spraying_date_list[0], "dd MMMM yyyy")}
              </p>
            </div>
          </div>
        ) : (
          ""
        )
      ) : (
        <div className="row px-4">
          <div className="col-5 text-center">
            <p>{timeStampToDate(detail?.previous_order_date, "dd MMMM yyyy")}</p>
          </div>
          <div className="col-2 text-center"></div>
          <div className="col-5 text-center">
            <p>
              {timeStampToDate(formRef?.current?.values?.spraying_date_list[0], "dd MMMM yyyy")}
            </p>
          </div>
        </div>
      )}
    </>
  )

  return (
    <>
      {/*popup approve*/}
      <PopupQuestion
        disable={isValid}
        onCancel={() => setShowModalDialog(false)}
        textCancel={"Batal"}
        textOk={"Lanjut"}
        title={"APPROVE ORDER"}
        onOk={() => formRef.current.handleSubmit()}
        show={showModalDialog}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>{t("messages.confirmation.order_spraying_approve")}</p>
          </RowModule>
        }
      />
      {/*popup edit order*/}
      <PopupQuestion
        disable={isValid}
        onCancel={() => setShowModalDialogEdit(false)}
        textCancel={"Batal"}
        textOk={"Lanjut"}
        title={
          status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED
            ? "EDIT ORDER"
            : "RESCHEDULE ORDER"
        }
        onOk={() => formRef.current.handleSubmit()}
        show={showModalDialogEdit}
        bodyCustom={popupBodyEdit}
      />
      {/*popup success*/}
      <PopupQuestion
        disable={isValid}
        onCancel={() =>
          history.push(
            RoutesConstants.ORDER.DETAIL_PASCA_PLANTING(
              ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED,
              status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING ? Order?.message : id
            )
          )
        }
        textCancel={"Lihat Detail"}
        textOk={"Oke"}
        onOk={() =>
          history.push(
            RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(
              ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.PENDING
            )
          )
        }
        show={showModalSuccess}
        bodyCustom={
          <Card>
            <div className="d-flex flex-column align-items-center">
              <SVG src={toAbsoluteUrl(ILUSTRATION_CONSTANS.SUCCESS_PLACEHOLDER_ORDER)} />
              <p className={"swal-text text-center"}>
                {status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED
                  ? t("messages.response.order_success_edit")
                  : t("messages.response.order_success")}
              </p>
            </div>
          </Card>
        }
      />
      {/*popup cancel*/}
      <PopupQuestion
        onCancel={() => setShowModalDialogCancel(false)}
        textCancel={"Batal"}
        textOk={"Lanjut"}
        title={
          status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED
            ? "CANCEL EDIT ORDER"
            : "CANCEL APPROVE ORDER"
        }
        onOk={() => history.push(RoutesConstants.ORDER.DETAIL_PASCA_PLANTING(status, id))}
        show={showModalDialogCancel}
        bodyCustom={
          <RowModule>
            <p className={"swal-text p-4"}>
              {status === ROUTING_STATUS.SPRAYING_PASCA_PLANTING_STRING.APPROVED
                ? t("messages.confirmation.order_edit_spraying_cancel")
                : t("messages.confirmation.order_spraying_cancel")}
            </p>
          </RowModule>
        }
      />
      <TabsInputComponent
        isEditTabs={false}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="approvalOrder"
        innerRef={formRef}
        componentTab={createEditFlow}
        validationSchema={ValidationScheme}
        onSubmitForm={onSubmitForm}
      />
    </>
  )
}
