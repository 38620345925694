import { getUrlParsingPageFilter } from "../../config/endpointCollection"
import { GeneralSlice } from "../reducers/General"
import { VIDEO } from "../../constants/InitTypeConstants"
import { requestDelete, requestGet, requestPost, requestPut } from "../../config"
import { VIDEO_URL } from "../../constants/UrlConstants"
import { VideoSlice } from "../reducers/Video"

const { actions: general } = GeneralSlice
const { actions: video } = VideoSlice

export const getPageData = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(general.setLoading({ loading: true, actionInitType: VIDEO.GET_LIST_PAGE }))
    video.setPageData({
      pageData: [],
      page: {
        totalCount: 0,
        currentElement: 0,
        currentPage: 0
      }
    })
    try {
      let res = await requestGet(VIDEO_URL.GET_LIST_PAGE + urlData)
      dispatch(
        video.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: VIDEO.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const createVideo = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(general.setLoading({ loading: true, actionInitType: VIDEO.CREATE }))
      try {
        await requestPost(VIDEO_URL.CREATE_VIDEO, data)
        dispatch(general.setLoading({ loading: false, actionInitType: VIDEO.CREATE }))
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: VIDEO.CREATE
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const updateVideo = (id, data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(general.setLoading({ loading: true, actionInitType: VIDEO.UPDATE }))
      try {
        await requestPut(VIDEO_URL.UPDATE_VIDEO + "/" + id, null, data)
        dispatch(general.setLoading({ loading: false, actionInitType: VIDEO.UPDATE }))
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: VIDEO.UPDATE
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getDetail = (param) => {
  return async (dispatch) => {
    if (param) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: VIDEO.GET_DETAIL
        })
      )
      try {
        let res = await requestGet(VIDEO_URL.GET_LIST_DETAIL + param)
        dispatch(video.getPage(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: VIDEO.GET_DETAIL
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const clearDataDetail = () => {
  return async (dispatch) => {
    dispatch(video.getPage(false))
  }
}

export const removeVideo = (param) => {
  return async (dispatch) => {
    if (param) {
      dispatch(general.setLoading({ loading: true, actionInitType: VIDEO.DELETE }))
      try {
        await requestDelete(VIDEO_URL.REMOVE_VIDEO + param)
        dispatch(general.setLoading({ loading: false, actionInitType: VIDEO.DELETE }))
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: VIDEO.DELETE
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}
