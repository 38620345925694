import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TabsInputComponent } from "../../../../component"
import * as Yup from "yup"
import {
  FORM_NEED,
  getMaximumErrorString,
  getMinimumErrorString,
  getStringErrorMassage
} from "../../../../util/constStringCollection"
import swal from "sweetalert"

import { useSubheader } from "../../../../_metronic/layout"
import { FormBenih } from "./FormCategoryItem/FormBenih"
import { createCategoryItem } from "../../../../redux/actions/MasterDataAction"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"

export const CreateBenih = ({
  history,
  match: {
    params: { subType }
  }
}) => {
  const dispatch = useDispatch()
  const subHeader = useSubheader()
  const formRef = useRef()
  const [isValid, setIsValid] = useState(true)
  const { General } = useSelector(state => state)
  const [initialValue, setInitialValue] = useState({
    plant_type_id: "",
    type: "SEED",
    sub_type: subType.toUpperCase(),
    category_name: ""
  })

  const [swalMessage, setSwalMessage] = useState("")
  const [swalIcon, setSwallIcon] = useState("")
  const stepsTitle = [null]
  const stepsDescription = [null]

  const createEditFlow = [<FormBenih key={0} isReadOnly={false} setIsValid={setIsValid} />]

  useEffect(() => {
    subHeader.setButton(buttonSubmited)
  }, [isValid])

  useEffect(() => {
    setInitialValue(prevState => ({
      ...prevState
    }))
    subHeader.setButton(buttonSubmited)
    subHeader.setBreadcrumbs([
      {
        pathname: "/master/category-item",
        title: "Kategori Item"
      },
      {
        pathname: "/master/category-item/seed/" + subType,
        title: "Benih"
      },
      {
        title: "Tambah"
      }
    ])
  }, [])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      if (swalMessage) {
        swal({
          icon: swalIcon,
          text: swalMessage
        })
      }
      history.push("/master/category-item/seed/" + subType)
    }
  }, [General.isFinishUpdateOrDelete])

  const ValidationScheme = [
    Yup.object().shape({
      plant_type_id: Yup.string().required(
        getStringErrorMassage("Tipe Tanaman ", FORM_NEED.harusDiIsi)
      ),
      category_name: Yup.string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama Varian ", FORM_NEED.harusDiIsi))
    })
  ]

  const buttonSubmited = (
    <button
      type="button"
      disabled={isValid}
      className={isValid ? "btn button-disabled" : "btn btn-primary"}
      onClick={() => {
        if (formRef.current && !isValid) {
          formRef.current.handleSubmit()
        }
      }}>
      Submit
    </button>
  )

  const onSubmitForm = e => {
    setSwalMessage("Benih berhasil ditambahkan.")
    setSwallIcon(toAbsoluteUrl("/media/svg/icons/Menu/success_placeholder.svg"))
    const { values, submited } = e
    if (submited) {
      dispatch(createCategoryItem(values))
    }
  }

  return (
    <>
      <TabsInputComponent
        isEditTabs={false}
        steps={stepsTitle}
        stepDescription={stepsDescription}
        formInitialValues={initialValue}
        formId="createBenih"
        innerRef={formRef}
        componentTab={createEditFlow}
        validationSchema={ValidationScheme}
        onSubmitForm={onSubmitForm}
      />
    </>
  )
}
