import React from "react"
import { CardDetail } from "../../../../component"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import { Card } from "react-bootstrap"

export const DetailUser = ({ data, descriptionLeft, flatIconAndText2Rows, descriptionRight }) => {
  return (
    <>
      <RowModule>
        {data?.status?.toUpperCase() === "INACTIVE" && (
          <RowModule>
            <Card style={{ background: "#F8F8F8" }}>
              <CardHeader title={"Status Change Reason"} className="mb-0 pb-2 bg-gray-card" />
              <CardBody>{data?.account_deactivation_reason}</CardBody>
            </Card>
          </RowModule>
        )}
      </RowModule>
      <RowModule>
        <CardDetail
          titleCardDetail={data != null ? data.account_name : "-"}
          descriptionLeft={descriptionLeft}
          descriptionRight={descriptionRight}
          flatIconAndText2Rows={flatIconAndText2Rows}
        />
      </RowModule>
    </>
  )
}
