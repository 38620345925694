import { loadUserLogin } from "../../../service/userManagementAction.js"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { useLocation } from "react-router"
import { useUrlParam } from "../../../hooks/useUrlParams.jsx"
import { useDispatch, useSelector } from "react-redux"
import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getListUser } from "../../../../redux/actions/MacAction.jsx"
import { getUrlParsingPageFilter } from "../../../../config/endpointCollection.jsx"
import TableView, {
  formatterRowComponent,
  initialFilterTable
} from "../../../../component/atoms/Tabel/index.jsx"
import { EmptyCard, Search, TableSkeleton } from "../../../../component/index.jsx"
import { Card } from "react-bootstrap"
import { CardBody } from "../../../../_metronic/_partials/controls/index.js"
import { PRIVILEGE_ENUM } from "../../../../config/privilegeCollection.jsx"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { linkWithCtrl } from "../../../helpers/Utils.js"

export const ListUser = ({ history }) => {
  const privileges = loadUserLogin().privilege
  const subHeader = useSubheader()
  const location = useLocation()
  const urlParams = useUrlParam()
  const dispatch = useDispatch()
  const { General, Mac } = useSelector((state) => state)

  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [queryStringObj, setQueryStringObj] = useState(undefined)

  const [search, setSearch] = useState({
    keyword: ""
  })

  const { t } = useTranslation()

  useEffect(() => {
    subHeader.setTitle(t("menu_aside.mac_user"))
    subHeader.setButton(false)
  }, [])

  useEffect(() => {
    const result = location?.search
    setQueryStringObj(urlParams.getUrlParamsObj)
    dispatch(getListUser(result))
  }, [location.search, urlParams.getUrlParamsObj])

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...param,
          sort: param.sortField + "," + param.sortOrder,
          keyword: search.keyword
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...param,
          keyword: search.keyword
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, search]
  )

  useEffect(() => {
    if (queryStringObj?.keyword) {
      setIsActiveSearch(true)
      setSearch({ keyword: queryStringObj?.keyword })
    }
  }, [queryStringObj])

  const searchFunc = (values) => {
    setSearch({ keyword: values.keyword })
    setIsActiveSearch(true)
    let param = {
      ...initialFilterTable,
      keyword: values.keyword
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }
  const handleResetSearch = () => {
    let param = {
      ...initialFilterTable,
      keyword: ""
    }
    setSearch({ keyword: "" })
    setIsActiveSearch(false)
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  const uiCode = (e) => {
    return (
      <div>
        <div className="text-dark font-weight-bolder mb-1 font-size-lg">{e?.code ?? "-"}</div>
      </div>
    )
  }
  const uiProfile = (e) => {
    return (
      <div>
        <div className="text-dark font-weight-bolder mb-1 font-size-lg">
          {e?.user_merchant_name ?? "-"}
        </div>
        <div className=" mb-1 font-size-lg">{e?.phone_number ?? "-"}</div>
      </div>
    )
  }
  const uiAddress = (e) => {
    return (
      <div>
        <div className="font-size-lg">{e?.full_address ?? "-"}</div>
      </div>
    )
  }

  const columns = [
    {
      dataField: "code",
      text: "ID PENGGUNA",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiCode
      }
    },
    {
      dataField: "name",
      text: "NAMA PENGGUNA",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiProfile
      }
    },
    {
      dataField: "address",
      text: "ALAMAT",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAddress
      }
    }
  ]

  return (
    <div>
      <div className={"filter-container"}>
        <div className="d-flex flex-wrap justify-content-between align-items-center mb-10">
          <Search
            isActiveSearch={isActiveSearch}
            placeholder={"Masukkan nama pengguna"}
            onSubmitForm={searchFunc}
            initialValues={search}
            name={"keyword"}
            trigerReset={handleResetSearch}
          />
        </div>
      </div>

      <Card>
        <CardBody>
          {General.loading ? (
            <TableSkeleton column={3} row={10} />
          ) : Mac?.pageData?.length === 0 ? (
            <EmptyCard
              body={
                isActiveSearch
                  ? t("messages.response.data_not_found")
                  : t("messages.response.empty_state")
              }
            />
          ) : (
            ""
          )}

          <TableView
            callbackAfterPageChange={changePage}
            columnProperties={columns}
            dataTable={Mac?.pageData ?? []}
            currentPage={Mac?.page?.currentPage ?? 0}
            currentElement={Mac?.page?.currentElement ?? 10}
            totalCount={Mac?.page?.totalCount ?? 0}
            loadingGetData={General.loading}
            rowClasses={() => {
              return "cursor-pointer"
            }}
            rowEvents={{
              onClick: (e, row) => {
                if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_ORDER)) {
                  const url = RoutesConstants.MAC.USER_DETAIL(row?.id)
                  if (e.ctrlKey) {
                    linkWithCtrl(url)
                  } else {
                    history.push(url)
                  }
                }
              }
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}
