import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import { Card, CardBody, CardHeader } from "../../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { SelectInput, TextInput } from "../../../../../component"
import { getPlantForm, getPlantType } from "../../../../../redux/actions/MasterDataAction"
import { handleSpace } from "../../../../helpers/TextHelper"

export const FormPesticide = ({ isReadOnly, setIsValid }) => {
  const dispatch = useDispatch()
  const { values: formValues } = useFormikContext()
  const [statePlantType, setStatePlantType] = useState()
  const [statePlantForm, setStatePlantForm] = useState()
  const { MasterData } = useSelector(state => state)
  const [plantType, setPlantType] = useState(formValues["plant_type_id"])
  const [plantForm, setPlantForm] = useState(formValues["shape_enum"])

  const { values } = useFormikContext()

  useEffect(() => {
    if (values?.plant_type_id && values?.shape_enum && values?.category_name) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [values])

  useEffect(() => {
    dispatch(getPlantType())
    dispatch(getPlantForm())
  }, [])

  useEffect(() => {
    if (MasterData.plantType) {
      setStatePlantType(MasterData.plantType.map(value => ({ label: value.name, value: value.id })))
    }
  }, [MasterData.plantType])

  useEffect(() => {
    if (MasterData.plantForm) {
      setStatePlantForm(
        MasterData.plantForm.map(value => ({ label: value.enum_value, value: value.enum_name }))
      )
    }
  }, [MasterData.plantForm])

  useEffect(() => {
    if (formValues.hasOwnProperty("plant_type_id")) {
      setPlantType(formValues["plant_type_id"])
    }
  }, [formValues["plant_type_id"]])

  useEffect(() => {
    if (formValues.hasOwnProperty("shape_enum")) {
      setPlantForm(formValues["shape_enum"])
    }
  }, [formValues["shape_enum"]])

  const onChangePlantType = e => {
    setPlantType(e)
  }

  const onChangePlantForm = e => {
    setPlantForm(e)
  }

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardHeader title="INFORMASI PESTISIDA" />
        <CardBody>
          <RowModule>
            <SelectInput
              name="plant_type_id"
              onChange={onChangePlantType}
              editable={!isReadOnly}
              label="Tipe Tanaman"
              placeholder="Silahkan pilih satu"
              options={statePlantType}
              withoutFeedback={false}
              clearNow={true}
              value={plantType}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name="shape_enum"
              onChange={onChangePlantForm}
              editable={!isReadOnly}
              label="Bentuk Pestisida"
              placeholder="Silahkan pilih satu"
              options={statePlantForm}
              withoutFeedback={false}
              clearNow={true}
              value={plantForm}
            />
          </RowModule>
          <RowModule>
            <TextInput
              onKeyDown={handleSpace}
              editable={!isReadOnly}
              label="Nama Varian"
              name="category_name"
              placeholder="Tambahkan nama varian"
              type={"text"}
            />
          </RowModule>
        </CardBody>
      </Card>
    </>
  )
}
