import { Form, Formik, useFormik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { Button, Card } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { RadioButtonGroup, SelectInput } from "../../../../component"
import { Popup, PopupQuestion } from "../../../../component/atoms/Popup"
import {
  assignFo,
  assignFoBulk,
  getListCombobox
} from "../../../../redux/actions/FieldOfficerAction"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { useSubheader } from "../../../../_metronic/layout"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { MitraList } from "./MitraList"
import { CardBody } from "../../../../_metronic/_partials/controls/index.js"

export const TabAssignMitra = () => {
  const dispatch = useDispatch()
  const { FieldOfficer, General } = useSelector((state) => state)
  const subHeader = useSubheader()

  const formAssignRef = useRef()
  const formAssign = useFormik({
    innerRef: formAssignRef,
    enableReinitialize: true,
    initialValues: {
      mode: "single",
      fo: null,
      mitra: null,
      mitras: [],
      search: null
    },
    onSubmit: (values) => {
      console.log(values)
    }
  })
  const [isBulk, setIsBulk] = useState(false)
  const [showPopupError, setShowPopupError] = useState(false)
  const [showPopupSuccess, setShowPopupSuccess] = useState(false)
  const [showPopupConfirm, setShowPopupConfirm] = useState(false)
  const [selectedMitras, setSelectedMitras] = useState([])

  useEffect(() => {
    dispatch(getListCombobox())
    let _title = "Assign Mitra"
    subHeader.setTitle(_title)
  }, [])

  useEffect(() => {
    setIsBulk(formAssign.values.mode.toLowerCase() === "bulk")
  }, [formAssign.values])

  const title = (title) => {
    return <div className="font-weight-bolder mt-10 mb-3">{title}</div>
  }

  const findFo = (foId) => {
    return FieldOfficer.combobox?.find((e) => e?.id === foId)
  }

  useEffect(() => {
    if (General.isFinishUpdateOrDelete) {
      setShowPopupConfirm(false)
      swal({
        text: "FO berhasil diassign ke Mitra",
        icon: toAbsoluteUrl("/media/svg/icons/Menu/Ilustrasi-success.svg")
      })
    }
  }, [General.isFinishUpdateOrDelete])

  const submitAssign = () => {
    if (isBulk) {
      const mitraId = []
      selectedMitras?.map((e, idx) => {
        mitraId[idx] = e?.mitra_id
      })
      dispatch(assignFoBulk(mitraId, formAssign?.values?.fo))
    } else {
      dispatch(assignFo(selectedMitras[0]?.mitra_id, formAssign?.values?.fo))
    }
  }
  return (
    <>
      {/* Popup Error */}
      <Popup
        type="error"
        body={<span>FO tidak boleh kosong!</span>}
        centered={true}
        persistent={true}
        show={showPopupError}
        onClick={() => {
          setShowPopupError(false)
        }}
      />
      {/* Popup Success */}
      <Popup
        type="success"
        body={<span>Assign Mitra berhasil.</span>}
        centered={true}
        persistent={true}
        show={showPopupSuccess}
        onClick={() => {
          setShowPopupSuccess(false)
        }}
      />
      {/* Popup Confirm */}
      <PopupQuestion
        show={showPopupConfirm}
        onOk={submitAssign}
        textOk="Submit"
        onCancel={() => {
          setShowPopupConfirm(false)
        }}
        title="KONFIRMASI"
        bodyCustom={
          <>
            <div>Pastikan data field officer dan mitra sudah sesuai.</div>
            <div className="mt-3">
              <table>
                <thead>
                  <tr>
                    <th>Field Officer</th>
                    <th className="px-5"> ---&gt; </th>
                    <th>Nama Mitra</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedMitras.map((e, i) => (
                    <tr key={i}>
                      <td>{i === 0 ? findFo(formAssign.values.fo)?.name : ""}</td>
                      <td></td>
                      <td>{e?.mitra_name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        }
      />
      <Card>
        <CardBody>
          <Formik initialValues={formAssign.initialValues} onSubmit={formAssign.handleSubmit}>
            <Form>
              <div>
                {title("1. Cara Assign")}
                <RowModule withoutSeparator={true} customColumnCss=" col-lg-6 col-md-6 col-sm-6">
                  <RadioButtonGroup
                    label="Per Mitra"
                    name="mode"
                    value="single"
                    editable={true}
                    withoutFeedback={true}
                    checked={formAssign?.values?.mode === "single"}
                    chooseAtBack={true}
                    onClick={(v) => {
                      if (v?.target?.checked) {
                        formAssign.setFieldValue("mode", "single")
                      }
                    }}
                  />
                  <RadioButtonGroup
                    label="Bulk"
                    name="mode"
                    value="bulk"
                    editable={true}
                    withoutFeedback={true}
                    checked={formAssign?.values?.mode === "bulk"}
                    chooseAtBack={true}
                    onClick={(v) => {
                      if (v?.target?.checked) {
                        formAssign.setFieldValue("mode", "bulk")
                      }
                    }}
                  />
                </RowModule>
              </div>
              <div>
                {title("2. Nama FO")}
                <RowModule withoutSeparator={true} customColumnCss=" col-lg-12 col-md-12 col-sm-12">
                  <SelectInput
                    name="fo"
                    editable={true}
                    label=""
                    placeholder="Pilih salah satu"
                    value={formAssign.values.fo}
                    options={(FieldOfficer.combobox ?? []).map((e) => ({
                      label: e?.name,
                      value: e?.id
                    }))}
                    withoutFeedback={true}
                    onChange={(v) => {
                      if (!v) {
                        formAssign.setFieldValue("fo", null)
                      } else {
                        formAssign.setFieldValue("fo", v)
                      }
                    }}
                  />
                </RowModule>
              </div>
              {formAssign.values.fo ? (
                <div>
                  {title("3. Nama Mitra")}
                  <RowModule withoutSeparator={true}>
                    <MitraList
                      key={isBulk ? "bulk" : "single"}
                      isBulk={isBulk}
                      fo={formAssign?.values?.fo}
                      onSelect={
                        isBulk
                          ? (rows) => {
                              if (formAssign.values.fo && rows.length > 0) {
                                setSelectedMitras(() => rows)
                              }
                            }
                          : null
                      }
                      onClickRow={
                        isBulk
                          ? null
                          : (row) => {
                              if (!formAssign.values.fo) {
                                setShowPopupError(true)
                              } else {
                                const rows = [row]
                                setSelectedMitras(() => rows)
                                setShowPopupConfirm(true)
                              }
                            }
                      }
                    />
                  </RowModule>
                </div>
              ) : null}
              <br />
              {isBulk && formAssign.values.fo ? (
                <Button variant="primary" onClick={() => setShowPopupConfirm(true)}>
                  SUBMIT
                </Button>
              ) : null}
            </Form>
          </Formik>
        </CardBody>
      </Card>
    </>
  )
}
