import React, { forwardRef, useEffect, useState } from "react"
import SVG from "react-inlinesvg"
import { useFormikContext } from "formik"
import { useDispatch, useSelector } from "react-redux"
import DatePicker from "react-datepicker"

import { TableDetailListApproveBulkPopup } from "../../TableDetailListApproveBulkPopup.jsx"
import { Popup } from "../../../../../component/atoms/Popup/index.jsx"
import { renderLabelRequired } from "../../../../helpers/FormikHelper.js"
import { timeStampToDate } from "../../../../helpers/DateHelper"
import { RowModule } from "../../../../../component/atoms/RowModule/RowModule.jsx"
import { SelectInput } from "../../../../../component/index.jsx"
import { ICON_CONSTANS } from "../../../../../constants/ImageConstants.js"
import { convertM2ToHectare, toThousandConvert } from "../../../../helpers/TextHelper.js"
import { CalenderSvgIcon } from "../../../../../component/atoms/Icons/SVG/calender.jsx"
import {
  getCalenderStatus,
  SavedGlobalListScheduleOrder
} from "../../../../../redux/actions/OrderAction.jsx"
import { useTranslation } from "react-i18next"

export const FormBulkSchedule = ({ data, listSelected, dataTeamSelected }) => {
  const { t } = useTranslation()
  const [teamSelected, setTeamSelected] = useState("")
  const [selectedDate, setSelectedDate] = useState([])
  const [calendarStatus, setCalendarStatus] = useState([])
  const [selectedDate1Before, setSelectedDate1Before] = useState(undefined)
  const [selectedMonth, setSelectedMonth] = useState(new Date())
  const [countInputDate, setCountInputDate] = useState(1)
  const [team, setTeam] = useState([])
  const [showPopupDetail, setShowPopupDetail] = useState(false)

  const { values: formValues } = useFormikContext()
  const dispatch = useDispatch()
  const { Order } = useSelector((state) => state)
  const CustomSelectDatePicker = forwardRef(({ value, onClick }, ref) => (
    <div className={"date-picker-custom-order"}>
      <input
        required={true}
        className="form-control"
        onClick={onClick}
        ref={ref}
        name={"template_date"}
        value={value}
        placeholder={"Pilih tanggal penyemprotan"}></input>
      <CalenderSvgIcon />
    </div>
  ))

  useEffect(() => {
    setTeamSelected(formValues?.team_id)
  }, [formValues])

  useEffect(() => {
    if (selectedDate?.length) {
      dispatch(SavedGlobalListScheduleOrder(selectedDate))
    }
  }, [selectedDate])

  useEffect(() => {
    if (selectedDate.length === 0) {
      setSelectedDate(Order?.listScheduleForm)
    }
  }, [Order.listScheduleForm])

  useEffect(() => {
    listSelected(selectedDate)
  }, [selectedDate])

  useEffect(() => {
    if (selectedDate.length > 0) {
      setCountInputDate(data.max_spraying_count)
    }
  }, [selectedDate])
  useEffect(() => {
    if (teamSelected) {
      let newStartDate = selectedMonth
      newStartDate.setDate(1)
      let newEndDate = new Date(newStartDate)
      newEndDate.setDate(1)
      newEndDate.setMonth(newEndDate.getMonth() + 1)
    }
  }, [selectedDate, teamSelected, selectedMonth])

  useEffect(() => {
    if (teamSelected) {
      let newStartDate = selectedMonth
      newStartDate.setDate(1)
      let newEndDate = new Date(newStartDate)
      newEndDate.setDate(1)
      newEndDate.setMonth(newEndDate.getMonth() + 1)
      dispatch(
        getCalenderStatus({
          start_date: timeStampToDate(newStartDate, "yyyy-MM-dd"),
          end_date: timeStampToDate(newEndDate, "yyyy-MM-dd"),
          team_id_list: teamSelected
        })
      )
      setTeamSelected(teamSelected)
    }
  }, [formValues, selectedDate, teamSelected, selectedMonth])

  useEffect(() => {
    if (Order.calendarStatus.length > 0) {
      setCalendarStatus(Order.calendarStatus.map((item) => timeStampToDate(item, "dd-MM-yyyy")))
    }
  }, [Order.calendarStatus])

  useEffect(() => {
    if (selectedDate) {
      formValues["spraying_date_list"] = selectedDate
    }
  }, [formValues, selectedDate])

  useEffect(() => {
    if (teamSelected) {
      if (dataTeamSelected) {
        const dataSelectedTeam = team.find((o) => o.value === teamSelected)

        dataTeamSelected({
          team: dataSelectedTeam?.label
        })
      }
      formValues["team_id"] = teamSelected
    }
  }, [teamSelected, team, formValues])
  useEffect(() => {
    if (countInputDate > 1) {
      if (selectedDate[0]) {
        if (selectedDate[0] != selectedDate1Before) {
          const newSelectedDate = [...selectedDate]
          Array.from({ length: countInputDate }).map((item, index) => {
            let date = new Date(newSelectedDate[0])
            newSelectedDate[index] = new Date(date.setDate(date.getDate() + index * 14))
          })
          setSelectedDate(newSelectedDate)
          if (newSelectedDate[0]) {
            setSelectedDate1Before(newSelectedDate[0])
          }
        }
      }
    }
  }, [selectedDate[0], countInputDate])

  const datePicker = (index) => {
    return (
      <div className={"mb-8"}>
        <label>
          Tanggal Penyemprotan
          {data?.max_spraying_count > 1 ? " ke-" + (index + 1) : ""}{" "}
          {index === 0 ? <span style={{ color: "red" }}>*</span> : ""}
        </label>
        <DatePicker
          selected={selectedDate[index]}
          onChange={(date) => {
            const newArray = [...selectedDate]
            newArray[index] = date
            setSelectedDate(newArray)
          }}
          monthsShown={1}
          dayClassName={(date) =>
            calendarStatus.indexOf(timeStampToDate(date, "dd-MM-yyyy")) > -1
              ? "haveSchedule"
              : undefined
          }
          onMonthChange={(date) => setSelectedMonth(date)}
          customInput={<CustomSelectDatePicker index={index} />}
          dateFormat="dd MMMM yyyy"
        />
      </div>
    )
  }

  useEffect(() => {
    if (Order.droneTeam) {
      setTeam(
        Order.droneTeam.map((value) => ({
          label: value.name + " - " + value.member,
          value: value.id
        }))
      )
    }
  }, [Order])

  const dataListCardHeader = [
    {
      title: "Jumlah Lahan",
      value: `${data?.order_list ? data?.order_list?.length : "-"} Lahan`,
      icon: ICON_CONSTANS.IC_ROUNDED_FIELD
    },
    {
      title: "Total Luas Pengerjaan",
      value: `${data?.total_land_area ? convertM2ToHectare(data?.total_land_area) : "-"} Ha (${
        data?.total_land_area ? toThousandConvert(data?.total_land_area) : "-"
      } m2)`,
      icon: ICON_CONSTANS.IC_ROUNDED_AREA
    }
  ]

  return (
    <>
      <Popup
        contentClassName={"modal-main modal_detail_team_customs"}
        show={showPopupDetail}
        titleCustom={<h1>List Penjadwalan Lahan</h1>}
        closeButton={true}
        showButton={false}
        onClick={() => setShowPopupDetail(false)}
        body={<TableDetailListApproveBulkPopup data={data} />}
        size={"lg"}
        onCancel={() => {
          setShowPopupDetail(false)
        }}
      />
      <div className={"px-21"}>
        <div className={"border p-10 mt-10 d-flex align-items-center justify-content-between"}>
          {dataListCardHeader.map((item, i) => (
            <div key={i} className={"d-flex align-items-center  m-auto"} style={{ gap: 8 }}>
              <SVG src={item?.icon} />
              <div>
                <div>{item.title}</div>
                <div className={"font-weight-bolder"}>{item.value}</div>
              </div>
            </div>
          ))}
          <div className={" m-auto"}>
            <div
              onClick={() => setShowPopupDetail(true)}
              className={"text-success font-weight-bolder  underline-hover cursor-pointer"}
              style={{ fontSize: 16 }}>
              Lihat Detail
            </div>
          </div>
        </div>

        <div className={"mt-10"}>
          <h4 className={"my-10"}>Tim Drone</h4>
          <RowModule customColumnCss="mb-6 col-12 col-md-12">
            <SelectInput
              name="team_id"
              label={renderLabelRequired(t("label.team_name"))}
              placeholder={t("placeholder.select_team_drone")}
              options={team}
              withoutFeedback={false}
              clearNow={true}
              value={teamSelected}
              onChange={(e) => setTeamSelected(e)}
            />
          </RowModule>
          <h4 className="mb-6">Penjadwalan Aktivitas Penyemprotan</h4>
          {Array.from({ length: countInputDate }).map((item, index) => datePicker(index))}
        </div>
      </div>
    </>
  )
}
