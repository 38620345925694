import { Card, CardBody } from "../../../../_metronic/_partials/controls"
import React, { forwardRef, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useFormikContext } from "formik"
import { getCalenderStatus, resetCalenderStatus } from "../../../../redux/actions/OrderAction"
import { timeStampToDate } from "../../../helpers/DateHelper"
import { CalenderSvgIcon } from "../../../../component/atoms/Icons/SVG/calender"
import DatePicker from "react-datepicker"
import { ApprovalDetailHeader } from "./ApprovalDetailHeader"
import { ApprovalRegionalAndTeamDrone } from "./ApprovalRegionalAndTeamDrone"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"
import { ICON_CONSTANS } from "../../../../constants/ImageConstants"
import { ORDER_STATUS_ENUM } from "../../../../constants/StringConstant"
import { Badge } from "react-bootstrap"
import { Skeleton } from "@material-ui/lab"

export const ApprovalForm = ({
  status,
  setIsValid,
  initialValue,
  id,
  btnSubmit,
  btnCancel,
  data,
  detailTeam
}) => {
  const dispatch = useDispatch()
  const { values: formValues } = useFormikContext()
  const { General, Order } = useSelector((state) => state)
  const [teamSelected, setTeamSelected] = useState([undefined])
  const [selectedDate, setSelectedDate] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(new Date())
  const [selectedDate1Before, setSelectedDate1Before] = useState("")
  const [calendarStatus, setCalendarStatus] = useState([])
  const [spraying, setSpraying] = useState([])
  const [indexPending, setIndexPending] = useState(null)
  const [dataHeader, setDataHeader] = useState([])

  const CustomSelectDatePicker = forwardRef(({ value, onClick }, ref) => (
    <div className={"date-picker-custom-order"}>
      <input
        className="form-control"
        onClick={onClick}
        ref={ref}
        value={value}
        placeholder={"Pilih tanggal penyemprotan"}></input>
      <CalenderSvgIcon />
    </div>
  ))

  useEffect(() => {
    if (teamSelected && teamSelected.length) {
      let newStartDate = selectedMonth
      newStartDate.setDate(1)
      let newEndDate = new Date(newStartDate)
      newEndDate.setDate(1)
      newEndDate.setMonth(newEndDate.getMonth() + 1)
      dispatch(
        getCalenderStatus({
          start_date: timeStampToDate(newStartDate, "yyyy-MM-dd"),
          end_date: timeStampToDate(newEndDate, "yyyy-MM-dd"),
          team_id_list: teamSelected
        })
      )
    }
    return () => {
      dispatch(resetCalenderStatus())
    }
  }, [formValues, selectedDate, teamSelected, selectedMonth])

  useEffect(() => {
    if (Order.calendarStatus.length > 0) {
      setCalendarStatus(Order.calendarStatus.map((item) => timeStampToDate(item, "dd-MM-yyyy")))
    }
  }, [Order.calendarStatus])

  useEffect(() => {
    if (
      status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED ||
      status.toUpperCase() === ORDER_STATUS_ENUM.RESCHEDULED
    ) {
      let newSpraying = []
      for (let i = 0; i < 1; i++) {
        newSpraying.push(i)
      }
      setSpraying(newSpraying)
    } else {
      if (data?.spraying_count) {
        let newSraying = []
        for (let i = 0; i < data?.spraying_count; i++) {
          newSraying.push(i)
        }
        setSpraying(newSraying)
      }
    }
  }, [data])

  useEffect(() => {
    if (selectedDate) {
      formValues["spraying_date_list"] = selectedDate
    }
    if (
      formValues["team_id"] &&
      !teamSelected.includes(undefined) &&
      (indexPending === null || formValues["spraying_date_list"][indexPending])
    ) {
      if (status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED) {
        if (
          JSON.stringify(detailTeam) === JSON.stringify(formValues["team_id"]) &&
          timeStampToDate(data?.previous_order_date, "dd-MM-yyyy") ===
            timeStampToDate(formValues["spraying_date_list"][0], "dd-MM-yyyy")
        ) {
          setIsValid(true)
        } else setIsValid(false)
      } else {
        setIsValid(false)
      }
    } else {
      setIsValid(true)
    }
  }, [formValues, selectedDate, status, teamSelected])

  // autofield + 14
  useEffect(() => {
    if (selectedDate[0]) {
      if (selectedDate[0] != selectedDate1Before) {
        const newSelectedDate = [...selectedDate]
        spraying.map((item, index) => {
          let date = new Date(newSelectedDate[0])
          newSelectedDate[index] = new Date(date.setDate(date.getDate() + index * 14))
        })
        setSelectedDate(newSelectedDate)
        setSelectedDate1Before(newSelectedDate[0])
      }
    }
  }, [selectedDate[0]])

  useEffect(() => {
    if (
      status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED ||
      status.toUpperCase() === ORDER_STATUS_ENUM.RESCHEDULED
    ) {
      if (data?.order_list) {
        const dataDate = data?.order_list.map((item) => {
          return new Date(item.activity_date)
        })
        setSelectedDate(dataDate)
      }
    }
  }, [data])

  useEffect(() => {
    console.log("init", initialValue)
    if (
      status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED ||
      status.toUpperCase() === ORDER_STATUS_ENUM.RESCHEDULED
    ) {
      if (initialValue.team_id) {
        setTeamSelected(initialValue.team_id)
      }
    }
  }, [initialValue, status])

  const datePicker = (index) => {
    const orderData = data.order_list[index]

    if (
      orderData.status === ORDER_STATUS_ENUM.PENDING ||
      orderData.status === ORDER_STATUS_ENUM.RESCHEDULED
    ) {
      if (indexPending === null) {
        setIndexPending(index)
      }
      return (
        <div className={"mb-8"}>
          {General.loading ? (
            <Skeleton animation="wave" variant={"rect"} height={32} width={250} />
          ) : status === ORDER_STATUS_ENUM.RESCHEDULED ? (
            <label>
              Tanggal Penyemprotan {index === 0 ? <span style={{ color: "red" }}>*</span> : ""}
            </label>
          ) : (
            <>
              {status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED || data?.is_additional_order ? (
                <label>
                  Tanggal Penyemprotan <span style={{ color: "red" }}>*</span>
                </label>
              ) : (
                <label>
                  Tanggal Penyemprotan ke {index + 1}{" "}
                  {index === 0 ? <span style={{ color: "red" }}>*</span> : ""}
                </label>
              )}
            </>
          )}

          {General.loading ? (
            <Skeleton animation="wave" variant={"rect"} height={40} />
          ) : (
            <DatePicker
              selected={selectedDate[index]}
              onChange={(date) => {
                const newArray = [...selectedDate]
                newArray[index] = date
                setSelectedDate(newArray)
              }}
              monthsShown={2}
              dayClassName={(date) =>
                calendarStatus.indexOf(timeStampToDate(date, "dd-MM-yyyy")) > -1
                  ? "haveSchedule"
                  : undefined
              }
              onMonthChange={(date) => setSelectedMonth(date)}
              customInput={<CustomSelectDatePicker index={index} />}
              dateFormat="dd MMMM yyyy"
              // minDate={
              //   status.toUpperCase() === OrderStatus.APPROVED ||
              //   status.toUpperCase() === OrderStatus.RESCHEDULED
              //     ? getMinSprayingBefore()
              //     : index === 0
              //     ? new Date()
              //     : getAutoSelecetDate(selectedDate[index - 1])
              // }
            />
          )}
          {status.toUpperCase() === ORDER_STATUS_ENUM.APPROVED ||
          status.toUpperCase() === ORDER_STATUS_ENUM.RESCHEDULED ? (
            <div className="mt-2 d-flex align-items-center">
              <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_INFO_REGION)} />
              <span className={"ml-2 mb-0"}>
                Tanggal Pengajuan Awal: {timeStampToDate(data?.previous_order_date, "dd MMMM yyyy")}
              </span>
            </div>
          ) : data?.is_additional_order ? (
            <div className="mt-2 d-flex align-items-center">
              <SVG src={toAbsoluteUrl(ICON_CONSTANS.IC_INFO_REGION)} />
              <span className={"ml-2 mb-0"}>
                Tanggal Pengajuan Aktivitas:{" "}
                {timeStampToDate(data?.activity_submission_date, "dd MMMM yyyy")}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      )
    } else {
      return (
        <div>
          <label>
            {General.loading ? (
              <Skeleton animation="wave" variant={"rect"} height={32} width={250} />
            ) : (
              "Tanggal Penyemprotan "
            )}
            {status === ORDER_STATUS_ENUM.RESCHEDULED ? (
              <>{` ke  ${orderData?.seq.toString()} `}</>
            ) : (
              <>{data?.spraying_count > 1 ? ` ke  ${index + 1} ` : ""}</>
            )}
            {index === 0 ? <span style={{ color: "red" }}>*</span> : ""}
          </label>
          <div className={"d-flex"} style={{ gap: 10, marginBottom: 10 }}>
            {orderData.status !== ORDER_STATUS_ENUM.CANCELED && (
              <h3>
                {" " + orderData.activity_date
                  ? timeStampToDate(orderData.activity_date, "dd-MM-yyyy")
                  : "-"}{" "}
              </h3>
            )}

            <div>
              <Badge variant="info">
                <div style={{ color: "#fff" }}>{orderData.status}</div>
              </Badge>
            </div>
          </div>
        </div>
      )
    }
  }

  const detailHeader = [
    {
      title: "Jenis Tanaman",
      value: data.plant_type
    },
    {
      title: "Tanggal Tanam",
      value: timeStampToDate(data.planting_date, "dd MMMM yyyy")
    }
  ]

  const dataHeaderPrePlanting = [
    {
      title: "Alasan Pengajuan",
      value: data?.reason ? data?.reason : "-"
    }
  ]

  useEffect(() => {
    if (data?.is_before_planting) {
      setDataHeader(dataHeaderPrePlanting)
    } else {
      setDataHeader(detailHeader)
    }
  }, [data, id])

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <ApprovalDetailHeader
            area_name={data.area_name}
            activity_name={data.activity_name}
            title={"Overview Data Lahan"}
            detailHeader={dataHeader}
          />
          <ApprovalRegionalAndTeamDrone
            title={"Tim Drone"}
            status={status}
            teamSelected={teamSelected}
            setTeamSelected={setTeamSelected}
          />
          <h4 className="mb-6">
            {General.loading ? (
              <Skeleton animation="wave" variant={"rect"} height={32} width={250} />
            ) : (
              "Penjadwalan Aktivitas Penyemprotan"
            )}
          </h4>
          {data.order_list &&
            data.order_list.length &&
            data?.spraying_count &&
            Array.from({ length: data?.spraying_count }).map((item, index) => datePicker(index))}

          <div className={"d-flex justify-content-end align-items-center"}>
            {btnCancel}
            {btnSubmit}
          </div>
        </CardBody>
      </Card>
    </>
  )
}
