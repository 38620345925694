import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { ID_CONTENT } from "../../constants/IdLanguageConstants"
import { EN_CONTENT } from "../../constants/EnLanguageConstants"

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    lng: "id",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: EN_CONTENT
      },
      id: {
        translation: ID_CONTENT
      }
    }
  })

export default i18n

export const lngs = {
  id: { nativeName: "Id" },
  en: { nativeName: "En" }
}
