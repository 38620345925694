import { MASTER, ORDER } from "../../constants/InitTypeConstants"
import {
  requestDownloadXls,
  requestGet,
  requestPost,
  requestPostMultipart,
  requestPut
} from "../../config"
import { ORDER_URL } from "../../constants/UrlConstants"
import { GeneralSlice } from "../reducers/General"
import { OrderSlice } from "../reducers/Order"
import { getUrlParsingCalenderStatus } from "../../config/endpointCollection"

const { actions: general } = GeneralSlice
const { actions: order } = OrderSlice

function setEmptyPageDataBeforeFetching(dispatch) {
  dispatch(
    order.setPageData({
      pageData: [],
      page: {
        totalCount: 0,
        currentElement: 0,
        currentPage: 0
      }
    })
  )
}

export const CancelOrderActions = (id, data) => {
  return async (dispatch) => {
    general.setLoadingGlobal({ loading: true, actionInitType: ORDER.CANCEL_ORDER })
    await requestPut(ORDER_URL.CANCEL_ORDER + id, "", data)
      .then(() => {
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: ORDER.CANCEL_ORDER
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ORDER.CANCEL_ORDER
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const GetCancelEnumList = () => {
  return async (dispatch) => {
    await requestGet(ORDER_URL.CANCEL_ENUM_LIST)
      .then((res) => {
        dispatch(order.setCancelEnumList({ data: res.data }))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const RejectOrderPackage = (id, data, initType) => {
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: ORDER.APPROVE_ORDER }))
    try {
      let res = await requestPut(ORDER_URL.REJECT_ORDER_PACKAGE + id, "", data)
      dispatch(order.setMessage(res.data))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: initType
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: initType
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
export const ApproveRejectOrder = (id, data, initType) => {
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: ORDER.APPROVE_ORDER }))
    try {
      let res = await requestPut(ORDER_URL.APPROVED_REJECT_ORDER + id, "", data)
      dispatch(order.setMessage(res.data))
      dispatch(
        general.setLoadingGlobal({
          loading: false,
          actionInitType: initType
        })
      )
      dispatch(
        general.finishUpdateApi({
          isFinishUpdateOrDelete: true,
          actionInitType: initType
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const EditOrder = (id, data) => {
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: ORDER.APPROVE_ORDER }))
    await requestPut(ORDER_URL.EDIT_ORDER + id, "", data)
      .then(() => {
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: ORDER.APPROVE_ORDER
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ORDER.APPROVE_ORDER
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const RescheduleOrder = (id, data) => {
  return async (dispatch) => {
    dispatch(general.setLoadingGlobal({ loading: true, actionInitType: ORDER.APPROVE_ORDER }))
    await requestPut(ORDER_URL.RESCHEDULED_ORDER + id, "", data)
      .then(() => {
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: ORDER.APPROVE_ORDER
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ORDER.APPROVE_ORDER
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const GetRejectReasonList = () => {
  return async (dispatch) => {
    await requestGet(ORDER_URL.GET_REJECT_REASON_LIST)
      .then((res) => {
        dispatch(order.setReasonList({ data: res.data }))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const GetDetailOrder = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_DETAIL_ORDER
      })
    )
    dispatch(order.setData({ data: null }))
    await requestGet(ORDER_URL.GET_DETAIL_ORDER + id)
      .then((res) => {
        dispatch(order.setData({ data: res.data }))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.GET_DETAIL_ORDER
          })
        )
      })
      .catch((e) => {
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.GET_DETAIL_ORDER
          })
        )
        dispatch(general.setError(e))
      })
  }
}

export const GetDetailOrderPascaTanam = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_DETAIL_ORDER
      })
    )
    dispatch(order.setData({ data: null }))
    await requestGet(ORDER_URL.GET_DETAIL_ORDER_PASCA_PLANTING(id))
      .then((res) => {
        dispatch(order.setData({ data: res.data }))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.GET_DETAIL_ORDER
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export const getOrderListAll = (param) => {
  return async (dispatch) => {
    dispatch(general.setLoading({ loading: true, actionInitType: ORDER.GET_LIST_ALL }))
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(ORDER_URL.GET_LIST_ALL + param)
      dispatch(
        order.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_LIST_ALL
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getOrderListApproved = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_LIST_APPROVED
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(ORDER_URL.GET_LIST_APPROVED + param)
      dispatch(
        order.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_LIST_APPROVED
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getOrderListPending = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_LIST_PENDING
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(ORDER_URL.GET_LIST_PENDING + param)
      dispatch(
        order.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_LIST_PENDING
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getOrderListPendingPraTanam = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_LIST_PENDING
      })
    )
    dispatch(
      order.setPageData({
        pageData: [],
        page: {
          totalCount: 0,
          currentElement: 0,
          currentPage: 0
        }
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(ORDER_URL.GET_LIST_PENDING_PRA_TANAM + param)
      dispatch(
        order.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_LIST_PENDING
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getOrderListRescheduled = (param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_LIST_RESCHEDULED
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)
    try {
      let res = await requestGet(ORDER_URL.GET_LIST_RESCHEDULED + param)
      dispatch(
        order.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_LIST_RESCHEDULED
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getStatusForFilterOrder = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: ORDER.GET_STATUS
      })
    )
    try {
      let res = await requestGet(ORDER_URL.GET_STATUS_FILTER)
      dispatch(order.setStatus(res.data))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: ORDER.GET_STATUS
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDetailHeader = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_DETAIL_HEADER
      })
    )
    try {
      let res = await requestGet(ORDER_URL.GET_DETAIL_HEADER + id)
      dispatch(order.setDetail(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_DETAIL_HEADER
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}
export const getDetailHeaderApproval = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_DETAIL_HEADER
      })
    )
    try {
      let res = await requestGet(ORDER_URL.GET_DETAIL_HEADER_APPROVAL + id)
      dispatch(order.setDetail(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_DETAIL_HEADER
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const GetDetailHeaderEditOrder = (id) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_DETAIL_HEADER
      })
    )
    try {
      let res = await requestGet(ORDER_URL.GET_DETAIL_EDIT_ORDER + id)
      dispatch(order.setDetail(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_DETAIL_HEADER
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getRegionOrder = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_REGION
      })
    )
    try {
      let res = await requestGet(ORDER_URL.GET_REGION_ORDER)
      dispatch(order.setRegion(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: MASTER.GET_REGION
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const getDroneTeam = () => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_DRONE_TEAM
      })
    )
    try {
      let res = await requestGet(ORDER_URL.GET_DRONE_TEAM)
      dispatch(order.setDroneTeam(res.data))
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_DRONE_TEAM
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const resetCalenderStatus = () => {
  return async (dispatch) => {
    dispatch(order.setCalendarStatus({}))
  }
}

export const getCalenderStatus = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingCalenderStatus(param)
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: ORDER.GET_CALENDAR_STATUS
      })
    )
    try {
      let res = await requestGet(ORDER_URL.GET_CALENDAR_STATUS + urlData)
      dispatch(order.setCalendarStatus(res.data?.date_list))
      dispatch(
        general.setLoadingComponent({
          loading: false,
          actionInitType: ORDER.GET_CALENDAR_STATUS
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const approveBulkActions = (data) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.APPROVE_BULK_ORDER
      })
    )
    requestPut(ORDER_URL.APPROVE_BULK, "", data)
      .then(() => {
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ORDER.APPROVE_BULK_ORDER
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.APPROVE_BULK_ORDER
          })
        )
      })
      .catch((e) => {
        general.setLoading({
          loading: false,
          actionInitType: ORDER.APPROVE_BULK_ORDER
        })
        dispatch(general.setError(e))
      })
  }
}
export const approveBulkPrePlanting = (data) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.APPROVE_BULK_ORDER
      })
    )
    requestPut(ORDER_URL.APPROVE_BULK_PRE_PLANTING, "", data)
      .then(() => {
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ORDER.APPROVE_BULK_ORDER
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.APPROVE_BULK_ORDER
          })
        )
      })
      .catch((e) => {
        general.setLoading({
          loading: false,
          actionInitType: ORDER.APPROVE_BULK_ORDER
        })
        dispatch(general.setError(e))
      })
  }
}

export const resetListDataFormApproveBulk = () => {
  return async (dispatch) => {
    dispatch(order.setListDataInitFormApproveBulk(undefined))
  }
}

export const ResetListInitDataFormApproveBulk = () => {
  return async (dispatch) => {
    dispatch(order.setListDataInitFormApproveBulk(null))
  }
}

export const GetListInitDataFormApproveBulk = (data) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_LIST_INIT_FORM_APPROVE_BULK
      })
    )
    requestPost(ORDER_URL.GET_LIST_DATA_APPROVE_BULK, data)
      .then((res) => {
        dispatch(order.setListDataInitFormApproveBulk(res.data))
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_LIST_INIT_FORM_APPROVE_BULK
        })
      })
      .catch((e) => {
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_LIST_INIT_FORM_APPROVE_BULK
        })
        dispatch(general.setError(e))
      })
  }
}

export const GetListInitDataFormApproveBulkPreplanting = (data) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_LIST_INIT_FORM_APPROVE_BULK
      })
    )
    requestPost(ORDER_URL.GET_LIST_DATA_APPROVE_BULK_PRE_PLANTING, data)
      .then((res) => {
        dispatch(order.setListDataInitFormApproveBulk(res.data))
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_LIST_INIT_FORM_APPROVE_BULK
        })
      })
      .catch((e) => {
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_LIST_INIT_FORM_APPROVE_BULK
        })
        dispatch(general.setError(e))
      })
  }
}

export function GetListSo(param) {
  return async (dispatch) => {
    dispatch(general.setLoading({ loading: true, actionInitType: ORDER.GET_LIST_SO }))
    await requestGet(ORDER_URL.GET_LIST_SO + (param ?? ""))
      .then((res) => {
        dispatch(
          order.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.GET_LIST_SO
          })
        )
      })

      .catch((e) => {
        dispatch(general.setError(e))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.GET_LIST_SO
          })
        )
      })
  }
}

export function GetListSPK(param) {
  return async (dispatch) => {
    dispatch(general.setLoading({ loading: true, actionInitType: ORDER.GET_LIST_SO }))
    await requestGet(ORDER_URL.GET_LIST_SPK + (param ?? ""))
      .then((res) => {
        dispatch(
          order.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.GET_LIST_SO
          })
        )
      })

      .catch((e) => {
        dispatch(general.setError(e))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.GET_LIST_SO
          })
        )
      })
  }
}

export function uploadSoFileXls(file) {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.UPLOAD_SO_XLS
      })
    )
    const formData = new FormData()
    formData.append("file", file)
    await requestPostMultipart(ORDER_URL.UPLOAD_SO_XLS, formData)
      .then(() => {
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.UPLOAD_SO_XLS
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ORDER.CANCEL_ORDER
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.UPLOAD_SO_XLS
          })
        )
      })
  }
}

export function getDetailSpk(id) {
  return async function (dispatch) {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_DETAIL_SPK
      })
    )
    await requestGet(ORDER_URL.GET_DETAIL_SPK(id))
      .then((res) => {
        dispatch(order.getPage(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.GET_DETAIL_SPK
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_DETAIL_SPK
        })
      })
  }
}

export function getDetailSo(id) {
  return async function (dispatch) {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_DETAIL_SO
      })
    )
    await requestGet(ORDER_URL.GET_DETAIL_SO(id))
      .then((res) => {
        dispatch(order.getPage(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.GET_DETAIL_SO
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
        general.setLoading({
          loading: false,
          actionInitType: ORDER.GET_DETAIL_SO
        })
      })
  }
}

export function downloadSelectedSoXls({ body, fileName }) {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.DOWNLOAD_SO_XLS
      })
    )
    await requestDownloadXls({
      url: ORDER_URL.DOWNLOAD_SO_XLS,
      fileName: fileName,
      body: body
    })
      .then(() => {
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ORDER.DOWNLOAD_SO_XLS
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.DOWNLOAD_SO_XLS
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
        general.setLoading({
          loading: false,
          actionInitType: ORDER.DOWNLOAD_SO_XLS
        })
      })
  }
}

export const SavedGlobalListScheduleOrder = (data) => {
  return async (dispatch) => {
    dispatch(order.setListScheduleForm(data))
  }
}

export const resetTableOrder = () => {
  return async (dispatch) => {
    dispatch(
      order.setPageData({
        pageData: [],
        page: {
          totalCount: 0,
          currentElement: 0,
          currentPage: 0
        }
      })
    )
  }
}

export const getListPascaPlanting = (status, param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_LIST_PASCA_PLANTING
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)

    await requestGet(ORDER_URL.GET_LIST_PASCA_PLANTING(status) + param)
      .then((res) => {
        dispatch(
          order.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.GET_LIST_PASCA_PLANTING
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}
export const getListPrePlantingSchedule = (status, param) => {
  return async (dispatch) => {
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ORDER.GET_LIST_PRE_PLANTING
      })
    )
    setEmptyPageDataBeforeFetching(dispatch)

    await requestGet(ORDER_URL.GET_LIST_PRE_PLANTING(status) + param)
      .then((res) => {
        dispatch(
          order.setPageData({
            pageData: res.data,
            page: {
              totalCount: res.all_element,
              currentElement: res.element,
              currentPage: res.page
            }
          })
        )
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ORDER.GET_LIST_PRE_PLANTING
          })
        )
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}

export function getSummaryCardSprayingPascaPlanting() {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: ORDER.GET_SUMMARY_CARD_SPRAYING
      })
    )
    await requestGet(ORDER_URL.GET_SUMMARY_CARD_SPRAYING())
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: ORDER.GET_SUMMARY_CARD_SPRAYING
          })
        )
        dispatch(order.setSummary(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}
export function getSummaryCardSprayingPrePlanting() {
  return async (dispatch) => {
    dispatch(
      general.setLoadingComponent({
        loading: true,
        actionInitType: ORDER.GET_SUMMARY_CARD_SPRAYING
      })
    )
    await requestGet(ORDER_URL.GET_SUMMARY_CARD_SPRAYING_PRE_PLANTING())
      .then((res) => {
        dispatch(
          general.setLoadingComponent({
            loading: false,
            actionInitType: ORDER.GET_SUMMARY_CARD_SPRAYING
          })
        )
        dispatch(order.setSummary(res.data))
      })
      .catch((e) => {
        dispatch(general.setError(e))
      })
  }
}
