import { useSubheader } from "../../../../_metronic/layout/index.js"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import * as yup from "yup"
import { TabsInputComponent } from "../../../../component/index.jsx"
import { FormTreatment } from "./FormTreatment/FormTreatment.jsx"
import {
  clearMessageMasterData,
  createTreatmentInsidePackage,
  editTreatmentInsidePackage,
  getDetailPackage,
  // getDetailTreatment,
  getDetailTreatmentPackage
} from "../../../../redux/actions/MasterDataAction.jsx"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import {
  FORM_NEED,
  getMaximumErrorNumber,
  getMaximumErrorString,
  getMinimumErrorNumber,
  getMinimumErrorString,
  getStringErrorMassage
} from "../../../../util/constStringCollection.js"
import { PopupQuestion } from "../../../../component/atoms/Popup/index.jsx"
import { useTranslation } from "react-i18next"
import { MASTER_DATA } from "../../../../constants/InitTypeConstants.js"
import swal from "sweetalert"
import { ILLUSTRATION_ASSETS_V2 } from "../../../../constants/ImageConstants.js"

export const CreateUpdateTreatment = ({
  history,
  match: {
    params: { package_id, plant_type_id, sequence, treatment_id }
  }
}) => {
  const { t } = useTranslation()
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const { General, MasterData } = useSelector((state) => state)
  const formRef = useRef()
  const [detailPackage, setDetailPackage] = useState("")
  const [detailTreatment, setDetailTreatment] = useState("")
  const [isValid, setIsValid] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [initialValue, setInitialValue] = useState({
    treatment_name: "",
    hst_start: "",
    hst_end: "",
    description: "",
    list_pesticide: []
  })

  const ValidationScheme = [
    yup.object().shape({
      treatment_name: yup
        .string()
        .min(3, getMinimumErrorString(3))
        .max(100, getMaximumErrorString(100))
        .required(getStringErrorMassage("Nama Treatment ", FORM_NEED.harusDiIsi)),
      hst_start: yup
        .number()
        .typeError("Harus number type")
        .required(getStringErrorMassage("Dari ", FORM_NEED.harusDiIsi))
        .positive()
        .max(99, getMaximumErrorNumber(99))
        .min(0, getMinimumErrorNumber(0)),
      hst_end: yup
        .number()
        .typeError("Harus number type")
        .required(getStringErrorMassage("Ke ", FORM_NEED.harusDiIsi))
        .positive()
        .max(120, getMaximumErrorNumber(120))
        .min(1, getMinimumErrorNumber(1)),
      description: yup.string().required(getStringErrorMassage("Deskripsi ", FORM_NEED.harusDiIsi)),
      list_pesticide: yup
        .array()
        .of(
          yup.object().shape({
            pesticide_id: yup
              .string()
              .required(getStringErrorMassage("Nama Pestisida ", FORM_NEED.harusDiIsi)),
            total_dose: yup
              .number()
              .typeError("Jumlah dosis harus number")
              .required(getStringErrorMassage("Jumlah Dosis ", FORM_NEED.harusDiIsi))
          })
        )
        .required()
    })
  ]

  const buttonSubmited = (
    <button
      type="button"
      disabled={isValid}
      className={isValid ? "btn button-disabled" : "btn btn-primary"}
      onClick={() => setShowModal(true)}>
      Kirim
    </button>
  )

  useEffect(() => {
    subHeader.setBreadcrumbs([
      {
        pathname: RoutesConstants.MASTER_DATA.PACKAGE.PAGE(),
        title: "Paket Penyemprotan"
      },
      {
        pathname: RoutesConstants.MASTER_DATA.PACKAGE.DETAIL(package_id),
        title: detailPackage?.name
      },
      {
        title: treatment_id ? "Edit Treatment" : "Tambah Treatment"
      }
    ])
    if (treatment_id) {
      subHeader.setTitle("Edit Treatment Ke-" + sequence)
    } else {
      subHeader.setTitle("Tambah Treatment Ke-" + sequence)
    }
  }, [detailPackage])

  useEffect(() => {
    if (package_id) dispatch(getDetailPackage(package_id))
    return () => {
      dispatch(clearMessageMasterData())
    }
  }, [package_id])

  useEffect(() => {
    if (MasterData.data) {
      setDetailPackage(MasterData.data)
    }
  }, [MasterData])

  useEffect(() => {
    if (treatment_id) {
      dispatch(getDetailTreatmentPackage(treatment_id))
    }
  }, [treatment_id])

  useEffect(() => {
    if (treatment_id) {
      if (MasterData.treatmentDetail) {
        setDetailTreatment(MasterData.treatmentDetail)
        const newInitValue = {
          treatment_name: MasterData.treatmentDetail?.treatment_name,
          hst_start: MasterData?.treatmentDetail?.hst_start,
          hst_end: MasterData?.treatmentDetail?.hst_end,
          description: MasterData?.treatmentDetail?.description,
          list_pesticide: MasterData?.treatmentDetail?.variant_list.map((item) => {
            return {
              pesticide_id: item?.id,
              total_dose: item?.total_dose
            }
          })
        }
        setInitialValue(newInitValue)
      }
    }
  }, [MasterData.treatmentDetail])

  useEffect(() => {
    subHeader.setButton(buttonSubmited)
  }, [isValid])

  useEffect(() => {
    if (treatment_id) {
      setTimeout(() => {
        if (isValid) {
          setIsValid(false)
        }
      }, 1000)
    }
  }, [])

  useEffect(() => {
    if (General?.isFinishUpdateOrDelete) {
      history.push(RoutesConstants.MASTER_DATA.PACKAGE.DETAIL(MasterData?.messages?.id))
      swal({
        icon:
          General.actionInitType === MASTER_DATA.CREATE_TREATMENT
            ? ILLUSTRATION_ASSETS_V2.IL_SUCCESS_ADD
            : ILLUSTRATION_ASSETS_V2.IL_POSITIVE_DIPERBARUI,
        text: MasterData?.messages?.message
      }).then()
    }
  }, [General, MasterData])

  function onSubmitForm(e) {
    const { submited, values } = e
    if (submited) {
      const data = {
        package_id: package_id,
        treatment_name: values?.treatment_name,
        hst_start: values?.hst_start,
        hst_end: values?.hst_end,
        description: values?.description,
        plant_type_id: plant_type_id,
        pesticide_list: values.list_pesticide.map((item) => {
          return {
            pesticide_id: item?.pesticide_id,
            total_dose: item?.total_dose
          }
        })
      }

      if (treatment_id) {
        dispatch(editTreatmentInsidePackage(treatment_id, data))
      } else {
        dispatch(createTreatmentInsidePackage(data))
      }
    }
  }

  const formTreatment = [
    <FormTreatment
      key={0}
      plantTypeId={plant_type_id}
      data={detailTreatment}
      isReadOnly={false}
      setIsValid={setIsValid}
      setFormValues={(e) => {
        setInitialValue(e)
      }}
    />
  ]

  function componentPreview() {
    return (
      <div>
        <div style={{ textAlign: "center" }} className={"pt-5 pb-7"}>
          {t(
            treatment_id
              ? "messages.confirmation.edit_treatment"
              : "messages.confirmation.add_treatment"
          )}
        </div>
      </div>
    )
  }

  return (
    <div>
      <PopupQuestion
        textCancel={t("button.cancel")}
        textOk={t("button.continue")}
        onOk={() => {
          formRef.current.handleSubmit()
          setShowModal(false)
        }}
        onCancel={() => setShowModal(false)}
        title={t(treatment_id ? "label.edit_treatment" : "label.add_treatment").toUpperCase()}
        show={showModal}
        bodyCustom={componentPreview()}
      />
      <TabsInputComponent
        isEditTabs={false}
        steps={[null]}
        stepDescription={[null]}
        formInitialValues={initialValue}
        innerRef={formRef}
        componentTab={formTreatment}
        validationSchema={ValidationScheme}
        onSubmitForm={onSubmitForm}
      />
    </div>
  )
}
