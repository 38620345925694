import React, { useEffect, useState } from "react"
import SVG from "react-inlinesvg"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import { Field, Form, Formik } from "formik"
import Divider from "@material-ui/core/Divider"

export const Search = ({
  placeholder,
  onSubmitForm,
  initialValues,
  name,
  isActiveSearch = false,
  innerRef,
  type = "text",
  trigerReset,
  disabled = false,
  customClass,
  ...props
}) => {
  const [hasValue, setHasValue] = useState(false)
  const handleSubmit = values => {
    onSubmitForm(values)
  }

  useEffect(() => {
    if (isActiveSearch) {
      setHasValue(true)
    } else {
      setHasValue(false)
    }
  }, [isActiveSearch])

  const handleChange = e => {
    if (e.target.value.length > 0) {
      setHasValue(true)
    } else {
      setHasValue(false)
    }
  }

  return (
    <Formik
      enableReinitialize
      innerRef={innerRef}
      onSubmit={handleSubmit}
      initialValues={initialValues}>
      {({ resetForm }) => {
        const handleReset = () => {
          resetForm()
          setHasValue(false)
          trigerReset()
        }
        return (
          <Form className={customClass}>
            <Paper
              className={`${disabled ? "search-atom_disabled" : "search-atom"} ${customClass}`}>
              {disabled ? (
                <span style={{ color: "#FFFFFF", width: "90%" }}>{placeholder}</span>
              ) : (
                <Field
                  className={"search-atom-input"}
                  name={name}
                  placeholder={placeholder}
                  onKeyUp={handleChange}
                  type={type}
                  {...props}
                />
              )}

              <IconButton type={"submit"} className={"search-atom-button"} aria-label="Search">
                <SVG
                  src={
                    disabled
                      ? toAbsoluteUrl("/media/svg/icons/Menu/ic_search_white.svg")
                      : toAbsoluteUrl("/media/svg/icons/Menu/ic_search.svg")
                  }
                />
              </IconButton>
              {hasValue && (
                <>
                  <Divider className={"divider"} />
                  <IconButton
                    type={"reset"}
                    onClick={handleReset}
                    className={"clear-atom-button"}
                    aria-label="Clear">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Menu/ic_bullet_close.svg")} />
                  </IconButton>
                </>
              )}
            </Paper>
          </Form>
        )
      }}
    </Formik>
  )
}
