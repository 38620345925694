import React, { useEffect, useState } from "react"
import { AddressMolecule, SelectInput, TextInput, UploadComponent } from "../../../../component"
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle
} from "../../../../_metronic/_partials/controls"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import {
  getListRegionalAll,
  resetUrlResponse,
  uploadAction
} from "../../../../redux/actions/MasterDataAction"
import { useDispatch, useSelector } from "react-redux"
import { UploadIcon } from "../../../../component/atoms/Icons/SVG/upload"
import { useFormikContext } from "formik"
import { UPLOAD } from "../../../../constants/InitTypeConstants"
import { handleSpace } from "../../../helpers/TextHelper"
import { getUserRegional } from "../../../service/userManagementAction.js"

export const DataMitraGoverment = ({ mitraTypeEdit, isReadOnly, mitraType, changeType }) => {
  const { MasterData } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [avatar, setAvatar] = useState("")
  const { values: formValues } = useFormikContext()
  const [urlKtp, setUrlKtp] = useState("")

  const [regionalList, setRegionalList] = useState([])
  const [regionalId, setRegionalId] = useState(null)
  const [isHaveRegional, setIsHaveRegional] = useState(false)
  const [isChangeRegional, setIsChangeRegional] = useState(false)

  useEffect(() => {
    const userRegional = getUserRegional()
    if (userRegional !== "null") {
      formValues["regionalId"] = userRegional
      setRegionalId(userRegional)
      setIsHaveRegional(true)
    }
  }, [regionalList])

  useEffect(() => {
    dispatch(getListRegionalAll())
  }, [dispatch])

  useEffect(() => {
    if (MasterData.listRegionalAll != undefined) {
      setRegionalList(
        MasterData.listRegionalAll.map((value) => ({
          label: value.name + " (" + value.code + ")",
          value: value.id
        }))
      )
    }
  }, [MasterData.listRegionalAll])

  function onChangeRegional(e) {
    setRegionalId(e)
    setIsChangeRegional(true)
  }

  useEffect(() => {
    if (formValues["mitra_type"]) {
      changeType(formValues)
    }
  }, [formValues])

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (MasterData.uploadType === UPLOAD.AVATAR) {
        setAvatar(MasterData.urlResponseUpload)
      } else if (MasterData.uploadType === UPLOAD.KTP) {
        setUrlKtp(MasterData.urlResponseUpload)
      }
    }
    return () => {
      dispatch(resetUrlResponse())
    }
  }, [MasterData.urlResponseUpload, MasterData.uploadType])

  function onChangeAvatar(e) {
    dispatch(uploadAction({ data: e, type: UPLOAD.AVATAR }))
  }

  function onChangeFileKtp(e) {
    dispatch(uploadAction({ data: e, type: UPLOAD.KTP }))
  }

  const placeholder = <UploadIcon />

  return (
    <>
      <Card className="card-custom card-shadowless">
        <CardBody>
          <RowModule>
            <CardHeaderTitle className={"my-12"}>{"Detail Mitra".toUpperCase()}</CardHeaderTitle>
          </RowModule>
          <RowModule>
            <SelectInput
              name="mitra_type"
              required={true}
              editable={!mitraTypeEdit}
              label="Pilih Jenis Mitra"
              placeholder="Pilih Salah Satu"
              options={mitraType}
              withoutFeedback={true}
              clearNow={true}
              value={formValues["mitra_type"]}
            />
          </RowModule>
          <RowModule>
            <SelectInput
              name="regionalId"
              required
              onChange={onChangeRegional}
              editable={isHaveRegional ? false : true}
              label={"Regional"}
              placeholder="Pilih Salah Satu"
              value={regionalId}
              options={regionalList}
            />
          </RowModule>
          <RowModule>
            <UploadComponent
              required
              urlParsing={avatar}
              name="mitra_pic_url"
              label="Profile Picture"
              editable={!isReadOnly}
              onChange={onChangeAvatar}
              placeholder={placeholder}
              maxSize={2048000}
              maxSizeText="2 MB"
            />
          </RowModule>
          <RowModule>
            <TextInput
              required
              editable={!isReadOnly}
              label="Nama Instansi"
              name="name"
              placeholder="Input Nama Instansi"
              onKeyDown={handleSpace}
            />
          </RowModule>
          <RowModule>
            <TextInput
              required
              editable={!isReadOnly}
              label="Email"
              name="email"
              placeholder="Input email"
            />
          </RowModule>
          <RowModule>
            <TextInput
              required
              editable={!isReadOnly}
              type="number"
              label="Nomor Telephone"
              name="phone"
              placeholder="Input nomor Telephone"
            />
          </RowModule>
        </CardBody>
      </Card>
      <Card className={"card-custom card-shadowless"}>
        <CardHeader title={"Penanggung Jawab".toUpperCase()} />
        <CardBody>
          <RowModule>
            <TextInput
              required
              editable={!isReadOnly}
              label="Nama Sesuai KTP"
              name="coordinator"
              placeholder="Input Nama Instansi"
            />
          </RowModule>
          <RowModule>
            <TextInput
              required
              editable={!isReadOnly}
              label="Nomor KTP"
              type="number"
              name="ktp_no"
              placeholder="Input Nomor KTP"
            />
          </RowModule>
          <RowModule>
            <UploadComponent
              urlParsing={urlKtp}
              required
              name="ktp_pic_url"
              label="Foto KTP"
              editable={!isReadOnly}
              onChange={onChangeFileKtp}
              placeholder={placeholder}
              maxSize={2048000}
              maxSizeText="2 MB"
            />
          </RowModule>
        </CardBody>
      </Card>
      <AddressMolecule
        name={"mitra"}
        required
        isReadOnly={isReadOnly}
        addressTitle={"Alamat Instansi".toUpperCase()}
        regionalId={regionalId}
        byRegional={true}
        isChangeRegional={isChangeRegional}
      />
    </>
  )
}
