import React, { useEffect, useRef, useState } from "react"
import * as Yup from "yup"
import {
  FORM_NEED,
  getMaximumErrorString,
  getMinimumErrorString,
  getStringErrorMassage
} from "../../../util/constStringCollection"
import { Button } from "react-bootstrap"
import { AddressMolecule, TabsInputComponent, TextInput, UploadComponent } from "../../../component"
import { useDispatch, useSelector } from "react-redux"
import { useSubheader } from "../../../_metronic/layout"
import {
  getDetail,
  createFo,
  updateFo,
  getListRoleFO
} from "../../../redux/actions/FieldOfficerAction"
import { PreviewFieldOfficer } from "./Preview"
import { RowModule } from "../../../component/atoms/RowModule/RowModule.jsx"
import { DataFieldOfficer } from "./FormView/dataFieldOfficer"
import { toAbsoluteUrl } from "../../../_metronic/_helpers"
import { RoutesConstants } from "../../../constants/RoutesConstants"
import { FIELD_OFFICER_ROLE_ENUM } from "../../../constants/StringConstant"
import { uploadAction } from "../../../redux/actions/MasterDataAction"
import { Form } from "formik"

export const FieldOfficerFormWrapper = ({
  history,
  match: {
    params: { id, tab }
  }
}) => {
  const subHeader = useSubheader()
  const dispatch = useDispatch()
  const formRef = useRef()
  const { FieldOfficer, MasterData, General } = useSelector((state) => state)
  const [stepsTitle, setStepsTitle] = useState(["Data Field Officer"])
  const [isSubmit] = useState(true)
  const [formData, setFormData] = useState({})
  const [activeStep, setActiveStep] = useState(0)
  const [swalMessage, setSwalMessage] = useState("")
  const [initialData, setInitialData] = useState({
    id: "",
    name: "",
    phone: "",
    email: "",
    avatar: "",
    foProvince: "",
    foPregency: "",
    foDistrict: "",
    foSubDistrict: "",
    foPostalCode: "",
    foAddress: "",
    roleFO: ""
  })
  const [swalIcon, setSwallIcon] = useState("")
  const [role, setRole] = useState(null)

  useEffect(() => {
    if (!id) {
      if (!stepsTitle.includes("Preview")) {
        stepsTitle.push("Preview")
        setStepsTitle([...stepsTitle])
      }
    }
    subHeader.setBreadcrumbs(null)
    subHeader.setTitle(id ? "" : "Tambah Field Officer")
  }, [id, FieldOfficer])

  useEffect(async () => {
    if (id) {
      dispatch(getDetail(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (General.isFinishUpdateOrDelete && General.actionInitType !== "UPLOAD") {
      swal(swalMessage, {
        icon: swalIcon
      })
      if (id) {
        tab ? history.push(RoutesConstants.FIELD_OFFICER.DETAIL_FO_QA(role, tab, id)) :
        history.push(RoutesConstants.FIELD_OFFICER.DETAIL_FO(role, id))
      } else {
        history.push(RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER))
      }
    }
  }, [General.isFinishUpdateOrDelete])

  useEffect(() => {
    dispatch(getListRoleFO())
  }, [])

  useEffect(async () => {
    if (isSubmit) {
      if (window.location.href.includes("edit")) {
        subHeader.setButton(
          <Button
            variant="primary"
            onClick={() => {
              if (formRef.current) {
                formRef.current?.handleSubmit()
              }
            }}>
            UPDATE
          </Button>
        )
      }
    }
  }, [isSubmit])

  useEffect(async () => {
    if (!id) {
      subHeader.setButton(
        <Button
          variant="primary"
          disabled={activeStep !== renderComponent().steps.length - 1}
          onClick={() => {
            if (formRef.current) {
              formRef.current?.handleSubmit()
            }
          }}>
          SUBMIT
        </Button>
      )
    } else {
      if (window.location.href.includes("new")) {
        subHeader.setButton(false)
      }
    }
  }, [id, activeStep])

  useEffect(() => {
    const state = FieldOfficer?.detail
    if (state?.account_id && id) {
      subHeader.setTitle("Edit FO Dengan Nama " + state?.name)
      setInitialData({
        avatar: state?.url_profile,
        email: state?.email,
        foProvince: state?.province_id,
        foRegency: state?.regency_id,
        foDistrict: state?.district_id,
        foSubDistrict: state?.sub_district_id,
        foPostalCode: state?.postal_code,
        foAddress: state?.address,
        name: state?.name,
        phone: state?.phone_number,
        roleFO: state?.role
      })
      setRole(state?.role === "FIELD_OFFICER" ? FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER : state?.role)
    }
  }, [FieldOfficer?.detail])

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, getMinimumErrorString(3))
      .max(100, getMaximumErrorString(100))
      .required(getStringErrorMassage("Nama", FORM_NEED.harusDiIsi)),
    email: Yup.string()
      .email("Invalid Email")
      .required(getStringErrorMassage("Email ", FORM_NEED.harusDiIsi)),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Harus Angka")
      .min(8, getMinimumErrorString(8))
      .max(15, getMaximumErrorString(15))
      .required(getStringErrorMassage("Phone Number ", FORM_NEED.harusDiIsi)),
    roleFO: Yup.string()
      .required(getStringErrorMassage("Role FO ", FORM_NEED.harusDiIsi))
      .nullable(),
    foProvince: Yup.string().required(getStringErrorMassage("Provinsi ", FORM_NEED.harusDiIsi)),
    foRegency: Yup.string().required(getStringErrorMassage("Kota ", FORM_NEED.harusDiIsi)),
    foDistrict: Yup.string().required(getStringErrorMassage("Kecamatan ", FORM_NEED.harusDiIsi)),
    foSubDistrict: Yup.string().required(getStringErrorMassage("Kelurahan ", FORM_NEED.harusDiIsi)),
    foPostalCode: Yup.string(),
    foAddress: Yup.string()
      .required(getStringErrorMassage("Alamat ", FORM_NEED.harusDiIsi))
      .max(255, getMaximumErrorString(255))
  })

  const handleSpace = (e) => {
    if (formRef.current.values.name === "" || e.target.selectionStart === 0) {
      e.keyCode === 32 && e.preventDefault()
    }
  }

  const renderComponent = () => {
    const steps = ["Data Field Officer"]
    const stepsDescription = ["Informasi Field Officer", "Review & Submit"]
    const flow = [<DataFieldOfficer key={0} isReadOnly={false} onKeyDown={handleSpace} />]

    if (!id) {
      if (!steps.includes("Preview")) {
        steps.push("Preview")
        stepsDescription.push("Review & Submit")
        flow.push(<PreviewFieldOfficer formData={formData} />)
      }
    }

    return {
      steps,
      stepsDescription,
      flow
    }
  }

  const onSubmit = async ({ submited, values }) => {
    const provinces = MasterData?.provinceList ?? []
    const province_name = provinces?.find((e) => e?.id === values?.foProvince)?.name ?? "-"
    const regencies = MasterData?.regencyList ?? []
    const regency_name = regencies?.find((e) => e?.id === values?.foRegency)?.name ?? "-"
    const districts = MasterData?.districtList ?? []
    const district_name = districts?.find((e) => e?.id === values?.foDistrict)?.name ?? "-"
    const subDistricts = MasterData?.subDistrictList ?? []
    const sub_district_name =
      subDistricts?.find((e) => e?.id === values?.foSubDistrict)?.name ?? "-"
    const roleFO = FieldOfficer?.role?.find((e) => e?.name === values?.roleFO)

    setFormData({
      name: values?.name ?? "-",
      phone_number: values?.phone ?? "-",
      email: values?.email ?? "-",
      province_id: values?.foProvince,
      province_name,
      regency_id: values?.foRegency,
      regency_name,
      district_id: values?.foDistrict,
      district_name,
      sub_district_id: values?.foSubDistrict,
      sub_district_name,
      postal_code: values?.foPostalCode,
      address: values?.foAddress ?? "-",
      avatar: values?.avatar,
      roleFO: roleFO
    })

    if (submited) {
      const payload = {
        name: values?.name,
        phone_number: values?.phone,
        email: values?.email,
        province_id: values?.foProvince,
        regency_id: values?.foRegency,
        district_id: values?.foDistrict,
        sub_district_id: values?.foSubDistrict,
        postal_code: values?.foPostalCode,
        full_address: values?.foAddress,
        url_profile: values?.avatar,
        role: values?.roleFO
      }

      if (id) {
        payload.field_officer_id = id
        dispatch(updateFo(payload))
        setSwalMessage("Data FO berhasil diperbarui")
        setSwallIcon(toAbsoluteUrl("/media/svg/icons/Menu/Ilustrasi-success.svg"))
        setRole(
          values?.roleFO === "FIELD_OFFICER" ? FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER : values?.roleFO
        )
      } else {
        dispatch(createFo(payload))
        setSwalMessage("Data FO berhasil dibuat")
        setSwallIcon(toAbsoluteUrl("/media/svg/icons/Menu/Ilustrasi-success.svg"))
      }
    }
  }

  return (
    <>
      <TabsInputComponent
        isEditTabs={[undefined, null, ""].indexOf(id) < 0}
        steps={renderComponent().steps}
        stepDescription={renderComponent().stepsDescription}
        innerRef={formRef}
        formInitialValues={initialData}
        formId="formFo"
        componentTab={renderComponent().flow}
        validationSchema={[validationSchema]}
        onSubmitForm={onSubmit}
        onChangeStep={(activeStep) => {
          setActiveStep(activeStep)
        }}
      />
    </>
  )
}

export const FieldOfficerForm = ({ id }) => {
  const dispatch = useDispatch()
  const { MasterData, FieldOfficer } = useSelector((state) => state)
  const [avatar, setAvatar] = useState("")

  useEffect(() => {
    if (MasterData.urlResponseUpload) {
      if (MasterData.uploadType === "AVATAR") {
        setAvatar(MasterData.urlResponseUpload)
      }
    }
  }, [MasterData.urlResponseUpload])

  useEffect(() => {
    const state = FieldOfficer?.detail
    if (!id) return
    if (state?.account_id) {
      setAvatar(state?.url_profile)
    }
  }, [FieldOfficer.detail])

  const onChangeAvatar = (e) => {
    dispatch(uploadAction({ data: e, type: "AVATAR" }))
  }

  return (
    <>
      <Form>
        <RowModule>
          <UploadComponent
            urlParsing={avatar}
            name="avatar"
            label="Avatar"
            onChange={onChangeAvatar}
          />
        </RowModule>

        <RowModule>
          <TextInput editable={true} label="Nama" name="name" placeholder="Input nama FO" />
        </RowModule>

        <RowModule>
          <TextInput
            editable={true}
            label="No. Handphone"
            name="phone"
            placeholder="Input no. handphone FO"
          />
        </RowModule>

        <RowModule>
          <TextInput editable={true} label="Email" name="email" placeholder="Input email FO" />
        </RowModule>

        <AddressMolecule
          name="fo"
          isReadOnly={false}
          addressTitle="Alamat FO"
          removeHorizontalPadding={true}
          removeTitleSeparator={true}
        />
      </Form>
    </>
  )
}
