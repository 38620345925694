/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Layout } from "../_metronic/layout"
import BasePage from "./BasePage"
import { AuthPage, Logout } from "./modules/Auth"
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage"
import * as actions from "./service/userDataAction"

export function Routes(props) {
  const dispatch = useDispatch()
  const { General } = useSelector((state) => state)

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(
      actions.checkJwtData({
        props
      })
    )
  }, [null, dispatch])

  useEffect(() => {
    if (General.errorMsg) {
      if (General.errorMsg.toUpperCase() === "UNAUTHORIZED") {
        window.location.href = "/logout"
      }
    }
  }, [General, dispatch])

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized /*Redirect to `/auth` when user is not authorized*/ ? (
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  )
}
