import React from "react"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { Badge } from "react-bootstrap"
import { timeStampToDate } from "../../../helpers/DateHelper"
import { ORDER_STATUS_ENUM } from "../../../../constants/StringConstant"
import { useSelector } from "react-redux"
import { Skeleton } from "@material-ui/lab"

export const SectionDetailOrderHeader = ({ data }) => {
  const { General } = useSelector((state) => state)
  const uiStatus = (e) => {
    switch (e?.status_enum) {
      case "PENDING":
        return <Badge variant="warning">{e?.status_string}</Badge>
      case "APPROVED":
        return <Badge variant="info text-white">{e?.status_string}</Badge>
      case "RESCHEDULED":
        return <Badge variant="rescheduled">{e?.status_string}</Badge>
      case "CANCELED":
        return <Badge variant="canceled">{e?.status_string}</Badge>
      case "REJECTED":
        return <Badge variant="danger">{e?.status_string}</Badge>
      default:
        return <Badge variant="secondary">{e?.status_string}</Badge>
    }
  }
  return (
    <div className={"col-xl-10"}>
      {General.loading ? (
        <div className={"d-flex align-items-center"} style={{ gap: 12 }}>
          <Skeleton animation="wave" variant={"text"} height={32} width={250} />
          <Skeleton animation="wave" variant={"text"} width={100} />
        </div>
      ) : (
        <div className={"d-flex align-items-center"} style={{ gap: 12 }}>
          <h2>AKTIVITAS {data?.activity_name.toUpperCase()}</h2>
          {uiStatus(data)}
        </div>
      )}
      {General.loading ? (
        <div className={"d-flex align-items-center w-full"} style={{ gap: 12 }}>
          <Skeleton animation="wave" variant={"text"} />
          <Skeleton animation="wave" variant={"text"} />
        </div>
      ) : (
        data?.submitted_by &&
        data?.status_enum === ORDER_STATUS_ENUM.PENDING && (
          <div className={"col-xl-6 p-0 mt-4"}>
            <RowModule>
              <div>{"Requested by"}</div>
              <div style={{ fontWeight: 600 }}>{": " + data.submitted_by}</div>
            </RowModule>
            <RowModule>
              <div>{"Submitted time"}</div>
              <div style={{ fontWeight: 600 }}>
                :&ensp;
                {data?.submitted_date
                  ? timeStampToDate(data?.submitted_date, "dd-MM-yyyy HH:mm:ss")
                  : "-"}
              </div>
            </RowModule>
          </div>
        )
      )}
    </div>
  )
}
