import { getUrlParsingPageFilter } from "../../config/endpointCollection"
import { GeneralSlice } from "../reducers/General"
import { ACCOUNT } from "../../constants/InitTypeConstants"
import { requestGet, requestPost, requestPut, requestDelete } from "../../config"
import { ACCOUNT_URL } from "../../constants/UrlConstants"
import { AccountSlice } from "../reducers/Account"

const { actions: general } = GeneralSlice
const { actions: account } = AccountSlice

export const getPageData = (param) => {
  return async (dispatch) => {
    let urlData = getUrlParsingPageFilter(param.pageReq)
    dispatch(
      general.setLoading({
        loading: true,
        actionInitType: ACCOUNT.GET_LIST_PAGE
      })
    )
    try {
      let res = await requestGet(ACCOUNT_URL.GET_LIST_PAGE + urlData)
      dispatch(
        account.setPageData({
          pageData: res.data,
          page: {
            totalCount: res.all_element,
            currentElement: res.element,
            currentPage: res.page
          }
        })
      )
      dispatch(
        general.setLoading({
          loading: false,
          actionInitType: ACCOUNT.GET_LIST_PAGE
        })
      )
    } catch (e) {
      dispatch(general.setError(e))
    }
  }
}

export const createUser = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoadingGlobal({
          loading: true,
          actionInitType: ACCOUNT.CREATE_USER
        })
      )
      try {
        let res = await requestPost(ACCOUNT_URL.CREATE_USER, data)
        dispatch(account.setCreateUser(res.data))
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: ACCOUNT.CREATE_USER
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ACCOUNT.CREATE_USER
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}
export const editUser = (data, id) => {
  return async (dispatch) => {
    if (data) {
      dispatch(general.setLoadingGlobal({ loading: true, actionInitType: ACCOUNT.EDIT_USER }))
      try {
        let res = await requestPut(ACCOUNT_URL.EDIT_USER + id, null, data)
        dispatch(account.setCreateUser(res.data))
        dispatch(
          general.setLoadingGlobal({
            loading: false,
            actionInitType: ACCOUNT.EDIT_USER
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ACCOUNT.EDIT_USER
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}
export const resetPassword = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: ACCOUNT.RESET_PASSWORD
        })
      )
      try {
        let resetData = await requestPost(ACCOUNT_URL.RESET_PASSWORD, data)
        dispatch(account.setPasswordReset(resetData?.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ACCOUNT.RESET_PASSWORD
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ACCOUNT.RESET_PASSWORD
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const editPassword = (data) => {
  return async (dispatch) => {
    if (data) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: ACCOUNT.EDIT_PASSWORD
        })
      )
      try {
        await requestPost(ACCOUNT_URL.EDIT_PASSWORD, data)
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ACCOUNT.EDIT_PASSWORD
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ACCOUNT.EDIT_PASSWORD
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}
export const deactivateStatusUser = (param, payload) => {
  return async (dispatch) => {
    if (param) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: ACCOUNT.DEACTIVATE_ACCOUNT
        })
      )
      try {
        await requestDelete(ACCOUNT_URL.DEACTIVATE_ACCOUNT + param, payload)
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ACCOUNT.DEACTIVATE_ACCOUNT
          })
        )
        dispatch(
          general.finishUpdateApi({
            isFinishUpdateOrDelete: true,
            actionInitType: ACCOUNT.DEACTIVATE_ACCOUNT
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const getUserDetail = (param) => {
  return async (dispatch) => {
    if (param) {
      dispatch(
        general.setLoading({
          loading: true,
          actionInitType: ACCOUNT.GET_DETAIL
        })
      )
      try {
        let res = await requestGet(ACCOUNT_URL.GET_USER_DETAIL + param)
        dispatch(account.getPage(res.data))
        dispatch(
          general.setLoading({
            loading: false,
            actionInitType: ACCOUNT.GET_DETAIL
          })
        )
      } catch (e) {
        dispatch(general.setError(e))
      }
    }
  }
}

export const clearResetPassword = () => {
  return async (dispatch) => {
    dispatch(account.setPasswordReset(null))
  }
}
