import React from "react"
import { CardDetail, DetailText, FlatIconAndText2Row } from "../../../../component"
import { FlatIconAndText } from "../../../../component/atoms/FlaticonAndText"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { Card } from "react-bootstrap"
import { CardBody, CardHeader } from "../../../../_metronic/_partials/controls"
import { loadUserLogin } from "../../../service/userManagementAction"
import { PRIVILEGE_ENUM } from "../../../../config/privilegeCollection"
import { Skeleton } from "@material-ui/lab"
import { useSelector } from "react-redux"

export const DetailPetani = ({
  farmerData,
  status,
  descriptionLeft,
  descriptionRight,
  flatIconAndText2Rows,
  setStatusPopup
}) => {
  const privileges = loadUserLogin().privilege
  const { General } = useSelector((state) => state)
  const mitraDesc = (data) => {
    return (
      <>
        <div className="d-flex flex-wrap mb-4">
          <FlatIconAndText
            text={data?.code ?? "-"}
            svg={"/media/svg/icons/Detail/ic_account.svg"}
          />

          <FlatIconAndText text={data?.email ?? "-"} svg={"/media/svg/icons/Detail/ic_mail.svg"} />
          {privileges.indexOf(PRIVILEGE_ENUM.MITRA_PHONE_NUMBER) > -1 && (
            <FlatIconAndText
              text={data?.phone ?? "-"}
              svg={"/media/svg/icons/Detail/ic_phone.svg"}
            />
          )}
        </div>
      </>
    )
  }

  const accountDataFarmer = [
    {
      title: "Pemilik Rekening",
      data: farmerData?.bank_account?.account_name ? farmerData?.bank_account?.account_name : "-"
    },
    {
      title: "Nama Bank",
      data: farmerData?.bank_account?.bank_name ? farmerData?.bank_account?.bank_name : "-"
    },
    {
      title: "No Rekening",
      data: farmerData?.bank_account?.account_number
        ? farmerData?.bank_account?.account_number
        : "-"
    }
  ]

  return (
    <>
      {status === "rejected" && (
        <RowModule>
          <Card className={"reject-list-card"}>
            <CardHeader
              title={
                General.loading ? (
                  <Skeleton animation="wave" variant={"text"} width={100} />
                ) : (
                  "Alasan Penolakan"
                )
              }
              className="mb-0 pb-2"
            />
            <CardBody>
              {General.loading ? (
                <Skeleton animation="wave" variant={"text"} width={100} />
              ) : !farmerData ? null : farmerData?.farmer_reject_enum?.length === 0 ? (
                <span>Alasan tidak ditemukan.</span>
              ) : (
                <ul>
                  {farmerData?.farmer_reject_enum?.map((e) => (
                    <li key={e.enum_value}>{e?.enum_value}</li>
                  ))}
                </ul>
              )}
            </CardBody>
          </Card>
        </RowModule>
      )}

      {status === "inactive" && farmerData?.farmer_activation === "INACTIVE" && (
        <RowModule>
          <Card style={{ background: "#F8F8F8" }}>
            <CardHeader title={"Status Change Reason"} className="mb-0 pb-2 bg-gray-card" />
            <CardBody>{farmerData?.farmer_deactivation_reason}</CardBody>
          </Card>
        </RowModule>
      )}

      <CardDetail
        titleCardDetail={farmerData != null ? farmerData.name : ""}
        descriptionLeft={descriptionLeft}
        descriptionRight={descriptionRight}
        flatIconAndText2Rows={flatIconAndText2Rows}
        wrap={status === "active" ? false : true}
        joinDate={farmerData?.join_date}
        kurBNI={farmerData?.has_kur}
        kurBNIImageurl={farmerData?.bank_image}
      />
      <br />

      {privileges.indexOf(PRIVILEGE_ENUM.FARMER_BANK_ACCOUNT_NO) > -1 && (
        <>
          <RowModule>
            <Card>
              <CardBody>
                {General.loading ? (
                  <Skeleton
                    animation="wave"
                    variant={"text"}
                    width={100}
                    height={24}
                    className={"mb-6"}
                  />
                ) : (
                  <h1 className={"mb-5 pb-5"}>Informasi Rekening</h1>
                )}
                <RowModule>
                  {accountDataFarmer.map((item, i) => (
                    <FlatIconAndText2Row key={i} title={item.title} description={item.data} />
                  ))}
                </RowModule>
              </CardBody>
            </Card>
          </RowModule>
          <br />
        </>
      )}

      <RowModule>
        {privileges.indexOf(PRIVILEGE_ENUM.FARMER_KTP_PICTURE) > -1 && (
          <div onClick={() => setStatusPopup("KTP")}>
            <DetailText
              title="KTP"
              menu="KTP"
              value={farmerData != null ? farmerData.ktp_pic_url : ""}
              type="image"
              withoutSeparator={true}
            />
          </div>
        )}

        {privileges.indexOf(PRIVILEGE_ENUM.FARMER_KTP_SELFIE_PICTURE) > -1 && (
          <div onClick={() => setStatusPopup("SWAFOTO")}>
            <DetailText
              title="Swafoto"
              menu="SWAFOTO"
              value={farmerData != null ? farmerData.selfie_pic_url : ""}
              type="image"
              withoutSeparator={true}
            />
          </div>
        )}
      </RowModule>

      <RowModule>
        <CardDetail
          headerWithoutLine={"Mitra"}
          titleCardDetail={farmerData ? farmerData?.mitra?.name ?? "-" : "-"}
          descriptionLeft={mitraDesc(farmerData?.mitra)}
          descriptionRight={null}
        />
      </RowModule>
    </>
  )
}
