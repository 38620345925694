import React from "react"
import { WARNING_AREA } from "../../../constants/InitTypeConstants"
import { toCapitalize } from "../../../app/helpers/TextHelper"
import SVG from "react-inlinesvg"

export const Warning = ({ potential_issue, title }) => {
  let icon = "/media/svg/icons/Detail/ic_nothing.svg",
    classes = "alert-info"
  if (potential_issue) {
    if (potential_issue?.toUpperCase() !== WARNING_AREA.TIDAK_ADA) {
      icon = "/media/svg/icons/Detail/ic_warning.svg"
      classes = "alert-warning"
    }
  }
  return (
    <div className={"alert mt-4"}>
      <div className={`alert-item ${classes} d-flex`}>
        <SVG className="mr-2 mb-1" src={icon} />
        <div className={"text"}>
          {title} : <b>{potential_issue ? toCapitalize(potential_issue) : "-"}</b>
        </div>
      </div>
    </div>
  )
}
