import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSubheader } from "../../../../_metronic/layout/index.js"
import { useTranslation } from "react-i18next"
import { Card } from "react-bootstrap"
import { FilterCard, TabsWithIcon } from "../../../../component/index.jsx"
import { RowModule } from "../../../../component/atoms/RowModule/RowModule.jsx"
import { MacSprayingOverdueTab } from "./SprayingTabs/MacSprayingOverdueTab.jsx"
import { MacSprayingScheduledTab } from "./SprayingTabs/MacSprayingScheduledTab.jsx"
import { MacSprayingDoneTab } from "./SprayingTabs/MacSprayingDoneTab.jsx"
import { MacSprayingCancelTab } from "./SprayingTabs/MacSprayingCancelTab.jsx"
import { MacSprayingRejectedTab } from "./SprayingTabs/MacSprayingRejectedTab.jsx"
import { RoutesConstants } from "../../../../constants/RoutesConstants.js"
import { ROUTING_STATUS } from "../../../../constants/StringConstant.js"
import { useLocation, useParams } from "react-router"
import {
  getListSchedulingMac,
  getSummaryCardSprayingMac
} from "../../../../redux/actions/MacAction.jsx"
import { useDispatch, useSelector } from "react-redux"
import { CardBody, CardHeader } from "../../../../_metronic/_partials/controls/index.js"
import { CalenderSvgIcon } from "../../../../component/atoms/Icons/SVG/calender.jsx"
import { DateRange } from "react-date-range"
import { dateSelect } from "../../../helpers/DateHelper.js"
import { format } from "date-fns"
import { initialFilterTable } from "../../../../component/atoms/Tabel/index.jsx"
import { getUrlParsingPageFilter } from "../../../../config/endpointCollection.jsx"
import { useUrlParam } from "../../../hooks/useUrlParams.jsx"
import { MacSprayingPendingTab } from "./SprayingTabs/MacSprayingPendingTab.jsx"
import { Skeleton } from "@material-ui/lab"

export const MacSprayingPage = ({ history }) => {
  const subheader = useSubheader()
  const formRef = useRef()
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const { tab } = useParams()
  const { Mac, General } = useSelector((state) => state)

  const location = useLocation()
  const urlParams = useUrlParam()

  const [listDataSummary, setListDataSummary] = useState([])
  const [tabActive, setTabActive] = useState(checkTabActive(tab))
  const [queryStringObj, setQueryStringObj] = useState(undefined)
  const [isActiveFilterEmptyState, setIsActiveFilterEmptyState] = useState(false)
  const [isActiveSearch, setIsActiveSearch] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [isShowFilter, setIsShowFilter] = useState(false)
  const [isActiveFilter, setIsActiveFilter] = useState(false)
  const [openDate, setOpenDate] = useState(false)
  const [dateRange, setDateRange] = useState("")
  const [dataListScheduling, setDataListScheduling] = useState(undefined)
  const [btnFilterDisable, setBtnFilterDisable] = useState(false)
  const [state, setState] = useState([
    {
      startDate: "",
      endDate: "",
      key: "selection"
    }
  ])

  const [initValue, setInitValue] = useState({
    ...initialFilterTable,
    merchant_user_id: "",
    start_date: "",
    end_date: ""
  })

  useEffect(() => {
    if (dateRange) {
      setBtnFilterDisable(false)
    } else {
      setBtnFilterDisable(true)
    }
  }, [formRef.current, dateRange])

  useEffect(() => {
    if (isActiveFilter) {
      setIsActiveFilterEmptyState(true)
    } else if (isActiveSearch) {
      setIsActiveFilterEmptyState(true)
    } else {
      setIsActiveFilterEmptyState(false)
    }
  }, [isActiveSearch, isActiveFilter, location])

  useEffect(() => {
    subheader.setTitle(t(`menu_aside.spraying`))
    subheader.setButton(null)
    dispatch(getSummaryCardSprayingMac())
  }, [])

  useEffect(() => {
    if (Mac?.pageData) {
      setDataListScheduling(Mac)
    }
  }, [Mac?.pageData])

  useEffect(() => {
    const result = location?.search
    fetchData(result)
  }, [location.search])

  useEffect(() => {
    setQueryStringObj(urlParams.getUrlParamsObj)
  }, [urlParams.getUrlParamsObj])

  useEffect(() => {
    const data = Mac?.data
    const dataList = [
      { label: "label.pending", value: data?.pending ?? 0 },
      { label: "label.scheduled", value: data?.scheduled ?? 0 },
      { label: "label.overdue", value: data?.overdue ?? 0 },
      { label: "label.done", value: data?.done ?? 0 },
      { label: "label.canceled", value: data?.canceled ?? 0 },
      { label: "label.rejected", value: data?.rejected ?? 0 }
    ]
    setListDataSummary(dataList)
  }, [Mac])

  useEffect(() => {
    setTabActive(checkTabActive(tab))
  }, [tab])

  useEffect(() => {
    checkQueryParamOnPageLoad()
  }, [queryStringObj])

  function fetchData(param) {
    dispatch(getListSchedulingMac(checkStatusByActiveTab(tabActive), param ? param : ""))
  }

  function checkQueryParamOnPageLoad() {
    if (queryStringObj?.string_filter) {
      setSearchValue(queryStringObj?.string_filter)
      setIsActiveSearch(true)
    }
    if (
      queryStringObj?.merchant_user_id ||
      queryStringObj?.start_date ||
      queryStringObj?.end_date
    ) {
      const data = {
        ...initialFilterTable,
        merchant_user_id: queryStringObj?.merchant_user_id,
        start_date: queryStringObj?.start_date,
        end_date: queryStringObj?.end_date,
        plant_type_id: queryStringObj?.plant_type_id
      }
      if (data) {
        setIsActiveFilter(true)
        setInitValue(data)
        if (data?.end_date && data.start_date) {
          let selectedDate =
            dateSelect(format(new Date(data.start_date), "yyyy-MM-dd")) +
            " s/d " +
            dateSelect(format(new Date(data.end_date), "yyyy-MM-dd"))
          setDateRange(selectedDate)
          setState([
            {
              startDate: new Date(data?.start_date),
              endDate: new Date(data?.end_date),
              key: "selection"
            }
          ])
        } else {
          setDateRange("")
        }
      }
    }
  }

  function checkTabActive(tab) {
    if (tab) {
      switch (tab) {
        case ROUTING_STATUS.MAC_SPRAYING_STRING.APPROVED:
          return 1
        case ROUTING_STATUS.MAC_SPRAYING_STRING.OVERDUE:
          return 2
        case ROUTING_STATUS.MAC_SPRAYING_STRING.DONE:
          return 3
        case ROUTING_STATUS.MAC_SPRAYING_STRING.CANCEL:
          return 4
        case ROUTING_STATUS.MAC_SPRAYING_STRING.REJECT:
          return 5
        case ROUTING_STATUS.MAC_SPRAYING_STRING.PENDING:
          return 0
      }
    } else {
      return 0
    }
  }

  function getTabsContents() {
    const labelList = [
      { label: t("label.pending") },
      { label: t("label.scheduled") },
      { label: t("label.overdue") },
      { label: t("label.done") },
      { label: t("label.canceled") },
      { label: t("label.rejected") }
    ]
    const componentList = [
      <MacSprayingPendingTab
        key={0}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListScheduling}
        status={checkStatusByActiveTab(tabActive)}
      />,
      <MacSprayingScheduledTab
        key={2}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListScheduling}
        status={checkStatusByActiveTab(tabActive)}
      />,
      <MacSprayingOverdueTab
        key={3}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListScheduling}
        status={checkStatusByActiveTab(tabActive)}
      />,
      <MacSprayingDoneTab
        key={4}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListScheduling}
        status={checkStatusByActiveTab(tabActive)}
      />,
      <MacSprayingCancelTab
        key={5}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListScheduling}
        status={checkStatusByActiveTab(tabActive)}
      />,
      <MacSprayingRejectedTab
        key={6}
        changePage={changePage}
        activeFilter={isActiveFilterEmptyState}
        data={dataListScheduling}
        status={checkStatusByActiveTab(tabActive)}
      />
    ]

    return {
      labels: labelList,
      contents: componentList
    }
  }

  function onSearch(values) {
    setSearchValue(values.string_filter)
    setIsActiveSearch(true)
    let param = {
      ...initialFilterTable,
      ...initValue,
      string_filter: values.string_filter
    }
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ pathname: location.pathname, search: urlParam })
  }

  function handleOpenCloseFilter(value) {
    setIsShowFilter(value)
  }

  function handleResetSearch() {
    setSearchValue("")
    setIsActiveSearch(false)
    const param = {
      ...initialFilterTable,
      ...initValue,
      string_filter: ""
    }
    const urlParam = getUrlParsingPageFilter(param)

    history.push({ search: urlParam })
  }

  function resetDate() {
    setOpenDate(false)
    setDateRange("")
    setState([
      {
        startDate: "",
        endDate: "",
        key: "selection"
      }
    ])
  }

  function onResetFilter() {
    setDateRange("")
    setIsShowFilter(false)
    setIsActiveFilter(false)
    setState({
      startDate: "",
      endDate: "",
      key: "selection"
    })
    setInitValue({
      merchant_user_id: "",
      start_date: "",
      end_date: ""
    })
    let param = {
      ...initialFilterTable,
      merchant_user_id: "",
      start_date: "",
      end_date: "",
      string_filter: searchValue
    }
    resetDate()
    const urlParam = getUrlParsingPageFilter(param)
    history.push({ search: urlParam })
  }

  const changePage = useCallback(
    (param) => {
      let urlParam
      if (param.sortField != null) {
        urlParam = getUrlParsingPageFilter({
          ...initValue,
          ...param,
          sort: param?.sortField ? param.sortField + "," + param.sortOrder : "",
          string_filter: searchValue
        })
      } else {
        urlParam = getUrlParsingPageFilter({
          ...initValue,
          ...param,
          string_filter: searchValue
        })
      }
      history.push({ pathname: location.pathname, search: urlParam })
    },
    [dispatch, searchValue, initValue]
  )

  function onSubmitFilter(e) {
    const valueArray = 0
    const { values, submited } = e
    if (submited) {
      setIsActiveFilter(true)
      setIsShowFilter(false)
      const paramData = {
        ...initialFilterTable,
        merchant_user_id: values?.merchant_user_id,

        start_date: state[valueArray].startDate
          ? format(state[valueArray].startDate, "yyyy-MM-dd")
          : "",
        end_date: state[valueArray].endDate ? format(state[valueArray].endDate, "yyyy-MM-dd") : "",
        string_filter: searchValue
      }
      if (paramData) {
        setInitValue(paramData)
        const urlParam = getUrlParsingPageFilter(paramData)
        history.push({ search: urlParam })
      }
    }
  }

  function changeTabs(data) {
    const urlParam = getUrlParsingPageFilter({
      ...initValue,
      string_filter: searchValue
    })
    history.push(RoutesConstants.MAC.LIST_SPRAYING(checkStatusByActiveTab(data) + urlParam))
  }

  function checkStatusByActiveTab(data) {
    switch (data) {
      case 1:
        return ROUTING_STATUS.MAC_SPRAYING_STRING.APPROVED
      case 2:
        return ROUTING_STATUS.MAC_SPRAYING_STRING.OVERDUE
      case 3:
        return ROUTING_STATUS.MAC_SPRAYING_STRING.DONE
      case 4:
        return ROUTING_STATUS.MAC_SPRAYING_STRING.CANCEL
      case 5:
        return ROUTING_STATUS.MAC_SPRAYING_STRING.REJECT
      case 0:
        return ROUTING_STATUS.MAC_SPRAYING_STRING.PENDING
    }
  }

  function submitLog() {
    const valueArray = 0
    let selectedDate =
      dateSelect(format(state[valueArray].startDate, "yyyy-MM-dd")) +
      " s/d " +
      dateSelect(format(state[valueArray].endDate, "yyyy-MM-dd"))
    setDateRange(selectedDate)
    setOpenDate(false)
  }

  function selectDate() {
    return (
      <>
        <label>{t("label.date")}</label>
        <div className={"d-flex justify-content-end"}>
          <div
            onClick={() => setOpenDate(!openDate)}
            className={
              "select-date d-flex justify-content-between align-items-center cursor-pointer"
            }>
            <label className={"mb-0 txt-12 cursor-pointer"}>
              {dateRange ? dateRange : "Pilih Range Tanggal"}
            </label>
            <CalenderSvgIcon />
          </div>
        </div>
      </>
    )
  }

  function filterComponent() {
    return (
      <>
        <CardHeader title={t("label.date")} className="px-0" />
        <CardBody className="px-0 py-5">
          {selectDate()}
          {openDate && dateRangePicker()}
        </CardBody>
        <Card className="card-custom card-shadowless "></Card>
      </>
    )
  }

  function dateRangePicker() {
    return (
      <div style={{ transform: `translateY(23px)` }}>
        <div className={"date-picker-custom "} style={{ fontSize: 11 }}>
          <DateRange
            className={"font-10"}
            onChange={(item) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="horizontal"
            preventSnapRefocus={true}
            calendarFocus="backwards"
          />
          <div className={"action-wrapper d-flex justify-content-end py-4 px-4 border-top"}>
            <button onClick={resetDate} type={"button"} className={"btn btn-outline-primary mx-4"}>
              {t("label.reset_date")}
            </button>
            <button onClick={submitLog} type={"button"} className={"btn btn-primary mx-4"}>
              {t("label.apply")}
            </button>
          </div>
        </div>
      </div>
    )
  }

  function summaryCard() {
    return (
      <div className={"mb-10"}>
        <Card>
          {General.loadingComponent ? (
            <div className={"d-flex p-10 align-items-center justify-content-between"}>
              {Array.from({ length: 7 }).map((item, index) => {
                return (
                  <div key={index}>
                    <Skeleton animation="wave" variant={"text"} width={100} />
                    <Skeleton animation="wave" variant={"text"} height={50} width={60} />
                  </div>
                )
              })}
            </div>
          ) : (
            <div className={"d-flex p-10 align-items-center justify-content-between"}>
              {listDataSummary.map((item, i) => (
                <div key={i}>
                  <div className={"text-black-50 mb-2"}>{t(item.label)}</div>
                  <h1>{item.value}</h1>
                </div>
              ))}
            </div>
          )}
        </Card>
      </div>
    )
  }

  return (
    <div className={"d-flex flex-column"}>
      {summaryCard()}
      <FilterCard
        // SEARCH
        isActiveSearch={isActiveSearch}
        placeholder={t("placeholder.insert_mac_username_or_request_id")}
        onSubmitForm={onSearch}
        initialValues={{ string_filter: searchValue }}
        name={"string_filter"}
        innerRef={formRef}
        trigerReset={handleResetSearch}
        // filter
        filterInnerRef={formRef}
        filterHandleSubmit={onSubmitFilter}
        filterFormInitialValues={initValue}
        filterComponent={filterComponent()}
        filterOnReset={onResetFilter}
        showFilter={isShowFilter}
        handleOpenCloseFilter={handleOpenCloseFilter}
        filterOn={isActiveFilter}
        btnDisable={btnFilterDisable}></FilterCard>
      <Card>
        <RowModule>
          <TabsWithIcon
            labelTab={getTabsContents().labels}
            componentTab={getTabsContents().contents}
            changeTab={changeTabs}
            initValue={tabActive}
          />
        </RowModule>
      </Card>
    </div>
  )
}
