import React, { useEffect, useState } from "react"
import { RowModule } from "../../../component/atoms/RowModule/RowModule.jsx"
import { Card, CardBody } from "../../../_metronic/_partials/controls"
import TableView, { formatterRowComponent } from "../../../component/atoms/Tabel"
import { PRIVILEGE_ENUM } from "../../../config/privilegeCollection"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { DetailSvgIcon } from "../../../component/atoms/Icons/SVG"
import { loadUserLogin } from "../../service/userManagementAction"
import { useDispatch, useSelector } from "react-redux"
import { getCategoryItem } from "../../../redux/actions/MasterDataAction"
import { useSubheader } from "../../../_metronic/layout"
import { linkWithCtrl } from "../../helpers/Utils"
import { useTranslation } from "react-i18next"
import { EmptyCard, TableSkeleton } from "../../../component/index.jsx"

export const CategoryItemList = ({ history }) => {
  const privileges = loadUserLogin().privilege
  const { t } = useTranslation()
  const { General, MasterData } = useSelector((state) => state)
  const [category, setCategory] = useState([])
  const dispatch = useDispatch()
  const subHeader = useSubheader()

  useEffect(() => {
    dispatch(getCategoryItem())
    subHeader.setButton(null)
    subHeader.setTitle("Kategori Item")
  }, [])

  useEffect(() => {
    if (MasterData.categoryList) {
      setCategory(
        [...MasterData.categoryList].sort((a, b) =>
          a.sub_type_category_name > b.sub_type_category_name ? 1 : -1
        )
      )
    }
  }, [MasterData])

  const uiName = (e) => {
    return (
      <>
        <p className="text-dark font-weight-bolder mb-1 font-size-lg w-100">
          {e.sub_type_category_name ?? "-"}
        </p>
      </>
    )
  }

  const uiAction = () => {
    return (
      <>
        {privileges.indexOf(PRIVILEGE_ENUM.DETAIL_AREA_REJECT) > -1 && (
          <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Detail</Tooltip>}>
            <a className="btn btn-icon btn-hover-primary btn-sm mx-3">
              <DetailSvgIcon></DetailSvgIcon>
            </a>
          </OverlayTrigger>
        )}
      </>
    )
  }

  const linkDetail = (e) => {
    history.push(
      "/master/category-item/" +
        e.type_category.toLowerCase() +
        "/" +
        e.sub_type_category.toLowerCase()
    )
  }

  const columns = [
    {
      dataField: "sub_type_category_name",
      text: "Nama Kategori",
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiName
      }
    },
    {
      dataField: "action",
      text: "ACTION",
      sort: false,
      formatter: formatterRowComponent,
      formatExtraData: {
        externalStyle: uiAction,
        onClickEvent: linkDetail
      },
      headerStyle: () => {
        return { width: "10%" }
      }
    }
  ]

  return (
    <>
      <Card>
        <RowModule>
          <Card className="card-shadowless">
            <CardBody>
              {General.loading ? (
                <TableSkeleton column={2} row={10} />
              ) : category.length === 0 ? (
                <EmptyCard body={t("messages.response.empty_state")} />
              ) : (
                ""
              )}
              <TableView
                callbackAfterPageChange={null}
                columnProperties={columns}
                dataTable={category}
                currentPage={0}
                currentElement={category.length}
                totalCount={category.length}
                loadingGetData={General.loading}
                keyField={"type_category"}
                ignoreSinglePage
                showingTotalPage={false}
                rowClasses={() => {
                  return "cursor-pointer"
                }}
                rowEvents={{
                  onClick: (e, row) => {
                    if (privileges.indexOf(PRIVILEGE_ENUM.DETAIL_AREA_REJECT) > -1) {
                      const url =
                        "/master/category-item/detail/" +
                        row.type_category.toLowerCase() +
                        "/" +
                        row.sub_type_category.toLowerCase()
                      if (e.ctrlKey) {
                        linkWithCtrl(url)
                      } else {
                        history.push(url)
                      }
                    }
                  }
                }}
              />
            </CardBody>
          </Card>
        </RowModule>
      </Card>
    </>
  )
}
