import { CreateEditFarmer } from "../app/modules/Farmer/CreateEditFarmer"
import { FarmerDetailLead } from "../app/modules/Farmer/FarmerDetailLead"
import { FarmerDetail } from "../app/modules/Farmer/FarmerDetail"
import { CreateEditMitra } from "../app/modules/Mitra/createEditMitra.jsx"
import { DetailMitra } from "../app/modules/Mitra/detail"
import { FieldOfficerFormWrapper } from "../app/modules/FieldOfficer/Form"
import { ListFieldOfficer } from "../app/modules/FieldOfficer/list"
import { DetailWrapper } from "../app/modules/FieldOfficer/Detail"
import { AssignMitraWrapper } from "../app/modules/FieldOfficer/AssignMitra"
import { ListAreaMap } from "../app/modules/Area/AreaMap"
import { ListAreaDetail } from "../app/modules/Area/AreaListDetail"
import { ListAreaLead } from "../app/modules/Area/AreaLead/AreaListLead.jsx"
import { AreaHistoryActivity } from "../app/modules/Area/AreaHistoryActivity"
import { DetailActivitySemai } from "../app/modules/Area/DetailActivity/DetailActivitySemai"
import { DetailActivityOlahTanah } from "../app/modules/Area/DetailActivity/DetailActivityOlahTanah"
import { DetailActivityPlanting } from "../app/modules/Area/DetailActivity/DetailActivityPlanting"
import { RoleList } from "../app/modules/Role/RoleList"
import { EditRole } from "../app/modules/Role/EditRole"
import { ListAccount } from "../app/modules/Account/AccountList"
import { ListAreaPending } from "../app/modules/Area/AreaPending/AreaPending.jsx"
import { ListAreaReject } from "../app/modules/Area/AreaReject/AreaListReject.jsx"
import { ListAreaDetailLead } from "../app/modules/Area/AreaListLeadDetail"
import { ListVideo } from "../app/modules/Video/VideoList"
import { CreateEditVideo } from "../app/modules/Video/CreateEditVideo"
import { ListVideoDetail } from "../app/modules/Video/VideoListDetail"
import { ListAreaUnassigned } from "../app/modules/Area/AreaUnassign/AreaListUnassigned.jsx"
import { ListAreaDetailUnassign } from "../app/modules/Area/AreaListUnassignedDetail"
import { CreateAccount } from "../app/modules/Account/AccountNew"
import { AccountDetail } from "../app/modules/Account/AccountDetail"
import { FertilizationDetail } from "../app/modules/Area/DetailActivity/FertilizationDetail"
import { SprayingDetail } from "../app/modules/Area/DetailActivity/SprayingDetail"
import { HarvestEstimationDetail } from "../app/modules/Area/DetailActivity/HarvestEstimationDetail"
import { HarvestDetail } from "../app/modules/Area/DetailActivity/HarvestDetail"
import { CategoryItemList } from "../app/modules/MasterData/CategoryItemList"
import { DetailBenih } from "../app/modules/MasterData/CategoryItemDetail/DetailBenih"
import { DetailMesin } from "../app/modules/MasterData/CategoryItemDetail/DetailMesin"
import { DetailPestisida } from "../app/modules/MasterData/CategoryItemDetail/DetailPestisida"
import { CreateBenih } from "../app/modules/MasterData/CategoryItemDetail/CreateBenih"
import { CreatePesticide } from "../app/modules/MasterData/CategoryItemDetail/CreatePesticide"
import { ManageReasonList } from "../app/modules/MasterData/ManageReasonList"
import { ManageReasonDetail } from "../app/modules/MasterData/ManageReasonDetail"
import { PackageList } from "../app/modules/MasterData/PackageList"
import { PackageDetail } from "../app/modules/MasterData/Package/PackageDetail"
import { PackageNew } from "../app/modules/MasterData/Package/PackageNew"
import { DetailPupuk } from "../app/modules/MasterData/CategoryItemDetail/DetailPupuk"
import { CreatePupuk } from "../app/modules/MasterData/CategoryItemDetail/CreatePupuk"
import BroadcastList from "../app/modules/BroadcastMessage/BroadcastList"
import BroadcastCreate from "../app/modules/BroadcastMessage/BroadcastCreate"
import BroadcastDetail from "../app/modules/BroadcastMessage/BroadcastDetail"
import { FoAgro } from "../app/modules/FieldOfficer/ListAgronomist/FoAgro"
import { CreateEditTeamDrone } from "../app/modules/FieldOfficer/ListAgronomist/TabsFoAgro/CreateEditTeamDrone"
import { DetailDroneTeam } from "../app/modules/FieldOfficer/ListAgronomist/Detail/DetailDroneTeam"
import { ICON_CONSTANS } from "../constants/ImageConstants"
import { DetailsOrder } from "../app/modules/Orders/DetailsOrder"
import ApprovalOrder from "../app/modules/Orders/ApprovalOrder"
import { ListFoHarvest } from "../app/modules/FieldOfficer/FoHarvest/ListFoHarvest"
import { RoutesConstants } from "../constants/RoutesConstants"
import { ListFoCoordinator } from "../app/modules/FieldOfficer/FoCoordinator/ListFoCoordinator"
import { FIELD_OFFICER_ROLE_ENUM, ROUTING_STATUS } from "../constants/StringConstant"
import { ListAreaActivityV2 } from "../app/modules/Area/AreaActivityV2/ListAreaActivityV2"
import { AreaListTeregistrasi } from "../app/modules/Area/AreaTeregistrasi/AreaListTeregistrasi"
import { DetailHarvestFailure } from "../app/modules/Area/DetailActivity/DetailHarvestFailure"
import { CreateFailedHarvest } from "../app/modules/Area/FailedHarvest/CreateFailedHarvest"
import { ListAreaActivityKur } from "../app/modules/Area/AreaActivityKur/ListAreaActivityKur"
import { PreviewBulkErp } from "../app/modules/Area/AreaActivityV2/TabsAreaActivity/FormBulkErp/PreviewBulkErp"
import { ListProfile } from "../app/modules/Mac/Profile/ListProfile"
import { ProfileDetail } from "../app/modules/Mac/Profile/ProfileDetail"
import { ListUser } from "../app/modules/Mac/User/ListUser"
import { UserDetail } from "../app/modules/Mac/User/UserDetail"
import { MacActivityReportList } from "../app/modules/Mac/MacActivityReport/MacActivityReportList.jsx"
import { MacSprayingPage } from "../app/modules/Mac/Spraying/MacSprayingPage.jsx"
import { MacSprayingDetail } from "../app/modules/Mac/Spraying/MacSprayingDetail.jsx"
import { DetailActivityReportMac } from "../app/modules/Mac/MacActivityReport/DetailActivityReportMac.jsx"
import { DetailPlantType } from "../app/modules/MasterData/CategoryItemDetail/DetailPlantType.jsx"
import { MacSprayingApproval } from "../app/modules/Mac/Spraying/MacSprayingApproval.jsx"
import { ListPascaTanam } from "../app/modules/Orders/PascaTanam/ListPascaTanam.jsx"
import { MasterDataRegionalPage } from "../app/modules/MasterData/regional/MasterDataRegionalPage.jsx"
import { PrePlantingSprayingPage } from "../app/modules/Orders/prePlanting/PrePlantingSprayingPage.jsx"
import { DetailPascaTanam } from "../app/modules/Orders/PascaTanam/DetailPascaTanam.jsx"
import { ApprovalPascaTanam } from "../app/modules/Orders/PascaTanam/ApprovalPascaTanam.jsx"
import { ApprovalBulkPascaTanam } from "../app/modules/Orders/PascaTanam/ApprovalBulkPascaTanam.jsx"
import { DetailPrePlantingPage } from "../app/modules/Orders/prePlanting/DetailPrePlantingPage.jsx"
import { ApprovalPrePlatingPage } from "../app/modules/Orders/prePlanting/ApprovalPrePlatingPage.jsx"
import { PrePlantingBulkApprove } from "../app/modules/Orders/prePlanting/FormPrePlanting/PrePlantingBulkApprove.jsx"
import { ListMitraV3 } from "../app/modules/Mitra/MitraListV3/ListMitraV3.jsx"
import { ListFarmerV2 } from "../app/modules/Farmer/FarmerListV2/ListFarmerV2.jsx"
import { ListMitraKurV3 } from "../app/modules/Mitra/MitraListV3/ListMitraKurV3.jsx"
import { FarmerListKur } from "../app/modules/Farmer/FarmerListV2/FarmerListKur.jsx"
import { DetailRegionalPage } from "../app/modules/MasterData/regional/DetailRegionalPage.jsx"
import { CreateRegionalPageV2 } from "../app/modules/MasterData/regional/CreateRegionalPageV2.jsx"
import { CreateSubRegionalPage } from "../app/modules/MasterData/regional/CreateSubRegionalPage.jsx"
import { CreateUpdateTreatment } from "../app/modules/MasterData/Package/CreateUpdateTreatment.jsx"
import { ListFoQaPage } from "../app/modules/FieldOfficer/FoQa/ListFoQaPage.jsx"
import { CreateSubmissionPage } from "../app/modules/Submission/CertificationBlock/CreateSubmission/CreateSubmissionPage.jsx"
import { ListCertificationBlock } from "../app/modules/Submission/CertificationBlock/List/ListCertificationBlock.jsx"
import { DetailCertificationPending } from "../app/modules/Submission/CertificationBlock/Detail/DetailCertificationPending.jsx"
import { DetailCertificationBlock } from "../app/modules/Submission/CertificationBlock/Detail/DetailCertificationBlock.jsx"

export const PRIVILEGE_ENUM = {
  //farmer
  CREATE_FARMER: "CREATE_FARMER",
  UPDATE_FARMER: "UPDATE_FARMER",
  APPROVAL_FARMER_REGISTRATION: "APPROVAL_FARMER_REGISTRATION",
  LIST_FARMER_PENDING: "LIST_FARMER_PENDING",
  LIST_FARMER_REGISTERED: "LIST_FARMER_REGISTERED",
  LIST_FARMER_REJECTED: "LIST_FARMER_REJECTED",
  DETAIL_FARMER_PENDING: "DETAIL_FARMER_PENDING",
  DETAIL_FARMER_REGISTERED: "DETAIL_FARMER_REGISTERED",
  DETAIL_FARMER_REJECTED: "DETAIL_FARMER_REJECTED",
  FARMER_BULK_INSERT: "FARMER_BULK_INSERT",
  FARMER_LEAD_LIST: "FARMER_LEAD_LIST",
  FARMER_LEAD_DETAIL: "FARMER_LEAD_DETAIL",
  FARMER_LEAD_DELETE: "FARMER_LEAD_DELETE",
  FARMER_LEAD_BULK: "FARMER_LEAD_BULK",
  FARMER_LEAD_CREATE: "FARMER_LEAD_CREATE",
  LIST_FARMER_REGISTERED_TAB_ALL: "LIST_FARMER_REGISTERED_TAB_ALL",
  LIST_FARMER_REGISTERED_TAB_FARMER: "LIST_FARMER_REGISTERED_TAB_FARMER",
  LIST_FARMER_REGISTERED_TAB_FARMER_KUR: "LIST_FARMER_REGISTERED_TAB_FARMER_KUR",

  //mitra
  CREATE_MITRA: "CREATE_MITRA",
  UPDATE_MITRA: "UPDATE_MITRA",
  APPROVAL_MITRA_REGISTRATION: "APPROVAL_MITRA_REGISTRATION",
  LIST_MITRA_PENDING: "LIST_MITRA_PENDING",
  LIST_MITRA_REGISTERED: "LIST_MITRA_REGISTERED",
  LIST_MITRA_REJECTED: "LIST_MITRA_REJECTED",
  DETAIL_MITRA_PENDING: "DETAIL_MITRA_PENDING",
  DETAIL_MITRA_REGISTERED: "DETAIL_MITRA_REGISTERED",
  DETAIL_MITRA_REJECTED: "DETAIL_MITRA_REJECTED",
  LIST_MITRA_REGISTERED_TAB_ALL: "LIST_MITRA_REGISTERED_TAB_ALL",
  LIST_MITRA_REGISTERED_TAB_MITRA: "LIST_MITRA_REGISTERED_TAB_MITRA",
  LIST_MITRA_REGISTERED_TAB_MITRA_KUR: "LIST_MITRA_REGISTERED_TAB_MITRA_KUR",

  //area
  MAP_PREVIEW: "MAP_PREVIEW",
  LIST_AREA_REGISTERED: "LIST_AREA_REGISTERED",
  AREA_DETAIL_REGISTERED: "AREA_DETAIL_REGISTERED",
  DETAIL_AREA_PENDING: "DETAIL_AREA_PENDING",
  DETAIL_AREA_PENDING_UNASSIGNED: "DETAIL_AREA_PENDING_UNASSIGNED",
  DETAIL_AREA_REJECT: "DETAIL_AREA_REJECT",
  LIST_AREA_PENDING: "LIST_AREA_PENDING",
  APPROVAL_AREA_REGISTRATION: "APPROVAL_AREA_REGISTRATION",
  LIST_AREA_REJECT: "LIST_AREA_REJECT",
  LIST_AREA_LEAD: "LIST_AREA_LEAD",
  DETAIL_AREA_LEAD: "DETAIL_AREA_LEAD",
  DETAIL_AREA_UNASSIGNED: "DETAIL_AREA_UNASSIGNED",
  LIST_AREA_UNASSIGNED: "LIST_AREA_UNASSIGNED",
  EDIT_PLANT_EVENT: "EDIT_PLANT_EVENT",
  LIST_AREA_ACTIVITY: "LIST_AREA_ACTIVITY",
  TAB_ACTIVITY: "TAB_ACTIVITY",
  DETAIL_ACTIVITY: "DETAIL_ACTIVITY",
  HARVEST_CONFIRMATION: "HARVEST_CONFIRMATION",
  TAB_ACTIVITY_HISTORY: "TAB_ACTIVITY_HISTORY",
  DETAIL_PLANTING_SEASON: "DETAIL_PLANTING_SEASON",
  HARVEST_TOTAL: "HARVEST_TOTAL",
  TOTAL_INCOME: "TOTAL_INCOME",
  DETAIL_ACTIVITY_SEEDLING: "DETAIL_ACTIVITY_SEEDLING",
  DETAIL_ACTIVITY_TILLAGE: "DETAIL_ACTIVITY_TILLAGE",
  DETAIL_ACTIVITY_PLANTING: "DETAIL_ACTIVITY_PLANTING",
  DETAIL_ACTIVITY_FERTILIZATION: "DETAIL_ACTIVITY_FERTILIZATION",
  DETAIL_ACTIVITY_SPRAYING: "DETAIL_ACTIVITY_SPRAYING",
  DETAIL_ACTIVITY_HARVEST_PREDICTION: "DETAIL_ACTIVITY_HARVEST_PREDICTION",
  DETAIL_ACTIVITY_HARVEST: "DETAIL_ACTIVITY_HARVEST",
  DETAIL_ACTIVITY_SPRAYING_PREPLANTING: "DETAIL_ACTIVITY_SPRAYING_PREPLANTING",
  DETAIL_ACTIVITY_HARVEST_FAILURE: "DETAIL_ACTIVITY_HARVEST_FAILURE",
  LIST_AREA_REGISTERED_TAB_ALL: "LIST_AREA_REGISTERED_TAB_ALL",
  LIST_AREA_REGISTERED_TAB_AREA: "LIST_AREA_REGISTERED_TAB_AREA",
  LIST_AREA_REGISTERED_TAB_AREA_KUR: "LIST_AREA_REGISTERED_TAB_AREA_KUR",
  LIST_AREA_ACTIVITY_TAB_ALL: "LIST_AREA_ACTIVITY_TAB_ALL",
  LIST_AREA_ACTIVITY_TAB_ACTIVITY: "LIST_AREA_ACTIVITY_TAB_ACTIVITY",
  LIST_AREA_ACTIVITY_TAB_ACTIVITY_KUR: "LIST_AREA_ACTIVITY_TAB_ACTIVITY_KUR",

  //fo
  LIST_FIELD_OFFICER: "LIST_FIELD_OFFICER",
  DETAIL_FIELD_OFFICER: "DETAIL_FIELD_OFFICER",
  ASSIGN_FIELD_OFFICER_TO_MITRA: "ASSIGN_FIELD_OFFICER_TO_MITRA",
  CREATE_FIELD_OFFICER: "CREATE_FIELD_OFFICER",
  EDIT_FIELD_OFFICER: "EDIT_FIELD_OFFICER",
  LIST_FIELD_OFFICER_ASSIGN: "LIST_FIELD_OFFICER_ASSIGN",
  // new
  // FO AGRONOMIS
  LIST_FIELD_OFFICER_AGRONOMIST: "LIST_FIELD_OFFICER_AGRONOMIST",
  LIST_FIELD_OFFICER_AGRONOMIST_TAB_LIST_FO: "LIST_FIELD_OFFICER_AGRONOMIST_TAB_LIST_FO",
  LIST_FIELD_OFFICER_AGRONOMIST_TAB_TEAM_DRONE: "LIST_FIELD_OFFICER_AGRONOMIST_TAB_TEAM_DRONE",
  LIST_FIELD_OFFICER_AGRONOMIST_TAB_JADWAL_TEAM: "LIST_FIELD_OFFICER_AGRONOMIST_TAB_JADWAL_TEAM",
  CREATE_AGRONOMIST_TEAM: "CREATE_AGRONOMIST_TEAM",
  EDIT_AGRONOMIST_TEAM: "EDIT_AGRONOMIST_TEAM",
  DETAIL_FIELD_OFFICER_AGRONOMIST: "DETAIL_FIELD_OFFICER_AGRONOMIST",
  DETAIL_TEAM_FIELD_OFFICER_AGRONOMIST: "DETAIL_TEAM_FIELD_OFFICER_AGRONOMIST",
  // FO COORDINATOR
  LIST_FIELD_OFFICER_COORDINATOR: "LIST_FIELD_OFFICER_COORDINATOR",
  DETAIL_FIELD_OFFICER_COORDINATOR: "DETAIL_FIELD_OFFICER_COORDINATOR",
  // FO HARVEST
  LIST_FIELD_OFFICER_HARVEST: "LIST_FIELD_OFFICER", // TODO : CHANGE ENUM PRIVILEGE
  DETAIL_FIELD_OFFICER_HARVEST: "DETAIL_FIELD_OFFICER_AGRONOMIST",
  //FO QUALITY ASSURANCE
  LIST_FIELD_OFFICER_QUALITY_ASSURANCE: "LIST_FIELD_OFFICER_QUALITY_ASSURANCE",
  DETAIL_FIELD_OFFICER_QUALITY_ASSURANCE: "DETAIL_FIELD_OFFICER_QUALITY_ASSURANCE",

  //CONFIDENTIAL_DATA
  FARMER_PHONE_NUMBER: "FARMER_PHONE_NUMBER",
  FARMER_KTP_NUMBER: "FARMER_KTP_NUMBER",
  FARMER_KTP_PICTURE: "FARMER_KTP_PICTURE",
  FARMER_KTP_SELFIE_PICTURE: "FARMER_KTP_SELFIE_PICTURE",
  FARMER_BANK_ACCOUNT_NO: "FARMER_BANK_ACCOUNT_NO",

  MITRA_PHONE_NUMBER: "MITRA_PHONE_NUMBER",
  MITRA_KTP_NUMBER: "MITRA_KTP_NUMBER",
  MITRA_KTP_PICTURE: "MITRA_KTP_PICTURE",
  MITRA_KTP_SELFIE_PICTURE: "MITRA_KTP_SELFIE_PICTURE",
  MITRA_BANK_ACCOUNT_NO: "MITRA_BANK_ACCOUNT_NO",
  AREA_CERTIFICATE: "AREA_CERTIFICATE",

  //setting
  LIST_ROLE: "LIST_ROLE",
  EDIT_ROLE_PRIVILEGE: "EDIT_ROLE_PRIVILEGE",
  RESET_USER_PASSWORD: "RESET_USER_PASSWORD",
  LIST_VIDEO: "LIST_VIDEO",
  INSERT_VIDEO: "INSERT_VIDEO",
  DETAIL_VIDEO: "DETAIL_VIDEO",
  UPDATE_VIDEO: "UPDATE_VIDEO",
  DELETE_VIDEO: "DELETE_VIDEO",
  CREATE_USER_CMS: "CREATE_USER_CMS",
  EDIT_USER_CMS: "EDIT_USER_CMS",
  DETAIL_USER_CMS: "DETAIL_USER_CMS",
  LIST_USER_CMS: "LIST_USER_CMS",
  BROADCAST_MESSAGE: "BROADCAST_MESSAGE",
  BROADCAST_MESSAGE_DETAIL: "BROADCAST_MESSAGE_DETAIL",
  BROADCAST_MESSAGE_REMOVE: "BROADCAST_MESSAGE_REMOVE",
  BROADCAST_MESSAGE_CREATE: "BROADCAST_MESSAGE_CREATE",

  //master
  CATEGORY_ITEM: "CATEGORY_ITEM",
  CATEGORY_ITEM_DETAIL: "CATEGORY_ITEM_DETAIL",
  MODIFIED_CATEGORY_ITEM: "MODIFIED_CATEGORY_ITEM",
  TREATMENT_LIST: "TREATMENT_LIST",
  TREATMENT_DETAIL: "TREATMENT_DETAIL",
  MODIFIED_TREATMENT: "MODIFIED_TREATMENT",
  TREATMENT_NEW: "TREATMENT_NEW",
  PACKAGE_LIST: "PACKAGE_LIST",
  PACKAGE_DETAIL: "PACKAGE_DETAIL",
  PACKAGE_NEW: "PACKAGE_NEW",
  MODIFIED_PACKAGE: "MODIFIED_PACKAGE",
  MANAGE_REASON_LIST: "MANAGE_REASON_LIST",
  MANAGE_REASON_DETAIL: "MANAGE_REASON_DETAIL",
  MODIFIED_MANAGE_REASON: "MODIFIED_MANAGE_REASON",
  LIST_PLANT_TYPE: "MODIFIED_MANAGE_REASON",
  EDIT_PLANT_TYPE: "MODIFIED_MANAGE_REASON",
  DETAIL_PLANT_TYPE: "MODIFIED_MANAGE_REASON",
  SUB_DETAIL_PLANT_TYPE: "MODIFIED_MANAGE_REASON",

  // ORDER
  LIST_ORDER_ALL: "LIST_ORDER_ALL",
  LIST_ORDER_APPROVE: "LIST_ORDER_APPROVE",
  LIST_ORDER_PENDING: "LIST_ORDER_PENDING",
  LIST_ORDER_RESCHEDULED: "LIST_ORDER_RESCHEDULED",
  DETAIL_ORDER: "DETAIL_ORDER",
  APPROVAL_ORDER: "APPROVAL_ORDER",
  EDIT_ORDER: "EDIT_ORDER",
  REJECT_ORDER: "REJECT_ORDER",
  CANCEL_ORDER: "CANCEL_ORDER",

  // SUBMISSION
  CERTIFICATION_LIST_SUBMISSION_ALL: "CERTIFICATION_LIST_SUBMISSION_ALL",
  CERTIFICATION_DETAIL_SUBMISSION: "CERTIFICATION_DETAIL_SUBMISSION",
  CERTIFICATION_LIST_SUBMISSION_PENDING: "CERTIFICATION_LIST_SUBMISSION_PENDING",
  CERTIFICATION_LIST_SUBMISSION_SUBMITTED: "CERTIFICATION_LIST_SUBMISSION_SUBMITTED",
  CERTIFICATION_LIST_SUBMISSION_REJECTED: "CERTIFICATION_LIST_SUBMISSION_REJECTED",
  CERTIFICATION_LIST_SUBMISSION_APPROVED: "CERTIFICATION_LIST_SUBMISSION_APPROVED",
  CERTIFICATION_DOWNLOAD_SUBMISSION: "CERTIFICATION_DOWNLOAD_SUBMISSION",
  CERTIFICATION_CREATE_SUBMISSION: "CERTIFICATION_CREATE_SUBMISSION",
  CERTIFICATION_CANCEL_SUBMISSION: "CERTIFICATION_CANCEL_SUBMISSION",

  // MAC
  DETAIL_ACTIVITY_REPORT_MAC: "CANCEL_ORDER"
}

export const PrivilegeCollection = [
  //    mitra
  {
    enum: [
      PRIVILEGE_ENUM.DETAIL_MITRA_REGISTERED,
      PRIVILEGE_ENUM.DETAIL_MITRA_PENDING,
      PRIVILEGE_ENUM.DETAIL_MITRA_REJECTED
    ],
    parent: "MITRA",
    url: RoutesConstants.MITRA.DETAIL_MITRA(":status", ":id"),
    component: DetailMitra
  },
  {
    enum: [PRIVILEGE_ENUM.UPDATE_MITRA],
    parent: "MITRA",
    url: "/mitras/action/:status/edit/:id",
    component: CreateEditMitra
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_MITRA_REGISTERED_TAB_ALL],
    parent: "MITRA",
    url: RoutesConstants.MITRA.LIST_MITRA(":tab"),
    component: ListMitraV3,
    iconMenu: ICON_CONSTANS.IC_MITRA_SMALL,
    navigateLink: RoutesConstants.MITRA.LIST_MITRA(ROUTING_STATUS.STATUS_ENUM.PENDING),
    indexMenu: 0,
    menuTitle: "sub_header.mitra_list"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_MITRA_REGISTERED_TAB_ALL],
    parent: "MITRA",
    url: RoutesConstants.MITRA.LIST_MITRA_KUR(),
    component: ListMitraKurV3,
    iconMenu: ICON_CONSTANS.IC_MITRA_SMALL,
    navigateLink: RoutesConstants.MITRA.LIST_MITRA_KUR(),
    indexMenu: 0,
    menuTitle: "label.list_mitra_ca"
  },
  //    FO
  {
    enum: [PRIVILEGE_ENUM.LIST_FIELD_OFFICER_AGRONOMIST_TAB_TEAM_DRONE],
    parent: "FO",
    url: "/field-officer/agronomist/team/detail/:id",
    component: DetailDroneTeam
  },
  {
    enum: [PRIVILEGE_ENUM.EDIT_FIELD_OFFICER],
    parent: "FO",
    url: "/field-officer/edit/:id",
    component: FieldOfficerFormWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.EDIT_AGRONOMIST_TEAM],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.EDIT_TEAM_DRONE(":action", ":id"),
    component: CreateEditTeamDrone
  },
  {
    enum: [PRIVILEGE_ENUM.CREATE_AGRONOMIST_TEAM],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.CREATE_TEAM(":action"),
    component: CreateEditTeamDrone
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_COORDINATOR],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.DETAIL_FO(":role", ":id"),
    component: DetailWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FIELD_OFFICER_COORDINATOR],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_COORDINATOR),
    component: ListFoCoordinator,
    iconMenu: ICON_CONSTANS.IC_FO,
    navigateLink: RoutesConstants.FIELD_OFFICER.LIST_FO(
      FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_COORDINATOR
    ),
    indexMenu: 0,
    menuTitle: "FO Coordinator"
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_AGRONOMIST],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.DETAIL_FO_AGRONOMIS(":role", ":id"),
    component: DetailWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FIELD_OFFICER_AGRONOMIST],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS(":tab"),
    component: FoAgro,
    iconMenu: "/media/svg/icons/Menu/ic_field_officer.svg",
    navigateLink: RoutesConstants.FIELD_OFFICER.LIST_FO_AGRONOMIS("list-fo"),
    indexMenu: 1,
    menuTitle: "FO Agronomist"
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.DETAIL_FO(":role", ":id"),
    component: DetailWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_HARVEST],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.DETAIL_FO(":role", ":id"),
    component: DetailWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FIELD_OFFICER_HARVEST],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_HARVEST),
    component: ListFoHarvest,
    iconMenu: ICON_CONSTANS.IC_FO,
    navigateLink: RoutesConstants.FIELD_OFFICER.LIST_FO(
      FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_HARVEST
    ),
    indexMenu: 2,
    menuTitle: "FO Harvest"
  },
  {
    enum: [PRIVILEGE_ENUM.EDIT_FIELD_OFFICER],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.EDIT_FO_QA(
      FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER_QA,
      ":tab",
      ":id"
    ),
    component: FieldOfficerFormWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_FIELD_OFFICER_QUALITY_ASSURANCE],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.DETAIL_FO_QA(":role", ":tab", ":id"),
    component: DetailWrapper
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FIELD_OFFICER_QUALITY_ASSURANCE],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.LIST_FO_QA(":tab"),
    component: ListFoQaPage,
    iconMenu: ICON_CONSTANS.IC_FO,
    navigateLink: RoutesConstants.FIELD_OFFICER.LIST_FO_QA(ROUTING_STATUS.STATUS_STRING.ACTIVE),
    indexMenu: 3,
    menuTitle: "FO Quality Assurance"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FIELD_OFFICER],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER),
    component: ListFieldOfficer,
    iconMenu: "/media/svg/icons/Menu/ic_field_officer.svg",
    navigateLink: RoutesConstants.FIELD_OFFICER.LIST_FO(FIELD_OFFICER_ROLE_ENUM.FIELD_OFFICER),
    indexMenu: 4,
    menuTitle: "Field Officer"
  },
  {
    enum: [PRIVILEGE_ENUM.ASSIGN_FIELD_OFFICER_TO_MITRA],
    parent: "FO",
    url: RoutesConstants.FIELD_OFFICER.ASSIGN_MITRA(),
    component: AssignMitraWrapper,
    iconMenu: "/media/svg/icons/Menu/ic_mitra.svg",
    navigateLink: RoutesConstants.FIELD_OFFICER.ASSIGN_MITRA(),
    indexMenu: 5,
    menuTitle: "Assign Mitra"
  },

  {
    enum: [PRIVILEGE_ENUM.AREA_DETAIL_REGISTERED],
    parent: "AREA",
    url: "/lahan/list/:status/:id/pending-unassigned",
    component: ListAreaDetailUnassign
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_PLANTING_SEASON],
    parent: "AREA",
    url: "/lahan/list/:status/:area_id/history-activity/:id",
    component: AreaHistoryActivity
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_SEEDLING],
    parent: "AREA",
    url: "/lahan/:status/:id/seedling/:activity_id",
    component: DetailActivitySemai
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_TILLAGE],
    parent: "AREA",
    url: "/lahan/:status/:id/tillage/:activity_id",
    component: DetailActivityOlahTanah
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_PLANTING],
    parent: "AREA",
    url: "/lahan/:status/:id/planting/:activity_id",
    component: DetailActivityPlanting
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_AREA_LEAD],
    parent: "AREA",
    url: "/lahan/list/lead/:id",
    component: ListAreaDetailLead
  },
  {
    enum: [PRIVILEGE_ENUM.AREA_DETAIL_REGISTERED],
    parent: "AREA",
    url: RoutesConstants.AREA.DETAIL_AREA(":status", ":id"),
    component: ListAreaDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_AREA_UNASSIGNED],
    parent: "AREA",
    url: "/lahan/list-unassign/:status/:id",
    component: ListAreaDetailUnassign
  },

  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_FERTILIZATION],
    parent: "AREA",
    url: "/lahan/:status/:id/fertilization/:activity_id",
    component: FertilizationDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_SPRAYING],
    parent: "AREA",
    url: "/lahan/:status/:id/spraying/:activity_id",
    component: SprayingDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_SPRAYING_PREPLANTING],
    parent: "AREA",
    url: "/lahan/:status/:id/spraying_preplanting/:activity_id",
    component: SprayingDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_HARVEST_PREDICTION],
    parent: "AREA",
    url: "/lahan/:status/:id/harvest_prediction/:activity_id",
    component: HarvestEstimationDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_HARVEST],
    parent: "AREA",
    url: "/lahan/:status/:id/harvest/:activity_id",
    component: HarvestDetail
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_HARVEST],
    parent: "AREA",
    url: "/lahan/:status/:id/harvest_failure/:activity_id",
    component: DetailHarvestFailure
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ACTIVITY_HARVEST],
    parent: "AREA",
    url: RoutesConstants.AREA.FORM_FAILED_HARVEST(":id"),
    component: CreateFailedHarvest
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_ACTIVITY],
    parent: "AREA",
    url: RoutesConstants.AREA.PREVIEW_BULK_ERP(),
    component: PreviewBulkErp
  },
  {
    enum: [PRIVILEGE_ENUM.MAP_PREVIEW],
    parent: "AREA",
    url: "/lahan/list/map",
    component: ListAreaMap,
    iconMenu: "/media/svg/icons/Menu/ic_location.svg",
    navigateLink: "/lahan/list/map",
    indexMenu: 0,
    menuTitle: "menu_aside.area_location"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_REGISTERED],
    parent: "AREA",
    url: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI(":tab"),
    component: AreaListTeregistrasi,
    iconMenu: "/media/svg/icons/Menu/ic_bullet_tick.svg",
    navigateLink: RoutesConstants.AREA.LIST_AREA_TEREGISTRASI("all"),
    indexMenu: 1,
    menuTitle: "menu_aside.area_terverifikasi"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_PENDING],
    parent: "AREA",
    url: "/lahan/list/pending",
    component: ListAreaPending,
    iconMenu: "/media/svg/icons/Menu/ic_clock.svg",
    navigateLink: "/lahan/list/pending",
    indexMenu: 2,
    menuTitle: "menu_aside.area_pending"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_PENDING],
    parent: "AREA",
    url: "/lahan/list/reject",
    component: ListAreaReject,
    iconMenu: "/media/svg/icons/Menu/ic_bullet_close.svg",
    navigateLink: "/lahan/list/reject",
    indexMenu: 3,
    menuTitle: "menu_aside.area_reject"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_UNASSIGNED],
    parent: "AREA",
    url: "/lahan/list-unassign/unassign",
    component: ListAreaUnassigned,
    iconMenu: "/media/svg/icons/Menu/ic_field_unassign.svg",
    navigateLink: "/lahan/list-unassign/unassign",
    indexMenu: 4,
    menuTitle: "menu_aside.area_unassigned"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_LEAD],
    parent: "AREA",
    url: "/lahan/list/lead",
    component: ListAreaLead,
    iconMenu: "/media/svg/icons/Menu/ic_map_lead.svg",
    navigateLink: "/lahan/list/lead",
    indexMenu: 5,
    menuTitle: "menu_aside.area_lead"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_ACTIVITY],
    parent: "AREA",
    url: RoutesConstants.AREA.LIST_AREA_ACTIVITY(":tab"),
    component: ListAreaActivityV2,
    iconMenu: "/media/svg/icons/Menu/ic_activity.svg",
    navigateLink: RoutesConstants.AREA.LIST_AREA_ACTIVITY("waiting"),
    indexMenu: 6,
    menuTitle: "menu_aside.area_activity_report"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_AREA_ACTIVITY],
    parent: "AREA",
    url: RoutesConstants.AREA.LIST_AREA_ACTIVITY_KUR(),
    component: ListAreaActivityKur,
    iconMenu: "/media/svg/icons/Menu/ic_activity.svg",
    navigateLink: RoutesConstants.AREA.LIST_AREA_ACTIVITY_KUR(),
    indexMenu: 7,
    menuTitle: "menu_aside.area_activity_report_kur"
  },
  // farmer
  {
    enum: [PRIVILEGE_ENUM.UPDATE_FARMER],
    parent: "FARMER",
    url: "/farmer/action/:status/:actionDetail/:id",
    component: CreateEditFarmer
  },
  {
    enum: [PRIVILEGE_ENUM.FARMER_LEAD_DETAIL],
    parent: "FARMER",
    url: "/farmer/list/lead/:id",
    component: FarmerDetailLead
  },
  {
    enum: [
      PRIVILEGE_ENUM.DETAIL_FARMER_REJECTED,
      PRIVILEGE_ENUM.DETAIL_FARMER_PENDING,
      PRIVILEGE_ENUM.DETAIL_FARMER_REGISTERED
    ],
    parent: "FARMER",
    url: RoutesConstants.FARMER.DETAIL_FARMER(":status", ":id"),
    component: FarmerDetail
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FARMER_REGISTERED],
    parent: "FARMER",
    url: RoutesConstants.FARMER.LIST_FARMER(":tab"),
    component: ListFarmerV2,
    iconMenu: "/media/svg/icons/Menu/ic_farmer.svg",
    navigateLink: RoutesConstants.FARMER.LIST_FARMER(ROUTING_STATUS.STATUS_ENUM.PENDING),
    indexMenu: 0,
    menuTitle: "label.farmer_list"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_FARMER_REGISTERED],
    parent: "FARMER",
    url: RoutesConstants.FARMER.LIST_FARMER_CA(),
    component: FarmerListKur,
    iconMenu: "/media/svg/icons/Menu/ic_farmer.svg",
    navigateLink: RoutesConstants.FARMER.LIST_FARMER_CA(),
    indexMenu: 0,
    menuTitle: "sub_header.farmer_list_ca"
  },

  {
    enum: [PRIVILEGE_ENUM.EDIT_ROLE_PRIVILEGE],
    parent: "SETTING",
    url: "/role/action/:type/:role/:id",
    component: EditRole
  },
  {
    enum: [PRIVILEGE_ENUM.UPDATE_VIDEO],
    parent: "SETTING",
    url: "/video/action/:actionDetail/:id",
    component: CreateEditVideo
  },
  {
    enum: [PRIVILEGE_ENUM.INSERT_VIDEO],
    parent: "SETTING",
    url: "/video/action/:actionDetail",
    component: CreateEditVideo
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_VIDEO],
    parent: "SETTING",
    url: "/video/list/:id",
    component: ListVideoDetail
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_ROLE],
    parent: "SETTING",
    url: "/role/list/:type",
    component: RoleList
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_ROLE],
    parent: "SETTING",
    url: "/role/list",
    component: RoleList,
    iconMenu: "/media/svg/icons/Code/ic_role_access.svg",
    navigateLink: "/role/list",
    indexMenu: 0,
    menuTitle: "Role List"
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_USER_CMS],
    parent: "SETTING",
    url: "/account/list/detail/:id",
    component: AccountDetail
  },
  {
    enum: [PRIVILEGE_ENUM.EDIT_USER_CMS],
    parent: "SETTING",
    url: "/account/action/:action/:id",
    component: CreateAccount
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_USER_CMS],
    parent: "SETTING",
    url: "/account/list",
    component: ListAccount,
    iconMenu: "/media/svg/icons/Menu/ic_account_cms.svg",
    navigateLink: "/account/list",
    indexMenu: 1,
    menuTitle: "User CMS"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_VIDEO],
    parent: "SETTING",
    url: "/video/list",
    component: ListVideo,
    iconMenu: "/media/svg/icons/Code/Play.svg",
    navigateLink: "/video/list",
    indexMenu: 2,
    menuTitle: "Manage Video Tutorial"
  },

  // broadcast
  {
    enum: [PRIVILEGE_ENUM.BROADCAST_MESSAGE_DETAIL],
    parent: "SETTING",
    url: "/broadcast/detail/:id",
    component: BroadcastDetail
  },
  {
    enum: [PRIVILEGE_ENUM.BROADCAST_MESSAGE_CREATE],
    parent: "SETTING",
    url: "/broadcast/new",
    component: BroadcastCreate
  },

  {
    enum: [PRIVILEGE_ENUM.BROADCAST_MESSAGE],
    parent: "SETTING",
    url: "/broadcast/list",
    component: BroadcastList,
    iconMenu: "/media/svg/icons/Detail/ic_mail.svg",
    navigateLink: "/broadcast/list",
    indexMenu: 3,
    menuTitle: "Broadcast Message"
  },
  // master
  //package
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_PACKAGE],
    parent: "MASTER",
    url: "/master/package/new",
    component: PackageNew
  },
  {
    enum: [PRIVILEGE_ENUM.PACKAGE_DETAIL],
    parent: "MASTER",
    url: RoutesConstants.MASTER_DATA.PACKAGE.DETAIL(":id"),
    component: PackageDetail
  },
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_PACKAGE],
    parent: "MASTER",
    url: "/master/package/list/edit/:id",
    component: PackageNew
  },
  {
    enum: [PRIVILEGE_ENUM.PACKAGE_LIST],
    parent: "MASTER",
    url: RoutesConstants.MASTER_DATA.REGIONAL.PAGE(),
    component: MasterDataRegionalPage,
    iconMenu: ICON_CONSTANS.IC_REGIONAL,
    navigateLink: RoutesConstants.MASTER_DATA.REGIONAL.PAGE(),
    indexMenu: 0,
    menuTitle: "menu_aside.regional"
  },
  {
    enum: [PRIVILEGE_ENUM.PACKAGE_LIST],
    parent: "MASTER",
    url: RoutesConstants.MASTER_DATA.PACKAGE.PAGE(),
    component: PackageList,
    iconMenu: ICON_CONSTANS.IC_PACKAGE,
    navigateLink: RoutesConstants.MASTER_DATA.PACKAGE.PAGE(),
    indexMenu: 1,
    menuTitle: "label.spraying_package"
  },
  //  treatment
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_TREATMENT],
    parent: "MASTER",
    url: RoutesConstants.MASTER_DATA.TREATMENT.EDIT(
      ":package_id",
      ":plant_type_id",
      ":sequence",
      ":treatment_id"
    ),
    component: CreateUpdateTreatment
  },
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_TREATMENT],
    parent: "MASTER",
    url: RoutesConstants.MASTER_DATA.TREATMENT.NEW(":package_id", ":plant_type_id", ":sequence"),
    component: CreateUpdateTreatment
  },

  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: "MASTER",
    url: RoutesConstants.MASTER_DATA.REGIONAL.ADD_REGIONAL(),
    component: CreateRegionalPageV2
  },

  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: "MASTER",
    url: RoutesConstants.MASTER_DATA.REGIONAL.CREATE_SUB_REGIONAL(":regionalId"),
    component: CreateSubRegionalPage
  },
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: "MASTER",
    url: RoutesConstants.MASTER_DATA.REGIONAL.EDIT_SUB_REGIONAL(":subRegionalId", ":regionalId"),
    component: CreateSubRegionalPage
  },

  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: "MASTER",
    url: RoutesConstants.MASTER_DATA.REGIONAL.EDIT_REGIONAL(":id"),
    component: CreateRegionalPageV2
  },
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: "MASTER",
    url: RoutesConstants.MASTER_DATA.REGIONAL.DETAIL_REGIONAL(":id"),
    component: DetailRegionalPage
  },
  //  benih
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: "MASTER",
    url: "/master/category-item/seed/new/:subType",
    component: CreateBenih
  },
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: "MASTER",
    url: "/master/category-item/fertilizer/new",
    component: CreatePupuk
  },
  {
    enum: [PRIVILEGE_ENUM.CATEGORY_ITEM_DETAIL],
    parent: "MASTER",
    url: "/master/category-item/detail/seed/:subType",
    component: DetailBenih
  },
  {
    enum: [PRIVILEGE_ENUM.CATEGORY_ITEM_DETAIL],
    parent: "MASTER",
    url: "/master/category-item/detail/fertilizer/:subType",
    component: DetailPupuk
  },
  //  pesticide
  {
    enum: [PRIVILEGE_ENUM.MODIFIED_CATEGORY_ITEM],
    parent: "MASTER",
    url: "/master/category-item/detail/pesticide/new/:subType",
    component: CreatePesticide
  },
  {
    enum: [PRIVILEGE_ENUM.CATEGORY_ITEM_DETAIL],
    parent: "MASTER",
    url: "/master/category-item/detail/pesticide/:subType",
    component: DetailPestisida
  },
  // plant type
  {
    enum: [PRIVILEGE_ENUM.DETAIL_PLANT_TYPE],
    parent: "MASTER",
    url: "/master/category-item/detail/plant_type/:subType",
    component: DetailPlantType
  },
  //  mesin
  {
    enum: [PRIVILEGE_ENUM.CATEGORY_ITEM_DETAIL],
    parent: "MASTER",
    url: "/master/category-item/detail/machine/:subType",
    component: DetailMesin
  },
  // pupuk
  {
    enum: [PRIVILEGE_ENUM.CATEGORY_ITEM],
    parent: "MASTER",
    url: "/master/category-item",
    component: CategoryItemList,
    iconMenu: "/media/svg/icons/Menu/ic_inventory.svg",
    navigateLink: "/master/category-item",
    indexMenu: 2,
    menuTitle: "Kategori Item"
  },
  //  manage reason
  {
    enum: [PRIVILEGE_ENUM.MANAGE_REASON_DETAIL],
    parent: "MASTER",
    url: "/master/manage-reason/:id",
    component: ManageReasonDetail
  },
  {
    enum: [PRIVILEGE_ENUM.MANAGE_REASON_LIST],
    parent: "MASTER",
    url: "/master/manage-reason",
    component: ManageReasonList,
    iconMenu: "/media/svg/icons/Menu/ic_idea.svg",
    navigateLink: "/master/manage-reason",
    indexMenu: 3,
    menuTitle: "Manage Reason"
  },

  // order
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "ORDER",
    url: RoutesConstants.ORDER.APPROVAL_PASCA_TANAM(":status", ":id"),
    component: ApprovalPascaTanam
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "ORDER",
    url: RoutesConstants.ORDER.APPROVAL_PRE_PLANTING(":status", ":id"),
    component: ApprovalPrePlatingPage
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "ORDER",
    url: RoutesConstants.ORDER.APPROVAL_BULK_PASCA_TANAM(),
    component: ApprovalBulkPascaTanam
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "ORDER",
    url: RoutesConstants.ORDER.APPROVAL_BULK_PRE_PLANTING(),
    component: ApprovalBulkPascaTanam
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ORDER],
    parent: "ORDER",
    url: "/order/detail/:status/:id",
    component: DetailsOrder
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ORDER],
    parent: "MAC",
    url: RoutesConstants.ORDER.DETAIL_PASCA_PLANTING(":status", ":id"),
    component: DetailPascaTanam
  },
  {
    enum: [PRIVILEGE_ENUM.DETAIL_ORDER],
    parent: "ORDER",
    url: RoutesConstants.ORDER.DETAIL_PRE_PLANTING(":status", ":id"),
    component: DetailPrePlantingPage
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_ORDER_ALL],
    parent: "ORDER",
    url: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS(":tab"),
    component: ListPascaTanam,
    iconMenu: ICON_CONSTANS.IC_ORDER_PASCA_PLANTING,
    navigateLink: RoutesConstants.ORDER.LIST_PASCA_PLANTING_BY_STATUS("pending"),
    indexMenu: 0,
    menuTitle: "menu_aside.schedule_pasca_planting"
  },
  {
    enum: [PRIVILEGE_ENUM.LIST_ORDER_ALL],
    parent: "ORDER",
    url: RoutesConstants.ORDER.LIST_PRE_PLANTING_BY_STATUS(":tab"),
    component: PrePlantingSprayingPage,
    iconMenu: ICON_CONSTANS.IC_DRONE_SPRAYING,
    navigateLink: RoutesConstants.ORDER.LIST_PRE_PLANTING_BY_STATUS("pending"),
    indexMenu: 1,
    menuTitle: "menu_aside.pre_planting"
  },

  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "ORDER",
    url: RoutesConstants.ORDER.APPROVAL(":status", ":id"),
    component: ApprovalOrder
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "ORDER",
    url: RoutesConstants.ORDER.APPROVAL_BULK(":planting"),
    component: PrePlantingBulkApprove
  },
  //   mac
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "MAC",
    url: RoutesConstants.MAC.PROFILE_DETAIL(":id"),
    component: ProfileDetail
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "MAC",
    url: RoutesConstants.MAC.DETAIL_ACTIVITY_REPORT(":id"),
    component: DetailActivityReportMac
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "MAC",
    url: RoutesConstants.MAC.USER_DETAIL(":id"),
    component: UserDetail
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "MAC",
    url: RoutesConstants.MAC.MAC_SPRAYING_APPROVAL(":status", ":id"),
    component: MacSprayingApproval
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "MAC",
    url: RoutesConstants.MAC.SPRAYING_DETAIL(":status", ":id"),
    component: MacSprayingDetail
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "MAC",
    url: RoutesConstants.MAC.LIST_PROFILE(),
    component: ListProfile,
    iconMenu: ICON_CONSTANS.IC_PROFILE_MAC,
    navigateLink: RoutesConstants.MAC.LIST_PROFILE(),
    indexMenu: 0,
    menuTitle: "menu_aside.mac_profile"
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "MAC",
    url: RoutesConstants.MAC.LIST_USER(),
    component: ListUser,
    iconMenu: ICON_CONSTANS.IC_USER_MAC,
    navigateLink: RoutesConstants.MAC.LIST_USER(),
    indexMenu: 1,
    menuTitle: "menu_aside.mac_user"
  },

  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "MAC",
    url: RoutesConstants.MAC.LIST_SPRAYING(":tab"),
    component: MacSprayingPage,
    iconMenu: ICON_CONSTANS.IC_PLANT_SPRAYING,
    navigateLink: RoutesConstants.MAC.LIST_SPRAYING(ROUTING_STATUS.MAC_SPRAYING_STRING.PENDING),
    indexMenu: 2,
    menuTitle: "menu_aside.spraying"
  },
  {
    enum: [PRIVILEGE_ENUM.APPROVAL_ORDER],
    parent: "MAC",
    url: RoutesConstants.MAC.ACTIVITY_REPORT_LIST(),
    component: MacActivityReportList,
    iconMenu: ICON_CONSTANS.IC_TASK,
    navigateLink: RoutesConstants.MAC.ACTIVITY_REPORT_LIST(),
    indexMenu: 3,
    menuTitle: "menu_aside.area_activity_report"
  },
  // ADD
  {
    enum: [PRIVILEGE_ENUM.CREATE_FARMER],
    parent: "ADD",
    url: "/farmer/action/:actionDetail",
    navigateLink: RoutesConstants.FARMER.CREATE(),
    component: CreateEditFarmer,
    iconMenu: ICON_CONSTANS.SUB_MENU_ADD.ADD_FARMER,
    title: "Petani",
    subTitle: "Tambah petani baru ke dalam sistem"
  },
  {
    enum: [PRIVILEGE_ENUM.CREATE_MITRA],
    parent: "ADD",
    url: "/mitras/action/:actionDetail",
    navigateLink: RoutesConstants.MITRA.CREATE(),
    component: CreateEditMitra,
    iconMenu: ICON_CONSTANS.SUB_MENU_ADD.ADD_MITRA,
    title: "Mitra",
    subTitle: "Tambah mitra baru ke dalam sistem"
  },
  {
    enum: [PRIVILEGE_ENUM.CREATE_FIELD_OFFICER],
    parent: "ADD",
    url: RoutesConstants.FIELD_OFFICER.CREATE_FO(),
    navigateLink: RoutesConstants.FIELD_OFFICER.CREATE_FO(),
    component: FieldOfficerFormWrapper,
    iconMenu: ICON_CONSTANS.SUB_MENU_ADD.ADD_FO,
    title: "FO (Field Officer)",
    subTitle: "Tambah FO baru ke dalam sistem"
  },
  {
    enum: [PRIVILEGE_ENUM.CREATE_USER_CMS],
    parent: "ADD",
    url: "/account/action/:actionDetail",
    navigateLink: RoutesConstants.SETTING.CREATE_USER(),
    component: CreateAccount,
    iconMenu: ICON_CONSTANS.SUB_MENU_ADD.ADD_USER_CMS,
    title: "User CMS",
    subTitle: "Tambah user CMS (admin) baru ke dalam sistem"
  },

  //   submission
  {
    enum: [PRIVILEGE_ENUM.CERTIFICATION_DETAIL_SUBMISSION],
    parent: "SUBMISSION",
    url: RoutesConstants.SUBMISSION.DETAIL_AREA_CERTIFICATION_PENDING(":id"),
    component: DetailCertificationPending
  },
  {
    enum: [PRIVILEGE_ENUM.CERTIFICATION_DETAIL_SUBMISSION],
    parent: "SUBMISSION",
    url: RoutesConstants.SUBMISSION.DETAIL_BLOCK_CERTIFICATION(":status", ":id"),
    component: DetailCertificationBlock
  },
  {
    enum: [PRIVILEGE_ENUM.CERTIFICATION_LIST_SUBMISSION_ALL],
    parent: "SUBMISSION",
    url: RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS(":tab"),
    component: ListCertificationBlock,
    iconMenu: ICON_CONSTANS.IC_SUBMISSION_AND_BLOCK,
    navigateLink: RoutesConstants.SUBMISSION.LIST_SUBMISSION_BY_STATUS("pending"),
    indexMenu: 0,
    menuTitle: "menu_aside.sertification_and_block"
  },
  {
    enum: [PRIVILEGE_ENUM.CERTIFICATION_CREATE_SUBMISSION],
    parent: "SUBMISSION",
    url: RoutesConstants.SUBMISSION.CREATE_SUBMISSION(),
    component: CreateSubmissionPage
  }
]

export const PrivilegeCollectionParent = [
  {
    parent: "FARMER",
    iconMenu: "/media/svg/icons/Menu/ic_farmer.svg",
    menuTitle: "menu.farmer"
  },
  {
    parent: "MITRA",
    iconMenu: "/media/svg/icons/Menu/ic_mitra.svg",
    menuTitle: "menu.mitra"
  },
  {
    parent: "FO",
    iconMenu: "/media/svg/icons/Menu/ic_field_officer.svg",
    menuTitle: "menu.fo"
  },
  {
    parent: "AREA",
    iconMenu: "/media/svg/icons/Menu/ic_area.svg",
    menuTitle: "menu.area"
  },
  {
    parent: "ORDER",
    iconMenu: ICON_CONSTANS.IC_MENU_CALENDAR,
    menuTitle: "menu.order"
  },
  {
    parent: "SUBMISSION",
    iconMenu: "/media/svg/icons/Menu/ic_spk.svg",
    menuTitle: "menu.submission"
  },
  {
    parent: "MAC",
    iconMenu: "/media/svg/icons/Menu/ic_mac.svg",
    menuTitle: "menu.mac"
  },
  {
    parent: "SETTING",
    iconMenu: "/media/svg/icons/Menu/ic_option.svg",
    menuTitle: "menu.setting"
  },
  {
    parent: "MASTER",
    iconMenu: "/media/svg/icons/Menu/ic_data_management.svg",
    menuTitle: "menu.master_data"
  }
]
